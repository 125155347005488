import { $UserAddPermissions, ACTIONS } from "@store/user-store";
import { ErrorIndicator, Loader } from "@ui/indicators";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import { BackButton } from "@components/back-button";
import { BodyNormal } from "@ui/fonts";
import { ColorfulButton } from "@components/common/common";
import { HeadBar } from "./head-bar";
import { TableBodyRowPosition } from "./table-body-row";
import clsx from "clsx";
import { setEmployees } from "@store/company/employees-store";
import style from "./single-position.module.scss";
import { useHistory } from "react-router-dom";
import { usePositionEmployeesQuery } from "@lib/positions";
import { usePostDocumentsQuery } from "@lib/request-training";
import { useStore } from "effector-react";

type SinglePositionPropsT = {
  departmentId: number;
  positionId: number;
  companyId: number;
  type: string;
};

export const SinglePosition: React.FC<SinglePositionPropsT> = ({
  departmentId,
  positionId,
  companyId,
  type,
}) => {
  const { isSuccess, employees, isError, isLoading } =
    usePositionEmployeesQuery({
      companyId,
      departmentId,
      positionId,
    });
  const permissions = useStore($UserAddPermissions);
  const history = useHistory();
  const usePostDocuments = usePostDocumentsQuery();
  const handleEmployeeId = (id: number) => {
    usePostDocuments.mutate(id, {
      onSuccess: () => {
        setEmployees(
          employees?.employees?.filter((employee) => {
            if (id === employee.id) {
              employee.status = "Документы отправлены";
              return employee;
            }
            return employee;
          }) ?? []
        );
      },
    });
  };

  return (
    <div className={style.wrapper}>
      <BackButton className={style.backButton} />
      {!isLoading && (
        <HeadBar
          type={type}
          departmentId={departmentId}
          positionId={positionId}
          department={employees?.department?.title}
          position={employees?.position?.title}
        />
      )}
      {permissions.hasPermission(
        ACTIONS.companyStaffing_employees_allowedToCreate
      ) && (
        <div className={clsx(style.creation_btn)}>
          <ColorfulButton
            text={"Добавить сотрудника"}
            onClick={() =>
              history.push(`/company/employees/create?startDepartment=0`)
            }
          />
        </div>
      )}

      <div>
        {isLoading ? (
          <Loader />
        ) : isError ? (
          <ErrorIndicator />
        ) : (
          <Table className={style.tableWrapper} aria-label="caption table">
            <TableHead>
              <TableRow>
                <TableCell className={style.table_head}>
                  <div className={style.table_head_wrapper}>
                    <div className={style.table_head_name}>Сотрудник</div>
                  </div>
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            {isSuccess && !!employees?.employees?.length && (
              <TableBody>
                {employees?.employees?.map((employee) => (
                  <TableBodyRowPosition
                    handleEmployeeId={handleEmployeeId}
                    employee={employee}
                    key={employee.id}
                  />
                ))}
              </TableBody>
            )}
          </Table>
        )}
        {isSuccess && !employees?.employees?.length && (
          <div className={style.list_empty}>
            <BodyNormal color={"gray-dark2"}>Список пуст</BodyNormal>
          </div>
        )}
      </div>
    </div>
  );
};

// EmployeesTable
