import {
  $RolesPermissionsStates,
  $User,
  $UserAddPermissions,
  UserPriorityEnum,
  fetchRolesPermissions,
} from "@store/user-store";
import { $Workers, $WorkersDataStates, getWorkers } from "@store/workers-store";
import { ErrorIndicator, Loader } from "@ui/indicators";
import { UserDataT, WorkerShortDataT } from "@interfaces/user";
import { useEffect, useMemo, useState } from "react";

import { CommonSearch } from "@ui/common-search";
import Select from "react-select";
import { SimpleOptionT } from "@interfaces/company/employees";
import { UserRolesTable } from "@components/tables/users-roles-tables";
import s from "@scss/pages/permissions.module.scss";
import { selectColourStyles2 } from "@components/common/common";
import { useLocation } from "react-router-dom";
import { useStore } from "effector-react";
import { useCompaniesQuery, useSelectedCompanyQuery } from "@lib/company";

export const PermissionsUsers: React.FC = () => {
  const { isFetched, error, isFetchedCompany } = useStore(
    $RolesPermissionsStates
  );
  const user = useStore($User) as UserDataT;
  const permissions = useStore($UserAddPermissions);
  const workers = useStore($Workers) as WorkerShortDataT[];
  const { isLoading } = useStore($WorkersDataStates);
  const [selectedCompany, setSelectedCompany] = useState(0);
  const [selectedRole, setSelectedRole] = useState(0);
  const [searchString, setSearchString] = useState("");

  const { company } = useSelectedCompanyQuery();
  const { companies } = useCompaniesQuery();

  const filteredWorkers: WorkerShortDataT[] = useMemo(() => {
    if (
      selectedCompany === 0 &&
      permissions.hasRequiredLevel(UserPriorityEnum.SuperAdmin)
    )
      return workers;
    if (
      selectedCompany === 0 &&
      !permissions.hasRequiredLevel(UserPriorityEnum.SuperAdmin)
    ) {
      return workers.filter((worker) => {
        let equals = false;
        worker.companies.forEach((company) => {
          if (!!companies?.find((_company) => company.id === _company.id)) {
            equals = true;
          }
        });
        return equals;
      });
    }
    const filteredWorkers = workers.filter((worker) => {
      return worker.companies
        .map((company) => company.id)
        .includes(selectedCompany);
    });
    return filteredWorkers;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workers, selectedCompany]);

  const rolesOptions: SimpleOptionT[] = useMemo(() => {
    const newRolesOptions: SimpleOptionT[] = [
      { value: 0, label: "Выбрать все роли" },
    ];
    filteredWorkers.forEach((worker) => {
      const alreadyExists = newRolesOptions.find(
        (role) => role.value === worker.role?.id
      );
      if (alreadyExists) return;
      newRolesOptions.push({
        value: worker.role?.id,
        label: worker.role?.name,
      });
    });
    if (!newRolesOptions.map((opt) => opt.value).includes(selectedRole))
      setSelectedRole(0);
    return newRolesOptions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredWorkers]);
  const location = useLocation();
  const IS_COMPANY_MODULE = !location.pathname.includes("company");
  useEffect(() => {
    if (!company) return;

    const doesNeedToFetch =
      (!isFetchedCompany &&
        location.pathname.includes("company") &&
        !isLoading) ||
      (!isFetched && !location.pathname.includes("company") && !isLoading);

    if (!isFetched && !isLoading) {
      getWorkers();
    }

    if (doesNeedToFetch) {
      fetchRolesPermissions(
        location.pathname.includes("company")
          ? {
              exactCompany: true,
              companyId: company.id,
              priority: user.role.priority,
            }
          : { priority: user.role.priority }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  const selectOptions = [
    { value: 0, label: "Все компании" },
    ...(companies?.map((company) => ({
      value: company.id,
      label: company.name,
    })) ?? []),
  ];

  if (error) return <ErrorIndicator />;
  if (isLoading) return <Loader />;

  // TODO:
  // if (!isFetched && !isFetchedCompany) return <Loader />;

  return (
    <>
      <div className={s.container}>
        <div className={s.filters}>
          {IS_COMPANY_MODULE && (
            <Select
              closeMenuOnSelect={true}
              options={selectOptions}
              isSearchable
              placeholder={"Компания"}
              menuPortalTarget={document.body}
              styles={selectColourStyles2({
                minWidth: "200px",
                overflow: "visible",
                marginRight: "10px",
              })}
              onChange={(option: { value: number; }) => setSelectedCompany(option.value)}
              theme={(theme: any) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#eaeaea",
                  primary: "#eaeaea",
                },
              })}
            />
          )}
          <Select
            closeMenuOnSelect={true}
            options={rolesOptions}
            isSearchable
            placeholder={"Роли"}
            menuPortalTarget={document.body}
            styles={selectColourStyles2({
              minWidth: "200px",
              overflow: "visible",
            })}
            onChange={(option: { value: number; }) => setSelectedRole(option.value)}
            theme={(theme: any) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: "#eaeaea",
                primary: "#eaeaea",
              },
            })}
          />
          <CommonSearch
            className="ml-auto"
            onChange={(value: any) => setSearchString(value)}
          />
        </div>
        <div className={s.table}>
          <UserRolesTable
            selectedCompany={selectedCompany}
            selectedRole={selectedRole}
            searchString={searchString}
            users={filteredWorkers}
          />
        </div>
      </div>
    </>
  );
};
