import { $UserAddPermissions, ACTIONS } from "@store/user-store";
import { Checkbox, TextField } from "@mui/material";
import { CheckboxCheckedSVG, CheckboxSVG } from "@components/svgs";
import React, { useEffect, useState } from "react";

import { BodyNormal } from "@ui/fonts";
import { Button } from "@ui/button";
import DirectoryVersionService from "@services/version-service";
import { Modal } from "@ui/modal";
import RolesPermissionsService from "@services/roles-permissions-service";
import styles from "./on-save-all.module.scss";
import { toast } from "react-hot-toast";
import { useStore } from "effector-react";
import { Loader } from "@ui/indicators";

export type SubmitObjectT = {
  name: string;
  textEmail: string;
  description: string | null;
};
type OnSaveAllProps = {
  type: "training" | "briefing" | "medical-examination";
  updateCategory: () => void;
  closeDownSave: () => void;
};

export const OnSaveAll: React.FC<OnSaveAllProps> = ({
  type,
  updateCategory,
  closeDownSave,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [listlUsers, setListlUsers] = useState<{ name: string; id: number }[]>(
    []
  );
  const permissions = useStore($UserAddPermissions);
  useEffect(() => {
    if (openModal) {
      listRoles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  const listRoles = () => {
    RolesPermissionsService.GetAllRoles((err, res) => {
      if (err || !res) {
        return Error(`При получении ${type} произошла ошибка`);
      }
      if (res.data) {
        if (res.data.length > 0) {
          const users = res.data.map((item) => ({
            id: item.id,
            name: item.name ?? "",
          }));
          setListlUsers(users);
        }
      }
    });
  };

  const [submitObject, setSubmitObject] = useState<SubmitObjectT>({
    name: "",
    textEmail: "",
    description: "",
  });
  const onSubmitSave = () => {
    if (!submitObject.name) return;

    setIsLoading(true);
    DirectoryVersionService.SaveAllChanges(
      type,
      submitObject,
      ids,
      (err, res) => {
        if (err || !res) {
          toast.error("Что-то пошло не так");
          return Error(
            "При получении списка инструктажей категорий произошла ошибка"
          );
        }
        setIsLoading(false);
        if (res.data) {
          closeDownSave();
          setOpenModal(false);
          updateCategory();
        }
      }
    );
  };

  const onCancelSubmit = () => {
    DirectoryVersionService.CancelAllChanges(type, (err) => {
      if (err) {
        return Error(`При отмене изменений ${type} произошла ошибка`);
      }
    });
    closeDownSave();
    updateCategory();
  };

  const [ids, setIds] = useState<number[]>([]);

  const onChangeCheck = (id: number) => {
    if (!!ids.find((i) => i === id)) {
      setIds(ids.filter((i) => i !== id));
    } else {
      setIds((prev) => [...prev, id]);
    }
  };

  const onCreateRow: React.ChangeEventHandler<HTMLInputElement> = ({
    target,
  }) => {
    setSubmitObject((prevState) => ({
      ...prevState,
      [target.name]:
        target.type === "number"
          ? target.value === "0"
            ? null
            : target.value
          : target.value,
    }));
  };

  return (
    <>
      {permissions.hasPermission(ACTIONS.directories_allowedToConfirmChanges) ||
      permissions.hasPermission(ACTIONS.directories_allowedToReverseChanges) ? (
        <div className={styles.save}>
          <div className={styles.buttons}>
            {permissions.hasPermission(
              ACTIONS.directories_allowedToReverseChanges
            ) && (
              <Button
                color={"white-green"}
                borderColor={"green"}
                onClick={onCancelSubmit}
              >
                <BodyNormal>Отменить</BodyNormal>
              </Button>
            )}
            {permissions.hasPermission(
              ACTIONS.directories_allowedToConfirmChanges
            ) && (
              <Button onClick={() => setOpenModal(true)}>
                <BodyNormal>Сохранить</BodyNormal>
              </Button>
            )}
          </div>
          <Modal
            open={openModal}
            onClose={() => !isLoading && setOpenModal(false)}
            className={styles.modal}
          >
            <div>
              <div className={styles.modal_head}>
                <TextField
                  style={{ width: "100%" }}
                  label={"Название версии"}
                  name={"name"}
                  required
                  variant="standard"
                  onChange={onCreateRow}
                />
              </div>
              <TextField
                style={{ width: "100%" }}
                label={"Текст письма"}
                name={"textEmail"}
                variant="standard"
                multiline
                rows={3}
                onChange={onCreateRow}
              />
              <div className={styles.modal_info}>
                <BodyNormal>
                  За более подробной информацией по изменениям вы можете
                  обратиться к администратору по почте info@0tservice.com.
                </BodyNormal>
              </div>
              <div>
                <BodyNormal color={"gray-darker2"}>
                  С уважением, Команда 0tservice.
                </BodyNormal>
              </div>
              <div className={styles.modal_check_head}>
                <div>
                  <BodyNormal>Выберите кому отправить это письмо:</BodyNormal>
                </div>
                <div className={styles.modal_check}>
                  <div className={styles.modal_boxes}>
                    {listlUsers?.length > 0 &&
                      listlUsers.map((item) => {
                        return (
                          <div
                            key={item.id}
                            onClick={() => onChangeCheck(item.id)}
                            className={styles.item_check}
                          >
                            <Checkbox
                              checked={!!ids.find((id) => id === item.id)}
                              name="select_all"
                              color="primary"
                              icon={<CheckboxSVG />}
                              checkedIcon={<CheckboxCheckedSVG />}
                            />
                            <BodyNormal className={styles.item_check_text}>
                              {item.name}
                            </BodyNormal>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className={styles.modal_button}>
                {permissions.hasPermission(
                  ACTIONS.directories_allowedToConfirmChanges
                ) && (
                  <Button disabled={isLoading} onClick={onSubmitSave}>
                    <BodyNormal>Отправить</BodyNormal>
                  </Button>
                )}
                {isLoading && (
                  <Loader width={24} height={24} autoHeight autoWidth />
                )}
              </div>
            </div>
          </Modal>
        </div>
      ) : null}
    </>
  );
};
