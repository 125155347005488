import { FC } from "react";
import { Tooltip } from "@mui/material";
import { WorkspaceFileT } from "@interfaces/company/workspace";
import { useSelectedCompanyQuery } from "@lib/company";
import {
  useArchiveWorkspaceFileMutation,
  useDeleteWorkspaceFileMutation,
  useDownloadWorkspaceFileMutation,
  useConvertWorkspaceDocumentMutation,
} from "@lib/workspace";
import { downloadBlob } from "@utils/downloadBlob";
import clsx from "clsx";
import style from "@scss/pages/company/company-workspace.module.scss";
import tableStyle from "@scss/components/tables/base-table.module.scss";
import { DocumentViewButton } from "@components/common";
import { useStore } from "effector-react";
import { $UserAddPermissions, ACTIONS } from "@store/user-store";
import { WrapperSVG } from "@components/WrapperSVG/WrapperSVG";
import { Archive, DownloadSimple, NotePencil, X, Gear } from "@phosphor-icons/react";
import { useModal } from "@modals/index";
import { useAsyncConfirm } from "@hooks/useAsyncConfirm";

export type WorkspaceFilePropsT = {
  file: Omit<WorkspaceFileT, "companyId" | "groupId">;
  groupId: number;
  directoryId: number;
  disabled?: boolean;
  isSelected: boolean;
  onSelect: (checked: boolean) => void;
};

export const WorkspaceFile: FC<WorkspaceFilePropsT> = ({
  file,
  groupId,
  directoryId,
  disabled,
  isSelected,
  onSelect,
}) => {
  const permissions = useStore($UserAddPermissions);

  const { company } = useSelectedCompanyQuery();

  const modal = useModal();
  const confirm = useAsyncConfirm();
  const type = file.path.split('.')[1].toLocaleLowerCase();

  const archiveMutation = useArchiveWorkspaceFileMutation();
  const deleteMutation = useDeleteWorkspaceFileMutation();
  const downloadMutation = useDownloadWorkspaceFileMutation();
  const convertMutation = useConvertWorkspaceDocumentMutation();

  const downloadHandler = () => {
    if (!company) return;

    downloadMutation.mutate(
      {
        companyId: company.id,
        groupId,
        directoryId,
        fileId: file.id,
      },
      {
        onSuccess: (blob) => {
          downloadBlob(blob, file.name);
        },
      }
    );
  };

  const handleArchive = () => {
    if (!company) return;

    archiveMutation.mutate({
      companyId: company.id,
      groupId,
      directoryId,
      fileId: file.id,
    });
  };

  const handleDelete = async () => {
    if (!company) return;

    const ok = await confirm({
      btnText: "Удалить",
      modalData: {
        text: `Вы уверены, что хотите удалить файл ${file.name}?`,
      },
    });
    if (!ok) return;

    deleteMutation.mutate({
      companyId: company.id,
      groupId,
      directoryId,
      fileId: file.id,
    });
  };

  const onLoadComponentError = function (errorCode: any) {
    console.log("Document error", errorCode);
  };

  const onRequestSaveAs = (event: any) => {
    if (!company) return;

    convertMutation.mutate({
      companyId: company.id,
      groupId,
      directoryId,
      fileId: file.id,
      ...event.data
    },{
      onSuccess: () => modal.close(),
    });
  }

  return (
    <tr>
      <td>
        <span
          className={clsx(
            tableStyle.column_fixed_height,
            tableStyle.checkboxLabel
          )}
        >
          <input
            type="checkbox"
            className={clsx(tableStyle.checkbox_item)}
            hidden
            checked={isSelected}
            onChange={(e) => onSelect(e.target.checked)}
            disabled={disabled}
            name=""
            id={file.name}
          />
          <label htmlFor={file.name}>
            <svg
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </label>
          { type && (type === 'docx' || type === 'xlsx') && (
            <img src={`/img/static/${type}.svg`} alt="" className={clsx(style.file_icon)} style={{ width: 32, height: 32 }} />
          )}
          { type && type === 'pdf' && (
            <img src="/img/static/pdf.png" alt="" className={clsx(style.file_icon)} style={{ width: 32, height: 32 }} />
          )}
          { type && type !== 'xlsx' && type !== 'docx' && type !== 'pdf' && (
            <svg
              className={clsx(style.file_icon)}
              width="32"
              height="32"
              style={{ minWidth: "32px" }}
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 0C6.9 0 6 0.9 6 2V30C6 31.1 6.9 32 8 32H28C29.1 32 30 31.1 30 30V8L22 0H8Z"
                fill="#DFE3F1"
              />
              <path d="M24 8H30L22 0V6C22 7.1 22.9 8 24 8Z" fill="#B0B7BD" />
              <path d="M30 14L24 8H30V14Z" fill="#CAD1D8" />
            </svg>
          )}
          {permissions.hasPermission(ACTIONS.companyWorkspace_files_allowedToEdit) &&
            (type === 'docx' || type === 'xlsx') ? (
            <button
              className={clsx(tableStyle.nameFile, style.workspaceFileName)}
              disabled={disabled}
              onClick={() => {
                modal.open("DocumentEditorModal", {
                  btnText: "Сохранить",
                  modalData: {
                    file: {
                      type: file.path.split('.')[1].toLocaleLowerCase(),
                      name: file.name,
                      path: `uploads/companies/${company?.id}/workspace/${file.path}`,
                      key: `${file.path}_${company?.id}`,
                    },
                    onLoadComponentError: onLoadComponentError,
                    onRequestSaveAs: onRequestSaveAs
                  },
                });
              }}
            >
              {file.name}
            </button>
          ) : (
            <label
              htmlFor={`key`}
              className={clsx(tableStyle.nameFile, style.workspaceFileName)}
            >
              {file.name}
            </label>
          )}
        </span>
      </td>
      <td>
        <div className={clsx(style.table_buttons)}>
          {(type === 'jpeg' || type === 'jpg' || type === 'png' || type === 'pdf') && (
            <Tooltip title="Посмотреть" placement="top">
              <button>
                <DocumentViewButton
                  fileName={file.name}
                  filePath={`uploads/companies/${company?.id}/workspace/${file.path}`}
                />
              </button>
            </Tooltip>
          )}
          {permissions.hasPermission(ACTIONS.companyWorkspace_files_allowedToEdit) &&
            (type === 'docx' || type === 'xlsx') && (
            <>
              <Tooltip title="Редактировать" placement="top">
                <button
                  disabled={disabled}
                  onClick={() => {
                    modal.open("DocumentEditorModal", {
                      btnText: "Сохранить",
                      modalData: {
                        file: {
                          type: file.path.split('.')[1].toLocaleLowerCase(),
                          name: file.name,
                          path: `uploads/companies/${company?.id}/workspace/${file.path}`,
                          key: `${file.path}_${company?.id}`,
                        },
                        onLoadComponentError: onLoadComponentError,
                        onRequestSaveAs: onRequestSaveAs
                      },
                    });
                  }}
                >
                  <WrapperSVG color={"orange"}>
                    <NotePencil size={24} alt="Редактировать" />
                  </WrapperSVG>
                </button>
              </Tooltip>

              <Tooltip title="Изменить" placement="top">
                <button
                  disabled={disabled}
                  onClick={() => {
                    modal.open("EditWorkspaceDocumentModal", {
                      btnText: "Сохранить",
                      modalData: {
                        modalTitle: "Изменить файл",
                        item: file,
                      },
                    });
                  }}
                >
                  <WrapperSVG color={"gray-dark"}>
                    <Gear size={24} alt="Изменить" />
                  </WrapperSVG>
                </button>
              </Tooltip>
              {/* Скрыл эту кнопку, пока не удаляй */}
              {/* <Tooltip title="Редактировать документ" placement="top"> */}
              {/*   <button> */}
              {/*     <a */}
              {/*       href={fullEditorUrl} */}
              {/*       target={"_blank"} */}
              {/*       rel={"noreferrer"} */}
              {/*     > */}
              {/*       <img */}
              {/*         src={EditDocumentIcon} */}
              {/*         alt="Редактировать" */}
              {/*         style={{ width: 19, height: 19, opacity: 0.75 }} */}
              {/*       /> */}
              {/*     </a> */}
              {/*   </button> */}
              {/* </Tooltip> */}
            </>
          )}
          {file.path ? (
            <Tooltip title="Скачать" placement="top">
              <button onClick={() => !disabled && downloadHandler()}>
                <WrapperSVG color={"green-light"}>
                  <DownloadSimple size={24} />
                </WrapperSVG>
              </button>
            </Tooltip>
          ) : null}
          {permissions.hasPermission(
            ACTIONS.companyWorkspace_files_allowedToArchive
          ) && (
            <Tooltip title="В архив" placement="top">
              <button disabled={disabled} onClick={handleArchive}>
                <WrapperSVG color="red">
                  <Archive size={24} />
                </WrapperSVG>
              </button>
            </Tooltip>
          )}
          {permissions.hasPermission(
            ACTIONS.companyWorkspace_files_allowedToDelete
          ) && (
            <Tooltip title="Удалить" placement="top">
              <button disabled={disabled} onClick={handleDelete}>
                <X size={24} />
              </button>
            </Tooltip>
          )}
        </div>
      </td>
    </tr>
  );
};
