import {
  $UserAddPermissions,
  ACTIONS,
  UserPriorityEnum,
} from "@store/user-store";
import { Archive, NotePencil } from "@phosphor-icons/react";

import { BodyNormal } from "@ui/fonts";
import { Button } from "@ui/button";
import { EmployeeListDataT } from "@interfaces/company/employees";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Tooltip } from "@mui/material";
import { WarnSVG } from "@components/svgs";
import { WrapperSVG } from "@components/WrapperSVG/WrapperSVG";
import clsx from "clsx";
import styles from "./table-body-row-position.module.scss";
import { useAddToArchiveEmployeeMutation } from "@lib/positions";
import { useHistory } from "react-router-dom";
import { useStore } from "effector-react";

type TableBodyRowPositionPropsT = {
  handleEmployeeId: (id: number) => void;
  employee: EmployeeListDataT;
};

export const TableBodyRowPosition: React.FC<TableBodyRowPositionPropsT> = ({
  employee,
  handleEmployeeId,
}) => {
  const history = useHistory();
  const permissions = useStore($UserAddPermissions);
  const arhiveEmployee = useAddToArchiveEmployeeMutation();

  const handleAddToArchive = (employeeId: number) => {
    arhiveEmployee.mutate({ employeeId });
  };

  return (
    <>
      {!employee?.isArchived && (
        <TableRow>
          <TableCell
            align="left"
            className={styles.cell}
            onClick={() => history.push(`/company/employees/${employee.id}`)}
          >
            <div className={styles.username}>
              <BodyNormal>{employee.name}</BodyNormal>
            </div>
          </TableCell>
          <TableCell
            align="right"
            onClick={() => history.push(`/company/employees/${employee.id}`)}
          >
            <div className={styles.filed}>
              {!employee.isDataFilled && (
                <>
                  <WarnSVG />
                  <BodyNormal color="red">
                    Пожалуйста, заполните персональные данные по сотруднику
                  </BodyNormal>
                </>
              )}
            </div>
          </TableCell>
          <TableCell align="left">
            <div className={styles.options}>
              <Button
                onClick={() => handleEmployeeId(employee.id)}
                disabled={employee.status !== "Отправить документы"}
              >
                {employee.status}
              </Button>
              <div className={styles.archive}>
                {!permissions.hasRequiredLevel(
                  UserPriorityEnum.Client
                ) ? null : (
                  <div className={clsx(styles.employee_control_col)}>
                    {permissions.hasPermission(
                      ACTIONS.companyStaffing_employees_allowedToEdit
                    ) && (
                      <Tooltip title="Изменить" placement="top">
                        <WrapperSVG color={"orange"} margin>
                          <NotePencil
                            size={24}
                            onClick={() =>
                              history.push(`/company/employees/${employee.id}`)
                            }
                          />
                        </WrapperSVG>
                      </Tooltip>
                    )}

                    {permissions.hasPermission(
                      ACTIONS.companyStaffing_employees_allowedToArchive
                    ) && (
                      <Tooltip title="В архив" placement="top">
                        <WrapperSVG color="red">
                          <Archive
                            size={24}
                            onClick={() => handleAddToArchive(employee.id)}
                          />
                        </WrapperSVG>
                      </Tooltip>
                    )}
                  </div>
                )}
              </div>
            </div>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
