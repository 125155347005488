import { Box, Checkbox, Group, Text, clsx } from "@mantine/core";
import {
  ButtonBase,
  Theme,
  Typography
} from "@mui/material";
import { makeStyles, createStyles } from '@mui/styles';
import { EmployeeListDataT, PostUserData } from "@interfaces/company/employees";
import {
  PositionDataT,
  DepartmentShortDataT,
} from "@interfaces/company/departments";
import React, { useEffect, useMemo } from "react";
import { selectColourStyles, selectTheme } from "@components/common/common";

import Select from "react-select";
import { getNoun } from "@utils/getNoun";
import { useEmployeeQuery } from "@lib/employees";
import { useModal } from "@modals/index";

interface EmployeePositionProps {
  departmentsData: {
    value: number;
    label: string;
    data: DepartmentShortDataT;
  }[];
  userData: PostUserData;
  user?: EmployeeListDataT;
  errors: { [key: string]: string };
  setErrors: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
  setUserData: React.Dispatch<React.SetStateAction<PostUserData>>;
}

const useFormStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputBase: {
      backgroundColor: "#F5F6FA",
    },
    first: {
      marginTop: 0,
    },
    label: {
      fontSize: "0.75rem",
      color: theme.palette.primary.main,
    },
    root: {
      backgroundColor: "#F5F6FA",
      padding: "10px 12px",
      borderRadius: 5,
      width: "100%",
      maxWidth: 420,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      textAlign: "start",
    },
    buttonError: {
      border: `1px solid ${theme.palette.error.main}`,
    },
    labelError: {
      color: theme.palette.error.main,
    },
    buttonDisabled: {
      opacity: 0.7,
    },
    labelDisabled: {
      color: theme.palette.grey[700],
    },
  })
);

export const EmployeePosition: React.FC<EmployeePositionProps> = ({
  departmentsData,
  userData,
  user,
  errors,
  setErrors,
  setUserData,
}) => {
  const classes = useFormStyles();
  const { open } = useModal();

  const { employee } = useEmployeeQuery(user?.id);

  const correctPosition = userData.position?.parentId || userData.position?.id;
  const correctDepartment =
    userData?.position?.department?.id ?? user?.position?.department?.id;

  const selectedPositionId =
    correctPosition !== undefined ? correctPosition : null;
  const selectedDepartmentId =
    correctDepartment !== undefined ? correctDepartment : null;

  const positions = useMemo(() => {
    const selectedDepartment =
      departmentsData?.find((item) => item.value === selectedDepartmentId) ??
      null;
    if (!selectedDepartment) return [];

    return selectedDepartment.data.positions.map((item) => ({
      value: item.id,
      label: item.title,
      data: item,
    }));
  }, [departmentsData, selectedDepartmentId]);

  const positionValue =
    correctPosition !== undefined
      ? positions.find((position) => position.value === correctPosition)
      : null;

  const handleDepartmentChange = (
    value: { value: number; label: string; data: DepartmentShortDataT } | null
  ) => {
    if (value?.value) {
      setErrors({});
      setUserData((prev) => ({
        ...prev,
        position: {
          id: correctPosition ?? -1,
          title: prev.position?.title ?? "",
          parentId: prev.position?.parentId ?? null,
          department: {
            id: value.value,
            title: prev.position?.department?.title ?? "",
          },
        },
      }));
    }
  };

  const handlePositionChange = (
    value: { value: number; label: string; data: PositionDataT } | null
  ) => {
    setErrors({});
    if (value?.value) {
      setUserData((prev) => ({
        ...prev,
        trainingPrograms:
          value.data.trainingPrograms?.map((item) => ({
            id: item.Pos_TrProg.trainingProgramId,
            location: item.Pos_TrProg.location,
          })) ??
          prev.trainingPrograms ??
          [],
        briefingPrograms:
          value.data.briefingPrograms?.map(
            (item) => item.Pos_BriProg.briefingProgramId
          ) ??
          prev.briefingPrograms ??
          [],
        medicalExaminationFactors: value.data.medicalExaminationFactors?.map(
          (item) => item.id
        ),
        positionId: value.value,
        position: {
          id: value.value,
          title: value.data.title,
          parentId: value.data.parentId,
          department: {
            id: prev.position?.department.id ?? 0,
            title: prev.position?.department.title ?? "",
          },
        },
      }));
    }
  };

  const handleChangeCustomPosition = () => {
    const selectedPosition = positions.find(
      ({ data }) => data.id === userData.positionId
    )?.data;

    const baseTrainingPrograms = selectedPosition
      ? selectedPosition.trainingPrograms?.map((item) => ({
          id: item.Pos_TrProg.trainingProgramId,
          location: item.Pos_TrProg.location,
        }))
      : [];

    const baseBriefingPrograms = selectedPosition
      ? selectedPosition.briefingPrograms?.map(
          (item) => item.Pos_BriProg.briefingProgramId
        )
      : [];

    const baseMedicalExams = selectedPosition?.medicalExaminationFactors.map(
      (item) => item.id
    );

    setUserData((prev) => ({
      ...prev,
      isCustomPosition: !prev.isCustomPosition,
      trainingPrograms: prev.isCustomPosition
        ? baseTrainingPrograms
        : prev.trainingPrograms,
      briefingPrograms: prev.isCustomPosition
        ? baseBriefingPrograms
        : prev.briefingPrograms,
      medicalExaminationFactors: prev.isCustomPosition
        ? baseMedicalExams
        : prev.medicalExaminationFactors,
    }));
  };

  useEffect(() => {
    if (!employee) {
      // Если мы создаём сотрудника, то берём изначальные программы и инструктажи из должности
      const department = departmentsData?.find(
        (department) => department.value === correctDepartment
      );
      const position = department?.data.positions?.find(
        (position) => position.id === correctPosition
      );
      if (!position) return;
      setUserData((prev) => ({
        ...prev,
        trainingPrograms: position.trainingPrograms.map((item) => ({
          id: item.Pos_TrProg.trainingProgramId,
          location: item.Pos_TrProg.location,
        })),
        briefingPrograms: position.briefingPrograms.map(
          (item) => item.Pos_BriProg.briefingProgramId
        ),
        medicalExaminationFactors: position.medicalExaminationFactors.map(
          (item) => item.id
        ),
      }));
      return;
    }

    setUserData((prev) => ({
      ...prev,
      trainingPrograms: employee.position.trainingPrograms?.map((item) => ({
        id: item.Pos_TrProg.trainingProgramId,
        location: item.Pos_TrProg.location,
      })),
      briefingPrograms: employee.position.briefingPrograms?.map(
        (item) => item.Pos_BriProg.briefingProgramId
      ),
      medicalExaminationFactors: employee.medicalExaminationControls?.map(
        (item) => item.factorId
      ),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employee]);

  const setProgramsValue = (
    trainingPrograms: { id: number; location: string }[],
    instructionPrograms: number[],
    medicalExaminationFactors: number[]
  ) => {
    setUserData((prev) => ({
      ...prev,
      trainingPrograms: trainingPrograms,
      briefingPrograms: instructionPrograms,
      medicalExaminationFactors,
    }));
  };

  return (
    <>
      <Box>
        <Select
          closeMenuOnSelect={true}
          value={
            departmentsData?.find(
              (item) => item.value === selectedDepartmentId
            ) ?? null
          }
          onChange={handleDepartmentChange}
          options={departmentsData}
          isSearchable
          menuPortalTarget={document.body}
          placeholder={
            <Group spacing={1}>
              <Text>Подразделение</Text>
              <Text size="xs">*</Text>
            </Group>
          }
          styles={selectColourStyles(
            !!errors.positionId &&
              !selectedDepartmentId && { border: "1px solid red" }
          )}
          theme={selectTheme}
          menuPlacement="auto"
        />
        {!!errors.positionId && !selectedDepartmentId && (
          <Text color="#f44336" m="3px 14px 0" size="xs">
            Выберите подразделение
          </Text>
        )}
      </Box>
      {selectedDepartmentId ? (
        <Box>
          <Select
            isDisabled={!selectedDepartmentId}
            closeMenuOnSelect={true}
            value={
              positions.find((item) => item.value === selectedPositionId) ??
              null
            }
            onChange={handlePositionChange}
            options={positions}
            isSearchable
            placeholder={
              <Group spacing={1}>
                <Text>Должность</Text>
                <Text size="xs">*</Text>
              </Group>
            }
            styles={selectColourStyles(
              !!errors.positionId && { border: "1px solid #f44336" }
            )}
            theme={selectTheme}
            menuPlacement="auto"
          />
          {!!errors.positionId && (
            <Text color="#f44336" m="3px 14px 0" size="xs">
              Выберите должность
            </Text>
          )}
        </Box>
      ) : null}
      {userData.positionId ? (
        <>
          <Checkbox
            disabled={correctPosition === undefined}
            label="Назначить дополнительные программы обучения и инструктажи"
            checked={userData.isCustomPosition}
            onChange={handleChangeCustomPosition}
            styles={{
              body: { borderColor: "#00B856", alignItems: "center" },
              input: {
                borderColor: "#00B856",
                "&:checked": {
                  backgroundColor: "#00B856",
                  borderColor: "#00B856",
                },
              },
            }}
          />
          <ButtonBase
            style={{ marginTop: 26 }}
            className={clsx(
              classes.root,
              errors.trainingPrograms && classes.buttonError,
              !selectedPositionId && classes.buttonDisabled
            )}
            disabled={!selectedPositionId}
            onClick={() =>
              open("CreatePositionModal", {
                btnText: userData.isCustomPosition ? "Сохранить" : "Закрыть",
                modalData: {
                  modalTitle: "Перечень назначенных программ обучения",
                  fieldTitle: "Название",
                  defaultModalType: "learn",
                  position: positionValue,
                  trainingPrograms: userData?.trainingPrograms,
                  briefingPrograms: userData?.briefingPrograms,
                  medicalExams: userData?.medicalExaminationFactors,
                  isUser: true,
                  disabledCustom: !userData.isCustomPosition,
                  setProgramsValue: setProgramsValue,
                },
              })
            }
          >
            <Typography
              className={clsx(
                classes.label,
                errors.trainingPrograms && classes.labelError,
                !selectedPositionId && classes.labelDisabled
              )}
            >
              Программы обучения
            </Typography>
            <Typography>
              {!!userData?.trainingPrograms &&
              userData?.trainingPrograms.length === 0 &&
              userData.isCustomPosition
                ? "Добавить перечень требуемых программ обучения"
                : `${getNoun(
                    userData?.trainingPrograms?.length ?? 0,
                    "Добавлена",
                    "Добавлено",
                    "Добавлено"
                  )} ${userData?.trainingPrograms?.length ?? 0} ${getNoun(
                    userData?.trainingPrograms?.length ?? 0,
                    "программа",
                    "программы",
                    "программ"
                  )}`}
            </Typography>
          </ButtonBase>
          <ButtonBase
            disabled={!selectedPositionId}
            className={clsx(
              classes.root,
              errors.trainingPrograms && classes.buttonError,
              !selectedPositionId && classes.buttonDisabled
            )}
            onClick={() =>
              open("CreatePositionModal", {
                btnText: userData.isCustomPosition ? "Сохранить" : "Закрыть",
                modalData: {
                  modalTitle: "Перечень назначенных инструктажей",
                  fieldTitle: "Название",
                  defaultModalType: "instruction",
                  position: positionValue,
                  trainingPrograms: userData?.trainingPrograms,
                  briefingPrograms: userData?.briefingPrograms,
                  medicalExams: userData?.medicalExaminationFactors,
                  isUser: true,
                  disabledCustom: !userData.isCustomPosition,
                  setProgramsValue: setProgramsValue,
                },
              })
            }
          >
            <Typography
              className={clsx(
                classes.label,
                errors.trainingPrograms && classes.labelError,
                !selectedPositionId && classes.labelDisabled
              )}
            >
              Инструктажи
            </Typography>
            <Typography>
              {!!userData?.briefingPrograms &&
              userData?.briefingPrograms?.length === 0 &&
              userData.isCustomPosition
                ? "Добавить перечень требуемых инструктажей"
                : `${getNoun(
                    userData?.briefingPrograms?.length ?? 0,
                    "Добавлен",
                    "Добавлено",
                    "Добавлено"
                  )} ${userData?.briefingPrograms?.length ?? 0} ${getNoun(
                    userData?.briefingPrograms?.length ?? 0,
                    "инструктаж",
                    "инструктажа",
                    "инструктажей"
                  )}`}
            </Typography>
          </ButtonBase>
          <ButtonBase
            disabled={!selectedPositionId}
            className={clsx(
              classes.root,
              errors.medicalExaminationFactors && classes.buttonError,
              !selectedPositionId && classes.buttonDisabled
            )}
            onClick={() =>
              open("CreatePositionModal", {
                btnText: userData.isCustomPosition ? "Сохранить" : "Закрыть",
                modalData: {
                  modalTitle: "Перечень назначенных медицинских осмотров",
                  fieldTitle: "Название",
                  defaultModalType: "medicalexams",
                  position: positionValue,
                  trainingPrograms: userData?.trainingPrograms,
                  briefingPrograms: userData?.briefingPrograms,
                  medicalExams: userData?.medicalExaminationFactors,
                  isUser: true,
                  disabledCustom: !userData.isCustomPosition,
                  setProgramsValue: setProgramsValue,
                },
              })
            }
          >
            <Typography
              className={clsx(
                classes.label,
                errors.trainingPrograms && classes.labelError,
                !selectedPositionId && classes.labelDisabled
              )}
            >
              Медицинские осмотры
            </Typography>
            <Typography>
              {!!userData?.medicalExaminationFactors &&
              userData?.medicalExaminationFactors?.length === 0 &&
              userData.isCustomPosition
                ? "Добавить перечень требуемых медицинских осмотров"
                : `${getNoun(
                    userData?.medicalExaminationFactors?.length ?? 0,
                    "Добавлен",
                    "Добавлено",
                    "Добавлено"
                  )} ${
                    userData?.medicalExaminationFactors?.length ?? 0
                  } ${getNoun(
                    userData?.medicalExaminationFactors?.length ?? 0,
                    "осмотр",
                    "осмотра",
                    "осмотров"
                  )}`}
            </Typography>
          </ButtonBase>
        </>
      ) : null}
    </>
  );
};
