import { BodyBold, BodyNormal } from "@ui/fonts";

import { Button } from "@ui/button";
import { Modal } from "@ui/modal";
import ProcedureService from "@services/programs-service";
import { TextField } from "@mui/material";
import { pushDirectoryProgramRowInstructionsTable } from "@store/directory-programm-instuctions";
import { pushDirectoryProgramRowTrainingTable } from "@store/directory-programm-training";
import styles from "./add-row-table.module.scss";
import { useState } from "react";

type AddRowTableProps = {
  id?: number;
  title?: string;
  changeId?: number;
  changeTitle?: string;
  open: boolean;
  onClose: () => void;
  changeAction?: "Добавлено" | "Изменено" | "Удалено";
  type: "training" | "briefing" | "medical-examination";
};

type InputRows = {
  title: string;
  termPrimaryConducting: number | null;
  period: number | null;
};

export const AddRowTable: React.FC<AddRowTableProps> = ({
  changeAction,
  open,
  id,
  title,
  changeId,
  changeTitle,
  onClose,
  type,
}) => {
  const [value, setValue] = useState<InputRows>({
    title: "",
    termPrimaryConducting: 0,
    period: 0,
  });

  const onCreateRow: React.ChangeEventHandler<HTMLInputElement> = ({
    target,
  }) => {
    let inputValue: string | number | null = target.value;
    if (target.type === "number") {
      inputValue = parseInt(inputValue, 10);
      if (isNaN(inputValue)) {
        inputValue = "";
      } else if (inputValue > 9999) {
        return inputValue;
      }
    }

    setValue((prevState) => ({
      ...prevState,
      [target.name]: inputValue,
    }));
  };

  const handlerPostRowTable = () => {
    if (!value.title.trim()) return;

    // NOTE: doing it because inputs swapped between 2 tables
    const period =
      type === "training" ? value.period : value.termPrimaryConducting;
    const termPrimaryConducting =
      type === "training" ? value.termPrimaryConducting : value.period;

    ProcedureService.PostProgramRowTable(
      id || changeId,
      type,
      { title: value.title, period, termPrimaryConducting },
      (err, res) => {
        if (err || !res) {
          return Error("При создании программы обучения произошла ошибка");
        }
        if (res.data) {
          onClose();
          if (type === "training") {
            pushDirectoryProgramRowTrainingTable({ programs: res.data });
          }
          if (type === "briefing") {
            pushDirectoryProgramRowInstructionsTable({ programs: res.data });
          }
        }
      }
    );
  };

  return (
    <Modal open={open} onClose={onClose} className={styles.modal}>
      <div className={styles.modal_head}>
        <BodyBold weight={"bold"}>
          {changeTitle ?? title}
          <br />
          {changeTitle && title && changeAction === "Изменено"
            ? ` (${title})`
            : ""}
        </BodyBold>
      </div>
      <div className={styles.modal_input}>
        <TextField
          required
          autoFocus
          multiline
          rows={3}
          name={"title"}
          type={"text"}
          style={{ width: "100%", backgroundColor: "white" }}
          onChange={onCreateRow}
          placeholder={
            type === "training"
              ? "Наименование программы..."
              : "Вид инструктажа..."
          }
          value={value?.title ?? ""}
          variant="standard"
        />

        <TextField
          value={value?.termPrimaryConducting ?? ""}
          style={{ width: "100%", backgroundColor: "white" }}
          inputProps={{
            max: 9999,
            inputMode: "numeric",
            pattern: "[0-9]*",
          }}
          type={"number"}
          label={`${
            type === "training" ? "Первичное обучение" : "Периодичность"
          }`}
          name={"termPrimaryConducting"}
          variant="filled"
          onChange={onCreateRow}
        />
        <TextField
          style={{ width: "100%", backgroundColor: "white" }}
          value={value?.period ?? ""}
          inputProps={{
            max: 9999,
            inputMode: "numeric",
            pattern: "[0-9]*",
          }}
          type={"number"}
          label={`${
            type === "training" ? "Повторное обучение" : "Срок проведения"
          }`}
          name={"period"}
          variant="filled"
          onChange={onCreateRow}
        />
      </div>
      <div className={styles.modal_buttons}>
        <Button color={"white-green"} borderColor={"green"} onClick={onClose}>
          <BodyNormal>Отменить</BodyNormal>
        </Button>
        <Button onClick={handlerPostRowTable}>
          <BodyNormal>Добавить</BodyNormal>
        </Button>
      </div>
    </Modal>
  );
};
