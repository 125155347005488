import { FC, useState } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { MedicalExaminationFactorT } from "@interfaces/medical-examination";
import { Checkbox, Tooltip } from "@mui/material";
import { TextField } from "@mui/material";
import { useStore } from "effector-react";
import { $UserAddPermissions, ACTIONS } from "@store/user-store";
import { DeleteCrossSVG } from "@components/svgs/delete-cross";
import { PencilSVG } from "@components/svgs";
import styles from "./medical-examination-factor.module.scss";
import { Button } from "@ui/button";
import { useForm } from "react-hook-form";
import { useUpdateMedicalExaminationFactorMutation } from "@lib/medical-examination";
import clsx from "clsx";
import { switchColor } from "@utils/switch-color";

type FormFields = Pick<
  MedicalExaminationFactorT,
  | "title"
  | "inspectionFrequency"
  | "medicalSpecialists"
  | "laboratoryFunctionalStudies"
>;

export type MedicalExaminationFactorPropsT = {
  factor: MedicalExaminationFactorT;
  index: number;
  selected: boolean;
  onSelect: (checked: boolean) => void;
  onDelete: () => void;
};

export const MedicalExaminationFactor: FC<MedicalExaminationFactorPropsT> = ({
  factor,
  index,
  selected,
  onSelect,
  onDelete,
}) => {
  const permissions = useStore($UserAddPermissions);

  const updateFactorMutation = useUpdateMedicalExaminationFactorMutation();

  const [isEditing, setIsEditing] = useState(false);

  const onSubmit = (data: FormFields) => {
    updateFactorMutation.mutate(
      {
        id: factor.change?.id ?? factor.id,
        ...data,
      },
      {
        onSuccess: () => {
          setIsEditing(false);
        },
      }
    );
  };

  if (isEditing) {
    return (
      <FormRow
        factor={factor}
        index={index}
        selected={selected}
        onSelect={onSelect}
        onSubmit={onSubmit}
      />
    );
  }

  return (
    <TableRow
      className={clsx(
        factor.change?.action ? styles[switchColor(factor.change?.action)] : ""
      )}
    >
      <TableCell>
        <Checkbox
          checked={selected}
          onChange={(e) => onSelect(e.target.checked)}
        />
      </TableCell>
      <TableCell>{index + 1}</TableCell>
      <TableCell width="40%">{factor.change?.title ?? factor.title}</TableCell>
      <TableCell>
        {factor.change?.inspectionFrequency ?? factor.inspectionFrequency}
      </TableCell>
      <TableCell>
        {factor.change?.medicalSpecialists ?? factor.medicalSpecialists}
      </TableCell>
      <TableCell>
        {factor.change?.laboratoryFunctionalStudies ??
          factor.laboratoryFunctionalStudies}
      </TableCell>
      <TableCell>
        <div className={styles.factor__actions}>
          {permissions.hasPermission(ACTIONS.directories_allowedToEdit) && (
            <Tooltip title="Изменить" placement={"top"}>
              <button onClick={() => setIsEditing(true)}>
                <PencilSVG
                  width="16"
                  color={
                    factor.change?.action === "Добавлено"
                      ? "green-darker"
                      : "green"
                  }
                />
              </button>
            </Tooltip>
          )}
          {factor.change?.action !== "Удалено" &&
            permissions.hasPermission(ACTIONS.directories_allowedToDelete) && (
              <Tooltip title="Удалить" placement={"top"}>
                <button onClick={onDelete}>
                  <DeleteCrossSVG size={14} />
                </button>
              </Tooltip>
            )}
        </div>
      </TableCell>
    </TableRow>
  );
};

type FormRowPropsT = {
  factor: MedicalExaminationFactorT;
  index: number;
  selected: boolean;
  onSelect: (checked: boolean) => void;
  onSubmit: (data: FormFields) => void;
};

const FormRow: FC<FormRowPropsT> = ({
  factor,
  index,
  selected,
  onSelect,
  onSubmit,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormFields>({
    mode: "onBlur",
    defaultValues: {
      title: factor.change?.title ?? factor.title,
      inspectionFrequency:
        factor.change?.inspectionFrequency ?? factor.inspectionFrequency,
      medicalSpecialists:
        factor.change?.medicalSpecialists ?? factor.medicalSpecialists,
      laboratoryFunctionalStudies:
        factor.change?.laboratoryFunctionalStudies ??
        factor.laboratoryFunctionalStudies,
    },
  });

  return (
    <TableRow
      className={clsx(
        factor.change?.action ? styles[switchColor(factor.change?.action)] : ""
      )}
    >
      <TableCell>
        <Checkbox
          checked={selected}
          onChange={(e) => onSelect(e.target.checked)}
        />
      </TableCell>
      <TableCell>{index + 1}</TableCell>
      <TableCell width="40%">
        <TextField
          type="text"
          variant="standard"
          style={{ width: "100%", backgroundColor: "white" }}
          error={!!errors.title}
          {...register("title")}
        />
      </TableCell>
      <TableCell>
        <TextField
          type="text"
          variant="standard"
          error={!!errors.inspectionFrequency}
          inputMode="numeric"
          {...register("inspectionFrequency", {
            pattern: /[0-9]*/,
            validate: {
              min_value: (value) => value >= 0,
            },
          })}
        />
      </TableCell>
      <TableCell>
        <TextField
          type="text"
          variant="standard"
          error={!!errors.medicalSpecialists}
          {...register("medicalSpecialists")}
        />
      </TableCell>
      <TableCell>
        <TextField
          type="text"
          variant="standard"
          error={!!errors.laboratoryFunctionalStudies}
          {...register("laboratoryFunctionalStudies")}
        />
      </TableCell>
      <TableCell>
        <div className={styles.factor__actions}>
          <Button onClick={handleSubmit(onSubmit)}>Сохранить</Button>
        </div>
      </TableCell>
    </TableRow>
  );
};
