import React, { useState } from "react";
import { clsx } from "@mantine/styles";
import { Checkbox } from "@mui/material";
import { BodyBold, BodyNormal } from "@ui/fonts";
import { Button } from "@ui/button";
import { ArrowDropDownSVG } from "@components/svgs";
import { CategoryStudyUrl } from "@interfaces/study-center";
import { ProcedureTrainingProgramsT } from "@interfaces/company/procedure";
import styles from "./category-table.module.scss";
import { ExternalLinkSVG } from "@components/svgs/external-link";
import { SendRequestModal } from "../send-request-modal";
import { useStore } from "effector-react";
import { $UserAddPermissions, ACTIONS } from "@store/user-store";
import { useChoiceState } from "@hooks/useChoiceState";

export type CategoryTableProps = JSX.IntrinsicElements["div"] & {
  category: ProcedureTrainingProgramsT;
};

export const CategoryTable: React.FC<CategoryTableProps> = ({
  category,
  className,
  ...props
}) => {
  const permissions = useStore($UserAddPermissions);

  const [isOpen, setIsOpen] = useState(true);
  const [isRequestModalOpen, setIsRequestModalOpen] = useState(false);

  const {
    selectedItems,
    areAllItemsSelected,
    selectAll,
    selectById,
    clearSelectedItems,
    getIsItemSelected,
  } = useChoiceState(category.programs, (program) => program.id);

  const studyUrl = getCategoryStudyUrl(category.title);

  const toggleIsOpen = () => setIsOpen((prev) => !prev);

  const handleCreateRequest = (e: React.MouseEvent) => {
    e.stopPropagation();

    setIsRequestModalOpen(true);
  };

  return (
    <div className={clsx(styles.CategoryTable, className)} {...props}>
      <SendRequestModal
        open={isRequestModalOpen}
        programs={selectedItems}
        onClose={() => {
          setIsRequestModalOpen(false);
          clearSelectedItems();
        }}
      />

      <div className={styles.CategoryTable__top}>
        <div
          className={styles.CategoryTable__name}
          tabIndex={0}
          onClick={toggleIsOpen}
        >
          {permissions.hasPermission(
            ACTIONS.educationalCenter_externalTraining_allowedToCreateApplicationsForTraining
          ) && (
            <Checkbox
              color="primary"
              checked={areAllItemsSelected}
              onChange={(e) => selectAll(e.target.checked)}
              onClick={(e) => e.stopPropagation()}
            />
          )}
          <span
            style={
              isOpen
                ? { transform: "rotate(0deg)" }
                : { transform: "rotate(180deg)" }
            }
          >
            <ArrowDropDownSVG color="#00B856" />
          </span>
          <BodyBold color="green" weight="bold">
            {category.title}
          </BodyBold>
          {permissions.hasPermission(
            ACTIONS.educationalCenter_externalTraining_allowedToCreateApplicationsForTraining
          ) && (
            <Button
              color="white"
              border
              borderColor="green"
              disabled={selectedItems.length === 0}
              onClick={handleCreateRequest}
              className={styles.CategoryTable__itemBtn}
            >
              Сформировать заявку на обучение
            </Button>
          )}
        </div>
        {studyUrl && (
          <BodyNormal color="green" className={styles.CategoryTable__studyUrl}>
            <a href={studyUrl.url} target="_blank" rel="noopener noreferrer">
              {studyUrl.label}
            </a>
            <ExternalLinkSVG />
          </BodyNormal>
        )}
      </div>
      {isOpen && (
        <>
          <hr className={styles.CategoryTable__hr} />
          <div className={styles.CategoryTable__items}>
            {category.programs.map((item) => (
              <div key={item.id} className={styles.CategoryTable__item}>
                <div className={styles.CategoryTable__itemLeft}>
                  {permissions.hasPermission(
                    ACTIONS.educationalCenter_externalTraining_allowedToCreateApplicationsForTraining
                  ) && (
                    <Checkbox
                      color="primary"
                      checked={getIsItemSelected(item)}
                      onChange={(e) => selectById(item, e.target.checked)}
                    />
                  )}
                  <BodyNormal color="dark">{item.title}</BodyNormal>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

const getCategoryStudyUrl = (name: string): CategoryStudyUrl | undefined => {
  if (name === "Охрана труда") {
    return {
      url: "https://ucrbp.ru/programmy/obuchenie_ohrana_truda/",
      label: "Обучение по охране труда",
    };
  }

  if (name === "Пожарно-технический минимум") {
    return {
      url: "https://ucrbp.ru/programmy/pozharnaya-bezopasnost/",
      label: "Обучение по пожарно-техническому минимуму",
    };
  }

  if (name === "Энергетическая безопасность") {
    return {
      url: "https://ucrbp.ru/programmy/energeticheskaya_bezopasnost/",
      label: "Обучение по энергетической безопасности",
    };
  }

  if (name === "Промышленная безопасность") {
    return {
      url: "https://ucrbp.ru/programmy/promyshlennaya_bezopasnost/",
      label: "Обучение по промышленной безопасности",
    };
  }

  if (name === "Первая помощь") {
    return {
      url: "https://ucrbp.ru/programmy/programdp/",
      label: "Обучение первой помощи",
    };
  }

  if (name === "Работы на высоте") {
    return {
      url: "https://ucrbp.ru/programmy/raboty_na_vysote/",
      label: "Обучение работам на высоте",
    };
  }

  if (name === "Гражданская оборона и чрезвычайные ситуации") {
    return {
      url: "https://ucrbp.ru/programmy/grazhdanskaya_oborona/",
      label: "Обучение Гражданской обороне и чрезвычайным ситуациям",
    };
  }

  if (name === "Экология") {
    return {
      url: "https://ucrbp.ru/programmy/ecology/",
      label: "Обучение по экологии",
    };
  }

  if (name === "Дополнительное образование. Повышение квалификации") {
    return {
      url: "https://ucrbp.ru/programmy/professionalnaya_perekvalifikaciya/",
      label: "Повышение квалификации",
    };
  }

  if (name === "Рабочие профессии") {
    return {
      url: "https://ucrbp.ru/programmy/rabochie_professii/",
      label: "Обучение рабочим профессиям",
    };
  }

  return;
};
