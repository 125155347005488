import { $UserAddPermissions, ACTIONS } from "@store/user-store";
import { Archive, DownloadSimple, NotePencil, X } from "@phosphor-icons/react";
import { ErrorIndicator, Loader } from "@ui/indicators";
import React, { useEffect, useState } from "react";
import { WorkspaceDirectoryShortDataT } from "@interfaces/company/workspace";
import { useHistory, useLocation } from "react-router-dom";

import { BackButton } from "@components/back-button";
import { DocumentViewButton } from "@components/common/common";
import { Tooltip } from "@mui/material";
import { WrapperSVG } from "@components/WrapperSVG/WrapperSVG";
import clsx from "clsx";
import { downloadBlob } from "@utils/downloadBlob";
import qs from "qs";
import style from "@scss/pages/company/company-workspace.module.scss";
import tableStyle from "@scss/components/tables/base-table.module.scss";
import toast from "react-hot-toast";
import { useAsyncConfirm } from "@hooks/useAsyncConfirm";
import useModal from "@modals/modal-hook";
import { useStore } from "effector-react";
import {
  useArchiveDocumentTemplateDirectoryMutation,
  useArchiveDocumentTemplateFileMutation,
  useDeleteDocumentTemplatesDirectoryMutation,
  useDeleteDocumentTemplatesFileMutation,
  useDocumentTemplatesFilesQuery,
  useDownloadDocumentTemplateFileMutation,
} from "@lib/document-templates";

export type DocumentTemplatesTablePropsT = {
  groupId: number;
  groupsCount: number;
  disabled?: boolean;
};

export const DocumentTemplatesTable: React.FC<DocumentTemplatesTablePropsT> = ({
  groupId,
  groupsCount,
  disabled = false,
}) => {
  const permissions = useStore($UserAddPermissions);

  const [folderId, setFolderId] = useState(0);
  const [paths, setPaths] = useState<WorkspaceDirectoryShortDataT[]>([]);

  const { files, isLoading, error } = useDocumentTemplatesFilesQuery(
    groupId,
    folderId
  );

  const archiveDirectoryMutation =
    useArchiveDocumentTemplateDirectoryMutation();
  const archiveFileMutation = useArchiveDocumentTemplateFileMutation();

  const deleteDirectoryMutation = useDeleteDocumentTemplatesDirectoryMutation();
  const deleteFileMutation = useDeleteDocumentTemplatesFileMutation();

  const downloadFileMutation = useDownloadDocumentTemplateFileMutation();

  const { open } = useModal();
  const { pathname } = useLocation();
  const history = useHistory();
  const confirm = useAsyncConfirm();

  const totalFilesCount =
    (files?.directories.length ?? 0) + (files?.files.length ?? 0);

  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const isSelectedAll = selectedItems.length === totalFilesCount;
  const selectAll = () => {
    if (isSelectedAll) {
      setSelectedItems([]);
      return;
    }

    setSelectedItems([
      ...(files?.directories.map((item: any) => "dir" + item.id) ?? []),
      ...(files?.files.map((item: any) => "file" + item.id) ?? []),
    ]);
  };

  const handleChangeSelectedItem = (type: string, id: number) => {
    if (selectedItems.includes(type + id)) {
      setSelectedItems((prev) => prev.filter((item) => item !== type + id));
      return;
    }
    setSelectedItems((prev) => [...prev, type + id]);
  };

  useEffect(() => {
    if (!files?.directories) return;

    const Querystring = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });
    const folderId = Querystring?.folder_id;

    if (folderId && +folderId) {
      if (paths.length > 1 && paths[paths.length - 2]?.id === +folderId) {
        paths.pop();
        setPaths(paths);
      } else {
        paths.push(
          files.directories.find(
            (dir: any) => dir?.id === +folderId
          ) as WorkspaceDirectoryShortDataT
        );
      }
    } else {
      setPaths([]);
    }

    setFolderId(+(folderId as string) || 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.search]);

  const handleClick = (id: number) => {
    const queryString = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });

    let newQueryStringObj: any = {
      folder_id: id,
    };
    if (queryString.group_id) {
      newQueryStringObj.group_id = queryString.group_id;
    }

    history.push({
      pathname,
      search: qs.stringify(newQueryStringObj),
    });
  };

  const handleDirectoryDelete = (directoryId: number) => {
    if (!directoryId) return;

    deleteDirectoryMutation.mutate(
      {
        groupId,
        directoryId,
      },
      {
        onError: () => {
          toast.error("Ошибка при удалении директории");
        },
      }
    );
  };

  const handleFileDelete = (fileId: number) => {
    if (!fileId) return;

    deleteFileMutation.mutate(
      {
        groupId,
        directoryId: folderId,
        fileId,
      },
      {
        onError: () => {
          toast.error("Ошибка при удалении файла");
        },
      }
    );
  };

  const sendDirToArchive = (directoryId: number) => {
    if (!directoryId) return;

    const Querystring = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });
    const groupId = Querystring?.group_id ? +Querystring.group_id : 0;

    archiveDirectoryMutation.mutate({
      groupId,
      directoryId,
    });
  };

  const sendFileToArchive = (fileId: number) => {
    const Querystring = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });
    const groupId = Querystring?.group_id ? +Querystring.group_id : 0;
    const directoryId = Querystring?.folder_id ? +Querystring.folder_id : 0;

    archiveFileMutation.mutate({
      groupId,
      directoryId,
      fileId,
    });
  };

  const removeSelectedItem = (item: string) => {
    setSelectedItems((prev) => prev.filter((_item) => _item !== item));
  };

  const getSelectedItemId = (item: string): [number, boolean] => {
    const isDirectory = item.startsWith("dir");

    let strippedString = item;
    if (isDirectory) {
      strippedString = item.replace("dir", "");
    } else {
      strippedString = item.replace("file", "");
    }

    return [parseInt(strippedString), isDirectory];
  };

  const handleDeleteSelectedItems = async () => {
    const ok = await confirm({
      modalData: {
        text: "Вы уверены, что хотите удалить выбранные файлы и папки?",
      },
    });
    if (!ok) return;

    for (const item of selectedItems) {
      const [id, isDirectory] = getSelectedItemId(item);

      if (isDirectory) {
        handleDirectoryDelete(id);
      } else {
        handleFileDelete(id);
      }

      removeSelectedItem(item);
    }
  };

  const handleArchiveSelectedItems = async () => {
    const ok = await confirm({
      modalData: {
        text: "Вы уверены, что хотите отправить в архив выбранные файлы и папки?",
      },
    });
    if (!ok) return;

    for (const item of selectedItems) {
      const [id, isDirectory] = getSelectedItemId(item);

      if (isDirectory) {
        sendDirToArchive(id);
      } else {
        sendFileToArchive(id);
      }

      removeSelectedItem(item);
    }
  };

  // TODO: move this to a separate `WorkspaceTableDirectoryItem` component with handlers above
  const tableDirectoriesContent = files?.directories.map((directory) => {
    const firstRow = (
      <label
        htmlFor={directory.name}
        className={clsx(tableStyle.column_fixed_height)}
      >
        <input
          type="checkbox"
          className={clsx(tableStyle.checkbox_item)}
          hidden
          checked={selectedItems.includes("dir" + directory.id)}
          onChange={() => undefined}
          name=""
          id={directory.name}
        />
        <label
          htmlFor={directory.name}
          onClick={() =>
            !disabled && handleChangeSelectedItem("dir", directory.id)
          }
        >
          <svg
            className="w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 13l4 4L19 7"
            />
          </svg>
        </label>
        <div
          className={style.directoryIconWrapper}
          tabIndex={0}
          onClick={() => !disabled && handleClick(directory.id)}
        >
          <svg
            className={clsx(style.file_icon)}
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            style={{ minWidth: "32px" }}
          >
            <path
              d="M0 11.7761V30.3969C0 31.452 0.806906 32.259 1.86209 32.259H34.1379C35.1931 32.259 36 31.452 36 30.3969V11.7761C36 10.721 35.1931 9.91406 34.1379 9.91406H1.86209C0.806906 9.91406 0 10.7831 0 11.7761Z"
              fill="#F7B84E"
            />
            <path
              d="M32.8963 9.93103C32.8963 8.56549 31.779 7.44829 30.4135 7.44829H20.4825L17.379 3.72412H3.7239C2.66872 3.72412 1.86182 4.59311 1.86182 5.58621V9.93103H32.8963Z"
              fill="#E4A12F"
            />
          </svg>
          <label
            htmlFor={`key`}
            className={clsx(
              tableStyle.nameFile,
              style.companyDocumentTemplatesFileName
            )}
          >
            {directory.name}
          </label>
        </div>
      </label>
    );

    return (
      <tr key={directory.id}>
        <td className={clsx(style.folder)}>{firstRow}</td>
        <td>
          <div className={clsx(style.table_buttons)}>
            {permissions.hasPermission(
              ACTIONS.companyDocumentTemplates_directories_allowedToEdit
            ) && (
              <Tooltip title="Изменить" placement="top">
                <button>
                  <WrapperSVG color={"orange"}>
                    <NotePencil
                      size={24}
                      onClick={() =>
                        !disabled &&
                        open("CreateFolderDocumentTemplatesModal", {
                          btnText: "Сохранить",
                          modalData: {
                            modalTitle: "Изменить папку",
                            itemTitle: directory.name,
                            item: directory,
                            groupId,
                          },
                        })
                      }
                    />
                  </WrapperSVG>
                </button>
              </Tooltip>
            )}
            {permissions.hasPermission(
              ACTIONS.companyDocumentTemplates_directories_allowedToArchive
            ) && (
              <Tooltip title="В архив" placement="top">
                <button>
                  <WrapperSVG color="red">
                    <Archive
                      size={24}
                      onClick={() =>
                        !disabled && sendDirToArchive(directory.id)
                      }
                    />
                  </WrapperSVG>
                </button>
              </Tooltip>
            )}
            {permissions.hasPermission(
              ACTIONS.companyDocumentTemplates_directories_allowedToDelete
            ) && (
              <Tooltip title="Удалить" placement="top">
                <button>
                  <X
                    size={24}
                    onClick={() =>
                      !disabled &&
                      open("ConfirmActionModal", {
                        btnText: "Удалить",
                        modalData: {
                          text: `Вы уверены, что хотите удалить папку ${directory.name} и все содержимое?`,
                        },
                        onConfirm: () => handleDirectoryDelete(directory.id),
                      })
                    }
                  />
                </button>
              </Tooltip>
            )}
          </div>
        </td>
      </tr>
    );
  });

  // TODO: move this to a separate `WorkspaceTableFileItem` component with handlers above
  const tableFilesContent = files?.files.map((file) => {
    const firstRow = (
      <label
        htmlFor={file.name}
        className={clsx(tableStyle.column_fixed_height)}
      >
        <input
          type="checkbox"
          className={clsx(tableStyle.checkbox_item)}
          hidden
          checked={selectedItems.includes("file" + file.id)}
          onChange={() => undefined}
          name=""
          id={file.name}
        />
        <label
          htmlFor={file.name}
          onClick={() => !disabled && handleChangeSelectedItem("file", file.id)}
        >
          <svg
            className="w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 13l4 4L19 7"
            />
          </svg>
        </label>
        <svg
          className={clsx(style.file_icon)}
          width="32"
          height="32"
          style={{ minWidth: "32px" }}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 0C6.9 0 6 0.9 6 2V30C6 31.1 6.9 32 8 32H28C29.1 32 30 31.1 30 30V8L22 0H8Z"
            fill="#DFE3F1"
          />
          <path d="M24 8H30L22 0V6C22 7.1 22.9 8 24 8Z" fill="#B0B7BD" />
          <path d="M30 14L24 8H30V14Z" fill="#CAD1D8" />
        </svg>
        <label
          htmlFor={`key`}
          className={clsx(
            tableStyle.nameFile,
            style.companyDocumentTemplatesFileName
          )}
        >
          {file.name}
        </label>
      </label>
    );

    const downloadHandler = () => {
      downloadFileMutation.mutate(
        {
          groupId,
          directoryId: folderId,
          fileId: file.id,
        },
        {
          onSuccess: (blob) => {
            downloadBlob(blob, file.name);
          },
        }
      );
    };

    return (
      <tr key={file.id}>
        <td>{firstRow}</td>
        <td>
          <div className={clsx(style.table_buttons)}>
            <Tooltip title="Посмотреть" placement="top">
              <button>
                <DocumentViewButton
                  fileName={file.name}
                  filePath={`uploads/document-templates/${file.path}`}
                />
              </button>
            </Tooltip>
            {permissions.hasPermission(
              ACTIONS.companyDocumentTemplates_files_allowedToEdit
            ) && (
              <>
                <Tooltip title="Изменить" placement="top">
                  <button>
                    <WrapperSVG color={"orange"}>
                      <NotePencil
                        size={24}
                        alt="Редактировать"
                        onClick={() =>
                          !disabled &&
                          open("EditDocumentTemplatesFileModal", {
                            btnText: "Сохранить",
                            modalData: {
                              modalTitle: "Изменить файл",
                              item: file,
                            },
                          })
                        }
                      />
                    </WrapperSVG>
                  </button>
                </Tooltip>
                {/* Скрыл эту кнопку, пока не удаляй */}
                {/* <Tooltip title="Редактировать документ" placement="top"> */}
                {/*   <button> */}
                {/*     <a */}
                {/*       href={fullEditorUrl} */}
                {/*       target={"_blank"} */}
                {/*       rel={"noreferrer"} */}
                {/*     > */}
                {/*       <img */}
                {/*         src={EditDocumentIcon} */}
                {/*         alt="Редактировать" */}
                {/*         style={{ width: 19, height: 19, opacity: 0.75 }} */}
                {/*       /> */}
                {/*     </a> */}
                {/*   </button> */}
                {/* </Tooltip> */}
              </>
            )}
            {file.path ? (
              <Tooltip title="Скачать" placement="top">
                <button onClick={() => !disabled && downloadHandler()}>
                  <WrapperSVG color={"green-light"}>
                    <DownloadSimple size={24} />
                  </WrapperSVG>
                </button>
              </Tooltip>
            ) : null}
            {permissions.hasPermission(
              ACTIONS.companyDocumentTemplates_files_allowedToArchive
            ) && (
              <Tooltip title="В архив" placement="top">
                <button onClick={() => !disabled && sendFileToArchive(file.id)}>
                  <WrapperSVG color="red">
                    <Archive size={24} />
                  </WrapperSVG>
                </button>
              </Tooltip>
            )}
            {permissions.hasPermission(
              ACTIONS.companyDocumentTemplates_files_allowedToDelete
            ) && (
              <Tooltip title="Удалить" placement="top">
                <button>
                  <X
                    size={24}
                    onClick={() =>
                      !disabled &&
                      open("ConfirmActionModal", {
                        btnText: "Удалить",
                        modalData: {
                          text: `Вы уверены, что хотите удалить файл ${file.name}?`,
                        },
                        onConfirm: () => handleFileDelete(file.id),
                      })
                    }
                  />
                </button>
              </Tooltip>
            )}
          </div>
        </td>
      </tr>
    );
  });

  return (
    <div className={tableStyle.base_table_container}>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <ErrorIndicator message={groupsCount === 0 ? "Групп нет" : undefined} />
      ) : (
        <>
          <p className={style.path_text}>
            Путь: /{" "}
            {paths.length > 0 ? paths.map((path) => path.name + " / ") : ""}
          </p>
          {!!paths.length && <BackButton className={style.path_text} />}
          <table className={clsx(tableStyle.base_table, style.workspace_table)}>
            <thead>
              <tr>
                <td>
                  <label>
                    <input
                      type="checkbox"
                      className={clsx(tableStyle.checkbox_item)}
                      name=""
                      hidden
                      checked={
                        selectedItems.length ===
                        (files?.directories.length ?? 0) +
                          (files?.files.length ?? 0)
                      }
                      onChange={() => undefined}
                    />
                    <label onClick={() => !disabled && selectAll()}>
                      <svg
                        className="w-5 h-5"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    </label>
                    <label className={clsx(tableStyle.checkbox_label)}>
                      Название
                    </label>
                  </label>
                </td>
                <td align="right">
                  {selectedItems.length > 0 && (
                    <div className={style.selectedItemsActions}>
                      {permissions.hasPermission(
                        ACTIONS.companyDocumentTemplates_directories_allowedToArchive
                      ) && (
                        <Tooltip title="В архив" placement="top">
                          <button>
                            <WrapperSVG color="red">
                              <Archive
                                size={24}
                                onClick={() =>
                                  !disabled && handleArchiveSelectedItems()
                                }
                              />
                            </WrapperSVG>
                          </button>
                        </Tooltip>
                      )}
                      {permissions.hasPermission(
                        ACTIONS.companyDocumentTemplates_files_allowedToDelete
                      ) && (
                        <Tooltip title="Удалить" placement="top">
                          <button>
                            <X
                              size={24}
                              onClick={() =>
                                !disabled && handleDeleteSelectedItems()
                              }
                            />
                          </button>
                        </Tooltip>
                      )}
                    </div>
                  )}
                </td>
              </tr>
            </thead>
            <tbody>
              {tableDirectoriesContent}
              {tableFilesContent}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};
