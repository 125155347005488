import { FC } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { Grid2 as Grid, TextField } from "@mui/material";

import { DatePicker } from '@mui/x-date-pickers';
import { TimePicker } from '@mui/x-date-pickers';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ruRU } from '@mui/x-date-pickers/locales';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from "moment";

import style from "@scss/modals/company/create-task.module.scss";
import {
  CreateTaskModalForm,
  CreateTaskModalTabProps,
} from "./create-task-modal";
import clsx from "clsx";
import { useModal } from "@modals/index";

export const CreateTaskModalMainTab: FC<CreateTaskModalTabProps> = ({
  className,
  touchField,
}) => {
  const { modalData } = useModal();

  const isEditMode = !!modalData.editMode;

  const isTaskEditable = modalData.event ? modalData.event.status === 1 : true;
  const isDeadlineEditable = modalData.event
    ? [1, 2].includes(modalData.event.status)
    : true;

  const {
    register,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<CreateTaskModalForm>();

  const [startDate, endDate] = watch(["startDate", "endDate"]);

  return (
    <div className={clsx(style.createTaskModal__mainTab, className)}>
      <TextField
        label="Название задачи"
        variant="filled"
        required
        slotProps={{
          input: {inputProps: {maxLength: 255}}
        }}
        disabled={!isTaskEditable}
        {...register("title", {
          required: "Обязательное поле",
        })}
      />
      <p className="error-text">{errors.title?.message}</p>
      <TextField
        label="Описание"
        variant="filled"
        required
        multiline
        minRows={10}
        maxRows={15}
        slotProps={{
          input: {inputProps: {maxLength: 16777216}}
        }}
        disabled={!isTaskEditable}
        {...register("description", {
          required: "Обязательное поле",
        })}
      />
      <p className="error-text">{errors.description?.message}</p>
      <div className="create-event-modal-pickers">
        <LocalizationProvider
          dateAdapter={AdapterMoment}
          adapterLocale={"ru"}
          localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
        >
          <Grid container className={clsx(style.main_pickers_container)}>
            <Grid size={{ xs: 12, md: 6 }}>
              <p>Начало</p>
              <Controller
                control={control}
                name="startDate"
                rules={{
                  validate: {
                    should_be_in_future: (value) => {
                      if (isEditMode) return true;

                      if (moment(value).isBefore(moment())) {
                        return "Дата не может быть в прошлом";
                      }
                      return true;
                    },
                  },
                  onChange: (e) => {
                    touchField("startDate");
                    const _startDate = moment(e.target.value);
                    const _endDate = moment(endDate);
                    if (_startDate.isAfter(_endDate)) {
                      touchField("endDate");
                      setValue(
                        "endDate",
                        _startDate.add(1, "hour").toISOString()
                      );
                    }
                  },
                }}
                render={({ field }) => (
                  <Grid container className={clsx(style.pickers_container)}>
                    <Grid size={{ xs: 12, sm: 5 }}>
                      <TimePicker
                        ampm={false}
                        value={moment(field.value)}
                        onChange={(date: Moment | null) =>
                          field.onChange(date?.toISOString())
                        }
                        format="HH:mm"
                        autoFocus={false}
                        className={clsx(style.pickers_input)}
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                          seconds: renderTimeViewClock,
                        }}
                        slotProps={{
                          actionBar: { actions: ['cancel', 'accept'] },
                          textField: {
                            variant: 'filled',
                            placeholder: 'Время',
                            error: !!errors.startDate,
                            disabled: !isTaskEditable
                          },
                        }}
                        localeText={{
                          toolbarTitle: '',
                          cancelButtonLabel: 'Отменить',
                          okButtonLabel: 'ОК',
                        }}
                      />
                    </Grid>
                    <Grid size={{ xs: 12, sm: 6 }}>
                      <DatePicker
                        value={moment(field.value)}
                        onChange={(value: Moment | null) =>
                          field.onChange(value?.toISOString())
                        }
                        minDate={moment()}
                        format="DD.MM.YYYY"
                        autoFocus={false}
                        className={clsx(style.pickers_input)}
                        slotProps={{
                          actionBar: { actions: ['cancel', 'accept'] },
                          textField: {
                            variant: 'filled',
                            placeholder: 'Дата',
                            error: !!errors.startDate,
                            disabled: !isTaskEditable
                          },
                        }}
                        localeText={{
                          toolbarTitle: '',
                          cancelButtonLabel: 'Отменить',
                          okButtonLabel: 'ОК',
                        }}
                      />
                    </Grid>
                  </Grid>
                )}
              />
            </Grid>

            <Grid size={{ xs: 12, md: 6 }}>
              <p>Крайний срок</p>
              <Controller
                control={control}
                name="endDate"
                rules={{
                  validate: {
                    should_be_in_future: (value) => {
                      if (isEditMode) return true;

                      if (moment(value).isBefore(moment())) {
                        return "Дата не может быть в прошлом";
                      }
                      return true;
                    },
                  },
                  onChange: (e) => {
                    touchField("endDate");
                    const value = e.target.value;
                    const _startDate = moment(startDate);
                    const _endDate = moment(value);
                    if (_endDate.isBefore(_startDate)) {
                      touchField("startDate");
                      setValue(
                        "startDate",
                        _endDate.subtract(1, "hour").toISOString()
                      );
                    }
                  },
                }}
                render={({ field }) => (
                  <Grid container className={clsx(style.pickers_container)}>
                    <Grid size={{ xs: 12, sm: 5 }}>
                      <TimePicker
                        ampm={false}
                        value={moment(field.value)}
                        onChange={(date: Moment | null) =>
                          field.onChange(date?.toISOString())
                        }
                        format="HH:mm"
                        autoFocus={false}
                        className={clsx(style.pickers_input)}
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                          seconds: renderTimeViewClock,
                        }}
                        slotProps={{
                          actionBar: { actions: ['cancel', 'accept'] },
                          textField: {
                            variant: 'filled',
                            placeholder: 'Время',
                            error: !!errors.endDate,
                            disabled: !isDeadlineEditable
                          },
                        }}
                        localeText={{
                          toolbarTitle: '',
                          cancelButtonLabel: 'Отменить',
                          okButtonLabel: 'ОК',
                        }}
                      />
                    </Grid>
                    <Grid size={{ xs: 12, sm: 6 }}>
                      <DatePicker
                        value={moment(field.value)}
                        onChange={(value: Moment | null) =>
                          field.onChange(value?.toISOString())
                        }
                        minDate={moment()}
                        format="DD.MM.YYYY"
                        autoFocus={false}
                        className={clsx(style.pickers_input)}
                        slotProps={{
                          actionBar: { actions: ['cancel', 'accept'] },
                          textField: {
                            variant: 'filled',
                            placeholder: 'Дата',
                            error: !!errors.endDate,
                            disabled: !isDeadlineEditable
                          },
                        }}
                        localeText={{
                          toolbarTitle: '',
                          cancelButtonLabel: 'Отменить',
                          okButtonLabel: 'ОК',
                        }}
                      />
                    </Grid>
                  </Grid>
                )}
              />
            </Grid>
          </Grid>
        </LocalizationProvider>
        <p className="error-text">
          {errors.startDate?.message || errors.endDate?.message}
        </p>
      </div>
    </div>
  );
};
