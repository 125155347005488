import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ruRU } from '@mui/x-date-pickers/locales';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {
  MenuItem,
  TextField,
  Theme,
} from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { makeStyles, createStyles } from '@mui/styles';
import { Procedure, ProcedureDependentT } from "@interfaces/company/procedure";
import React, { useEffect, useState } from "react";
import moment, { Moment } from "moment";

import { BodyNormal } from "@ui/fonts";
import { Button } from "@ui/button";
import { Modal } from "@ui/modal";
import { Text } from "@mantine/core";
import styles from "./generate-cell.module.scss";
import toast from "react-hot-toast";

interface GenerateCellProps {
  type?: "string" | string[] | "date" | "input_date" | "input_string";
  value?: Date | string | number;
  name: string;
  label: string;
  onChange?: (id: number, value: { [key: string]: string }) => void;
  onBlur?: () => void;
  defaultValue: Date | Moment | string | number | undefined | null | ProcedureDependentT;
  row: Procedure;
}

const useFormStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputBase: {
      minWidth: 150,
      paddingLeft: 5,
      paddingRight: 5,

      "&:focus": {
        backgroundColor: "transparent",
      },
    },
  })
);

export const GenerateCell: React.FC<GenerateCellProps> = ({
  type,
  value,
  defaultValue,
  name,
  label,
  onChange,
  onBlur,
  row,
}) => {
  const [dateValue, setDateValue] = useState<Moment | null>(
    defaultValue ? moment(defaultValue as Moment) : null
  );
  const [open, setOpen] = useState<boolean>(false);
  const classes = useFormStyles();
  const [errorText, setErrorText] = useState<string>();
  const [isDateError, setIsDateError] = useState(false);

  useEffect(() => {
    if (name === "lastTrainingDate" && !defaultValue) {
      setDateValue(null);
    }
    if (name === "lastTrainingDate" && defaultValue) {
      setDateValue(defaultValue as Moment);
    }
  }, [name, defaultValue]);

  useEffect(() => {
    if (type === "input_date" && defaultValue) {
      setDateValue(moment(defaultValue as string));
    }
  }, [type, defaultValue, name]);

  const onClose = () => {
    setOpen(false);
    setErrorText("");
  };

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = ({
    target: { value },
  }) => {
    if (!onChange || !row.id) return undefined;
    onChange(row.id, { [name]: value });
  };

  const handleDateChange = (date: Moment | null) => {
    if (!onChange || !row.id) return;

    setIsDateError(false);

    const valDate =
      date && date?.isValid() ? date?.format("YYYY-MM-DD") : "";

    // show input as error if entered date is in the future
    if (moment().isBefore(valDate, "days")) {
      toast.error("Дата не может быть позже текущей даты");

      setIsDateError(true);
      return;
    }

    setDateValue(date as Moment);
    onChange(row.id, { [name]: valDate });
  };

  const handleSelectChange = (event: SelectChangeEvent<typeof defaultValue>) => {
    const {
      target: { value },
    } = event;
    if (
      value === "Повторное" &&
      defaultValue === "Первичное" &&
      !row?.lastTrainingDate
    ) {
      setErrorText("Укажите дату обучения");
      setOpen(true);
      return;
    }
    if (defaultValue === "Повторное" && value === "Первичное") {
      if (!onChange || !row.id) return undefined;

      onChange(row.id, { [name]: value });
      setErrorText("Дата прошлого выполнения процедуры будет удалена.");
      setOpen(true);
    }
    if (!onChange || !row.id) return undefined;
    onChange(row.id, { [name]: value as string });
  };

  return (
    <>
      {type === "input_string" && (
        <TextField
          value={value}
          defaultValue={defaultValue}
          onChange={handleChange}
          onBlur={onBlur}
          variant="standard"
        />
      )}
      {type === "input_date" && (
        <LocalizationProvider
          dateAdapter={AdapterMoment}
          adapterLocale={"ru"}
          localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
        >
          <DatePicker
            value={dateValue || null}
            onChange={handleDateChange}
            format="DD.MM.YYYY"
            autoFocus={false}
            maxDate={moment()}
            slotProps={{
              actionBar: { actions: ['cancel', 'accept'] },
              textField: {
                variant: 'standard',
                error: isDateError,
                placeholder: 'Выберите дату',
                InputProps: { classes: { root: classes.inputBase } }
              },
            }}
            localeText={{
              toolbarTitle: '',
              cancelButtonLabel: 'Отменить',
              okButtonLabel: 'ОК',
            }}
          />
        </LocalizationProvider>
      )}
      {Array.isArray(type) && (
        <Select
          variant="standard"
          value={defaultValue}
          onBlur={onBlur}
          onChange={handleSelectChange}
          inputProps={{ className: classes.inputBase }}
        >
          {type.map((item) => (
            <MenuItem value={item} key={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      )}
      {!Array.isArray(type) &&
        type !== "input_date" &&
        type !== "input_string" && (
          <Text color="gray">Что-то пошло не так </Text>
        )}
      {open && (
        <Modal open={open} onClose={onClose} className={styles.modal}>
          <div className={styles.modal_head}>
            <BodyNormal>{errorText}</BodyNormal>
          </div>
          <div className={styles.modal_buttons}>
            {/* <Button
              color={"white-green"}
              borderColor={"green"}
              onClick={onClose}
            >
              <BodyNormal>Отменить</BodyNormal>
            </Button> */}
            <Button onClick={onClose}>Принять</Button>
          </div>
        </Modal>
      )}
    </>
  );
};
