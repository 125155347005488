import {
  $RolesPermissions,
  $User,
  $UserAddPermissions,
  ACTIONS,
  UserPriorityEnum,
  UserRoleEnum,
} from "@store/user-store";
import { FC, memo, useState } from "react";
import { UserDataT, WorkerShortDataT } from "@interfaces/user";

import { NotePencil } from "@phosphor-icons/react";
import { SimpleOptionT } from "@interfaces/company/employees";
import { Tooltip } from "@mui/material";
import WorkerService from "@services/worker-service";
import { WrapperSVG } from "@components/WrapperSVG/WrapperSVG";
import clsx from "clsx";
import s from "@scss/components/tables/tariff-change-table.module.scss";
import tableStyle from "@scss/components/tables/base-table.module.scss";
import { updateWorkerData } from "@store/workers-store";
import { useLocation } from "react-router-dom";
import { useModal } from "@modals/index";
import { useStore } from "effector-react";
import { useSelectedCompanyQuery } from "@lib/company";

type TableRowProps = {
  user: WorkerShortDataT;
  index: number;
  rolesOptions?: SimpleOptionT[];
  onRoleChange: any;
  selectedCompany: number;
  isCompanyModule: boolean;
};
const TableRow: FC<TableRowProps> = ({
  user,
  index,
  selectedCompany,
  isCompanyModule,
}) => {
  const [showAllCompanies, setShowAllCompanies] = useState(false);
  const { open } = useModal();
  const permissions = useStore($UserAddPermissions);

  return (
    <tr key={index}>
      <td>
        <span>{user.name}</span>
      </td>
      <td>
        <span>{user?.role?.name ?? user?.roleId}</span>
      </td>
      {!isCompanyModule && (
        <td>
          <span
            className={clsx(tableStyle.listOfCompanies)}
            dangerouslySetInnerHTML={{
              __html:
                user.companies
                  .map((company) => {
                    if (company.id === selectedCompany)
                      return `<b>${company.name}</b>`;
                    return company.name;
                  })
                  .slice(0, showAllCompanies ? undefined : 2)
                  .join(", ") +
                (!showAllCompanies && user.companies.length > 2 ? "..." : ""),
            }}
          ></span>
          <span
            className={tableStyle.showMoreOrLess}
            onClick={() => setShowAllCompanies(!showAllCompanies)}
          >
            {user.companies.length > 2 &&
              (showAllCompanies ? "Скрыть" : "Показать все")}
          </span>
        </td>
      )}
      <td align="right" style={{ textAlign: "right" }}>
        {((user.roleId === UserRoleEnum.Worker &&
          permissions.hasPermission(
            ACTIONS.roles_levelFourthRoles_allowedToEdit
          )) ||
          (user.roleId === UserRoleEnum.Client &&
            permissions.hasPermission(
              ACTIONS.roles_levelThirdRoles_allowedToEdit
            )) ||
          (user.roleId === UserRoleEnum.Admin &&
            permissions.hasPermission(
              ACTIONS.roles_levelSecondRoles_allowedToEdit
            )) ||
          (user.roleId === UserRoleEnum.SuperAdminMinus &&
            permissions.hasPermission(
              ACTIONS.roles_levelFirstRoles_allowedToEdit
            )) ||
          permissions.hasPermission(ACTIONS.users_allowedToEdit) ||
          permissions.hasRequiredLevel(UserPriorityEnum.Owner)) && (
          <Tooltip title="Изменить" placement={"top"}>
            <button
              onClick={() =>
                open("CreateUserModal", {
                  modalData: {
                    modalTitle: "Изменить пользователя",
                    worker: user,
                  },
                  btnText: "Сохранить",
                })
              }
            >
              <WrapperSVG color={"orange"}>
                <NotePencil size={24} />
              </WrapperSVG>
            </button>
          </Tooltip>
        )}
      </td>
    </tr>
  );
};

type UserRolesTableProps = {
  selectedCompany: number;
  selectedRole: number;
  searchString: string;
  users: WorkerShortDataT[];
};
export const UserRolesTable: FC<UserRolesTableProps> = memo(
  ({ selectedCompany, selectedRole, searchString, users }) => {
    const rolesNPermissions = useStore($RolesPermissions);
    const user = useStore($User) as UserDataT;
    const permissions = useStore($UserAddPermissions);
    const location = useLocation();
    const IS_COMPANY_MODULE = location.pathname.includes("company");

    const { company } = useSelectedCompanyQuery();

    const rolesOptions = rolesNPermissions?.roles.map((role) => {
      const canCreateSuperadmin =
        [UserRoleEnum.SuperAdmin].includes(user.id) &&
        [UserRoleEnum.SuperAdmin, UserRoleEnum.SuperAdminMinus].includes(
          role.id
        ) &&
        !permissions.hasPermission(
          ACTIONS.roles_levelFirstRoles_allowedToAssign
        );
      const canCreateAdmin =
        [UserRoleEnum.SuperAdmin, UserRoleEnum.SuperAdminMinus].includes(
          user.id
        ) &&
        [UserRoleEnum.Admin].includes(role.id) &&
        !permissions.hasPermission(
          ACTIONS.roles_levelSecondRoles_allowedToAssign
        );
      const canCreateClient =
        [
          UserRoleEnum.SuperAdmin,
          UserRoleEnum.SuperAdminMinus,
          UserRoleEnum.Admin,
        ].includes(user.id) &&
        [UserRoleEnum.Client].includes(role.id) &&
        !permissions.hasPermission(
          ACTIONS.roles_levelThirdRoles_allowedToAssign
        );
      const canCreateSot =
        [
          UserRoleEnum.SuperAdmin,
          UserRoleEnum.SuperAdminMinus,
          UserRoleEnum.Admin,
          UserRoleEnum.Client,
        ].includes(user.id) &&
        [UserRoleEnum.Worker].includes(role.id) &&
        !permissions.hasPermission(
          ACTIONS.roles_levelFourthRoles_allowedToAssign
        );

      return {
        value: role.id,
        label: role.name,
        isDisabled:
          canCreateSuperadmin ||
          canCreateAdmin ||
          canCreateClient ||
          canCreateSot,
      };
    }) as SimpleOptionT[];

    const onRoleChange = (
      selectedOption: SimpleOptionT,
      worker: WorkerShortDataT
    ) => {
      WorkerService.UpdateWorker(
        worker.id,
        {
          roleId: selectedOption.value,
          name: worker.name,
          email: worker.email,
          companies: worker.companies.map((comp) => comp.id),
        },
        (err, res) => {
          if (err || !res) {
          } else {
            updateWorkerData(res.data);
          }
        }
      );
    };
    const tableBodyContent = users.map((worker, index) => {
      if (selectedCompany) {
        let userHaveACompany = false;
        worker.companies.forEach((company) => {
          if (selectedCompany === company.id) userHaveACompany = true;
        });
        if (!userHaveACompany) return null;
      }
      if (selectedRole && selectedRole !== worker.roleId) {
        return null;
      }
      if (IS_COMPANY_MODULE) {
        let relatedToCompany = false;
        worker.companies.forEach((workerCompany) => {
          if (
            workerCompany.name
              .toLowerCase()
              .includes(company?.name.toLowerCase() ?? "")
          ) {
            relatedToCompany = true;
          }
        });
        if (!relatedToCompany) return null;
      }
      if (searchString) {
        let equals = false;
        if (
          searchString &&
          worker.name.toLowerCase().includes(searchString.toLowerCase())
        ) {
          equals = true;
        }
        if (!IS_COMPANY_MODULE && searchString)
          worker.companies.forEach((company) => {
            if (
              !IS_COMPANY_MODULE &&
              searchString &&
              company.name.toLowerCase().includes(searchString.toLowerCase())
            ) {
              equals = true;
            }
          });
        if (!equals) return null;
      }

      return (
        <TableRow
          key={index}
          user={worker}
          index={index}
          onRoleChange={onRoleChange}
          rolesOptions={rolesOptions}
          selectedCompany={selectedCompany}
          isCompanyModule={IS_COMPANY_MODULE}
        />
      );
    });
    return (
      <table className={clsx(tableStyle.base_table, s.table)}>
        <thead>
          <tr>
            <td>
              <span>ПОЛЬЗОВАТЕЛИ</span>
            </td>
            <td>
              <span>РОЛЬ</span>
            </td>
            {!IS_COMPANY_MODULE && (
              <td>
                <span>КОМПАНИЯ</span>
              </td>
            )}
            <td align="right"></td>
          </tr>
        </thead>
        <tbody>{tableBodyContent}</tbody>
      </table>
    );
  }
);
