import { FC, useState } from "react";
import { BodyBold, Subtitle } from "@ui/fonts";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";

import { $UserAddPermissions, ACTIONS } from "@store/user-store";
import { Button } from "@ui/button";
import { DeleteCrossSVG } from "@components/svgs/delete-cross";
import { PencilSVG } from "@components/svgs";
import clsx from "clsx";
import styles from "./medical-examination-category.module.scss";
import { switchColor } from "@utils/switch-color";
import { EditNameCategory } from "feature/directory-systems/edit-name-category";
import {
  MedicalExaminationCategoryT,
  MedicalExaminationFactorT,
} from "@interfaces/medical-examination";
import { useStore } from "effector-react";
import { MedicalExaminationFactor } from "../medical-examination-factor";
import { useChoiceState } from "@hooks/useChoiceState";
import {
  useDeleteMedicalExaminationCategoryMutation,
  useDeleteMedicalExaminationFactorMutation,
  useUpdateMedicalExaminationCategoryMutation,
} from "@lib/medical-examination";
import { useAsyncConfirm } from "@hooks/useAsyncConfirm";
import { CreateMedicalExaminationFactorModal } from "../create-medical-examination-factor/create-medical-examination-factor";

export type MedicalExaminationCategoryPropsT = {
  category: MedicalExaminationCategoryT;
  factors: MedicalExaminationFactorT[];
};

export const MedicalExaminationCaterogy: FC<
  MedicalExaminationCategoryPropsT
> = ({ category, factors }) => {
  const permissions = useStore($UserAddPermissions);

  const updateCategoryMutation = useUpdateMedicalExaminationCategoryMutation();
  const deleteCategoryMutation = useDeleteMedicalExaminationCategoryMutation();
  const deleteFactorMutation = useDeleteMedicalExaminationFactorMutation();

  const confirm = useAsyncConfirm();

  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [categoryTitle, setCategoryTitle] = useState(
    category.change?.title ?? category.title ?? ""
  );
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const choice = useChoiceState(
    factors,
    (factor) => factor.change?.id ?? factor.id
  );

  const canSeeChanges = permissions.hasPermission([
    ACTIONS.directories_allowedToCreate,
    ACTIONS.directories_allowedToEdit,
    ACTIONS.directories_allowedToDelete,
    ACTIONS.directories_allowedToConfirmChanges,
    ACTIONS.directories_allowedToReverseChanges,
  ]);

  const isDeleteTable = () => category.change?.action === "Удалено";

  const handleDeleteCategory = () => {
    setIsEditingTitle(false);
    deleteCategoryMutation.mutate(category.change?.id ?? category.id ?? -1);
  };

  const handleUpdateTitle = (value: string) => {
    setIsEditingTitle(false);
    setCategoryTitle(value);
    updateCategoryMutation.mutate({
      id: category.change?.id ?? category.id ?? -1,
      title: value,
    });
  };

  const handleDeleteFactor = async (factorId: number) => {
    const ok = await confirm({
      btnText: "Да",
      modalData: {
        title: "Подтвердите действие",
        text: "Вы уверены, что хотите удалить фактор?",
        cancelBtnText: "Нет",
        removeIcon: true,
      },
    });
    if (!ok) return;

    deleteFactorMutation.mutate(factorId);
  };

  const handleDeleteSelectedFactors = async () => {
    const promises = [];
    for (const factor of choice.selectedItems) {
      promises.push(deleteFactorMutation.mutateAsync(choice.getItemId(factor)));
    }
    await Promise.allSettled(promises);

    choice.clearSelectedItems();
  };

  return (
    <div>
      <div className={styles.category__header}>
        <div className={styles.category__title}>
          <Checkbox
            color="primary"
            checked={choice.areAllItemsSelected}
            onChange={(e) => choice.selectAll(e.target.checked)}
          />
          {isEditingTitle ? (
            <EditNameCategory
              title={categoryTitle}
              id={category.change?.id ?? category.id ?? -1}
              changeTitle={categoryTitle}
              type="medical-examination"
              onClose={() => setIsEditingTitle(false)}
              onDelete={handleDeleteCategory}
              onUpdate={handleUpdateTitle}
            />
          ) : (
            <div>
              <div
                className={clsx(
                  category.change?.action
                    ? styles[switchColor(category.change?.action)]
                    : ""
                )}
              >
                <Subtitle weight="bold">{categoryTitle}</Subtitle>
              </div>
            </div>
          )}
          {!isEditingTitle &&
            permissions.hasPermission(ACTIONS.directories_allowedToEdit) && (
              <Tooltip title="Изменить" placement={"top"}>
                <button onClick={() => setIsEditingTitle(true)}>
                  <PencilSVG />
                </button>
              </Tooltip>
            )}
        </div>
        {choice.selectedItems.length > 0 &&
          permissions.hasPermission(ACTIONS.directories_allowedToDelete) && (
            <Tooltip title="Удалить" placement={"top"}>
              <button
                tabIndex={0}
                className={styles.contain_title__right}
                title="Удалить выбранные программы"
                onClick={handleDeleteSelectedFactors}
              >
                <DeleteCrossSVG size={18} />
              </button>
            </Tooltip>
          )}
      </div>
      <div className={styles.table}>
        <Table className={styles.table_wrapper} aria-label="caption table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {head.map((item) => (
                <TableCell key={item.id} className={styles.table_head}>
                  <div>{item.title}</div>
                </TableCell>
              ))}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {factors.map((factor, index) => {
              if (factor.change && !canSeeChanges) return null;

              return (
                <MedicalExaminationFactor
                  key={index}
                  factor={factor}
                  index={index}
                  selected={choice.getIsItemSelected(factor)}
                  onSelect={(checked) => choice.selectById(factor, checked)}
                  onDelete={() =>
                    handleDeleteFactor(factor.change?.id ?? factor.id)
                  }
                />
              );
            })}
          </TableBody>
        </Table>
      </div>

      {!isDeleteTable() && (
        <div className={styles.category__createFactorBtn}>
          {permissions.hasPermission(ACTIONS.directories_allowedToCreate) && (
            <Button
              color="white-green"
              borderColor="green"
              onClick={() => setIsCreateModalOpen(true)}
            >
              <BodyBold>+ Добавить фактор</BodyBold>
            </Button>
          )}
        </div>
      )}
      <CreateMedicalExaminationFactorModal
        isOpen={isCreateModalOpen}
        category={category}
        onClose={() => setIsCreateModalOpen(false)}
      />
    </div>
  );
};

type HeadTableT = {
  id: string;
  title: string;
}[];

const head: HeadTableT = [
  {
    id: "0",
    title: "№",
  },
  {
    id: "1",
    title: "Название",
  },
  {
    id: "2",
    title: "Периодичность",
  },
  {
    id: "3",
    title: "Участие врачей-специалистов",
  },
  {
    id: "4",
    title: "Лабораторные и функциональные исследования",
  },
];
