import { SearchSVG } from "@components/svgs";
import clsx from "clsx";
import style from "./search.module.scss";
import { styled } from "@mui/system";
import useAutocomplete from '@mui/material/useAutocomplete';

type Props<TOption> = Omit<JSX.IntrinsicElements["input"], "color"> & {
  options: TOption[];
  getOptionLabel: (option: TOption) => string;
  onSelectOption?: (option: TOption) => void;
  color?: "gray" | "white";
  showDropDown?: boolean;
};

const Listbox = styled("ul")(({ theme }) => ({
  width: "100%",
  margin: 0,
  padding: 0,
  zIndex: 1,
  position: "absolute",
  top: "100%",
  left: "0",
  listStyle: "none",
  backgroundColor: theme.palette.mode === "light" ? "#fff" : "#000",
  overflow: "auto",
  maxHeight: 200,
  border: "1px solid #e2e5f1",
  "& li": {
    padding: "6px 16px",
  },
  "& li.Mui-focused": {
    backgroundColor: "#eff1f8",
    cursor: "pointer",
  },
  "& li:active": {
    backgroundColor: "#F5F6FA",
  },
}));

export const Search = function <TOption>({
  options,
  getOptionLabel,
  onSelectOption,
  color = "gray",
  showDropDown = true,
  className,
  ...props
}: Props<TOption>) {
  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    id: "use-autocomplete",
    options,
    getOptionLabel,
    clearOnBlur: true,
    clearOnEscape: true,
    onChange: (_, value, reason) => {
      if (reason === "selectOption" && value) {
        onSelectOption?.(value);
      }
    },
  });

  return (
    <div className={clsx(style.container, className)} {...getRootProps()}>
      <input
        type="text"
        className={clsx(style[color])}
        {...getInputProps()}
        {...props}
      />
      <SearchSVG />
      {groupedOptions.length > 0 && showDropDown ? (
        <Listbox {...getListboxProps()}>
          {(groupedOptions as TOption[]).map((option, index) => (
            <li
              {...getOptionProps({ option, index })}
              className={style.list_item}
            >
              {getOptionLabel(option)}
            </li>
          ))}
        </Listbox>
      ) : null}
    </div>
  );
};
