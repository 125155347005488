import {
  AddAdditionalSpace,
  AddPlanGroupModal,
  AddingDepartmentDocumentModal,
  ConfirmActionModal,
  CreateCompanyModal,
  CreateDepartmentModal,
  CreateDocumentTemplatesGroupModal,
  CreateEmployeeModal,
  CreateEventModal,
  CreateFolderDocumentTemplatesModal,
  CreateFolderModal,
  CreateLegalInfoFolderModal,
  CreatePlanEventModal,
  CreatePlanGroupModal,
  CreatePositionModal,
  CreateRoleModal,
  CreateSuperAdminModal,
  CreateTaskModal,
  CreateUserModal,
  CreateWorkspaceGroupModal,
  EditWorkspaceDocumentModal,
  EventEmailModal,
  NotificationModal,
  RespondToFeedbackModal,
  SelectCompanyModal,
  SendPrescriptionModal,
  SendingForSignatureModal,
  SignatureLogModal,
  UploadDocumentTemplatesFileModal,
  UploadLegalInformationModal,
  UploadResponsibilityModal,
  UploadUserImageModal,
  UploadWorkspaceDocumentModal,
  CreateWorkspaceDocumentModal,
  EditDocumentTemplatesFileModal,
  CreateQuestionAnswerModal,
  DocumentEditorModal,
} from "../modals/modal-items";
import {
  ModalContentT,
  ModalT,
  ModalsListT,
  SetModalComponentPropsT,
} from "@interfaces/modals";
import { createEvent, createStore } from "effector";

export const ModalsList: ModalsListT = {
  UploadDocumentTemplatesFileModal: UploadDocumentTemplatesFileModal,
  CreateFolderDocumentTemplatesModal: CreateFolderDocumentTemplatesModal,
  EventEmailModal: EventEmailModal,
  CreateDocumentTemplatesGroupModal: CreateDocumentTemplatesGroupModal,
  CreateCompanyModal: CreateCompanyModal,
  UploadUserImage: UploadUserImageModal,
  ConfirmActionModal: ConfirmActionModal,
  CreateUserModal: CreateUserModal,
  CreateWorkspaceGroupModal: CreateWorkspaceGroupModal,
  UploadWorkspaceDocumentModal: UploadWorkspaceDocumentModal,
  CreateWorkspaceDocumentModal: CreateWorkspaceDocumentModal,
  EditWorkspaceDocumentModal: EditWorkspaceDocumentModal,
  CreateEventModal: CreateEventModal,
  CreateDepartmentModal: CreateDepartmentModal,
  CreateEmployeeModal: CreateEmployeeModal,
  UploadResponsibilityModal: UploadResponsibilityModal,
  CreateFolderModal: CreateFolderModal,
  SendingForSignatureModal: SendingForSignatureModal,
  AddingDepartmentDocumentModal: AddingDepartmentDocumentModal,
  SignatureLogModal: SignatureLogModal,
  CreatePlanGroupModal: CreatePlanGroupModal,
  CreatePlanEventModal: CreatePlanEventModal,
  NotificationModal: NotificationModal,
  CreateSuperAdminModal: CreateSuperAdminModal,
  RespondToFeedbackModal: RespondToFeedbackModal,
  CreateLegalInfoFolderModal: CreateLegalInfoFolderModal,
  UploadLegalInformationModal: UploadLegalInformationModal,
  CreateTaskModal: CreateTaskModal,
  AddPlanGroupModal: AddPlanGroupModal,
  SendPrescriptionModal: SendPrescriptionModal,
  AddAdditionalSpace: AddAdditionalSpace,
  CreateRoleModal: CreateRoleModal,
  CreatePositionModal: CreatePositionModal,
  SelectCompanyModal,
  EditDocumentTemplatesFileModal,
  CreateQuestionAnswerModal,
  DocumentEditorModal: DocumentEditorModal,
};

// СОСТОЯНИЕ МОДАЛЬНОГО ОКНА
export const setModalDisplay = createEvent<boolean>(
  "Изменение состояния модального окна"
);

export const $modal = createStore<ModalT>({ display: false }).on(
  setModalDisplay,
  (_, newState) => ({ display: newState })
);

// КОМПОНЕНТ МОДАЛЬНОГО ОКНА
export const setModalComponent = createEvent<SetModalComponentPropsT>(
  "Изменения контента модального окна"
);

export const $modalComponent = createStore<ModalContentT>({
  key: Math.random(),
  component: ModalsList["CreateCompanyModal"],
  btnText: "Создать",
  onConfirm: null,
}).on(setModalComponent, (_, newState) => ({
  key: newState.key || Math.random(),
  component: ModalsList[newState.component],
  btnText: newState.btnText || "Создать",
  onConfirm: newState.onConfirm || null,
}));

// ДАННЫЕ ДЛЯ КОНТЕНТА МОДАЛЬНОГО ОКНА
export const setModalData = createEvent<any>(
  "Изменение данных контента модального окна"
);

export const $modalData = createStore<null | any>(null).on(
  setModalData,
  (_, newState) => newState
);
