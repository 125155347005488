// GROUP
export type WorkspaceGroupT = {
  id: number;
  name: string;
  companyId: number;
  isAvailable: boolean;
};

export type WorkspaceGroupData = Pick<WorkspaceGroupT, "companyId" | "name">;
export type UpdateWorkspaceGroupData = Pick<
  WorkspaceGroupT,
  "id" | "name" | "companyId"
>;
export type DeleteWorkspaceGroupData = Pick<
  WorkspaceGroupT,
  "id" | "companyId"
>;

export type WorkspaceGetDirectoriesAndFilesResponse = {
  directories: Omit<WorkspaceDirectoryT, "companyId" | "groupId">[];
  files: Omit<WorkspaceFileT, "companyId" | "groupId">[];
};

export type WorkspaceGetArchivedDirectoriesAndFilesResponse = {
  directories: (Omit<WorkspaceDirectoryT, "companyId" | "groupId"> & {
    directory: null | { name: string; id: number };
  })[];
  files: (Omit<WorkspaceFileT, "companyId" | "groupId"> & {
    directory: null | { name: string; id: number };
  })[];
};

export type WorkspaceGetFilesParams = {
  nameOrder?: "ASC" | "DESC";
  updatedOrder?: "ASC" | "DESC";
  createdOrder?: "ASC" | "DESC";
};

export type WorkspaceEmployeeParams = {
  companyId: number;
  employeeId: number;
};

export type WorkspaceArchiveEmployeesParams = {
  companyId: number;
  employees: number[];
};

export type WorkspaceCommonParams = {
  companyId: number;
  groupId: number;
  directoryId: number;
};

// DIRECTORIES
export type WorkspaceDirectoryT = {
  id: number;
  companyId: number;
  groupId: number | null;
  directoryId: number | null;
  name: string;
  isArchived: boolean;
  createdAt: string;
  updatedAt: string;
};

export type WorkspaceDirectoryShortDataT = {
  id: number;
  name: string;
};

export type WorkspaceDirectoryData = WorkspaceCommonParams & {
  name: string;
};

export type WorkspaceDocumentData = WorkspaceDirectoryData & {
  type: string;
};

export type WorkspaceDocumentConvertData = WorkspaceCommonParams & {
  fileType: string;
  title: string;
  url: string;
  fileId: string,
};

// FILES
export type WorkspaceFileT = {
  id: number;
  companyId: number;
  groupId: number | null;
  directoryId: number | null;
  name: string;
  path: string;
  isArchived: boolean;
  signingFrequency: SigningFrequency | null;
  createdAt: string;
  updatedAt: string;
};

export type WorkspaceFileShortDataT = {
  readonly id: number;
  name: string;
  path: string;
  downloadUrl?: string;
  hash: string;
  extension: string;
  signingFrequency: SigningFrequency | null;
  groupId?: number;
};

export type WorkspaceAddFilesData = WorkspaceCommonParams & {
  files: File[];
  signingFrequency: SigningFrequency;
};
export type WorkspaceUpdateFileData = WorkspaceCommonParams & {
  fileId: number;
  name: string;
  signingFrequency: SigningFrequency | null;
};
export type WorkspaceArchiveFileData = WorkspaceCommonParams & {
  fileId: number;
};
export type WorkspaceDeleteFileData = WorkspaceCommonParams & {
  fileId: number;
};
export type WorkspaceDownloadFileData = WorkspaceCommonParams & {
  fileId: number;
};

export enum SigningFrequency {
  OneTime = "Разово",
  Quarter = "Квартрал",
  OnceMonth = "Месяц",
  HalfYear = "Полгода",
  OnceYear = "Год",
}

export type WorkspaceTablePropsT = {
  disabled?: boolean;
};

export type WorkspaceGroupFile = {
  id: number;
  name: string;
};

export type RequestAccessForGroupData = {
  companyId: number;
  groupId: number;
};

export type AvailabilityWorkspaceGroupData = {
  companyId: number;
  groupId: number;
  isAvailable: boolean;
};
