import { $User, $UserAddPermissions, ACTIONS } from "@store/user-store";
import { CalendarBlank, NotePencil, Trash } from "@phosphor-icons/react";
import { Link, useHistory } from "react-router-dom";
import { PlanTaskStatusEnum } from "@interfaces/specialist-plan";
import { TableCell, TableRow, Tooltip } from "@mui/material";
import {
  pushToMonthEventsData,
  setNotificationEvents,
} from "@store/company/event-store";

import { EditSVG } from "@components/svgs";
import EventService from "@services/event-service";
import SpecialistPlanService from "@services/specialist-plan-service";
import { UserDataT } from "@interfaces/user";
import { WrapperSVG } from "@components/WrapperSVG/WrapperSVG";
import clsx from "clsx";
import moment from "moment";
import styles from "./workplan-group-item.module.scss";
import { useModal } from "@modals/index";
import { useStore } from "effector-react";
import { WorkplanGroupT, WorkplanTaskT } from "@interfaces/workplan";
import { useQueryClient } from "react-query";
import { workplanQueryKeys } from "@lib/workplan/queryKeys";

const TASK_STATUS_COMPLETED = 6;

export type WorkplanGroupItemProps = {
  task: WorkplanTaskT;
  group: WorkplanGroupT;
  isCompanyDefined: boolean;
};

export const WorkplanGroupItem: React.FC<WorkplanGroupItemProps> = ({
  task,
  group,
  isCompanyDefined,
}) => {
  const queryClient = useQueryClient();
  const permissions = useStore($UserAddPermissions);
  const user = useStore($User) as UserDataT;

  const history = useHistory();

  const { open, close } = useModal();

  let status: "s1" | "s2" | "s3" | "s4" | "s5" | "s6" | "s7";
  task.status
    ? (status = `s${task.status}` as
        | "s1"
        | "s2"
        | "s3"
        | "s4"
        | "s5"
        | "s6"
        | "s7")
    : (status = "s1");

  const isCalendarButtonDisabled = task.status === 4 || task.status === 6; // задачи со статусом "Выполнена" или "Подписание проверено" нельзя добавлять в календарь

  const handleTaskDeleting = (task: WorkplanTaskT) => {
    SpecialistPlanService.deleteTask(
      task.group.company.id,
      task.id,
      task.groupId,
      (err, res) => {
        if (err || !res) {
          return console.error("При удалении задачи произошла ошибка");
        }
        queryClient.invalidateQueries(workplanQueryKeys.all);
      }
    );
  };

  const handleCompleteChange = (status: number, taskId: number) => {
    SpecialistPlanService.changeTaskStatus(taskId, status, (err, res) => {
      if (err || !res?.data) {
        throw new Error("При изменении статуса задачи произошла ошибка");
      }
      queryClient.invalidateQueries(workplanQueryKeys.all);
    });
  };

  const clickOnStatusHandler = (task: WorkplanTaskT) => {
    let status = 5;
    if (task.status === 5) {
      status = 6;
    }
    open("ConfirmActionModal", {
      modalData: {
        text: `Отметить задачу "${task.title}" как ${
          task.status === 5 ? "выполненную" : "не выполненную"
        }?`,
      },
      btnText: "Да",
      onConfirm: () => {
        try {
          handleCompleteChange(status, task.id);
          task.status = status;
        } catch (err) {}
      },
    });
  };

  const handleSavingTaskToCalendar = (task: WorkplanTaskT) => {
    EventService.create(
      {
        companyId: task.group.company.id,
        taskId: task.id,
        title: task.title,
        desc: task.desc,
        dateStart: task.startDate,
        dateFinish: task.deadline,
        isFinancial: task.isFinancial,
        status: 1,
        groupId: task.groupId,
        createdBy: user.id,
      },
      (err, res) => {
        if (err || !res) {
          return console.error(
            "При сохранении задачи в календарь произошла ошибка"
          );
        }
        pushToMonthEventsData([res.data]);

        const currentDate = new Date();
        const startDate = new Date(task.startDate);
        const finishDate = new Date(task.deadline);

        if (
          (currentDate.getDate() === startDate.getDate() &&
            currentDate.getMonth() === startDate.getMonth() &&
            currentDate.getFullYear() === startDate.getFullYear()) ||
          (currentDate.getDate() === finishDate.getDate() &&
            currentDate.getMonth() === finishDate.getMonth() &&
            currentDate.getFullYear() === finishDate.getFullYear())
        ) {
          setNotificationEvents([res.data]);
        }
        if (!task.savedToCalendar) {
          SpecialistPlanService.changeSavedStatus(task.id, (err, res) => {
            if (err || !res) {
              return console.error(
                "При изменении состояния сохранения в календарь произошла ошибка"
              );
            }
            task.savedToCalendar = 1;
            queryClient.invalidateQueries(workplanQueryKeys.all);
          });
        }
        open("NotificationModal", {
          modalData: {
            modalTitle: "Создание ивента",
            text: "Ивент из задачи успешно создан",
          },
        });
      }
    );
  };

  const handleEditTask = async () => {
    if (task.status === TASK_STATUS_COMPLETED) {
      const ok = await new Promise((resolve) => {
        open("ConfirmActionModal", {
          btnText: "Да",
          modalData: {
            title: "Редактирование выполненной задачи",
            text: "Вы действительно хотите внести изменения в выполненную задачу?",
            cancelBtnText: "Нет",
            onCancel: () => {
              resolve(false);
              close();
            },
          },
          onConfirm: () => resolve(true),
        });
      });

      if (!ok) return;
    }

    open("CreatePlanEventModal", {
      btnText: "Сохранить",
      modalData: {
        modalTitle: "Редактировать задачу",
        groupId: group.id,
        companyId: task.group.company.id,
        editMode: true,
        task,
      },
    });
  };

  const handleViewTask = () => {
    if (task.status !== TASK_STATUS_COMPLETED) {
      return handleEditTask();
    }

    open("CreatePlanEventModal", {
      btnText: "Закрыть",
      modalData: {
        modalTitle: "Задача",
        groupId: group.id,
        companyId: task.group.company.id,
        editMode: true,
        task,
        disabled: true,
      },
    });
  };

  const ColorCell = (_day: number | null | string, status: number) => {
    if (status === 4 || status === 6 || status === 7) {
      return;
    }

    const targetDate = _day && new Date(_day);
    if (!_day || !targetDate) return "";

    const day = targetDate.getTime();

    if (day === undefined) return;
    if (day === null || day === 0) return styles.box_red;

    const currentDate = Date.now();
    const remainingTime = day - currentDate;
    const days = Math.ceil(remainingTime / (1000 * 60 * 60 * 24));

    if (remainingTime < 0) {
      return styles.box_red;
    }

    return days === 2
      ? styles.box_yellow
      : days === 1 || days < 1
        ? styles.box_red
        : "";
  };

  const stopPropagation = (cb: Function) => {
    return (e: React.MouseEvent) => {
      e.stopPropagation();
      cb();
    };
  };

  return (
    <TableRow
      className={clsx(styles.row, ColorCell(task.deadline, task.status))}
      {...((task.withSigning
        ? permissions.hasPermission(
            ACTIONS.companySpecialistWorkPlan_tasks_allowedToEdit
          )
        : permissions.hasPermission(
            ACTIONS.companyProcedureControls_allowedToEdit
          )) && {
        onClick: () => handleViewTask(),
      })}
    >
      {!isCompanyDefined && (
        <TableCell
          className={clsx(styles.cell_border, styles.cell_svg)}
          align="left"
          component="th"
          scope="row"
        >
          <Link to="/company/workplan">{task.group.company.name}</Link>
        </TableCell>
      )}
      <TableCell className={styles.cell_border} align="left">
        <div className={styles["text-hidden"]}>
          <span>{task.title}</span>
        </div>
      </TableCell>
      <TableCell className={styles.cell_border} align="center">
        {moment(task.startDate).format("DD.MM.YYYY")}
      </TableCell>
      <TableCell className={styles.cell_border} align="center">
        {moment(task.deadline).format("DD.MM.YYYY")}
      </TableCell>
      <TableCell className={styles.cell_border} align="center">
        <div
          className={clsx(styles.status, styles[status])}
          {...((task.status === 5 || task.status === 6) &&
            (task.withSigning
              ? permissions.hasPermission(
                  ACTIONS.companyDocumentSigningControl_allowedToEdit
                )
              : permissions.hasPermission(
                  ACTIONS.companySpecialistWorkPlan_tasks_allowedToEdit
                )) && {
              onClick: stopPropagation(() => clickOnStatusHandler(task)),
            })}
        >
          {PlanTaskStatusEnum[status]}
        </div>
      </TableCell>
      <TableCell className={styles.cell_border} align="left">
        <div className={styles["text-hidden"]}>{task.desc ?? ""}</div>
      </TableCell>
      <TableCell className={clsx(styles.cell_border)} align="left">
        <div className={styles.cell_icons}>
          {task.isFinancial && (
            <Tooltip title="Финансовая задача" placement="top">
              <button>
                <img
                  src="/img/static/coins.png"
                  height={"20px !important"}
                  alt=""
                />
              </button>
            </Tooltip>
          )}
          {!!task.withSigning && (
            <Tooltip title="Задача с подписанием документов" placement="top">
              <button
                onClick={stopPropagation(() =>
                  history.push("/company/signing-control")
                )}
              >
                <EditSVG />
              </button>
            </Tooltip>
          )}
          {permissions.hasPermission(
            ACTIONS.companySpecialistWorkPlan_tasks_allowedToSendToEventsCalendar
          ) && (
            <Tooltip title="Добавить в календарь" placement="top">
              <button
                disabled={isCalendarButtonDisabled}
                style={isCalendarButtonDisabled ? { opacity: 0.4 } : {}}
                onClick={stopPropagation(() =>
                  open("ConfirmActionModal", {
                    btnText: "Отправить",
                    modalData: {
                      text: task.savedToCalendar
                        ? `Задача уже отправлена в календарь. Отправить задачу "${task.title}" в календарь повторно?`
                        : `Отправить задачу "${task.title}" в календарь?`,
                    },
                    onConfirm: () => handleSavingTaskToCalendar(task),
                  })
                )}
              >
                <WrapperSVG color={task.savedToCalendar ? "green" : "blue"}>
                  <CalendarBlank size={24} />
                </WrapperSVG>
              </button>
            </Tooltip>
          )}
          {(task.withSigning
            ? permissions.hasPermission(
                ACTIONS.companyDocumentSigningControl_allowedToEdit
              )
            : permissions.hasPermission(
                ACTIONS.companySpecialistWorkPlan_tasks_allowedToEdit
              )) && (
            <Tooltip title="Редактировать задачу" placement="top">
              <button onClick={stopPropagation(handleEditTask)}>
                <WrapperSVG color={"orange"}>
                  <NotePencil size={24} />
                </WrapperSVG>
              </button>
            </Tooltip>
          )}
          {permissions.hasPermission(
            ACTIONS.companySpecialistWorkPlan_tasks_allowedToDelete
          ) && (
            <Tooltip title="Удалить задачу" placement="top">
              <button
                onClick={stopPropagation(() =>
                  open("ConfirmActionModal", {
                    modalData: {
                      text: `Вы уверены что хотите удалить задачу "${task.title}"?`,
                    },
                    btnText: "Удалить",
                    onConfirm: () => handleTaskDeleting(task),
                  })
                )}
              >
                <Trash size={24} />
              </button>
            </Tooltip>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};
