import SpecialistPlanService from "@services/specialist-plan-service";

import { TextField } from "@mui/material";

import { useModal } from "@modals/index";
import useStyles from "@ui/material-ui-styles";
import { useQueryClient } from "react-query";
import { workplanQueryKeys } from "@lib/workplan/queryKeys";

const CreatePlanGroupModal = () => {
  const queryClient = useQueryClient();

  const { modalComponent, modalData, close } = useModal();
  const classes = useStyles();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const titleField = document.querySelector(
      'input[name="groupTitle"]'
    ) as HTMLInputElement;

    if (!titleField || !titleField.value) return;

    if (modalData.editMode) {
      if (!modalData.itemId) {
        throw new Error("Отсутствует идентфикатор для редактирования данных");
      }
      SpecialistPlanService.updateGroup(
        modalData.companyId,
        modalData.itemId,
        titleField.value,
        (err, res) => {
          if (err || !res) {
            return console.error(
              "При изменении данных группы произошла ошибка"
            );
          }
          queryClient.invalidateQueries(workplanQueryKeys.all);
          close();
        }
      );
      return;
    }
    // CREATING GROUP
    SpecialistPlanService.createGroup(
      modalData.companyId,
      { title: titleField.value },
      (err, res) => {
        if (err || !res) {
          return console.error("При создании группы произошла ошибка");
        }
        queryClient.invalidateQueries(workplanQueryKeys.all);
      }
    );

    close();
  };

  return (
    <div key={modalComponent.key}>
      <p className="modal_title">{modalData.modalTitle}</p>
      <div className="underline" />
      <form className={`modal_content ${classes.root}`} onSubmit={handleSubmit}>
        <TextField
          label={"Название категории"}
          name={"groupTitle"}
          variant="filled"
          required
          defaultValue={modalData.itemTitle}
        />
        <button type="submit" className="modal_btn">
          {modalComponent.btnText}
        </button>
      </form>
    </div>
  );
};

export default CreatePlanGroupModal;
