import { FC, useState } from "react";
import {
  SigningControlJournalParams,
  signingControlJournalSortingKeys,
} from "@interfaces/signing-control";

import { Button } from "@ui/button";
import { Center } from "@mantine/core";
import { DatePeriodInput } from "@components/date-period-input";
import { DropDownLabel, DropDownMultiSelect } from "@ui/drop-down";
import { Loader } from "@ui/indicators";
import { Pagination } from "@components/pagination";
import { SigningControlJournalTable } from "feature/signing-control-journal-table";
import { Typography } from "@mui/material";
import { downloadBlob } from "@utils/downloadBlob";
import { handleApiErrorLocally } from "@utils/handleApiError";
import styles from "@scss/pages/company/signing-control-journal.module.scss";
import toast from "react-hot-toast";
import { useChoiceState } from "@hooks/useChoiceState";
import { useCompanyPositions } from "@lib/company-positions";
import { useDepartmentListQuery } from "@lib/departments";
import { useDownloadSigningControlJournalMutation } from "@lib/signing-control-journal/useDownloadSigningControlJournalMutation";
import { useEmployeeListQuery } from "@lib/employees/useEmployeeListQuery";
import { useSigningControlJournalQuery } from "@lib/signing-control-journal";
import { useSorting } from "@hooks/useSorting";
import { useWorkspaceGroupFilesQuery } from "@lib/workspace/useWorkspaceGroupFilesQuery";
import { useWorkspaceGroupsQuery } from "@lib/workspace";
import { useSelectedCompanyQuery } from "@lib/company";

const LIMIT = 20;
const getOffset = (page: number) => LIMIT * (page - 1);

const FIRST_CHOICE = {
  id: -1,
  title: "Выбрать все",
};

const statusList = [
  FIRST_CHOICE,
  {
    id: 1,
    title: "Документ подписан",
  },
  {
    id: 2,
    title: "Отправлено на подписание",
  },
];

const SigningControlJournal: FC = () => {
  const { company } = useSelectedCompanyQuery();

  const [page, setPage] = useState(1);

  const sorting = useSorting(signingControlJournalSortingKeys, "ASC");

  const { groups } = useWorkspaceGroupsQuery(company?.id);
  const { files } = useWorkspaceGroupFilesQuery(company?.id, [
    0,
    ...(groups?.map((group) => group.id) ?? []),
  ]);
  const filesList = [
    FIRST_CHOICE,
    ...(files?.map((file) => ({
      id: file.id,
      title: file.name,
    })) ?? []),
  ];

  const { data: employees } = useEmployeeListQuery();
  const employeesList = [
    FIRST_CHOICE,
    ...(employees?.map((employee) => ({
      id: employee.id,
      title: employee.name ?? "",
    })) ?? []),
  ];

  const { data: companyPositions } = useCompanyPositions();
  const positionsList = [
    FIRST_CHOICE,
    ...(companyPositions?.map((position) => ({
      id: position.id,
      title: position.title,
    })) ?? []),
  ];

  const { data: departments } = useDepartmentListQuery();
  const departmentsList = [
    FIRST_CHOICE,
    ...(departments?.map((department) => ({
      id: department.id,
      title: department.title,
    })) ?? []),
  ];

  const [period, setPeriod] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [selectedStatus, setSelectedStatus] = useState<string>();
  const [selectedFiles, setSelectedFiles] = useState<number[]>([]);
  const [selectedEmployees, setSelectedEmployees] = useState<number[]>([]);
  const [selectedDepartments, setSelectedDepartments] = useState<number[]>([]);
  const [selectedPositions, setSelectedPositions] = useState<number[]>([]);

  const offset = getOffset(page);
  const params: SigningControlJournalParams = {
    companyId: company?.id ?? -1,
    limit: LIMIT,
    offset,
    signedStart: period[0]?.toISOString() ?? undefined,
    signedEnd: period[1]?.toISOString() ?? undefined,
    employeesId: selectedEmployees,
    documentsId: selectedFiles,
    status:
      selectedStatus && selectedStatus !== "-1"
        ? parseInt(selectedStatus)
        : undefined,
    departmentsId: selectedDepartments,
    positionsId: selectedPositions,

    ...sorting.getActiveSorting(),
  };
  const { data, isLoading } = useSigningControlJournalQuery(params);

  const choice = useChoiceState(data?.rows ?? [], (item) => item.id);

  const downloadSigningControlJournalMutation =
    useDownloadSigningControlJournalMutation();

  const handleDownload = (params: SigningControlJournalParams) => {
    downloadSigningControlJournalMutation.mutate(params, {
      onSuccess: (file) => {
        const filename = `${company?.name} - Журнал подписания документов.xlsx`;
        downloadBlob(file, filename);
      },
      onError: (error) => {
        handleApiErrorLocally(error, (err) => {
          if (err) {
            toast.error(err);
          }
        });
      },
    });
  };

  const handleDownloadSelected = () => {
    handleDownload({
      ...params,
      signingsId: choice.selectedItems.map((item) => item.id),
    });
  };

  const totalPages = Math.ceil((data?.count ?? 0) / LIMIT);

  const handleDropdownChange = (
    value: string,
    setState: (values: number[]) => void
  ) => {
    setState(
      value
        .split(",")
        .map((v) => parseInt(v))
        .filter((id) => !isNaN(id) && id !== -1)
    );
  };

  return (
    <div className={styles.signingControlJournal}>
      <div className={styles.signingControlJournal__filters}>
        <DropDownMultiSelect
          typeStyle="input"
          defaultTitle="Подразделения"
          direction={departmentsList}
          allId={FIRST_CHOICE.id}
          handlerChange={(value) =>
            handleDropdownChange(value, setSelectedDepartments)
          }
        />
        <DropDownMultiSelect
          typeStyle="input"
          defaultTitle="Должности"
          direction={positionsList}
          allId={FIRST_CHOICE.id}
          handlerChange={(value) =>
            handleDropdownChange(value, setSelectedPositions)
          }
        />
        <DropDownMultiSelect
          typeStyle="input"
          defaultTitle="Сотрудники"
          direction={employeesList}
          allId={FIRST_CHOICE.id}
          handlerChange={(value) =>
            handleDropdownChange(value, setSelectedEmployees)
          }
        />
        <DropDownMultiSelect
          typeStyle="input"
          defaultTitle="Документы"
          direction={filesList}
          allId={FIRST_CHOICE.id}
          handlerChange={(value) =>
            handleDropdownChange(value, setSelectedFiles)
          }
        />
        <DropDownLabel
          label="Статус"
          list={statusList}
          type="status"
          handlerChangeFilter={(_, value) => setSelectedStatus(value)}
        />
        <DatePeriodInput
          label="Дата подписания"
          value={period}
          onChange={setPeriod}
        />
      </div>
      <DownloadButton
        selectedItemsCount={choice.selectedItems.length}
        isLoading={downloadSigningControlJournalMutation.isLoading}
        params={params}
        onDownload={handleDownload}
        onDownloadSelected={handleDownloadSelected}
      />
      {isLoading || !data?.rows ? (
        <Center h={LIMIT * 90 + 10}>
          <Loader />
        </Center>
      ) : data.rows.length === 0 ? (
        <Center h={300}>
          <Typography>Нет данных</Typography>
        </Center>
      ) : (
        <SigningControlJournalTable
          data={data.rows}
          offset={offset}
          sorting={sorting}
          choice={choice}
        />
      )}
      <div className={styles.signingControlJournal__pagination}>
        <Pagination value={page} onChange={setPage} total={totalPages} />
      </div>
    </div>
  );
};

type DownloadButtonProps = {
  selectedItemsCount: number;
  isLoading: boolean;
  params: SigningControlJournalParams;
  onDownload: (params: SigningControlJournalParams) => void;
  onDownloadSelected: () => void;
};

const DownloadButton: FC<DownloadButtonProps> = ({
  selectedItemsCount,
  isLoading,
  params,
  onDownload,
  onDownloadSelected,
}) => {
  if (selectedItemsCount > 0) {
    return (
      <Button
        className={styles.signingControlJournal__downloadBtn}
        onClick={() => onDownloadSelected()}
      >
        {isLoading ? <Loader width={24} height={24} /> : "Скачать выбранное"}
      </Button>
    );
  }

  return (
    <Button
      className={styles.signingControlJournal__downloadBtn}
      onClick={() => onDownload(params)}
    >
      {isLoading ? <Loader width={24} height={24} /> : "Скачать xlsx"}
    </Button>
  );
};

export default SigningControlJournal;
