import {
  ColorfulActionButtonPropsT,
  ItemsOfStringArrayT,
  SortOptionT,
  TabItemsPropsT,
  TableSearchProcedurePropsT,
  TableTopPanelPropsT,
  TitlePropsT,
} from "@interfaces/common";
import { Link, useLocation } from "react-router-dom";
import React, { FC } from "react";
import Select, { StylesConfig } from "react-select";

import { BackButton } from "@components/back-button";
import { EmbedPDF } from "@simplepdf/react-embed-pdf";
import { EyeSVG } from "@components/svgs/eye";
import { WarnSVG } from "@components/svgs";
import { WrapperSVG } from "@components/WrapperSVG/WrapperSVG";
import styles from "@scss/common/common.module.scss";
import clsx from "clsx";

export const BreadCrumb: FC<ItemsOfStringArrayT> = ({ items }) => {
  const content = items.map((item, idx) => {
    return (
      <div key={item + idx} className="bread-crumb-item">
        {item}
        {idx + 1 !== items.length ? (
          <p style={{ margin: "0 10px" }}>/</p>
        ) : null}
      </div>
    );
  });

  return <div className="bread-crumbs">{content}</div>;
};

export const TableTopPanel = (props: TableTopPanelPropsT) => {
  const {
    text,
    options,
    onSelectOption,
    onSearch,
    hideSearchPanel,
    hideSelectPanel,
    withoutPadding,
  } = props;

  const onSelectOptionsHandler = (option: SortOptionT) => {
    if (onSelectOption) {
      onSelectOption(option);
    }
  };

  let sortOptions = [
    { label: "Сначала новые", value: 10 },
    { label: "Сначала старые", value: 20 },
  ];

  if (options) {
    sortOptions = options;
  }

  return (
    <div
      className={`table-top-panel ${withoutPadding ? "without_padding" : ""}`}
    >
      <p className="font-semibold">{text}</p>
      <div className="table-top-panel__widgets">
        <div
          className={`search-panel ${withoutPadding ? "without_margin" : ""} ${
            hideSearchPanel ? "hidden" : ""
          }`}
        >
          <input
            type="text"
            name="search"
            id="search"
            placeholder="Поиск"
            className="search-field"
          />
          <img
            src="/img/static/search-icon.png"
            alt=""
            className="search-icon"
            onClick={() => {
              const searchingValue = document.querySelector(
                "#search"
              ) as HTMLInputElement;
              return onSearch ? onSearch(searchingValue?.value || "") : null;
            }}
          />
        </div>
        <Select
          name="sort-option"
          id="sort-option-select"
          className={hideSelectPanel ? "hidden" : ""}
          defaultValue={{ label: "Сортировка", value: 0 }}
          menuPortalTarget={document.body}
          variant="standard"
          theme={(theme: { colors: any; }) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: "#eaeaea",
              primary: "#00B856",
            },
          })}
          options={sortOptions}
          onChange={
            onSelectOptionsHandler as (option: SortOptionT | null) => void
          }
        />
      </div>
    </div>
  );
};

export const TableSearchProcedure = (props: TableSearchProcedurePropsT) => {
  const {
    text,
    onSearch,
    hideSearchPanel = "hidden",
    withoutPadding = true,
  } = props;

  return (
    <div
      className={`table-top-panel ${withoutPadding ? "without_padding" : ""}`}
    >
      <p className="font-semibold">{text}</p>
      <div className="table-top-panel__widgets">
        <div
          className={`search-panel ${withoutPadding ? "without_margin" : ""} ${
            hideSearchPanel ? "hidden" : ""
          }`}
        >
          <input
            type="text"
            name="search"
            id="search"
            placeholder="Поиск"
            className="search-field"
          />
          <img
            src="/img/static/search-icon.png"
            alt=""
            className="search-icon"
            onClick={() => {
              const searchingValue = document.querySelector(
                "#search"
              ) as HTMLInputElement;
              return onSearch ? onSearch(searchingValue?.value || "") : null;
            }}
          />
        </div>
      </div>
    </div>
  );
};

export const TabItems: React.FC<TabItemsPropsT> = ({
  items,
  warning,
  warningLoading,
}) => {
  const { pathname } = useLocation();
  const tabs = items.map((item, idx) => {
    const regExp = new RegExp(`${item.path}${item.exact ? "$" : ""}`, "i");
    const isActive =
      regExp.test(pathname) ||
      (pathname.includes("procedure-control") &&
        item.path.includes("procedure-control"));

    return (
      <Link
        to={item.path}
        key={idx}
        className={`tab-item ${isActive ? "active" : ""}`}
      >
        <div className={styles.tab_warning}>
          {(!warning?.isBriefingFill || !warning?.isTrainingFill) &&
            !warningLoading &&
            item.path.includes("procedure-control") && <WarnSVG />}
          {item.label}
        </div>
      </Link>
    );
  });

  return <div className="tab-items">{tabs}</div>;
};

// GREEN BUTTON WITH PAGE TITLE FOR CREATING NEW RESOURCE

export const ColorfulButton: React.FC<ColorfulActionButtonPropsT> = (props) => {
  const {
    link,
    text,
    type,
    plusIcon = true,
    customIcon,
    className,
    onClick,
  } = props;

  let content = plusIcon ? (
    <svg
      width="20"
      height="20"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 6v6m0 0v6m0-6h6m-6 0H6"
      />
    </svg>
  ) : null;

  content = customIcon || content;

  if (!link) {
    return (
      <button
        className={clsx("colorful_action_btn", className, {
          colorful_action_btn_noText: !text,
        })}
        onClick={(e) => (onClick ? onClick(e) : null)}
        type={type || "submit"}
      >
        {text && text}
        {content}
      </button>
    );
  }

  return (
    <Link
      to={link}
      className={clsx("colorful_action_btn", className, {
        colorful_action_btn_noText: !text,
      })}
      onClick={(e) => (onClick ? onClick(e) : null)}
    >
      {text && text}
      {content}
    </Link>
  );
};
export const selectTheme = (theme: any) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#eaeaea",
    primary: "#00B856",
  },
});

export const selectColourStyles = (
  containerStyles: any = {},
  { isError = false }: any = {}
): StylesConfig<any, any> => ({
  menuPortal: (base) => ({
    ...base,
    zIndex: 100000,
  }),
  container: (base) => ({
    ...base,
    backgroundColor: "#f5f6fa",
    marginTop: "15px",
    minHeight: "55px",
    maxHeight: "max-content",
    borderRadius: "5px",
    ...containerStyles,
    minWidth: "100px",
    ...(isError ? { border: "1px solid red" } : {}),
  }),
  control: (styles) => ({
    ...styles,
    backgroundColor: "#f5f6fa",
    minHeight: "55px",
    height: "100%",
    maxHeight: "165px",
    border: "none",
    overflowY: "auto",
  }),
  multiValue: (base) => ({
    ...base,
    fontSize: "18px",
    backgroundColor: "#eeeeee",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
  input: (base) => ({
    ...base,
    width: "100%",
    height: "100%",
  }),
});
export const selectColourStylesBoxes = (
  containerStyles: any = {}
): StylesConfig<any, any> => ({
  menuPortal: (base) => ({
    ...base,
    zIndex: 100000,
  }),
  container: (base) => ({
    ...base,
    backgroundColor: "#f5f6fa",
    marginTop: "15px",
    minHeight: "55px",
    maxHeight: "max-content",
    borderRadius: "5px",
    ...containerStyles,
    minWidth: "100px",
  }),
  control: (styles) => ({
    ...styles,
    backgroundColor: "#f5f6fa",
    minHeight: "55px",
    height: "100%",
    maxHeight: "165px",
    border: "none",
    overflowY: "auto",
  }),
  multiValue: (base) => ({
    ...base,
    fontSize: "18px",
    backgroundColor: "#eeeeee",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
  input: (base) => ({
    ...base,
    width: "100%",
    height: "100%",
  }),
});

export const selectColourStyles2 = (
  containerStyles: any = {}
): StylesConfig<any, any> => ({
  menuPortal: (base) => ({
    ...base,
    zIndex: 100000,
  }),
  container: (base) => ({
    ...base,
    backgroundColor: "#f5f6fa",
    height: "40px",
    maxWidth: "190px",
    maxHeight: "max-content",
    borderRadius: "20px",
    ...containerStyles,
  }),
  control: (styles) => ({
    ...styles,
    backgroundColor: "#f5f6fa",
    height: "36px",
    border: "none",
    ...containerStyles,
  }),
  multiValue: (base) => ({
    ...base,
    fontSize: "18px",
    backgroundColor: "#eeeeee",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
  option: (base, { isDisabled }) => ({
    ...base,
    color: "#373737",
    ...(isDisabled && { backgroundColor: "#eeeeee", opacity: 0.3 }),
  }),
});
export const selectColourStylesTarrifChangeButton = (
  controlStyles: any = {}
): StylesConfig<any, any> => ({
  menuPortal: (base) => ({
    ...base,
    zIndex: 100000,
  }),
  container: (base) => ({
    ...base,
    backgroundColor: "transparent",
    height: "36px",
    maxHeight: "max-content",
    cursor: "pointer",
    whiteSpace: "nowrap",
    minWidth: "165px",
    width: "max-content",
  }),
  control: (styles) => ({
    ...styles,
    height: "36px",
    color: "#fff",
    borderRadius: "18px",
    display: "flex",
    alignItems: "center",
    ...controlStyles,
  }),
  input: (styles) => ({ ...styles, color: "#fff" }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
  placeholder: (base) => ({
    ...base,
    color: "#fff",
  }),
  valueContainer: (base) => ({
    ...base,
    color: "#fff",
    padding: "0 12px",
  }),
  singleValue: (base) => ({
    ...base,
    color: "#fff",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#fff !important",
    transform: "scale(1.2)",
  }),
  option: (base) => ({
    ...base,
    color: "#373737",
  }),
  loadingIndicator: (base) => ({
    ...base,
    color: "#fff !important",
  }),
});
export const selectColourStylesSigning = (
  containerStyles: any = {}
): StylesConfig<any, any> => ({
  menuPortal: (base) => ({
    ...base,
    zIndex: 100000,
  }),
  container: (base) => ({
    ...base,
    backgroundColor: "#f5f6fa",
    marginTop: "15px",
    minHeight: "55px",
    maxHeight: "max-content",
    borderRadius: "20px",
    ...containerStyles,
  }),
  control: (styles) => ({
    ...styles,
    backgroundColor: "#f5f6fa",
    minHeight: "55px",
    height: "100%",
    maxHeight: "165px",
    border: "none",
    overflowY: "scroll",
  }),
  multiValue: (base) => ({
    ...base,
    fontSize: "18px",
    backgroundColor: "#eeeeee",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? "red"
        : isSelected
          ? "#00B856"
          : isFocused
            ? "#eeeeee"
            : undefined,
      color: isDisabled ? "#ccc" : isSelected ? "white" : undefined,
    };
  },
});
export const Title: React.FC<TitlePropsT> = ({ text, withHistory }) => {
  if (withHistory) {
    return <BackButton />;
  }

  return <h1 className="content-title">{text}</h1>;
};

export type DocumentViewButtonPropsT = {
  fileName: string;
  filePath: string;
};

export const DocumentViewButton = React.forwardRef(
  (
    { fileName, filePath }: DocumentViewButtonPropsT,
    ref: React.LegacyRef<HTMLButtonElement> | undefined
  ) => {
    const fileUrl = `${process.env.API_URL}/${filePath}`;

    const viewBtnForPDF = (
      <EmbedPDF>
        <a href={fileUrl}>
          <WrapperSVG color={"blue-dark"} margin>
            <EyeSVG size={24} />
          </WrapperSVG>
        </a>
      </EmbedPDF>
    );

    const viewBtnForImage = (
      <button ref={ref}>
        <a href={fileUrl} target="_blank" rel="noreferrer">
          <WrapperSVG color={"blue-dark"} margin>
            <EyeSVG size={24} />
          </WrapperSVG>
        </a>
      </button>
    );
    const extension = fileName.split(".").pop();

    switch (extension) {
      case "jpeg":
        return viewBtnForImage;
      case "jpg":
        return viewBtnForImage;
      case "png":
        return viewBtnForImage;
      case "pdf":
        return viewBtnForPDF;
      default:
        return null;
    }
  }
);
