import {
  $SelectedPrescription,
  pushToPrescriptions,
  setSelectedPrescription,
  updatePrescription,
} from "@store/prescription-store";
import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import Dropzone from "@ui/dropzone";
import PrescriptionService from "@services/prescription-service";
import { PrescriptionsFilesList } from "@components/prescriptions/prescription-item";
import { TextField } from "@mui/material";
import Validator from "@utils/validator";
import clsx from "clsx";
import style from "@scss/pages/prescription/create-prescription.module.scss";
import { useStore } from "effector-react";
import useStyles from "@ui/material-ui-styles";
import { useSelectedCompanyQuery } from "@lib/company";

const MAX_FILE_SIZE_MB = 64;

const CreatePrescription: React.FC = () => {
  const prescription = useStore($SelectedPrescription);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [validation, setValidation] = useState({
    titleError: "",
    descError: "",
    executorError: "",
  });
  const [uploadError, setUploadError] = useState("");
  const { company } = useSelectedCompanyQuery();

  const classes = useStyles();
  const query = useRouteMatch<{ id: string }>();
  const history = useHistory();

  const editMode = !!query.params?.id;
  let asd = false;

  useEffect(() => {
    if (editMode && !prescription) {
      return history.push("/prescriptions");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!company) return;

    const title = document.querySelector(
      'input[name="title"]'
    ) as HTMLInputElement;
    const desc = document.querySelector(
      'textarea[name="desc"]'
    ) as HTMLTextAreaElement;

    const validating: any = {
      ...Validator(title.value, "title")
        .isRequired("Это поле обязательно")
        .getErrors(),
      ...Validator(desc.value, "desc")
        .isRequired("Это поле обязательно")
        .getErrors(),
    };

    if (Validator.hasError(validating)) {
      return setValidation(validating);
    }

    const formData = new FormData();
    formData.append("title", title.value);
    formData.append("desc", desc.value);
    formData.append("companyId", `${company.id}`);
    uploadedFiles.forEach((file) => formData.append("files", file));

    if (editMode) {
      if (!prescription) return;
      return PrescriptionService.update(
        prescription._id,
        formData,
        (err, res) => {
          if (err || !res) {
            if (err?.response?.status === 422) {
              return setValidation(validating);
            }
            return console.error("При обновлении предписания произошла ошибка");
          }
          updatePrescription(res.data);
          setSelectedPrescription(res.data);
          history.push("/company/prescriptions");
        }
      );
    }
    // CREATING NEW PRESCRIPTION
    PrescriptionService.create(formData, async (err, res) => {
      if (err || !res) {
        if (err?.response?.status === 422) {
          return setValidation(validating);
        }
        return console.error("При создании предписания произошла ошибка");
      }
      pushToPrescriptions([res.data]);
      setSelectedPrescription(res.data);
      asd
        ? history.push(`/company/prescriptions/${res.data._id}?send=true`)
        : history.push("/company/prescriptions");
    });
  };

  return (
    <div className={clsx(style.creation_content_container)}>
      <div className={clsx(style.title_panel)}>
        {!editMode ? "Создать предписание" : "Изменить предписание"}
      </div>
      <div className="underline" />
      <form className={`${classes.root}`} onSubmit={handleSubmit}>
        <TextField
          label="Введите название"
          placeholder={"Название"}
          variant="filled"
          name={"title"}
          defaultValue={editMode ? prescription?.title : ""}
          required
        />
        <p className="error-text">{validation.titleError}</p>
        <p className="error-text">{validation.executorError}</p>
        <TextField
          label="Введите текст"
          placeholder={"Текст"}
          variant="filled"
          required
          name={"desc"}
          defaultValue={editMode ? prescription?.desc : ""}
          multiline
          rows={4}
        />
        <p className="error-text">{validation.descError}</p>
        {/* FILE DROPZONE */}
        <div className={clsx(style.dropzone)}>
          <Dropzone
            onUpload={(files) => setUploadedFiles(files)}
            maxFiles={5}
            maxFileSize={MAX_FILE_SIZE_MB * 1024 * 1024}
            maxFileSizeUnits="Мб"
            error={uploadError}
            onError={setUploadError}
          />
          {editMode && prescription ? (
            <p>Загруженные ранее: {prescription.files.length}</p>
          ) : null}
        </div>
        {/* UPLOADED FILES (ONLY FOR EDIT MODE) */}
        <PrescriptionsFilesList files={uploadedFiles} />
        <button className={clsx(style.submit_btn)} type={"submit"}>
          Сохранить
        </button>

        {!editMode && (
          <button
            onClick={() => (asd = true)}
            className={clsx(style.submit_btn)}
            type={"submit"}
          >
            Сохранить и отправить
          </button>
        )}
      </form>
    </div>
  );
};

export default CreatePrescription;
