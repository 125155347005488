import {
  $AllTariffs,
  fetchAllTariffs,
  updateTariff,
} from "@store/payment-store";
import {
  ArrowDropDownGraySVG,
  CheckboxCheckedSVG,
  CheckboxMinusSVG,
  CheckboxSVG,
  PencilSVG,
} from "@components/svgs";
import { DEFAULT_TARIFFS, TariffT } from "@interfaces/payment";
import React, { useEffect, useMemo, useState } from "react";

import { Checkbox, Tooltip } from "@mui/material";
import { ColorfulButton } from "@components/common/common";
import { DropDownSelect } from "@ui/drop-down-select";
import Gradient from "@components/gradient/Gradient";
import Loader from "@ui/indicators/loader";
import { Skeleton } from "@mui/material";
import TariffService from "@services/tariff-service";
import { TextField } from "@mui/material";
import clsx from "clsx";
import styles from "@scss/pages/payment-edit.module.scss";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { useModal } from "@modals/index";
import { useStore } from "effector-react";
import useStyles from "@ui/material-ui-styles";

// Это костыль, чтобы быстро решить проблему с пустым стором
const PaymentTariffEdit: React.FC = () => {
  const allTariffs = useStore($AllTariffs);

  useEffect(() => {
    if (!allTariffs) {
      fetchAllTariffs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return allTariffs ? <PaymentTariffEditLogic /> : <Loader />;
};

const PaymentTariffEditLogic: React.FC = () => {
  const allTariffs = useStore($AllTariffs) as TariffT[];
  const { open } = useModal();
  const classes = useStyles();
  const [fillColor, setFillColor] = useState<string>("#ffaa2b");
  const [strokeColor, setStrokeColor] = useState<string>("#ffaa2b");
  const [selectedTariffId, setSelectedTariffId] = useState<number>(1);
  const [selectedTariff, SetSelectedTariff] = useState(
    allTariffs.find((tariff) => tariff.id === selectedTariffId) as TariffT
  );

  const history = useHistory();

  useEffect(() => {
    const tariff = allTariffs.find(
      (tariff) => tariff.id === selectedTariffId
    ) as TariffT;
    SetSelectedTariff(tariff);
    setFillColor(tariff.fillColor);
    setStrokeColor(tariff.strokeColor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTariffId]);

  const changeIncludingOfModule = (id: number) => {
    SetSelectedTariff({
      ...selectedTariff,
      modules: selectedTariff!.modules.map((module) => {
        if (module.id === id) {
          return { ...module, includes: !module.includes };
        }
        return module;
      }),
    });
  };
  const changeTariffName = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    SetSelectedTariff({
      ...selectedTariff,
      title: e.target.value,
    });
  };
  const changeTariffPrice = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    SetSelectedTariff({
      ...selectedTariff,
      price: +e.target.value,
    });
  };
  const deleteModule = (id: number) => {
    SetSelectedTariff({
      ...selectedTariff,
      modules: selectedTariff!.modules.filter((module) => module.id !== id),
    });
  };
  const addModule = () => {
    const newId =
      selectedTariff.modules.length > 0
        ? selectedTariff.modules.slice(-1)[0].id + 1
        : 1;
    const newModules = [
      ...selectedTariff!.modules,
      { id: newId, name: "Новый функционал", includes: false },
    ];
    SetSelectedTariff({
      ...selectedTariff,
      modules: newModules,
    });
  };
  const editModuleName = (id: number, name: string) => {
    SetSelectedTariff({
      ...selectedTariff,
      modules: selectedTariff!.modules.map((module) => {
        if (module.id === id) {
          return { ...module, name };
        }
        return module;
      }),
    });
  };
  const saveChanges = () => {
    const data: Partial<TariffT> = {};
    data.title = selectedTariff.title;
    data.space = selectedTariff.space;
    data.price = selectedTariff.price;
    data.modules = selectedTariff.modules;
    data.fillColor = fillColor;
    data.strokeColor = strokeColor;
    TariffService.update(selectedTariff.id, data, (err, res) => {
      if (err || !res) {
        toast.error(`Не удалось изменить тариф: ${err?.message}`);
      }
      if (res) {
        updateTariff(selectedTariff);
        toast.success("Тариф изменён");
      }
      history.goBack();
    });
  };

  const restoreTariff = (tariffId: number) => {
    const defaultTariff = DEFAULT_TARIFFS.find(
      (tariff) => tariff.id === tariffId
    ) as TariffT;
    setFillColor(defaultTariff.fillColor);
    setStrokeColor(defaultTariff.strokeColor);
    SetSelectedTariff(defaultTariff);
    open("NotificationModal", {
      modalData: {
        modalTitle: "",
        text: `Тариф восстановлен по умолчанию! Чтобы изменения применились, нажмите сохранить.`,
      },
    });
  };

  const functionalityList = useMemo(() => {
    if (!selectedTariff) return <div>Ошибка</div>;
    return selectedTariff.modules.map((module) => {
      return (
        <div className={styles.functional_item}>
          <Checkbox
            checked={module.includes}
            name="isFinancial"
            color="primary"
            icon={<CheckboxSVG />}
            checkedIcon={<CheckboxCheckedSVG />}
            onChange={(e) => changeIncludingOfModule(module.id)}
          />
          <ChangableName
            name={module.name}
            id={module.id}
            changeName={editModuleName}
          />
          <Tooltip title="Удалить" placement={"top"}>
            <button
              className={styles.delete}
              onClick={() => deleteModule(module.id)}
            >
              <CheckboxMinusSVG />
            </button>
          </Tooltip>
        </div>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTariff, selectedTariff.modules]);
  const tariffOptions = allTariffs.map((tariff) => ({
    value: tariff.id,
    label: tariff.title,
  }));
  return (
    <section className="tab-content-item">
      <div className="tab-content-item__top-panel">
        <h4 className="tab-content-title flex-n-c">
          Редактирование тарифа
          <DropDownSelect
            className={styles.selectTariff}
            title={"Base"}
            options={tariffOptions}
            value={tariffOptions.find(
              (tariff) => tariff.value === selectedTariffId
            )}
            onChange={(option) => setSelectedTariffId(option.value)}
          />
          <div className={styles.arrow}>
            <ArrowDropDownGraySVG />
          </div>
        </h4>
      </div>
      <div className={clsx(classes.root, styles.content)}>
        <TextField
          label="Название"
          placeholder={"Введите название тарифа"}
          variant="filled"
          name={"title"}
          value={selectedTariff?.title}
          onChange={changeTariffName}
          required
          inputProps={{ maxLength: 200 }}
        />
        <TextField
          label="Цена"
          placeholder={"Введите цену в рублях"}
          variant="filled"
          name={"title"}
          value={selectedTariff?.price}
          onChange={changeTariffPrice}
          required
        />
        <div className={styles.block}>
          <p className={styles.block_title}>Оформление</p>
          <div className={styles.block_content}>
            <div className={styles.colors}>
              <div className={styles.color}>
                <p>Основной цвет</p>
                <div>
                  <Gradient id={"color-picker-1"} onChange={setFillColor} />
                </div>
              </div>
              <div className={styles.color}>
                <p>Контур</p>
                <div>
                  <Gradient id={"color-picker-2"} onChange={setStrokeColor} />
                </div>
              </div>
            </div>
            <div
              className={styles.demo}
              style={{ backgroundColor: fillColor, borderColor: strokeColor }}
            >
              <p className={styles.demo_title}>Предпросмотр</p>
              <div
                className={styles.flag}
                style={{ backgroundColor: strokeColor }}
              ></div>
              <Skeleton variant="text" sx={{ fontSize: "0.6rem" }} />
              <Skeleton variant="text" sx={{ fontSize: "0.6rem" }} />
              <Skeleton variant="text" sx={{ fontSize: "0.6rem" }} />
              <Skeleton variant="text" sx={{ fontSize: "0.6rem" }} />
              <Skeleton variant="text" sx={{ fontSize: "0.6rem" }} />
              <Skeleton variant="text" sx={{ fontSize: "0.6rem" }} />
            </div>
          </div>
        </div>

        <div className={styles.block}>
          <p className={styles.block_title}>Функции</p>
          <div className={styles.block_content}>
            <div className={styles.functional}>{functionalityList}</div>
          </div>
          <div className={styles.buttons}>
            <ColorfulButton text={"Добавить функцию"} onClick={addModule} />
            <ColorfulButton
              text={"Восстановить тариф по умолчанию"}
              plusIcon={false}
              onClick={() => restoreTariff(selectedTariffId)}
            />
            <ColorfulButton
              text={"Сохранить"}
              onClick={saveChanges}
              plusIcon={false}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

type ChangableNameProps = {
  name: string;
  id: number;
  changeName: (id: number, name: string) => void;
};
const ChangableName: React.FC<ChangableNameProps> = ({
  name,
  id,
  changeName,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [value, setValue] = useState(name);
  const saveChangedName = () => {
    setEditMode(false);
    changeName(id, value);
  };
  return (
    <>
      <span className={styles.title}>
        {editMode ? (
          <input
            className={styles.styleInput}
            defaultValue={name}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        ) : (
          <div className={styles.row}>{name}</div>
        )}
        {editMode ? (
          <ColorfulButton text="Сохранить" onClick={saveChangedName} />
        ) : (
          <Tooltip title="Изменить" placement={"top"}>
            <button onClick={() => setEditMode(true)}>
              <PencilSVG />
            </button>
          </Tooltip>
        )}
      </span>
    </>
  );
};

export default PaymentTariffEdit;
