import React from "react";
import CreatableSelect from "react-select/creatable";
import makeAnimated from "react-select/animated";
import {
  EmployeeAvailableToAdd,
  SingleTrainingRequest,
  TrainingRequestStudent,
} from "@interfaces/request-training";
import { BodyLight, BodyNormal } from "@ui/fonts";
import styles from "./add-employees-modal.module.scss";
import { selectColourStylesSigning, selectTheme } from "@components/common";

const animatedComponents = makeAnimated();

export type ProgramProps = {
  data: EmployeeAvailableToAdd;
  training: SingleTrainingRequest;
  selectedStudents: Record<number, TrainingRequestStudent[]>;
  includeOtherEmployees: boolean;
  onChange: (programId: number, value: TrainingRequestStudent[]) => void;
};

export const Program: React.FC<ProgramProps> = ({
  data,
  selectedStudents,
  includeOtherEmployees,
  onChange,
}) => {
  const _employees = includeOtherEmployees
    ? data.employees
    : data.employeesFromControls;

  const selectedStudentsList = _employees.filter(
    (employee) =>
      !!selectedStudents[data.program.id]?.find(
        (_employee) => _employee.id === employee.id
      )
  );

  const handleChange = (value: TrainingRequestStudent[]) => {
    onChange(data.program.id, value);
  };

  if (_employees.length === 0) {
    return null;
  }

  return (
    <div>
      <div className={styles.AddEmployeesModal__select}>
        <BodyNormal>Программa {data.program.title}</BodyNormal>
        <BodyLight>Выберите сотрудников</BodyLight>
        <CreatableSelect
          closeMenuOnSelect={false}
          components={animatedComponents}
          isMulti
          isSearchable
          value={
            selectedStudents[data.program.id]?.length > 0
              ? selectedStudents[data.program.id]
              : []
          }
          options={_employees}
          getOptionValue={(item: { id: any; }) => item.id}
          getOptionLabel={(item: { name: any; }) => item.name}
          placeholder={"Сотрудники"}
          onChange={(values: TrainingRequestStudent[]) => handleChange(values)}
          styles={selectColourStylesSigning()}
          theme={selectTheme}
          menuPlacement="bottom"
          menuPortalTarget={document.body}
          maxMenuHeight={200}
          formatCreateLabel={(val: any) => `Добавить "${val}"`}
        />
      </div>
      <div className={styles.AddEmployeesModal__selectedList}>
        <BodyNormal>Выбрано:</BodyNormal>
        <div className={styles.AddEmployeesModal__selectedListContent}>
          {selectedStudentsList.map((employee, i, list) => (
            <div key={i}>
              <BodyLight>{employee.name}</BodyLight>
              {i < list.length - 1 && ","}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
