import { FC, FormEvent, useState } from "react";
import { WrapperSVG } from "@components/WrapperSVG/WrapperSVG";
import { useCreateInternalMaterialMutation } from "@lib/internal-study-center/useCreateInternalMaterialMutation";
import { clsx } from "@mantine/core";
import { TextField } from "@mui/material";
import { Image } from "@phosphor-icons/react";
import Dropzone from "@ui/dropzone";
import useStyles from "@ui/material-ui-styles";
import { useHistory, useParams } from "react-router-dom";
import styles from "./create-public-postlink.module.scss";

export type CreatePublicPostlinkProps = {
  type: "create" | "edit";
  title?: string;
  link?: string;
};

export const CreatePublicPostlink: FC<CreatePublicPostlinkProps> = ({
  type,
  title: defaultTitle = "",
  link: defaultLink = "",
}) => {
  const { sectionId, categoryId } = useParams() as {
    sectionId: string;
    categoryId: string;
  };
  const history = useHistory();

  const createMaterialMutation = useCreateInternalMaterialMutation();

  const [title, setTitle] = useState(defaultTitle);
  const [link, setLink] = useState(defaultLink);
  const [uploadedImages, setUploadedImages] = useState<File[]>([]);

  const [validation, setValidation] = useState({
    titleError: "",
    linkError: "",
    uploadError: "",
  });

  const classes = useStyles();

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (!categoryId || !sectionId) return;

    if (type === "create") {
      if (uploadedImages.length === 0) {
        return setValidation((prev) => ({
          ...prev,
          uploadError: "Выберите картинку для статьи",
        }));
      }

      createMaterialMutation.mutate(
        {
          sectionId: +sectionId,
          categoryId: +categoryId,
          title,
          link,
          preview: uploadedImages[0],
          text: null,
        },
        {
          onSuccess: () => {
            history.push("/study-center/public-materials");
          },
        }
      );
      return;
    }
  };

  return (
    <div className={styles.createPostlink}>
      <form
        onSubmit={onSubmit}
        className={clsx(classes.root, styles.createPostlink__form)}
      >
        <TextField
          label="Название пост-ссылки"
          variant="filled"
          name="title"
          required
          placeholder="Название пост-ссылки"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <p className="error-text">{validation.titleError}</p>
        {type === "create" && (
          <>
            <p className={styles.createPostlink__fieldMessage}>
              <WrapperSVG color="green" cursor={false}>
                <Image size={24} />
              </WrapperSVG>
              Загрузить фото *
            </p>
            <p className={styles.createPostlink__fieldDescription}>
              Допускается только формат изображения!
            </p>
            <Dropzone
              widthFull={true}
              accept="image/*"
              maxFiles={1}
              typeFile={"image"}
              onUpload={(files) => setUploadedImages(files)}
            />
            <p className="error-text">{validation.uploadError}</p>
            <TextField
              label="Ссылка на яндекс-диск"
              multiline
              variant="filled"
              name="text"
              required
              placeholder="Ссылка на яндекс-диск"
              value={link}
              onChange={(e) => setLink(e.target.value)}
            />
          </>
        )}

        <p className="error-text">{validation.linkError}</p>
        <button
          disabled={createMaterialMutation.isLoading}
          type="submit"
          className={styles.createPostlink__submitBtn}
        >
          {type === "create" ? "Создать" : "Сохранить"}
        </button>
      </form>
    </div>
  );
};
