import { $UserAddPermissions, UserPriorityEnum } from "@store/user-store";
import { BodyBold, Subtitle } from "@ui/fonts";
import {
  ChoosingTariffTableT,
  PaymentHistoryPropsType,
  TariffPeriodPropsT,
  TariffT,
  TariffVariants,
} from "../../types/payment";
import { CircleDoneSVG, CircleNotDoneSVG, PencilSVG } from "@components/svgs";
import { TariffEnum } from "@interfaces/company/company";
import React, { FC, useCallback, useEffect, useState } from "react";

import { Link } from "react-router-dom";
import Loader from "@ui/indicators/loader";
import { TableTopPanel } from "@components/common";
import TariffService from "@services/tariff-service";
import { Tooltip } from "@mui/material";
import clsx from "clsx";
import styles from "@scss/components/tariff.module.scss";
import { useModal } from "@modals/index";
import { useStore } from "effector-react";
import { useSelectedCompanyQuery } from "@lib/company";

// FIRST TAB COMPONENTS

export const TarifficationPeriodItems = ({
  items,
  current,
  onClick,
  setPrice,
}: TariffPeriodPropsT) => {
  const content = items.map((item, idx) => {
    return (
      <button
        key={idx}
        className={`tariff-period-btn ${
          TariffVariants[idx] === current ? "active" : ""
        }`}
        onClick={() => {
          setPrice(item.price);
          onClick(TariffVariants[idx]);
        }}
      >
        {item.period} мес
        <br />
        {item.price} ₽
      </button>
    );
  });

  return <>{content}</>;
};

// SECOND TAB COMPONENTS

export const PaymentHistoryList = ({
  list,
  onOptionSelect,
  onSearch,
}: PaymentHistoryPropsType) => {
  if (!list) {
    return <div>Loading...</div>;
  }

  const content = list.map((payment) => {
    const id = payment.id.toString();
    return (
      <tr key={id}>
        <td>{payment.name}</td>
        <td>{payment.term}</td>
        <td>{payment.income_amount}</td>
        <td>{payment.date_start}</td>
        <td />
      </tr>
    );
  });

  return (
    <>
      <TableTopPanel
        onSelectOption={onOptionSelect}
        text={`${list.length} оплат`}
        hideSearchPanel
        options={[
          {
            value: 30,
            label: "По Названию",
          },
          {
            value: 40,
            label: "По сроку оплаты",
          },
          {
            value: 50,
            label: "По сумме оплаты",
          },
        ]}
      />
      <div className="table-container">
        <table className="tab-table">
          <thead>
            <tr>
              <td>Оплата</td>
              <td>Срок оплаты (мес)</td>
              <td>Сумма (₽)</td>
              <td>Дата оплаты</td>
              <td />
            </tr>
          </thead>
          <tbody>{content}</tbody>
        </table>
      </div>
    </>
  );
};
// THIRD TAB COMPONENTS

const PrintMarkOrCross = ({ value }: { value: boolean }) => {
  return !value ? (
    <img src="/img/static/cross.png" alt="" className="" />
  ) : (
    <img src="/img/static/check-mark.png" alt="" className="" />
  );
};

export const ChoosingTariffTable = ({ items }: ChoosingTariffTableT) => {
  if (!items) {
    return <Loader />;
  }

  return (
    <div className="tariff-table-container">
      <table className="tariff-table">
        <thead>
          <tr>
            <td />
            <td>
              <div className="tariff choosing-tariff">
                <div className="tariff-cont">
                  <div>
                    <p className="tariff-title">Тариф {items[0].title}</p>
                    <p className="tariff-subtitle">Ваш выбранный тариф</p>
                    <p className="tariff-desc">У вас осталось 3 месяца</p>
                  </div>
                  <button className="choose-tariff-btn">Продлить тариф</button>
                </div>
              </div>
            </td>
            <td>
              <div className="tariff choosing-tariff light-tariff">
                <div className="tariff-cont">
                  <div>
                    <p className="tariff-title">Тариф {items[1].title}</p>
                    <p className="tariff-subtitle">Бесплатно</p>
                  </div>
                  <button className="choose-tariff-btn">Выбрать тариф</button>
                </div>
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Учебный центр</td>
            <td>
              <PrintMarkOrCross value={items[0].studyCenter} />
            </td>
            <td>
              <PrintMarkOrCross value={items[1].studyCenter} />
            </td>
          </tr>
          <tr>
            <td>Оповещения</td>
            <td>
              <PrintMarkOrCross value={items[0].notification} />
            </td>
            <td>
              <PrintMarkOrCross value={items[1].notification} />
            </td>
          </tr>
          <tr>
            <td>Предписания</td>
            <td>
              <PrintMarkOrCross value={items[0].instruction} />
            </td>
            <td>
              <PrintMarkOrCross value={items[1].instruction} />
            </td>
          </tr>
          <tr>
            <td>Органайзер</td>
            <td>
              <PrintMarkOrCross value={items[0].organizer} />
            </td>
            <td>
              <PrintMarkOrCross value={items[1].organizer} />
            </td>
          </tr>
          <tr>
            <td>Новости законодательства</td>
            <td>
              <PrintMarkOrCross value={items[0].news} />
            </td>
            <td>
              <PrintMarkOrCross value={items[1].news} />
            </td>
          </tr>
          <tr>
            <td>Ответственность</td>
            <td>
              <PrintMarkOrCross value={items[0].responsibility} />
            </td>
            <td>
              <PrintMarkOrCross value={items[1].responsibility} />
            </td>
          </tr>
          <tr>
            <td>Судебная практика</td>
            <td>
              <PrintMarkOrCross value={items[0].arbitragePractice} />
            </td>
            <td>
              <PrintMarkOrCross value={items[1].arbitragePractice} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const TariffCard: FC<{ tariff: TariffT; companyTariff: number }> = ({
  tariff,
  companyTariff,
}) => {
  const { company } = useSelectedCompanyQuery();

  const { open } = useModal();
  const permissions = useStore($UserAddPermissions);
  const handlerTitle = (str: string) => {
    return str.replace(/\[[^\]]*\]/g, "");
  };
  const [itemsToShow, setItemsToShow] = useState<boolean>(true);
  const changeShownItems = () => {
    setItemsToShow((prev) => !prev);
  };
  const onTariffClickHandler = () => {
    TariffService.SendEmailToAdminAboutNewTariff(tariff.id, (err, res) => {
      if (err || !res) console.error(err?.message);
      open("NotificationModal", {
        modalData: {
          modalTitle: "",
          text: `Благодарим за обращение! Ваша заявка принята. Скоро с Вами свяжется наш специалист.
                Время ожидания обратной связи от 30 до 60 минут в рабочий день с 9-00 до 17-30.`,
        },
      });
    });
  };
  const showChoosedTariff =
    companyTariff === tariff.id &&
    !permissions.hasRequiredLevel(UserPriorityEnum.Admin);
  const isExpiring =
    companyTariff === tariff.id &&
    company &&
    (([TariffEnum.Demo].includes(company.tariff) &&
      company.tariffExpires - Date.now() < 259200000) ||
      ([TariffEnum.Basic, TariffEnum.Extended, TariffEnum.Maximum].includes(
        company.tariff
      ) &&
        company.tariffExpires - Date.now() < 604800000));

  const handlerTitleOptions = (str: string) => {
    let matches = str.match(/\[([^\]]+)\]/g);
    return matches ? matches.map((match) => match.slice(1, -1)).join(" ") : "";
  };

  return (
    <div
      key={tariff.title}
      className={clsx("card", "hover-animation-card", {
        card__chosen: showChoosedTariff,
      })}
      style={{
        backgroundColor:
          companyTariff === tariff.id && showChoosedTariff
            ? tariff.fillColor
            : "white",
        borderColor: tariff.strokeColor,
      }}
    >
      {permissions.hasRequiredLevel(UserPriorityEnum.Owner) && (
        <div className="edit-icon">
          <Tooltip title="Изменить" placement={"top"}>
            <Link to={"./tariffs/edit"}>
              <PencilSVG />
            </Link>
          </Tooltip>
        </div>
      )}
      <div className="flag" style={{ backgroundColor: tariff.strokeColor }}>
        {" "}
        {tariff.space / 1000000} Гб{" "}
      </div>
      <p className={styles.title}>
        <Subtitle weight="bold">{handlerTitle(tariff.title)}</Subtitle>
      </p>
      {permissions.hasRequiredLevel(UserPriorityEnum.Client) && (
        <>
          {!permissions.hasRequiredLevel(UserPriorityEnum.Admin) && (
            <button
              className={`${isExpiring ? "expiring" : ""}`}
              onClick={() => onTariffClickHandler()}
            >
              {showChoosedTariff
                ? "Продлить этот тариф"
                : "Начать использовать"}
            </button>
          )}
          <p className={styles.price}>
            {/* TODO если тариф макс то не показываем стоимость */}
            {tariff.id !== 3 && (
              <Subtitle weight="bold">
                {tariff.price.toLocaleString().replaceAll(",", " ")} ₽
              </Subtitle>
            )}
            {tariff.title.includes("[") && (
              <BodyBold weight="bold" color="gray-darker2">
                {`(${handlerTitleOptions(tariff.title)})`}
              </BodyBold>
            )}
          </p>
        </>
      )}
      <div className="devider"></div>
      <ul
        className={clsx("modules", styles.box, itemsToShow && styles.box_close)}
      >
        {tariff.modules.map((module, index) => {
          // if (index > itemsToShow - 1) return null;
          return (
            <li
              key={module.id}
              style={module.includes ? {} : { opacity: 0.3 }}
              className={styles.row}
            >
              <div className={styles.icon}>
                {module.includes ? <CircleDoneSVG /> : <CircleNotDoneSVG />}
              </div>
              <div className={styles.word}>{module.name}</div>
            </li>
          );
        })}
      </ul>
      <div className="showMoreOrLess" onClick={changeShownItems}>
        {itemsToShow ? "Показать еще" : "Скрыть"}
      </div>
    </div>
  );
};

export const ChoosingTariffCards = ({ items }: ChoosingTariffTableT) => {
  const { company } = useSelectedCompanyQuery();

  const [tariffList, setTariffList] = useState<TariffT[]>([]);
  const [verMobile, setVerMobile] = useState<boolean>();

  useEffect(() => {
    if (!company) return;

    if (items) {
      const myTariff = items.filter(
        (tariff, item) => tariff.id === company.tariff
      );
      const withOutMyTariff = items.filter(
        (tariff, item) => tariff.id !== company.tariff
      );
      if (myTariff && verMobile) {
        setTariffList([...myTariff, ...withOutMyTariff]);
        return;
      }

      setTariffList(items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, verMobile, company]);

  const resizeHandler = useCallback(() => {
    const clientWidth = document.documentElement.clientWidth;

    if (clientWidth < 1640) {
      setVerMobile(true);
    }

    if (clientWidth > 1640) {
      setVerMobile(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document]);

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);

    return () => window.removeEventListener("resize", resizeHandler);
  }, [resizeHandler]);

  if (!items && !!tariffList) {
    return <Loader />;
  }
  //   const MENU_PANEL_TABS = [
  //     "Учебный центр",
  //     "Оповещения",
  //     "Предписания",
  //     "Новости законодательства",
  //     "Ответственность",
  //     "Судебная практика",
  //   ];

  const TariffCards =
    tariffList &&
    company &&
    tariffList.map((tariff) => {
      return (
        <TariffCard
          key={tariff.id}
          tariff={tariff}
          companyTariff={company.tariff}
        />
      );
    });
  return <div className="tariff-cards">{TariffCards}</div>;
};
