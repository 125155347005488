import { $Employees, fetchEmployees } from "@store/company/employees-store";
import {
  selectColourStylesSigning,
  selectTheme,
} from "@components/common/common";
import { useEffect, useState } from "react";

import CreatableSelect from "react-select";
import PrescriptionService from "@services/prescription-service";
import clsx from "clsx";
import makeAnimated from "react-select/animated";
import style from "@scss/modals/prescriptions/choosing-executor.module.scss";
import { useHistory } from "react-router-dom";
import { useModal } from "@modals/index";
import { useStore } from "effector-react";
import useStyles from "@ui/material-ui-styles";

const animatedComponents = makeAnimated();

const SendPrescriptionModal: React.FC = () => {
  const { modalComponent, modalData, close, open } = useModal();
  const history = useHistory();
  const classes = useStyles();
  const employees = useStore($Employees);
  useEffect(() => {
    fetchEmployees();
  }, []);

  const [employeesOptions, setEmployeeOptions] =
    useState<{ label: string; value: string }[]>();
  const [selectedEmployees, setSelectedEmployees] =
    useState<{ label: string; value: string }[]>();

  const handleOptionChanging2 = (values: any) => {
    setSelectedEmployees(values);
  };

  useEffect(() => {
    setEmployeeOptions(
      employees.map((employee) => ({
        label: employee.name,
        value: employee.email,
      }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employees.length]);

  const handleSubmit = () => {
    const emails = selectedEmployees
      ? selectedEmployees.map(
          (option: { label: string; value: string }) => option.value
        )
      : null;
    if (emails) {
      PrescriptionService.send(modalData.id, emails, (err, res) => {
        if (err || !res) {
        } else {
          open("NotificationModal", {
            modalData: {
              modalTitle: "",
              text: "Предписание отправлено",
            },
            onConfirm: () => {
              close();
              history.push("/company/prescriptions");
            },
          });
        }
      });
    }
  };

  return (
    <div
      key={modalComponent.key}
      className={clsx(style.send_prescription_modal)}
    >
      <p className="modal_title">Выберите адресатов</p>
      <div className="underline" />
      <div className={clsx("modal_content", classes.root, style.content)}>
        <CreatableSelect
          closeMenuOnSelect={false}
          components={animatedComponents}
          isMulti
          value={selectedEmployees}
          options={employeesOptions}
          isSearchable
          placeholder={"Сотрудники"}
          onChange={(values: any) => handleOptionChanging2(values as any)}
          styles={selectColourStylesSigning()}
          theme={selectTheme}
          menuPlacement="bottom"
          menuPortalTarget={document.body}
          maxMenuHeight={200}
          noOptionsMessage={() => "Ничего не найдено"}
        />
        <button
          type="submit"
          className={clsx("modal_btn", style.close_button)}
          onClick={handleSubmit}
        >
          {modalComponent.btnText}
        </button>
      </div>
    </div>
  );
};

export default SendPrescriptionModal;
