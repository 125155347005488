import {
  $RolesPermissions,
  $UserAddPermissions,
  ACTIONS,
  GetRolePriorityName,
  UserPriorityEnum,
  getPermissionName,
  pushToRolesPermissions,
} from "@store/user-store";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import { CheckboxCheckedSVG, CheckboxSVG } from "@components/svgs";
import { selectColourStyles, selectTheme } from "@components/common/common";
import { SetStateAction, useEffect, useMemo, useState } from "react";

import { Permission } from "@interfaces/store/users-store-types";
import RolesPermissionsService from "@services/roles-permissions-service";
import Select from "react-select";
import { SimpleOptionT } from "@interfaces/company/employees";
import s from "@scss/modals/users/create_user.module.scss";
import { useLocation } from "react-router-dom";
import useModal from "@modals/modal-hook";
import { useStore } from "effector-react";
import useStyles from "@ui/material-ui-styles";

const CreateRoleModal = () => {
  const { modalComponent, modalData, close } = useModal();
  const classes = useStyles();
  const rolesNPermissions = useStore($RolesPermissions);
  const permissions = useStore($UserAddPermissions);
  const location = useLocation();

  const [permissionsIds, setPermissionsIds] = useState<Set<number>>(
    new Set([])
  );
  const [roleName, setRoleName] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState<SimpleOptionT>();
  const [options, setOptions] = useState<SimpleOptionT[]>([]);

  useEffect(() => {
    const avaliablePriorities: number[] = [];
    rolesNPermissions &&
      rolesNPermissions.roles.forEach((role) => {
        if (!avaliablePriorities.includes(role.priority))
          avaliablePriorities.push(role.priority);
      });
    setOptions(
      avaliablePriorities
        .filter(function (priority) {
          if (priority === 0) {
            return false;
          }
          if (
            priority === 1 &&
            !permissions.hasPermission(
              ACTIONS.roles_levelFirstRoles_allowedToCreate
            )
          ) {
            return false;
          }
          if (
            priority === 2 &&
            !permissions.hasPermission(
              ACTIONS.roles_levelSecondRoles_allowedToCreate
            )
          ) {
            return false;
          }
          if (
            priority === 3 &&
            !permissions.hasPermission(
              ACTIONS.roles_levelThirdRoles_allowedToCreate
            )
          ) {
            return false;
          }
          if (
            priority === 4 &&
            !permissions.hasPermission(
              ACTIONS.roles_levelFourthRoles_allowedToCreate
            )
          ) {
            return false;
          }
          return true;
        })
        .map((priority) => {
          return {
            value: priority,
            label: `${priority} уровень (${GetRolePriorityName(priority)})`,
          };
        })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolesNPermissions]);
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!selectedOption) return console.error("Приоритетность роли не выбрана");
    RolesPermissionsService.CreateRole(
      {
        name: roleName,
        permissions: Array.from(permissionsIds),
        companyId: location.pathname.includes("company")
          ? modalData.companyId
          : undefined,
        priority: selectedOption.value,
      },
      (err, res) => {
        if (err || !res) {
        } else {
          pushToRolesPermissions(res.data);
          close();
        }
      }
    );
  };

  const onChangeHandler = (e: any, permission: any) => {
    if (!e.target.checked) {
      permissionsIds.delete(permission.id);
      setPermissionsIds(new Set(Array.from(permissionsIds)));
    } else {
      setPermissionsIds(
        new Set([...Array.from(permissionsIds), permission.id])
      );
    }
  };

  const filterPermissions = (permission: any) => {
    if (
      !permissions.hasRequiredLevel(UserPriorityEnum.SuperAdmin) &&
      [
        ACTIONS.roles_levelFirstRoles_allowedToCreate,
        ACTIONS.roles_levelFirstRoles_allowedToEdit,
        ACTIONS.roles_levelFirstRoles_allowedToAssign,
      ].includes(permission.action_name)
    ) return false;
    
    if (
      !permissions.hasRequiredLevel(UserPriorityEnum.Admin) &&
      [
        ACTIONS.roles_levelFirstRoles_allowedToCreate,
        ACTIONS.roles_levelFirstRoles_allowedToEdit,
        ACTIONS.roles_levelFirstRoles_allowedToAssign,
        ACTIONS.roles_levelSecondRoles_allowedToCreate,
        ACTIONS.roles_levelSecondRoles_allowedToEdit,
        ACTIONS.roles_levelSecondRoles_allowedToAssign,
      ].includes(permission.action_name)
    ) return false;

    return true;
  }

  const groupsPermissions = (groups: any, permission: any) => {
    let title: string | null = null;
    let slug: string = permission.action_name.split('_')[0];
    switch(slug){
      case 'personalAccount':
        title = "Личный кабинет";
        break;
      case 'users':
        title = "Пользователи";
        break;
      case 'roles':
        title = "Роли";
        break;
      case 'companies':
        title = "Компании";
        break;
      case 'companyWorkspace':
        title = "Компания - рабочее пространство";
        break;
      case 'companyDocumentTemplates':
        title = "Компания - шаблоны документов";
        break;
      case 'companyStaffing':
        title = "Компания - штатное расписание";
        break;
      case 'companyEventsCalendar':
        title = "Компания - календарь событий";
        break;
      case 'companySpecialistWorkPlan':
        title = "Компания - план работы специалиста";
        break;
      case 'companyDocumentSigningControl':
        title = "Компания - контроль подписания документов";
        break;
      case 'companyPrescriptions':
        title = "Компания - предписания";
        break;
      case 'companyProcedureControls':
        title = "Компания - контроль выполнения процедур";
        break;
      case 'companyApplicationsForTraining':
        title = "Компания - заявки на обучение";
        break;
      case 'projectNews':
        title = "Новости проекта";
        break;
      case 'educationalCenter':
        title = "Учебный центр";
        break;
      case 'developmentNews':
        title = "Скоро в Личном кабинете";
        break;
      case 'legislationNews':
        title = "Новости законодательства";
        break;
      case 'usefulMaterials':
        title = "Полезные материалы";
        break;
      case 'responsibility':
        title = "Ответственность";
        break;
      case 'legalInformation':
        title = "Юридическая информация";
        break;
      case 'judicialPractice':
        title = "Судебная практика";
        break;
      case 'payment':
        title = "Оплата";
        break;
      case 'specialistHelp':
        title = "Помощь специалиста";
        break;
      case 'directories':
        title = "Справочники";
        break;
      case 'support':
        title = "Техподдержка";
        break;
      case 'questionAnswer':
        title = "Вопрос ответ";
        break;
      case 'documentTemplates':
        title = "Шаблоны документов";
        break;
      default:
        title = "Прочее (не распределено)";
    }
    if(!groups[slug]){
      groups[slug] = {
        title: title,
        slug: slug,
        permissions: [{...permission}]
      }
    }else{
      groups[slug].permissions.push(permission)
    }
    return groups;
  }

  const memoPermissions = useMemo(
    () =>
      Object.values(rolesNPermissions?.permissions
        .filter((permission: any) => filterPermissions(permission))
        .reduce(groupsPermissions, []))
        .map((group: any, index: number) => {
          return (
            <div>
              <div className="mt-30 mb-5 font-lg">{group.title}:</div>
              {group.permissions && group.permissions.map((permission: any, index: number) => {
                return (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        defaultChecked={false}
                        name="isFinancial"
                        color="primary"
                        icon={<CheckboxSVG />}
                        checkedIcon={<CheckboxCheckedSVG />}
                      />
                    }
                    label={getPermissionName(permission.action_name)}
                    labelPlacement="start"
                    onChange={(e) => onChangeHandler(e, permission)}
                  />
                )
              })}
            </div>
          );
        }
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [permissionsIds.size, rolesNPermissions]
  );

  return (
    <div key={modalComponent.key} className={s.wrapper}>
      <p className="modal_title">{modalData.modalTitle}</p>
      <div className="underline" />
      <form className={`modal_content ${classes.root}`} onSubmit={handleSubmit}>
        <TextField
          label={"Введите название новой роли"}
          name={"directoryTitle"}
          variant="filled"
          required
          onChange={(e) => setRoleName(e.target.value)}
        />
        <Select
          value={selectedOption}
          options={options}
          placeholder={"Уровень роли"}
          onChange={(value: SetStateAction<SimpleOptionT | undefined>) => setSelectedOption(value)}
          styles={selectColourStyles()}
          theme={selectTheme}
          menuPlacement="auto"
          menuPortalTarget={document.body}
        />
        <p className={s.title}>Выберите функционал для роли:</p>
        <div className={s.list}>{memoPermissions}</div>
        <button type="submit" className="modal_btn">
          {modalComponent.btnText}
        </button>
        <button
          type="submit"
          style={{ marginLeft: "24px" }}
          className="modal_btn colorful-btn"
          onClick={close}
        >
          Отменить
        </button>
      </form>
    </div>
  );
};

export default CreateRoleModal;
