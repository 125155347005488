import { FormControl, InputLabel, MenuItem } from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React, { useState } from "react";
import { AxiosError } from "axios";
import Dropzone from "@ui/dropzone";
import { FileDocSVG } from "@components/svgs";
import { SigningFrequency } from "@interfaces/company/workspace";
import { WrapperSVG } from "@components/WrapperSVG/WrapperSVG";
import { X } from "@phosphor-icons/react";
import clsx from "clsx";
import { periods } from "@pages/company/workspace";
import qs from "qs";
import styles from "@scss/modals/company/workspace-document.module.scss";
import { useAddWorkspaceFilesMutation } from "@lib/workspace";
import { useHistory } from "react-router-dom";
import useModal from "@modals/modal-hook";
import useStyles from "@ui/material-ui-styles";
import { DownloadFolderSVG } from "@components/svgs/download-folder";
import { formatFileSize } from "@utils/formatFileSize";
import { useSelectedCompanyQuery } from "@lib/company";

const UploadWorkspaceDocumentModal: React.FC = () => {
  const { company } = useSelectedCompanyQuery();

  const [signingFrequency, setSigningFrequency] = useState(periods[0].value);
  const [uploadedFiles, setUploadedFiles] = useState<{ [key: string]: File }>(
    {}
  );
  const [error, setError] = useState<string>("");
  const [fileCount, setFileCount] = useState(Object.keys(uploadedFiles).length);

  const addWorkspaceFilesMutation = useAddWorkspaceFilesMutation();

  const history = useHistory();
  const { modalComponent, close, open } = useModal();
  const classes = useStyles();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!company) return;

    if (Object.keys(uploadedFiles).length === 0) return;

    const queryString = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    }) as any;

    const groupId = queryString.group_id ? +queryString.group_id : 0;
    const directoryId = queryString.folder_id ? +queryString.folder_id : 0;

    addWorkspaceFilesMutation.mutate(
      {
        companyId: company.id,
        groupId,
        directoryId,
        files: Object.values(uploadedFiles),
        signingFrequency,
      },
      {
        onSuccess: () => {
          close();
        },
        onError: (error) => {
          const err = error as AxiosError;
          const message = err.message as string | undefined;
          console.error(err.response?.data);
          if (!message) return;

          if (message.match("large")) {
            return setError(
              "Недопустимый размер файла. Максимальный размер 5мб"
            );
          }

          if (message.match("space")) {
            return open("NotificationModal", {
              modalData: {
                modalTitle: "Недостаточно места!",
                text: `Сообщаем, что у Вас не осталось свободного места в хранилище.
                          Для увеличения объема памяти вы можете обратиться на почту к Администратору (tarif@0tservice.com).
                          С уважением, Команда 0tservice.`,
              },
            });
          }
        },
      }
    );
  };

  const handleSigningFrequencyChange = (event: SelectChangeEvent<SigningFrequency>) => {
    const value = event.target.value as SigningFrequency;
    setSigningFrequency(value);
  };

  const handleUpload = (files: File[]) => {
    const newFiles: { [key: string]: File } = {};

    files.forEach((file) => {
      const fileId = file.name + file.size; // Уникальный идентификатор файла
      if (!uploadedFiles[fileId]) {
        newFiles[fileId] = file;
      }
    });

    if (Object.keys(newFiles).length === 0) {
      // Все файлы являются дубликатами, ничего не добавляем
      return;
    }

    const updatedFiles = { ...uploadedFiles, ...newFiles };
    setUploadedFiles(updatedFiles);
    setFileCount((prevCount) => prevCount + Object.keys(newFiles).length);
  };

  const handleDeleteFile = (fileId: string) => {
    const updatedFiles = { ...uploadedFiles };
    delete updatedFiles[fileId];
    setUploadedFiles(updatedFiles);
    setFileCount((prevCount) => prevCount - 1);
  };
  return (
    <div className={clsx(styles.workspaceDocumentModal)}>
      <p className="modal_title">Загрузить файлы</p>
      <div className="underline" />
      <form
        className={`modal_content ${classes.root} ${clsx(
          styles.workspaceDocumentModalForm
        )}`}
        onSubmit={handleSubmit}
      >
        <Dropzone
          onUpload={(files: File[]) => handleUpload(files)}
          filesCount={fileCount}
          icon={<DownloadFolderSVG width={24} height={24} />}
        />
        <div className={styles.formFilesList}>
          <div className={styles.listLoadFile}>
            {Object.entries(uploadedFiles).map(([fileId, file]) => (
              <div key={fileId} className={styles.formFileItem}>
                <div className={styles.formFile}>
                  <FileDocSVG />
                  <span className={styles.formFile__name}>{file.name}</span>
                  <span className={styles.formFile__fileSize}>
                    ({formatFileSize(file.size)})
                  </span>
                </div>
                <div
                  onClick={() => handleDeleteFile(fileId)}
                  className={styles.deleteFile}
                >
                  <WrapperSVG color={""}>
                    <X size={14} />
                  </WrapperSVG>
                </div>
              </div>
            ))}
          </div>
        </div>
        <FormControl variant="filled" className={styles.periodSelect}>
          <InputLabel id="ot-specialist">Периодичность подписания</InputLabel>
          <Select
            labelId="ot-specialist"
            value={signingFrequency}
            onChange={handleSigningFrequencyChange}
          >
            {periods.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {error ? <p className="error-text">{error}</p> : null}
        <button
          type="submit"
          className="modal_btn"
          disabled={addWorkspaceFilesMutation.isLoading}
        >
          {modalComponent.btnText}
        </button>
      </form>
    </div>
  );
};

export default UploadWorkspaceDocumentModal;
