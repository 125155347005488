import {
  DeleteCategoriesProgrammT,
  ProgramTableT,
  СategoriesAndChangeT,
  СategoriesT,
} from "@interfaces/directory/version";
import {
  Procedure,
  ProcedureInstructionQueryParams,
  ProcedureTrainingQueryParams,
  TablesDirectionDataT,
} from "@interfaces/company/procedure";

import Fetcher from "@http/fetcher";
import { ResCallback } from "@interfaces/common";
import { UpdateDataT } from "feature/procedure-control/table-category/table-category";

class ProcedureControlService {
  static async DeleteCategoriesProgramm(
    id: number,
    type: string,
    cb: ResCallback<DeleteCategoriesProgrammT>
  ) {
    try {
      const res = await Fetcher.delete<DeleteCategoriesProgrammT>(
        `/directories/${type}/categories/${id}`
      );

      if (res.status === 200) {
        cb(null, res);
      }
    } catch (err) {
      // @ts-ignore
      cb(err);
    }
  }
  static async EditCategoryProgramm(
    id: number,
    type: string,
    title: string,
    taskGroup: string,
    cb: ResCallback<СategoriesT>
  ) {
    try {
      const params = { title, taskGroup };
      const res = await Fetcher.patch<СategoriesT>(
        `/directories/${type}/categories/${id}`,
        params
      );

      if (res.status === 200) {
        cb(null, res);
      }
    } catch (err) {
      // @ts-ignore
      cb(err);
    }
  }

  static async AddCategoryProgramm(
    type: string,
    value: {
      title: string;
      taskGroup: string;
    },
    cb: ResCallback<СategoriesT>
  ) {
    try {
      const params = value;
      const res = await Fetcher.post<СategoriesT>(
        `/directories/${type}/categories`,
        params
      );

      if (res.status === 201) {
        cb(null, res);
      }
    } catch (err) {
      // @ts-ignore
      cb(err);
    }
  }

  static async GetAllCategories(
    type: "training" | "briefing",
    isIncludeChanges: boolean,
    cb: ResCallback<СategoriesAndChangeT[]>
  ) {
    try {
      const res = await Fetcher.get<СategoriesAndChangeT[]>(
        `/directories/${type}/categories?isIncludeChanges=${isIncludeChanges}`
      );
      if (res.status === 200) {
        cb(null, res);
      }
    } catch (err) {
      // @ts-ignore
      cb(err);
    }
  }

  static async GetCategoriesInstructionsProgram(
    params: ProcedureInstructionQueryParams,
    cb: ResCallback<TablesDirectionDataT[]>
  ) {
    try {
      const res = await Fetcher.modified.get<TablesDirectionDataT[]>(
        `/procedures-control/briefing`,
        {
          params,
        }
      );

      if (res.status === 200) {
        cb(null, res);
      }
    } catch (err) {
      // @ts-ignore
      cb(err);
    }
  }

  static async GetTrainingControls(
    filter: ProcedureTrainingQueryParams,
    cb: ResCallback<TablesDirectionDataT[]>
  ) {
    try {
      const res = await Fetcher.modified.get<TablesDirectionDataT[]>(
        `/procedures-control/training`,
        {
          params: filter,
        }
      );

      if (res.status === 200) {
        cb(null, res);
      }
    } catch (err) {
      // @ts-ignore
      cb(err);
    }
  }

  static async GetInstructionsControls(
    filter: ProcedureInstructionQueryParams,
    cb: ResCallback<TablesDirectionDataT[]>
  ) {
    try {
      const res = await Fetcher.modified.get<TablesDirectionDataT[]>(
        "/procedures-control/briefing",
        {
          params: filter,
        }
      );

      cb(null, res);
    } catch (err: any) {
      cb(err);
    }
  }

  static async EditInstructionProcedure(
    data: { id: number; [key: string]: string | number },
    cb: ResCallback<ProgramTableT>
  ) {
    try {
      const res = await Fetcher.modified.put<ProgramTableT>(
        `/procedures-control/briefing/${data.id}`,
        data
      );

      if (res.status === 200) {
        cb(null, res);
      }

      return res.data;
    } catch (err) {
      // @ts-ignore
      cb(err);
    }
  }

  static async EditTriningProcedure(
    type: "training" | "briefing",
    data: UpdateDataT,
    cb: ResCallback<Procedure>
  ) {
    try {
      const res = await Fetcher.modified.put<Procedure>(
        `/procedures-control/${type}/${data.id}`,
        data
      );

      if (res.status === 200) {
        cb(null, res);
      }

      return res.data;
    } catch (err) {
      // @ts-ignore
      cb(err);
    }
  }

  static async GetTrainingExcelFileForAllCompanies(
    filter: ProcedureTrainingQueryParams,
    cb: ResCallback<Blob>
  ) {
    try {
      const res = await Fetcher.get<Blob>(
        `/companies/procedures-control/training/download`,
        {
          params: filter,
          responseType: "blob",
        }
      );

      if (res.status === 200) {
        cb(null, res);
        return res;
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async GetTrainingExcelFile(
    filter: ProcedureTrainingQueryParams,
    cb: ResCallback<Blob>
  ) {
    const params = { ...filter };

    try {
      const res = await Fetcher.modified.get<Blob>(
        `/procedures-control/training/download`,
        {
          params,
          responseType: "blob",
        }
      );

      if (res.status === 200) {
        cb(null, res);
        return res;
      }
    } catch (err) {
      // @ts-ignore
      cb(err);
    }
  }

  static async GetInstructionsExcelFileForAllCompanies(
    filter: ProcedureInstructionQueryParams,
    cb: ResCallback<Blob>
  ) {
    try {
      const res = await Fetcher.get<Blob>(
        `/companies/procedures-control/briefing/download`,
        {
          params: filter,
          responseType: "blob",
        }
      );

      if (res.status === 200) {
        cb(null, res);
        return res;
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async GetInstructionsExcelFile(
    filter: ProcedureInstructionQueryParams,
    cb: ResCallback<Blob>
  ) {
    const params = { ...filter };

    try {
      const res = await Fetcher.modified.get<Blob>(
        `/procedures-control/briefing/download`,
        {
          params,
          responseType: "blob",
        }
      );

      if (res.status === 200) {
        cb(null, res);
        return res;
      }
    } catch (err) {
      // @ts-ignore
      cb(err);
    }
  }
}

export default ProcedureControlService;
