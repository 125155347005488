import {
  GetLegislationNewsPropsT,
  GetLegislationNewsResT,
} from "@interfaces/requests/legislation-news";
import { LegislationNewsT } from "@interfaces/legislation-news";

import Fetcher from "@http/fetcher";

import { ResCallback } from "@interfaces/common";

class LegislationNewsService {
  static async getAll(
    params: GetLegislationNewsPropsT,
    cb: ResCallback<GetLegislationNewsResT>
  ) {
    try {
      const res = await Fetcher.get<GetLegislationNewsResT>(
        "/legislation-news",
        {
          params,
        }
      );

      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err: any) {
      return cb(err);
    }
  }

  static async getById(id: string, cb: ResCallback<LegislationNewsT>) {
    try {
      const res = await Fetcher.get<LegislationNewsT>(
        `/legislation-news/${id}`
      );

      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err: any) {
      return cb(err);
    }
  }

  static async create(data: FormData, cb: ResCallback<LegislationNewsT>) {
    try {
      const res = await Fetcher.post<LegislationNewsT>(
        `/legislation-news`,
        data
      );
      if (res.status === 201) {
        return cb(null, res);
      }
    } catch (err: any) {
      return cb(err);
    }
  }

  static async update(id: string, data: FormData, cb: ResCallback<string>) {
    const formData = {
      title: data.get("title"),
      tags: data.getAll("tags"),
      text: data.get("text"),
      createdAt: data.get("createdAt"),
    };

    try {
      const res = await Fetcher.patch<string>(
        `/legislation-news/${id}`,
        formData
      );
      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err: any) {
      return cb(err);
    }
  }

  static async delete(id: string, cb: ResCallback<string>) {
    try {
      const res = await Fetcher.delete<string>(`/legislation-news/${id}`);

      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err: any) {
      return cb(err);
    }
  }

  static async download(filename: string, cb: ResCallback<Blob>) {
    try {
      const res = await Fetcher.get<Blob>(
        `/legislation-news/download/${filename}`,
        {
          responseType: "blob",
        }
      );
      cb(null, res);
    } catch (err: any) {
      return cb(err);
    }
  }
}

export default LegislationNewsService;
