import { BackButton } from "@components/back-button";
import { Link } from "react-router-dom";
import { DocumentViewButton } from "@components/common/common";
import { PrescriptionFileT, PrescriptionT } from "@interfaces/prescriptions";
import clsx from "clsx";
import { FC } from "react";
import { NotePencil, Trash } from "@phosphor-icons/react";
import { ColorfulButton } from "@components/common/common";
import { $User, $UserAddPermissions, ACTIONS } from "@store/user-store";
import moment from "moment";
import { Tooltip } from "@mui/material";
import style from "@scss/pages/prescription/prescriptions.module.scss";
import PrescriptionService from "@services/prescription-service";
import { WrapperSVG } from "@components/WrapperSVG/WrapperSVG";
import {
  $Prescriptions,
  removePrescription,
  setPrescriptions,
  setSelectedPrescription,
} from "@store/prescription-store";
import { useStore } from "effector-react";
import { UserDataT } from "@interfaces/user";
import { useModal } from "@modals/index";
import { formatFileSize } from "@utils/formatFileSize";
import { useSelectedCompanyQuery } from "@lib/company";
import { useDownloadPrescriptionFileMutation } from "@lib/prescriptions";
import { downloadBlob } from "@utils/downloadBlob";

export type PrescriptionItemProps = {
  prescription: PrescriptionT;
  singleMode: boolean;
};

export const PrescriptionItem: FC<PrescriptionItemProps> = ({
  prescription,
  singleMode,
}) => {
  const user = useStore($User) as UserDataT;
  const permissions = useStore($UserAddPermissions);
  const { rows, count } = useStore($Prescriptions);

  const modal = useModal();

  const handleComplete = (
    e: any,
    prescriptionId: string,
    creatorId: number
  ) => {
    if (user.id !== creatorId) {
      return (e.target.checked = !e.target.checked);
    }

    PrescriptionService.markAsCompleted(
      prescriptionId,
      e.target.checked,
      (err, res) => {
        if (err) {
          e.target.checked = !e.target.checked;
          return console.error("Произошла неожиданная ошибка");
        }
        setPrescriptions({
          count,
          rows: rows.map((prescription) => {
            if (prescription._id === prescriptionId) {
              prescription.isCompleted = e.target.checked;
            }
            return prescription;
          }),
        });
      }
    );
  };

  const handleDelete = (id: string) => {
    PrescriptionService.delete(id, user.roleId, (err, res) => {
      if (err) {
        return console.error("При удалении предписания произошла ошибка");
      }
      removePrescription(id);
    });
  };

  return (
    <div
      className={clsx(style.prescription_item, {
        [style.single_item]: singleMode,
      })}
    >
      <div className={clsx(style.prescription_item_content)}>
        {singleMode && <BackButton className={style.backButton} />}
        <div className={clsx(style.prescription_item_title)}>
          <label htmlFor={prescription._id}>
            <input
              type="checkbox"
              className={clsx(style.checkbox_item)}
              disabled={user.id !== prescription.creatorId}
              hidden
              name=""
              id={prescription._id}
              defaultChecked={prescription.isCompleted}
              onChange={(e) =>
                handleComplete(e, prescription._id, prescription.creatorId)
              }
            />
            <label htmlFor={prescription._id}>
              <svg
                className="w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </label>
            <label
              htmlFor={prescription._id}
              className={clsx(
                style.checkbox_label,
                style.prescription_title_text
              )}
            >
              {prescription.title}
            </label>
          </label>
          <div className={clsx(style.prescription_action_buttons)}>
            {permissions.hasPermission(
              ACTIONS.companyPrescriptions_allowedToEdit
            ) && (
              <Link
                to={`/company/prescriptions/${prescription._id}/edit`}
                onClick={() => setSelectedPrescription(prescription)}
              >
                <WrapperSVG color={"orange"} margin>
                  <Tooltip title="Изменить" placement="top">
                    <NotePencil size={24} />
                  </Tooltip>
                </WrapperSVG>
              </Link>
            )}
            {permissions.hasPermission(
              ACTIONS.companyPrescriptions_allowedToDelete
            ) && (
              <WrapperSVG color="">
                <Tooltip title="Удалить" placement="top">
                  <Trash
                    size={24}
                    onClick={() =>
                      modal.open("ConfirmActionModal", {
                        btnText: "Удалить",
                        modalData: {
                          text: `Вы уверены, что хотите удалить предписание "${prescription.title}"?`,
                        },
                        onConfirm: () => handleDelete(prescription._id),
                      })
                    }
                  />
                </Tooltip>
              </WrapperSVG>
            )}
          </div>
        </div>
        <Link
          to={`/company/prescriptions/${prescription._id}`}
          onClick={() => setSelectedPrescription(prescription)}
        >
          <p className={clsx(style.prescription_gray_text)}>
            {moment(prescription.createdAt).format("lll")}
          </p>
          <p className={clsx(style.prescription_text)}>{prescription.desc}</p>
        </Link>
      </div>
      {prescription.files.length === 0 ? null : singleMode ? (
        <div className="px-20">
          <PrescriptionsFilesList files={prescription.files} />
        </div>
      ) : (
        <div
          className={clsx(
            style.prescription_files_amount,
            style.prescription_gray_text
          )}
        >
          <img src="/img/static/pin-icon.png" alt="" />
          Прикреплено файлов {prescription.files.length}
        </div>
      )}
      {singleMode && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "16px",
          }}
        >
          <ColorfulButton
            text={"Отправить предписание"}
            onClick={() =>
              modal.open("SendPrescriptionModal", {
                btnText: "Отправить",
                modalData: {
                  text: `Вы уверены, что хотите удалить предписание "${prescription.title}"?`,
                  id: prescription._id,
                },
              })
            }
          />
        </div>
      )}
    </div>
  );
};

export type PrescriptionsFilesListPropsT = {
  files: File[] | PrescriptionFileT[];
};

export const PrescriptionsFilesList: React.FC<PrescriptionsFilesListPropsT> = ({
  files,
}) => {
  const { company } = useSelectedCompanyQuery();

  const downloadFileMutation = useDownloadPrescriptionFileMutation();

  const content = files.map((file, idx) => {
    let originalFileName = "";
    let fileName = "";
    let filePath = "";
    if ("originalname" in file) {
      originalFileName = file.originalname;
      fileName = file.filename;
      filePath = `uploads/companies/${company?.id}/prescriptions/${fileName}`;
    } else if ("name" in file) {
      originalFileName = file.name;
      fileName = file.name;
      filePath = file.name;
    }

    const handleDownload = () => {
      if (!company) return;

      downloadFileMutation.mutate(
        {
          companyId: company.id,
          fileName: fileName,
        },
        {
          onSuccess: (blob) => {
            downloadBlob(blob, originalFileName);
          },
        }
      );
    };

    return (
      <div key={idx} className="form-file-item">
        <div className="flex-n-c">
          <svg
            className="mr-10"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 0C6.9 0 6 0.9 6 2V30C6 31.1 6.9 32 8 32H28C29.1 32 30 31.1 30 30V8L22 0H8Z"
              fill="#DFE3F1"
            />
            <path d="M24 8H30L22 0V6C22 7.1 22.9 8 24 8Z" fill="#B0B7BD" />
            <path d="M30 14L24 8H30V14Z" fill="#CAD1D8" />
          </svg>
          {originalFileName} ({formatFileSize(file.size)})
        </div>
        <div className="flex-n-c">
          <DocumentViewButton fileName={fileName} filePath={filePath} />
          <button onClick={handleDownload}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className=""
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              style={{ width: "20px", height: "20px", margin: 0 }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
              />
            </svg>
          </button>
        </div>
      </div>
    );
  });

  return <div className="form-files-list">{content}</div>;
};
