import { TextField } from "@mui/material";
import useModal from "@modals/modal-hook";
import clsx from "clsx";
import style from "@scss/modals/document-editor.module.scss";
import { useEffect } from "react";
import { $User } from "@store/user-store";
import { UserDataT } from "@interfaces/user";
import { useStore } from "effector-react";
import { DocumentEditor } from "@onlyoffice/document-editor-react";
import sign from "jwt-encode";

const DocumentEditorModal = () => {
  const { modalComponent, modalData, close } = useModal();
  const user = useStore($User) as UserDataT;

  useEffect(() => {
    const modalContent = document.querySelector(`.${clsx(style.document_editor)}`) as HTMLDivElement;
    modalContent.closest("div.react-responsive-modal-modal")?.classList?.add(clsx(style.document_editor_container));
  }, []);

  let type = 'word';
  switch(modalData.file.type){
    case 'doc':
    case 'docx':
    case 'rtf':
      type = 'word';
      break;
    case 'xlsx':
      type = 'cell';
      break;
    case 'pdf':
      type = 'pdf';
      break;
  }

  const secret = 'adD-@5h!8_bc(12@%)5f)1!zXcN';
  const data = {
    document: {
      fileType: modalData.file.type,
      key: modalData.file.key,
      title: modalData.file.name,
      url: `${process.env.BACKEND_SERVER}/${modalData.file.path}`,
      permissions: {
        chat: false,
        comment: false,
        copy: true,
        deleteCommentAuthorOnly: false,
        download: true,
        edit: true,
        editCommentAuthorOnly: false,
        fillForms: true,
        modifyContentControl: true,
        modifyFilter: true,
        print: true,
        protect: true,
        review: false,
        rename: true,
      }
    },
    documentType: type,
    editorConfig: {
      lang: 'ru',
      callbackUrl: `${process.env.BACKEND_SERVER}/api/v1/save-document`,
      customization: {
        autosave: true,
        hideRulers: true,
        forcesave: true,
        hideNotes: true,
        integrationMode: 'embed',
      },
      user: {
        name: user.name,
        id: user.id.toString(),
        group: user.role.id.toString()
      }
    },
  };
  const jwt = sign(data, secret);

  return (
    <div key={modalComponent.key} className={clsx(style.document_editor)}>
        <DocumentEditor
          id={modalData.file.key}
          documentServerUrl={`${process.env.DOCUMENT_SERVER}`}
          editorConfig_lang="ru"
          width="100%"
          height="100%"
          config={{
            token: jwt,
            document: {
              fileType: modalData.file.type,
              key: modalData.file.key,
              title: modalData.file.name,
              url: `${process.env.BACKEND_SERVER}/${modalData.file.path}`,
              permissions: {
                chat: false,
                comment: false,
                copy: true,
                deleteCommentAuthorOnly: false,
                download: true,
                edit: true,
                editCommentAuthorOnly: false,
                fillForms: true,
                modifyContentControl: true,
                modifyFilter: true,
                print: true,
                protect: true,
                review: false,
                rename: true,
              }
            },
            documentType: type,
            editorConfig: {
              lang: 'ru',
              callbackUrl: `${process.env.BACKEND_SERVER}/api/v1/save-document`,
              customization: {
                autosave: true,
                hideRulers: true,
                forcesave: true,
                hideNotes: true,
                integrationMode: 'embed',
                features: {
                  spellcheck: {
                    mode: true,
                    change: true,
                  }
                }
              },
              user: {
                name: user.name,
                id: user.id.toString(),
                group: user.role.id.toString()
              }
            },
            events: {
              onRequestSaveAs: modalData.onRequestSaveAs
            }
          }}
          onLoadComponentError={modalData.onLoadComponentError}
        />
    </div>
  );
};

export default DocumentEditorModal;
