import {
  $Employees,
  fetchEmployees,
  pushToEmployees,
} from "@store/company/employees-store";
import { Box, Group, Stepper, Text, clsx } from "@mantine/core";
import { Theme } from "@mui/material";
import { Button } from "@mui/base";
import { makeStyles, createStyles } from '@mui/styles';
import { EmployeeListDataT, PostUserData } from "@interfaces/company/employees";
import EmployeeService, {
  ConfirmEmployeeData,
  employeesService,
} from "@services/employee-service";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { BackButton } from "@components/back-button";
import EmployeesGeneral from "./employees-general";
import EmployeesInstructions from "./employees-instructions";
import EmployeesTraining from "./employees-training";
import { fetchDepartments } from "@store/company/departments-store";
import { handleApiError } from "@utils/handleApiError";
import styles from "@scss/pages/company/company-info.module.scss";
import { useModal } from "@modals/index";
import { useStore } from "effector-react";
import { useSelectedCompanyQuery } from "@lib/company";
import { EmployeesMedicalExams } from "./employees-medical-exams";

const requiredFields = [
  "name",
  "employmentDate",
  "snils",
  "email",
  "positionId",
];

const useFormStyles = makeStyles((theme: Theme) =>
  createStyles({
    backButton: {
      height: 40,
      padding: "0 24px",
      marginBottom: 15,
      textTransform: "none",
      borderRadius: 25,
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
      },
    },
    saveButton: {
      backgroundColor: "transparent",
      border: `1px solid ${theme.palette.primary.main}`,
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  })
);

interface EmployeesCreateProps {
  type?: "create" | "edit";
}

const EmployeesCreate: React.FC<EmployeesCreateProps> = ({
  type = "create",
}) => {
  const { company } = useSelectedCompanyQuery();

  const { replace, push, location } = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const startScreen = searchParams.get("startScreen");
  const startDepartment = searchParams.get("startDepartment");
  const { id } = useParams<{ id?: string }>();
  const employees = useStore($Employees);
  const [active, setActive] = useState(startScreen ? +startScreen : 0);

  const [isTrainingControlFilled, setIsTrainingControlFilled] = useState(false);
  const [isBriefingControlFilled, setIsBriefingControlFilled] = useState(false);
  const [
    isMedicalExaminationControlFilled,
    setIsMedicalExaminationControlFilled,
  ] = useState(false);

  const user = useMemo(() => {
    if (!employees || !id) {
      return undefined;
    }
    return employees.find((user) => user.id === +id);
  }, [id, employees]);

  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const [userData, setUserData] = useState<PostUserData>({
    ...user,
    isCustomPosition: !!user?.position.parentId,
    companyId: company?.id,
  } as PostUserData);

  const modal = useModal();

  const checkErrors = (): boolean => {
    const isError = requiredFields.reduce((acc, key) => {
      if (key === "snils") {
        console.error(userData[key as keyof PostUserData]);
        console.error((user ?? {})[key as keyof EmployeeListDataT]);
      }
      if (!userData[key as keyof PostUserData]) {
        return [...acc, key];
      }
      return acc;
    }, [] as string[]);
    if (isError.length !== 0) {
      isError.forEach((key) => {
        setErrors((prev) => ({ ...prev, [key]: "Заполните это поле" }));
      });
      return true;
    }

    return false;
  };

  const showSecondStepSuccessModal = ({
    message = "",
    type = "",
  }: {
    message?: string;
    type?: string;
  }) => {
    modal.open("NotificationModal", {
      modalData: {
        text: message,
        type,
      },
      onConfirm: () => {
        modal.close();
      },
    });
  };

  const showFirstStepModal = async () => {
    return new Promise<boolean>((resolve) => {
      modal.open("ConfirmActionModal", {
        btnText: "Да",
        modalData: {
          text: "Вы внесли все данные по сотруднику?",
          cancelBtnText: "Нет, внесу позже",
          onCancel: () => {
            modal.close();
            resolve(false);
          },
        },
        onConfirm: () => {
          modal.close();
          resolve(true);
        },
      });
    });
  };

  const showSecondStepModal = async () => {
    return new Promise<boolean>((resolve) => {
      modal.open("ConfirmActionModal", {
        btnText: "Да",
        modalData: {
          text: "Вы внесли всю информацию об обучении сотрудника?",
          cancelBtnText: "Нет, внесу позже",
          onCancel: () => {
            modal.close();
            resolve(false);
          },
        },
        onConfirm: () => {
          if (!id) return;

          modal.close();
          resolve(true);
          setIsTrainingControlFilled(true);
          showSecondStepSuccessModal({
            message:
              "Данные по обучению сотрудника занесены в таблицу контроля выполнения процедур",
            type: "positive",
          });
        },
      });
    });
  };

  const showThirdStepSuccessModal = (
    message = "Данные по инструктажам сотрудника занесены в таблицу контроля выполнения процедур"
  ) => {
    modal.open("NotificationModal", {
      modalData: {
        text: message,
        type: "positive",
      },
      onConfirm: () => {
        modal.close();
      },
    });
  };

  const showForthStepSuccessModal = (
    message = "Данные по медицинским осмотрам сотрудника занесены в таблицу контроля выполнения процедур"
  ) => {
    modal.open("NotificationModal", {
      modalData: {
        text: message,
        type: "positive",
      },
      onConfirm: () => {
        modal.close();
      },
    });
  };

  const showThirdStepModal = async (openNewPage: boolean) => {
    return new Promise<boolean>((resolve) => {
      modal.open("ConfirmActionModal", {
        btnText: "Да",
        modalData: {
          text: "Вы внесли всю информацию об инструктажах сотрудника?",
          cancelBtnText: "Нет, внесу позже",
          onCancel: () => {
            modal.close();

            resolve(false);

            if (openNewPage) push({ pathname: "/company/employees" });
          },
        },
        onConfirm: () => {
          if (!id) return;

          modal.close();
          setIsBriefingControlFilled(true);
          showThirdStepSuccessModal();

          resolve(true);

          if (openNewPage) push({ pathname: "/company/employees" });
        },
      });
    });
  };

  const showForthStepModal = async (openNewPage: boolean) => {
    return new Promise<boolean>((resolve) => {
      modal.open("ConfirmActionModal", {
        btnText: "Да",
        modalData: {
          text: "Вы внесли всю информацию об медицинских осмотрах сотрудника?",
          cancelBtnText: "Нет, внесу позже",
          onCancel: () => {
            modal.close();

            resolve(false);

            if (openNewPage) push({ pathname: "/company/employees" });
          },
        },
        onConfirm: () => {
          if (!id) return;

          modal.close();
          setIsMedicalExaminationControlFilled(true);
          showForthStepSuccessModal();

          resolve(true);

          if (openNewPage) push({ pathname: "/company/employees" });
        },
      });
    });
  };

  const changeStep = async (nextStep: number) => {
    const hasErrors = checkErrors();
    if (hasErrors) return;

    if (nextStep === active) return;

    let isError = false;

    if (active === 0) {
      if (type === "create") {
        try {
          const res = await employeesService.create(userData);
          fetchDepartments();
          pushToEmployees(res.data);
          setActive((current) => (current < 3 ? current + 1 : current));
          replace(`/company/employees/${res?.data.id}?startScreen=1`);
        } catch (err) {
          isError = true;
        }

        if (isError) return;
      }
      if (type === "edit" && !!Object.keys(userData).length) {
        try {
          const res = await employeesService.update(user?.id ?? 0, userData);
          fetchDepartments();
          pushToEmployees(res.data);
          setActive((current) => (current < 3 ? current + 1 : current));
          replace(`/company/employees/${user?.id}?startScreen=1`);
          // setUserData({}); i don't remember why we reset userData here and somewhere below, but bug was because of this, so i commented it out
        } catch (err) {
          isError = true;
        }

        if (isError) return;
      }
    }
    if (active === 1) {
      await showSecondStepModal();
    }
    if (active === 2) {
      await showThirdStepModal(false);
    }
    if (active === 3) {
      await showForthStepModal(false);
    }

    if (isError) return;

    setActive(nextStep);
  };

  const stylesUI = useFormStyles();
  const nextStep = async (num?: number) => {
    const hasErrors = checkErrors();
    if (hasErrors) return;

    if (active === 0) {
      await handleGeneralStep();
      return;
    }
    if (active === 1) {
      await showSecondStepModal();
    }
    if (active === 2) {
      await showThirdStepModal(false);
    }
    if (active === 3) {
      const isMedicalExaminationControlFilled = await showForthStepModal(true);
      if (id && !user?.isConfirmed) {
        confirmEmployee({
          id: +id,
          isTrainingControlFilled,
          isBriefingControlFilled,
          isMedicalExaminationControlFilled,
        });
        return;
      }
    }

    const isNumExists = typeof num !== "undefined";
    setActive((current) =>
      isNumExists ? num ?? 0 : current < 3 ? current + 1 : current
    );
  };
  const prevStep = async () => {
    if (active === 0) {
      await handleGeneralStep();
      return;
    }
    if (active === 1) {
      await showSecondStepModal();
    }
    if (active === 2) {
      await showThirdStepModal(false);
    }
    if (active === 3) {
      await showForthStepModal(false);
    }
    setActive((current) => (current > 0 ? current - 1 : current));
  };

  useEffect(() => {
    if (type === "edit") {
      fetchEmployees();
    }
  }, [type]);

  useEffect(() => {
    replace({ search: `startScreen=${active}` });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  useEffect(() => {
    if (!user) return;

    setUserData((prev) => ({
      ...user,
      ...prev,
      isCustomPosition: !!user?.position.parentId,
    }));
  }, [user, active]);

  useEffect(() => {
    if (startDepartment) {
      setUserData((prev) => ({ ...prev, departments: [+startDepartment] }));
    }
  }, [startDepartment]);

  const confirmEmployee = (data: ConfirmEmployeeData) => {
    if (!company) return;

    return EmployeeService.ConfirmNewEmployee(company.id, data);
  };

  const handleGeneralStep = async () => {
    const hasErrors = checkErrors();
    if (hasErrors) return;

    let isError = false;

    if (type === "create") {
      try {
        const res = await employeesService.create(userData);
        fetchDepartments();
        pushToEmployees(res.data);
        replace(`/company/employees/${res?.data.id}?startScreen=1`);
      } catch (err) {
        isError = true;
        handleApiError(err, setErrors);
      }

      if (isError) return;
    }

    if (type === "edit" && !!Object.keys(userData).length) {
      try {
        const res = await employeesService.update(user?.id ?? 0, userData);
        fetchDepartments();
        pushToEmployees(res.data);
        replace(`/company/employees/${user?.id}?startScreen=1`);
        /* setUserData({}); */
      } catch (err) {
        isError = true;
        handleApiError(err, setErrors);
      }
    }

    if (isError) return;

    setActive((current) => (current < 3 ? current + 1 : current));
  };

  const handleSaveAndExit = async () => {
    const hasErrors = checkErrors();
    if (hasErrors) return;

    if (active === 0) {
      if (type === "create") {
        let isError = false;

        try {
          const res = await employeesService.create(userData);
          fetchDepartments();
          pushToEmployees(res.data);

          await showFirstStepModal();
          showSecondStepSuccessModal({
            message:
              "Данные по сотруднику занесены в таблицы контроля процедур",
            type: "positive",
          });
        } catch (err) {
          isError = true;
          handleApiError(err, setErrors);
        }

        if (isError) return;
      } else {
        let isError = false;

        try {
          const res = await employeesService.update(user?.id ?? 0, userData);
          fetchDepartments();
          pushToEmployees(res.data);

          await showFirstStepModal();
          showSecondStepSuccessModal({
            message:
              "Данные по сотруднику занесены в таблицы контроля процедур",
            type: "positive",
          });
        } catch (err) {
          isError = true;
          handleApiError(err, setErrors);
        }

        if (isError) return;
      }
    } else if (active === 1) {
      showSecondStepSuccessModal({
        message: "Данные по сотруднику занесены в таблицы контроля процедур",
        type: "positive",
      });
    } else if (active === 2) {
      showThirdStepSuccessModal(
        "Данные по сотруднику занесены в таблицы контроля процедур"
      );
    } else if (active === 3) {
      if (id && !user?.isConfirmed)
        confirmEmployee({
          id: +id,
          isTrainingControlFilled,
          isBriefingControlFilled,
          isMedicalExaminationControlFilled,
        });
      showForthStepSuccessModal(
        "Данные по сотруднику занесены в таблицы контроля процедур"
      );
    }

    push("/company/employees");
  };

  return (
    <Box sx={{ padding: "20px 22px 24px" }}>
      {active === 0 && <BackButton className={styles.backButton} />}
      <Text sx={{ fontSize: 18, fontWeight: 600 }}>Добавить сотрудника</Text>
      <div className={styles.stepper}>
        <Stepper
          styles={{ content: { paddingTop: 30 } }}
          mt={20}
          color="green.5"
          active={active}
          onStepClick={changeStep}
          breakpoint="sm"
        >
          <Stepper.Step
            label="Персональные данные:"
            description="Заполните информацию о сотруднике"
            onClick={() => nextStep(0)}
          >
            <EmployeesGeneral
              setErrors={setErrors}
              userData={userData}
              setUserData={setUserData}
              errors={errors}
              user={user}
            />
          </Stepper.Step>
          <Stepper.Step
            label="Программы обучения:"
            description="Заполните недостающие данные об обучении"
            onClick={() => nextStep(1)}
          >
            <EmployeesTraining />
          </Stepper.Step>
          <Stepper.Step
            label="Инструктажи:"
            description="Заполните недостающие данные об инструктажах"
            onClick={() => nextStep(2)}
          >
            <EmployeesInstructions />
          </Stepper.Step>
          <Stepper.Step
            label="Мед. осмотры:"
            description="Заполните недостающие данные об мед. осмотрах"
            onClick={() => nextStep(3)}
          >
            <EmployeesMedicalExams />
          </Stepper.Step>
          <Stepper.Completed>
            Completed, click back button to get to previous step
          </Stepper.Completed>
        </Stepper>
      </div>
      {/* TODO: Перенести эти кнопки в компонент каждого шага и убрать проверку для 3 шага ниже */}
      <Group mt={40}>
        {active !== 0 && (
          <Button
            disabled={active === 0}
            className={clsx(stylesUI.backButton)}
            onClick={prevStep}
          >
            Назад
          </Button>
        )}
        {active === 3 ? (
          <>
            <button
              type="submit"
              className={styles.company_edit_btn}
              onClick={() => nextStep()}
            >
              Сохранить и выйти
            </button>
          </>
        ) : (
          <>
            <button
              type="submit"
              className={clsx(styles.company_edit_btn)}
              onClick={() => nextStep()}
            >
              Продолжить
            </button>
            <Button
              className={clsx(stylesUI.backButton, stylesUI.saveButton)}
              onClick={handleSaveAndExit}
            >
              Сохранить и выйти
            </Button>
          </>
        )}
      </Group>
    </Box>
  );
};

export default EmployeesCreate;
