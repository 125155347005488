import { $UserAddPermissions, ACTIONS } from "@store/user-store";
import {
  useCreateWorkspaceGroupMutation,
  useDeleteWorkspaceGroupMutation,
  useUpdateWorkspaceGroupMutation,
} from "@lib/workspace";

import React from "react";
import { TextField } from "@mui/material";
import useModal from "@modals/modal-hook";
import { useStore } from "effector-react";
import useStyles from "@ui/material-ui-styles";
import { useSelectedCompanyQuery } from "@lib/company";

const CreateWorkspaceGroupModal: React.FC = () => {
  const { modalComponent, modalData, close } = useModal();

  const { company } = useSelectedCompanyQuery();

  const classes = useStyles();
  const permissions = useStore($UserAddPermissions);

  const createWorkspaceGroupMutation = useCreateWorkspaceGroupMutation();
  const updateWorkspaceGroupMutation = useUpdateWorkspaceGroupMutation();
  const deleteWorkspaceGroupMutation = useDeleteWorkspaceGroupMutation();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!company) return;

    const modalBtn = document.querySelector(
      "button.modal_btn"
    ) as HTMLButtonElement;
    modalBtn.disabled = true;

    const groupTitle = document.querySelector(
      'input[name="groupTitle"]'
    ) as HTMLInputElement;

    // CREATING NEW GROUP
    if (groupTitle?.value && !modalData.editMode) {
      createWorkspaceGroupMutation.mutate(
        {
          companyId: company.id,
          name: groupTitle.value,
        },
        {
          onSuccess: () => {
            close();

            if (modalData.onConfirm) {
              modalData.onConfirm();
            }
          },
        }
      );
    }

    if (groupTitle?.value && modalData.editMode) {
      updateWorkspaceGroupMutation.mutate(
        {
          companyId: company.id,
          id: modalData.groupId,
          name: groupTitle.value,
        },
        {
          onSuccess: () => {
            close();
          },
        }
      );
    }
  };

  const handleDelete = () => {
    if (typeof modalData.groupId !== "number" || !company) return;

    deleteWorkspaceGroupMutation.mutate(
      {
        companyId: company.id,
        id: modalData.groupId,
      },
      {
        onSuccess: () => {
          close();
        },
      }
    );
  };

  return (
    <div key={modalComponent.key}>
      <p className="modal_title">{modalData.modalTitle}</p>
      <div className="underline" />
      <form className={`modal_content ${classes.root}`} onSubmit={handleSubmit}>
        <TextField
          label={"Название группы"}
          name={"groupTitle"}
          variant="filled"
          required
          defaultValue={modalData.itemTitle}
        />
        {modalData.editMode ? (
          <div className="modal_buttons_group">
            <button type="submit" className="modal_btn">
              {modalComponent.btnText}
            </button>
            {permissions.hasPermission(
              ACTIONS.companyWorkspace_groups_allowedToDelete
            ) && (
              <button
                type="button"
                className="modal_btn"
                onClick={handleDelete}
              >
                Удалить
              </button>
            )}
          </div>
        ) : (
          <button type="submit" className="modal_btn">
            {modalComponent.btnText}
          </button>
        )}
      </form>
    </div>
  );
};

export default CreateWorkspaceGroupModal;
