import { ErrorIndicator, Loader } from "@ui/indicators";
import { memo, useEffect, useState } from "react";
import moment, { Moment } from "moment";

import CompanyService from "@services/company-service";
import { GreyArrowDownSVG } from "@components/svgs";
import { Search } from "@ui/search";
import clsx from "clsx";
import s from "@scss/components/tables/tariff-change-table.module.scss";
import tableStyle from "@scss/components/tables/base-table.module.scss";
import { useCompaniesQuery } from "@lib/company";
import { useSearch } from "@hooks/useSearch";
import { TariffCompany } from "./tariff-company";
import { useQueryClient } from "react-query";
import { companyQueryKeys } from "@lib/company/queryKeys";

const TariffChangeTable = memo(() => {
  const [sortBy, setSortBy] = useState({
    name: false,
    space: false,
    tariff: false,
    date: false,
  });

  const search = useSearch();
  const queryClient = useQueryClient();

  const { companies, isLoading, isError } = useCompaniesQuery({
    searchString: search.debounced,
  });

  const [sortedCompanies, setSortedCompanies] = useState(companies ?? []);

  useEffect(() => {
    if (!companies) return;

    setSortedCompanies(companies);
  }, [companies]);

  useEffect(() => {
    sortCompanies("date");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortCompanies = (by: "name" | "space" | "tariff" | "date") => {
    switch (by) {
      case "name":
        if (!sortBy.name) {
          setSortBy({ name: true, space: false, tariff: false, date: false });
          return setSortedCompanies(
            sortedCompanies.sort((a, b) => a.name.localeCompare(b.name))
          );
        } else {
          setSortBy({ name: false, space: false, tariff: false, date: false });
          return setSortedCompanies(
            sortedCompanies.sort((a, b) => b.name.localeCompare(a.name))
          );
        }
      case "space":
        if (!sortBy.space) {
          setSortBy({ name: false, space: true, tariff: false, date: false });
          return setSortedCompanies(
            sortedCompanies.sort(
              (a, b) =>
                parseFloat(a.workspaceSize) - parseFloat(b.workspaceSize)
            )
          );
        } else {
          setSortBy({ name: false, space: false, tariff: false, date: false });
          return setSortedCompanies(
            sortedCompanies.sort(
              (a, b) =>
                parseFloat(b.workspaceSize) - parseFloat(a.workspaceSize)
            )
          );
        }
      case "date":
        if (!sortBy.date) {
          setSortBy({ name: false, space: false, tariff: false, date: true });
          return setSortedCompanies(
            sortedCompanies.sort(
              (a, b) =>
                new Date(a.tariffExpires).getTime() -
                new Date(b.tariffExpires).getTime()
            )
          );
        } else {
          setSortBy({ name: false, space: false, tariff: false, date: false });
          return setSortedCompanies(
            sortedCompanies.sort(
              (a, b) =>
                new Date(b.tariffExpires).getTime() -
                new Date(a.tariffExpires).getTime()
            )
          );
        }
      case "tariff":
        if (!sortBy.tariff) {
          setSortBy({ name: false, space: false, tariff: true, date: false });
          return setSortedCompanies(
            sortedCompanies.sort((a, b) => a.tariff - b.tariff)
          );
        } else {
          setSortBy({ name: false, space: false, tariff: false, date: false });
          return setSortedCompanies(
            sortedCompanies.sort((a, b) => b.tariff - a.tariff)
          );
        }
    }
  };

  const addAdditionalSpace = (companyId: number, gbs: number) => {
    CompanyService.changeTariff(
      companyId,
      { additionalSpace: gbs },
      async (err, res) => {
        if (err || !res) {
        } else if (res) {
          // checkAuth()
          queryClient.invalidateQueries(companyQueryKeys.all);
          const updatedCompany = companies?.find(
            (company) => company.id === companyId
          );
          if (!updatedCompany) return;

          setSortedCompanies(
            sortedCompanies.map((company) => {
              if (company.id === companyId) {
                return {
                  ...company,
                  additionalSize: String(
                    parseFloat(updatedCompany.additionalSize) + gbs
                  ),
                };
              }
              return company;
            })
          );
        }
      }
    );
  };

  const changeDeadlines = (companyId: number, date: Moment) => {
    const tariffExpires = date?.valueOf() ?? null;
    console.log(date);

    CompanyService.changeTariff(
      companyId,
      { tariffExpires },
      async (err, res) => {
        if (err || !res) {
        } else if (res) {
          queryClient.invalidateQueries(companyQueryKeys.all);
          setSortedCompanies(
            sortedCompanies.map((company) => {
              if (company.id === companyId) {
                return {
                  ...company,
                  tariffStarts: Date.now(),
                  tariffExpires,
                };
              }
              return company;
            })
          );
        }
      }
    );
  };

  const updateCompanyLocal = (
    companyId: number,
    tariff: number,
    tariffStarts: number,
    tariffExpires: number
  ) => {
    setSortedCompanies(
      sortedCompanies.map((company) => {
        if (company.id === companyId) {
          return { ...company, tariff, tariffStarts, tariffExpires };
        }
        return company;
      })
    );
  };

  return (
    <>
      <div className={s.search}>
        <Search
          options={companies ?? []}
          getOptionLabel={(company) => company.name}
          showDropDown={false}
          placeholder="Поиск по ИНН и названию компании"
          value={search.value}
          onChange={search.onChange}
        />
      </div>
      <div className={clsx(tableStyle.base_table_container)}>
        {isLoading ? (
          <Loader />
        ) : isError ? (
          <ErrorIndicator />
        ) : (
          <table className={clsx(tableStyle.base_table, s.table)}>
            <thead>
              <tr>
                <td>
                  <div tabIndex={0} onClick={() => sortCompanies("name")}>
                    <span className={s.cell}>КОМПАНИЯ</span>
                    <span
                      className={clsx(tableStyle.sort_icon, {
                        [tableStyle.sort_icon_active]: sortBy.name,
                      })}
                    >
                      <GreyArrowDownSVG />
                    </span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>ИНН</span>
                  </div>
                </td>
                <td>
                  <div tabIndex={0} onClick={() => sortCompanies("tariff")}>
                    <span>ТАРИФ</span>
                    <span
                      className={clsx(tableStyle.sort_icon, {
                        [tableStyle.sort_icon_active]: sortBy.tariff,
                      })}
                    >
                      <GreyArrowDownSVG />
                    </span>
                  </div>
                </td>
                <td>
                  <div tabIndex={0} onClick={() => sortCompanies("date")}>
                    <span>СРОК ДЕЙСТВИЯ</span>
                    <span
                      className={clsx(tableStyle.sort_icon, {
                        [tableStyle.sort_icon_active]: sortBy.date,
                      })}
                    >
                      <GreyArrowDownSVG />
                    </span>
                  </div>
                </td>
                <td>
                  <span></span>
                </td>
                <td>
                  <div tabIndex={0} onClick={() => sortCompanies("space")}>
                    <span>ОСТАВШИЙСЯ ОБЪЕМ</span>
                    <span
                      className={clsx(tableStyle.sort_icon, {
                        [tableStyle.sort_icon_active]: sortBy.space,
                      })}
                    >
                      <GreyArrowDownSVG />
                    </span>
                  </div>
                </td>
              </tr>
            </thead>
            <tbody>
              {sortedCompanies.map((company) => (
                <TariffCompany
                  key={company.id}
                  company={company}
                  updateCompanyLocal={updateCompanyLocal}
                  onUpdateDeadline={changeDeadlines}
                  onAddAdditionalSpace={addAdditionalSpace}
                />
              ))}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
});
export default TariffChangeTable;
