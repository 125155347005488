import { BodyNormal } from "@ui/fonts";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import styles from "./table-body-row-documents.module.scss";
import { useHistory } from "react-router-dom";
import { useModal } from "@modals/index";

type TableBodyRowDocumentsPropsT = {
  file: {
    id: number;
    name: string;
  };
  departmentId: number;
};

export const TableBodyRowDocuments: React.FC<TableBodyRowDocumentsPropsT> = ({
  file,
  departmentId,
}) => {
  const history = useHistory();
  const { open } = useModal();
  return (
    <>
      <TableRow>
        <TableCell
          align="left"
          className={styles.cell}
          onClick={() => history.push(`/company/employees/${file.id}`)}
        >
          <div className={styles.nameDoc}>
            <BodyNormal>{file.name}</BodyNormal>
          </div>
        </TableCell>
        <TableCell align="right"></TableCell>
        <TableCell align="left">
          <div className={styles.archive}>
            <button
              className={styles.signatures_list_btn}
              onClick={() =>
                open("SignatureLogModal", {
                  modalData: {
                    file,
                    departmentId,
                  },
                })
              }
            >
              Журнал подписей
            </button>
          </div>
        </TableCell>
      </TableRow>
    </>
  );
};
