import {
  $Employees,
  pushToEmployees,
  setEmployees,
} from "@store/company/employees-store";
import { $Departments, setDepartments } from "@store/company/departments-store";
import {
  PositionForSelectDataT,
  PositionGroupT,
  SelectedPositionsT,
  SimpleOptionT,
} from "@interfaces/company/employees";
import { selectColourStyles, selectTheme } from "@components/common/common";
import { ReactChild, ReactFragment, ReactPortal, useCallback, useEffect, useState } from "react";

import EmployeeService from "@services/employee-service";
import Select from "react-select";
import { TextField } from "@mui/material";
import Validator from "@utils/validator";
import clsx from "clsx";
import makeAnimated from "react-select/animated";
import style from "@scss/modals/company/create-company.module.scss";
import useModal from "@modals/modal-hook";
import { useStore } from "effector-react";
import useStyles from "@ui/material-ui-styles";

const animatedComponents = makeAnimated();

const CreateEmployeeModal: React.FC = () => {
  const employees = useStore($Employees);
  const departmentItems = useStore($Departments);
  const [positionItems, setPositionItems] = useState<
    PositionForSelectDataT[][]
  >([]);
  const [selectedDepartments, setSelectedDepartments] = useState<number[]>([]);
  const [selectedPositions, setSelectedPositions] = useState<
    SelectedPositionsT[]
  >([]);
  const [departmentOptions, setDepartmentOptions] = useState<SimpleOptionT[]>(
    []
  );
  const [positionOptions, setPositionOptions] = useState<PositionGroupT[]>([]);
  const [selectedDepartmentOptions, setSelectedDepartmentOptions] = useState<
    SimpleOptionT[]
  >([]);
  const [selectedPositionOptions, setSelectedPositionOptions] = useState<
    SimpleOptionT[]
  >([]);
  const [validation, setValidation] = useState<any>({});

  const { close, modalComponent, modalData } = useModal();
  const classes = useStyles();

  useEffect(() => {
    setDepartmentOptions(
      departmentItems.map((department) => ({
        label: department.title,
        value: department.id,
      }))
    );
  }, [departmentItems]);

  useEffect(() => {
    // ИЗМЕНЕНИЕ ДОЛЖНОСТЕЙ, КОТОРЫЕ ПОТОМ БУДУТ ФИЛЬТРОВАТЬСЯ ДЛЯ ПОКАЗА В ВЫПАДАЮЩЕМ СПИСКЕ
    // departmentId И departmentTitle ДОБАВЛЯЮТСЯ ДЛЯ ТОГО, ЧТОБЫ В КАЧЕСТВЕ ЗАГОЛОВКА ГРУППЫ ВЫПАДАЮЩЕГО СПИСКА ЗАДВАТЬСЯ
    // departmentTitle И ПРИ ВЫБОРЕ ДОЛЖНОСТИ НАЙТИ РОДИТЕЛЬСКОЕ ПОДРАЗДЕЛЕНИЕ ПО departmentId И В КАЧЕСТВЕ ВЫБРАННЫХ ДОЛЖНОСТЕЙ
    // ПОДРАЗДЕЛЕНИЯ ЗАДАВАТЬ position.id(В БУДУЩЕМ ПРИСВАИВАЕТСЯ В position.value(см. ниже), ПОТОМУ ЧТО
    // БИБЛИОТЕКА SELECT ОЖИДАЕТ ИМЕННО ПОЛЯ label и value.
    setSelectedDepartmentOptions(
      departmentItems
        .filter((department) =>
          selectedDepartments.find(
            (selectedDepartmentId) => selectedDepartmentId === department.id
          )
        )
        .map((department) => ({
          label: department.title,
          value: department.id,
        }))
    );

    if (selectedDepartments.length === 0) {
      setPositionItems([]);
      return setSelectedPositions([]);
    }

    setPositionItems(
      departmentItems
        .filter((departmentItem) =>
          selectedDepartments.find(
            (selectedDepartment) => selectedDepartment === departmentItem.id
          )
        )
        .map((departmentItem) =>
          departmentItem.positions.map((position, idx) => {
            (position as PositionForSelectDataT).departmentId =
              departmentItem.id;
            if (idx === 0) {
              (position as PositionForSelectDataT).departmentTitle =
                departmentItem.title;
            }
            return position as PositionForSelectDataT;
          })
        )
    );
    updatedSelectedOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDepartments]);

  useEffect(() => {
    updatedSelectedOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPositions]);

  useEffect(() => {
    // СМ. КОММЕНТАРИЙ ВЫШЕ, ДЛЯ ПОНИМАНИЯ ЛОГИКИ ПРОИСХОДЯШЕГО ДАННОГО БЛОКА КОДА
    const posOptions = positionItems.map((posGroup) => {
      return {
        label: posGroup[0]?.departmentTitle || "",
        departmentId: posGroup[0]?.departmentId || 0,
        options: posGroup.map((pos) => {
          (pos as any).value = pos.id;
          (pos as any).label = pos.title;
          return pos as PositionForSelectDataT;
        }),
      };
    });
    setPositionOptions(posOptions);
  }, [positionItems]);

  const updatedSelectedOptions = useCallback(() => {
    let allSelectedDepartmentsPosOptions: PositionForSelectDataT[] = [];
    const selectedPositionsIds: number[] = [];

    positionOptions
      .filter((posOptionGroup) =>
        selectedDepartments.find(
          (departmentId) => departmentId === posOptionGroup.departmentId
        )
      )
      .map((posOptionGroup) => posOptionGroup.options)
      .forEach((selectedPosOptions) =>
        allSelectedDepartmentsPosOptions.push(...selectedPosOptions)
      );

    selectedPositions.forEach((selectedPosition) =>
      selectedPositionsIds.push(...selectedPosition.positions)
    );

    setSelectedPositionOptions(
      allSelectedDepartmentsPosOptions.filter((selectedDepartmentPosOption) =>
        selectedPositionsIds.find(
          (posId) => posId === selectedDepartmentPosOption.id
        )
      )
    );
  }, [positionOptions, selectedPositions, selectedDepartments]);

  const handleOptionChanging = (
    options: SimpleOptionT[] | PositionForSelectDataT[],
    type: "department" | "position"
  ) => {
    if (type === "department") {
      return setSelectedDepartments(
        (options as SimpleOptionT[]).map((option) => option.value)
      );
    }

    const selectedPosItems: SelectedPositionsT[] = [];
    (options as PositionForSelectDataT[]).forEach((option) => {
      const departmentWithPositionIndex = selectedPosItems.findIndex(
        (item: any) => item.departmentId === option.departmentId
      );
      if (!(departmentWithPositionIndex < 0)) {
        selectedPosItems[departmentWithPositionIndex].positions.push(
          option.value
        );
      } else {
        selectedPosItems.push({
          departmentId: option.departmentId,
          positions: [option.value],
        });
      }
    });
    setSelectedPositions(selectedPosItems);
  };

  useEffect(() => {
    if (modalData.employee) {
      const defaultSelectedPositionOptions = modalData.employee.positions.map(
        (pos: any) => ({ ...pos, label: pos.title, value: pos.id })
      );
      const defaultSelectedPositions = departmentItems
        .filter((department) =>
          modalData.employee.departments.find(
            (employeeDepartment: any) => employeeDepartment.id === department.id
          )
        )
        .map((department) => ({
          departmentId: department.id,
          positions: defaultSelectedPositionOptions.map(
            (defaultPos: any) => defaultPos.value
          ),
        }));

      setSelectedDepartmentOptions(
        modalData.employee.departments.map((department: any) => ({
          ...department,
          label: department.title,
          value: department.id,
        }))
      );
      setSelectedDepartments(
        modalData.employee.departments.map((department: any) => department.id)
      );
      setSelectedPositions(defaultSelectedPositions);

      setTimeout(() => {
        setSelectedPositionOptions(defaultSelectedPositionOptions);
      }, 100);
    }
    // CHANGING OVERFLOW Y TO VISIBLE FOR CORRECT DISPLAY OF THE MODAL
    const modalContent = document.querySelector(
      `.${clsx(style.create_company_modal)}`
    ) as HTMLDivElement;
    modalContent
      .closest("div.react-responsive-modal-modal")
      ?.classList?.add(clsx(style.overflowYVisible));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const modalBtn = document.querySelector(
      "button.modal_btn"
    ) as HTMLButtonElement;
    modalBtn.disabled = true;

    const nameField = document.querySelector(
      'input[name="name"]'
    ) as HTMLInputElement;
    const emailField = document.querySelector(
      'input[name="email"]'
    ) as HTMLInputElement;
    const phoneField = document.querySelector(
      'input[name="phone"]'
    ) as HTMLInputElement;

    if (!nameField) {
      return console.error("Произошла неожиданная ошибка");
    }

    const positions: number[] = [];
    selectedPositions.forEach((pos) => positions.push(...pos.positions));

    const validating = {
      ...Validator(nameField.value, "name")
        .isRequired()
        .withMessage("Это поле обязательное")
        .getErrors(),
      ...Validator(emailField.value || "dummyemail@mail.ru", "email")
        .isEmail()
        .withMessage("Некорректный адрес эл. почты")
        .getErrors(),
      ...Validator(selectedDepartments.length.toString(), "department")
        .isGreater(0)
        .withMessage(
          "Сотрудник должен находиться как минимум в 1 подразделении"
        )
        .getErrors(),
      ...Validator(positions.length.toString(), "position")
        .isGreater(0)
        .withMessage("Сотруднику должна быть присвоена как минимум 1 должность")
        .getErrors(),
    };

    if (Validator.hasError(validating)) {
      modalBtn.disabled = false;
      return setValidation(validating);
    }

    const newData = {
      name: nameField.value,
      email: emailField.value,
      phone: phoneField.value,
      departments: selectedDepartments,
      positions,
    };
    if (modalData.employee) {
      return EmployeeService.update(
        modalData.employee.id,
        newData,
        (err, res) => {
          if (err || !res) {
            if (err?.response?.status === 422) {
              setValidation(err.response.data);
              modalBtn.disabled = false;
            }
            return console.error(
              "При обновлении данных сотрудника произошла ошибка"
            );
          }
          setEmployees(
            employees.map((employee) => {
              if (employee.id === modalData.employee.id) {
                employee = res.data;
              }
              return employee;
            })
          );
          if (modalComponent.onConfirm) {
            modalComponent.onConfirm(res.data);
          }
          close();
        }
      );
    }
    EmployeeService.create(newData, (err, res) => {
      if (err || !res) {
        if (err?.response?.status === 422) {
          setValidation(err.response.data);
          modalBtn.disabled = false;
        }
        return console.error(
          "При добавлении нового сотрудника произошла ошибка"
        );
      }
      setDepartments(
        departmentItems.map((department) => {
          if (
            selectedDepartments.find(
              (selectedDepartmentId) => selectedDepartmentId === department.id
            )
          ) {
            department.employeesCount++;
          }
          return department;
        })
      );
      pushToEmployees(res.data);
      if (modalComponent.onConfirm) {
        modalComponent.onConfirm(res.data);
      }
      close();
    });
  };

  return (
    <div key={modalComponent.key} className={clsx(style.create_company_modal)}>
      <p className="modal_title">
        {modalData.editMode ? "Изменить сотрудника" : "Добавить сотрудника"}
      </p>
      <div className="underline" />
      <form className={`modal_content ${classes.root}`} onSubmit={handleSubmit}>
        <TextField
          label="Имя"
          variant="filled"
          placeholder={"Имя сотрудника"}
          defaultValue={modalData.employee?.name || ""}
          name={"name"}
        />
        <p className="error-text">{validation.nameError}</p>
        <TextField
          label="Почта"
          placeholder={"Почта"}
          variant="filled"
          name={"email"}
          defaultValue={modalData.employee?.email || ""}
        />
        <p className="error-text">{validation.emailError}</p>
        <TextField
          label="Телефон"
          placeholder={"Телефон"}
          variant="filled"
          name={"phone"}
          defaultValue={modalData.employee?.phone || ""}
        />
        <Select
          closeMenuOnSelect={true}
          components={animatedComponents}
          isMulti
          value={selectedDepartmentOptions}
          options={departmentOptions}
          isSearchable
          menuPortalTarget={document.body}
          placeholder={"Подразделения"}
          onChange={(values: any) =>
            handleOptionChanging(values as any, "department")
          }
          styles={selectColourStyles()}
          theme={selectTheme}
        />
        <p className="error-text">{validation.departmentError}</p>
        <Select
          closeMenuOnSelect={true}
          components={animatedComponents}
          isMulti
          menuPortalTarget={document.body}
          value={selectedPositionOptions}
          options={positionOptions}
          formatGroupLabel={(data: { label: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined; }) => (
            <div>
              <span>{data.label}</span>
            </div>
          )}
          isSearchable
          placeholder={"Должности"}
          onChange={(values: any) => handleOptionChanging(values as any, "position")}
          styles={selectColourStyles()}
          theme={selectTheme}
        />
        <p className="error-text">{validation.positionError}</p>
        <button type="submit" className="modal_btn">
          {modalComponent.btnText}
        </button>
      </form>
    </div>
  );
};

export default CreateEmployeeModal;
