import { Archive, DownloadSimple } from "@phosphor-icons/react";
import { CheckboxCheckedSVG, CheckboxSVG } from "@components/svgs";

import { Checkbox } from "@mui/material";
import { Loader } from "@ui/indicators";
import QueryString from "qs";
import React from "react";
import { DepartmentFileForAddingToPositionDataT } from "@interfaces/company/departments";
import { WrapperSVG } from "@components/WrapperSVG/WrapperSVG";
import clsx from "clsx";
import style from "@scss/components/tables/sending-for-signature.module.scss";
import tableStyle from "@scss/components/tables/base-table.module.scss";
import { useArchiveWorkspaceFileMutation } from "@lib/workspace";
import { useHistory } from "react-router-dom";
import { useSelectedCompanyQuery } from "@lib/company";

export type AddingDocumentTablePropsT = {
  selectedDocs: Array<number>;
  onSelect: (val: Array<number>) => void;
  files: DepartmentFileForAddingToPositionDataT[] | null;
  signMode?: boolean;
  archiveHandler: (fileId: number) => void;
  onSelectAll: (checked: boolean) => void;
};

const AddingDocumentTable: React.FC<AddingDocumentTablePropsT> = (props) => {
  const {
    selectedDocs,
    onSelect,
    files,
    signMode,
    archiveHandler,
    onSelectAll,
  } = props;

  const history = useHistory();

  const { company } = useSelectedCompanyQuery();

  const archiveWorkspaceFileMutation = useArchiveWorkspaceFileMutation();

  const query = QueryString.parse(history.location.search);
  const groupId = query.group_id ? +query.group_id : 0;
  const directoryId = query.folder_id ? +query.folder_id : 0;

  const handleChange = (e: any, id: number) => {
    if (e.target.checked) {
      return onSelect([...selectedDocs, id]);
    }
    onSelect(selectedDocs.filter((selectedDocId) => selectedDocId !== id));
  };

  const sendFileToArchive = (fileId: number) => {
    if (!company) return;

    archiveWorkspaceFileMutation.mutate(
      {
        companyId: company.id,
        groupId,
        directoryId,
        fileId,
      },
      {
        onSuccess: () => {
          archiveHandler(fileId);
        },
      }
    );
  };

  const tableBodyContent = files?.map((file, idx) => {
    const id = file.id;
    return (
      <tr key={idx}>
        <td>
          <label className={style.checkedList}>
            <Checkbox
              checked={
                !!selectedDocs.find((selectedDocId) => selectedDocId === id)
              }
              onChange={(e) => handleChange(e, id)}
              color="primary"
              icon={<CheckboxSVG />}
              checkedIcon={<CheckboxCheckedSVG />}
              classes={{ disabled: "mui_disabled_checkbox" }}
            />
            <span>{file.name}</span>
          </label>
        </td>
        <td>
          <div className={clsx(style.actions_col)}>
            {signMode ? <button>Отправить на подпись</button> : null}
            <a
              href={` ${process.env.API_URL}/${file.path}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <WrapperSVG color={"green-light"}>
                <DownloadSimple size={24} />
              </WrapperSVG>
            </a>
            <WrapperSVG color="red">
              <Archive size={24} onClick={() => sendFileToArchive(file.id)} />
            </WrapperSVG>
          </div>
        </td>
      </tr>
    );
  });

  return (
    <div className={clsx(tableStyle.base_table_container)}>
      {!files ? (
        <Loader autoHeight />
      ) : (
        <table
          className={clsx(
            tableStyle.base_table,
            style.sending_for_signature_table
          )}
        >
          <thead>
            <tr>
              <td>
                <label className={style.tableHead}>
                  <Checkbox
                    checked={selectedDocs.length === files?.length}
                    onChange={(e) => onSelectAll(e.target.checked)}
                    color="primary"
                    icon={<CheckboxSVG />}
                    checkedIcon={<CheckboxCheckedSVG />}
                    classes={{ disabled: "mui_disabled_checkbox" }}
                  />
                  Название документа
                </label>
              </td>
              <td />
            </tr>
          </thead>
          <tbody>{tableBodyContent}</tbody>
        </table>
      )}
    </div>
  );
};

export default AddingDocumentTable;
