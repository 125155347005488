import {
  ChangeEvent,
  Dispatch,
  FC,
  FormEvent,
  SetStateAction,
  useRef,
} from "react";

import clsx from "clsx";

import styles from "./ChatActions.module.scss";
import { Button } from "@ui/button";
import TextField from "@mui/material/TextField";
import { PaperPlaneTilt, Paperclip } from "@phosphor-icons/react";
import useStyles from "@ui/material-ui-styles";
import { isValidFilesSize } from "@utils/isValidFilesSize";
import toast from "react-hot-toast";
import { useStore } from "effector-react";
import { $DeviceType } from "@store/device-type-store";
import { ErrorBoundary } from "feature/error-boundary/ErrorBoundary";

export type SendMessageDataT = { text?: string; clear: () => void };

type ChatActionsPropsT = {
  className?: string;
  onSubmit: (data: SendMessageDataT) => void;
  files: File[];
  setFiles: Dispatch<SetStateAction<File[]>>;
  disabled?: boolean;
};

export const ChatActions: FC<ChatActionsPropsT> = ({
  className,
  onSubmit,
  files,
  setFiles,
  disabled,
}) => {
  const { isPhone } = useStore($DeviceType);
  const classes = useStyles();
  const textRef = useRef<HTMLInputElement>(null);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    const isMessageEmpty = textRef.current?.value === "";
    const hasFiles = files.length > 0;
    if (!isMessageEmpty || (isMessageEmpty && hasFiles)) {
      onSubmit({
        text: textRef.current?.value,
        clear: () => {
          if (textRef.current) textRef.current.value = "";
        },
      });
    }
  };

  const handleFilesChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return;

    const newFiles = Array.from(e.target.files);
    const allFiles = newFiles.concat(files);

    const isValidSize = isValidFilesSize(allFiles, 64);

    if (!isValidSize) {
      toast.error("Размер файлов должен быть не более 64 Мб");
    } else {
      if (allFiles.length > 5) {
        toast.error("Количество файлов должно быть не более 5");
      }

      setFiles(allFiles.slice(0, 5));
    }

    e.target.value = "";
  };

  return (
    <form
      className={clsx(
        classes.root,
        styles.chatActions__root,
        isPhone && styles.chatActions__root_mobile
      )}
      autoComplete="off"
      noValidate
      onSubmit={handleSubmit}
    >
      <div className={styles.chatActions}>
        <div className={styles.fileInput}>
          <input
            multiple
            hidden
            type="file"
            accept="*"
            name="feedback-files"
            id="feedback-files"
            onChange={handleFilesChange}
          />
          <label
            htmlFor="feedback-files"
            className={clsx(
              styles.fileInput__label,
              styles.chatActions__button,
              isPhone && styles.fileInput__label_mobile,
              isPhone && styles.chatActions__button_mobile
            )}
          >
            <Paperclip size={isPhone ? 24 : 28} weight="light" color="#999" />
          </label>
        </div>
        <ErrorBoundary isComponent>
          <TextField
            focused
            inputRef={textRef}
            autoFocus
            className={styles.chatActions__textInput}
            label="Сообщение"
            name="message"
            variant="filled"
            onChange={(e) => {
              e.target.value = e.target.value.substring(0, 725);
            }}
          />
        </ErrorBoundary>
        <Button
          type="submit"
          className={clsx(
            styles.chatActions__sendBtn,
            styles.chatActions__button,
            isPhone && styles.chatActions__sendBtn_mobile,
            isPhone && styles.chatActions__button_mobile
          )}
          disabled={disabled}
        >
          <PaperPlaneTilt size={isPhone ? 20 : 24} />
        </Button>
      </div>
    </form>
  );
};
