import { $UserAddPermissions, UserPriorityEnum } from "@store/user-store";
import { BodyBold, BodyLight } from "@ui/fonts";

import { Button } from "@ui/button";
import { TextField } from "@mui/material";
import Validator from "@utils/validator";
import styles from "./UserPassword.module.scss";
import { useState } from "react";
import { useStore } from "effector-react";
import useStyles from "@ui/material-ui-styles";
import { userService } from "@services/user-service";

export const UserPassword = () => {
  const permissions = useStore($UserAddPermissions);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [validation, setValidation] = useState({
    oldPasswordError: "",
    passwordError: "",
    passwordConfirmError: "",
  });
  const classes = useStyles();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const oldPassword = document.querySelector(
      'input[name="oldPassword"]'
    ) as HTMLInputElement;
    const password = document.querySelector(
      'input[name="password"]'
    ) as HTMLInputElement;
    const passwordConfirm = document.querySelector(
      'input[name="passwordConfirm"]'
    ) as HTMLInputElement;

    if (!oldPassword || !password || !passwordConfirm) return;

    const validation = {
      ...Validator(oldPassword.value, "oldPassword")
        .isLength(
          { min: 6, max: 40 },
          "Пароль должен содержать не менее 6 и не более 40 символов"
        )
        .getErrors(),
      ...Validator(password.value, "password")
        .isLength(
          { min: 6, max: 40 },
          "Пароль должен содержать не менее 6 и не более 40 символов"
        )
        .getErrors(),
      ...Validator(passwordConfirm.value, "passwordConfirm")
        .isEqual(password.value, "Пароли не совпадают")
        .getErrors(),
    };

    if (Validator.hasError(validation)) {
      return setValidation(validation as any);
    }

    userService
      .changePassword({
        oldPassword: oldPassword.value,
        password: password.value,
        passwordConfirm: passwordConfirm.value,
      })
      .then(() => {
        setValidation({} as any);
        setEditMode(false);
      })
      .catch((err) => {
        if (err?.response?.status === 422) {
          return setValidation(err.response.data);
        }
      });
  };

  return (
    <div className={styles.wrapper}>
      <p>
        <BodyBold weight={"bold"}>Настройки пароля</BodyBold>
      </p>
      {editMode ? (
        <form className={classes.root} onSubmit={handleSubmit}>
          <TextField
            label="Старый пароль"
            variant="filled"
            type="password"
            name="oldPassword"
          />
          <p className="error-text">{validation.oldPasswordError}</p>
          <TextField
            label="Новый пароль"
            variant="filled"
            type="password"
            name="password"
          />
          <p className="error-text">{validation.passwordError}</p>
          <TextField
            label="Повторите пароль"
            variant="filled"
            type="password"
            name="passwordConfirm"
          />
          <p className="error-text">{validation.passwordConfirmError}</p>
          <div className={styles.buttons}>
            <Button type="submit" className={styles.buttonOptions}>
              Сохранить изменения
            </Button>
            <Button
              type="reset"
              color={"white-green"}
              borderColor={"green"}
              className={styles.buttonOptions}
              onClick={() => {
                setValidation({} as any);
                setEditMode(false);
              }}
            >
              Отмена
            </Button>
          </div>
        </form>
      ) : (
        <div className={styles.password_section_content}>
          <div className={styles.passwordMask}>
            <BodyLight>Пароль:</BodyLight>
            <p>
              <BodyBold weight="bold">**********</BodyBold>
            </p>
          </div>
          {permissions.hasRequiredLevel(UserPriorityEnum.Owner) && (
            <div>
              <Button
                onClick={() => setEditMode(true)}
                className={styles.buttonOptions}
              >
                Сменить пароль
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
