import {
  $User,
  $UserAddPermissions,
  ACTIONS,
  UserRoleEnum,
  setUserData,
} from "@store/user-store";

import { BodyNormal } from "@ui/fonts";
import { Button } from "@ui/button";
import { TextField } from "@mui/material";
import { UserDataT } from "@interfaces/user";
import styles from "./UserData.module.scss";
import { useState } from "react";
import { useStore } from "effector-react";
import useStyles from "@ui/material-ui-styles";
import { useForm } from "react-hook-form";
import { UpdateUserDataPropsT } from "@interfaces/requests/auth";
import { userService } from "@services/user-service";

export const UserData: React.FC = () => {
  const user = useStore($User) as UserDataT;
  const permissions = useStore($UserAddPermissions);
  const [editMode, setEditMode] = useState<boolean>(false);

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<UpdateUserDataPropsT>({
    defaultValues: {
      name: user.name,
      email: user.email,
      phone: user.phone,
    },
  });

  const classes = useStyles();

  const onSubmit = handleSubmit((data) => {
    if (user.roleId === UserRoleEnum.Admin) return;

    userService.update(data).then(() => {
      setUserData({
        ...user,
        email: data.email || user.email,
        name: data.name,
        phone: data.phone,
      });
      setEditMode(false);
    });
  });

  return editMode ? (
    <form className={classes.root} onSubmit={onSubmit}>
      <TextField
        label="ФИО"
        variant="filled"
        error={!!errors.name}
        helperText={errors.name?.message}
        {...register("name", {
          required: "Обязательное поле",
          validate: {
            length: (value) =>
              value.length >= 3 && value.length <= 40
                ? true
                : "Поле должно содержать не менее 3 и не более 40 символов",
          },
        })}
      />
      {user.roleId === UserRoleEnum.Admin ||
      user.roleId === UserRoleEnum.SuperAdmin ? (
        <>
          <TextField
            label="Почта"
            variant="filled"
            error={!!errors.email}
            helperText={errors.email?.message}
            {...register("email", {
              validate: {
                invalid_format: (value) =>
                  // eslint-disable-next-line no-useless-escape
                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                    value ?? ""
                  )
                    ? true
                    : "Некорректный адрес эл. почты",
              },
            })}
          />
        </>
      ) : null}
      <TextField
        label="Телефон"
        variant="filled"
        error={!!errors.phone}
        helperText={errors.phone?.message}
        {...register("phone", {
          validate: {
            invalid_format: (value) => {
              if (!value) return true;
              if (
                /^((8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)(\d{1,3}([- ])?){7,12}$/.test(
                  value
                )
              ) {
                return true;
              }

              return "Некорректный номер телефона";
            },
          },
        })}
      />
      {/* <p className="error-text">{validation.updateError}</p> */}
      <div className={styles.buttons}>
        <Button type="submit" className={styles.buttonOptions}>
          Сохранить изменения
        </Button>
        <Button
          type="reset"
          color={"white-green"}
          borderColor={"green"}
          className={styles.buttonOptions}
          onClick={() => {
            reset();
            setEditMode(false);
          }}
        >
          Отмена
        </Button>
      </div>
    </form>
  ) : (
    <div className={styles.user_info}>
      <p className={styles.user_info_item}>
        ФИО: <BodyNormal weight="bold">{user.name}</BodyNormal>
      </p>
      <p className={styles.user_info_item}>
        Почта: <BodyNormal weight="bold">{user.email}</BodyNormal>
      </p>
      <p className={styles.user_info_item}>
        Телефон: <BodyNormal weight="bold">{user.phone}</BodyNormal>
      </p>
      <p className={styles.user_info_item}>
        Роль: <BodyNormal weight="bold">{user.role?.name}</BodyNormal>
      </p>
      {permissions.hasPermission(ACTIONS.personalAccount_allowedToEdit) && (
        <div className={styles.buttons}>
          <Button
            onClick={() => setEditMode(true)}
            className={styles.buttonOptions}
          >
            Изменить данные
          </Button>
        </div>
      )}
    </div>
  );
};
