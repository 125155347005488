import {
  ArrowDropDownGraySVG,
  CheckboxCheckedSVG,
  CheckboxSVG,
} from "@components/svgs";
import { Procedure, TablesDirectionDataT } from "@interfaces/company/procedure";
import { Text, clsx } from "@mantine/core";

import { Checkbox } from "@mui/material";
import { RowTableCategory } from "../row-table-category/row-table-category";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { UseSortingResult } from "@hooks/useSorting";
import { memo } from "react";
import style from "@scss/components/tables/table-category.module.scss";

export type UpdateDataT = {
  id: number;
  [key: string]: string | number | null;
};

export type TableControlProps = {
  type: "training" | "briefing";
  offset: number;
  table?: TablesDirectionDataT;
  ignoredKeys: (keyof Procedure)[];
  selectedProcedures: Procedure[];
  sorting?: UseSortingResult;
  companyId?: number;
  onSelectAll: (checked: boolean) => void;
  onSelectProcedure: (procedure: Procedure, checked: boolean) => void;
};

export const TableCategory: React.FC<TableControlProps> = memo(
  ({
    type,
    offset,
    ignoredKeys,
    table,
    selectedProcedures,
    sorting,
    companyId,
    onSelectAll,
    onSelectProcedure,
  }) => {
    return (
      <Table className={style.table_wrapper} aria-label="caption table">
        <TableHead>
          <TableRow>
            <TableCell>
              <Checkbox
                checked={selectedProcedures.length === table?.body.length}
                name="select_all"
                color="primary"
                icon={<CheckboxSVG />}
                checkedIcon={<CheckboxCheckedSVG />}
                onChange={(_, checked) => onSelectAll(checked)}
                classes={{ disabled: "mui_disabled_checkbox" }}
              />
            </TableCell>
            <TableCell>№</TableCell>
            {table?.config
              .filter((item) => !ignoredKeys.includes(item.key))
              .map((item, i) => (
                <TableCell key={i} align="right" className={style.table_head}>
                  <div
                    className={style.table_head_wrapper}
                    onClick={() =>
                      sorting?.onItemSortingChange(`${item.key}Order`)
                    }
                  >
                    <div className={style.table_head_name}>{item.label}</div>
                    <div className={style.table_head_sort} tabIndex={0}>
                      <div
                        className={clsx(style.table_head_sortIcon, {
                          [style.table_head_sortIcon_rotated]:
                            sorting?.shouldIconRotate(`${item.key}Order`),
                        })}
                      >
                        <ArrowDropDownGraySVG />
                      </div>
                    </div>
                  </div>
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {!!table?.body.length ? (
            table?.body.map((row, index: number) => (
              <RowTableCategory
                index={index + offset}
                key={row.id}
                row={row}
                type={type}
                ignoredKeys={ignoredKeys}
                config={table?.config}
                selectedProcedures={selectedProcedures}
                companyId={companyId}
                onSelectProcedure={onSelectProcedure}
              />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={(table?.config.length ?? 0) + 1}>
                <Text align="center" color="gray">
                  Список пуст
                </Text>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    );
  }
);
