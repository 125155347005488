import "@scss/pages/question-answer.scss";

import { Paperclip, X } from "@phosphor-icons/react";

import { BackButton } from "@components/back-button";
import { Button } from "@ui/button";
import { FileDocSVG } from "@components/svgs";
import { Loader } from "@ui/indicators";
import TextField from "@mui/material/TextField";
import clsx from "clsx";
import { formatFileSize } from "@utils/formatFileSize";
import styles from "./SendFeedBack.module.scss";
import toast from "react-hot-toast";
import { useFeedbackQuery } from "@lib/feedback";
import { useHistory } from "react-router-dom";
import useStyles from "@ui/material-ui-styles";
import { useForm } from "react-hook-form";

type SendFeedbackData = {
  title: string;
  description: string;
  files: FileList | null;
};

type SendFeedBackT = {
  handlerNewFeedBack: () => void;
  newFeedBack: boolean;
};

export const SendFeedBack: React.FC<SendFeedBackT> = ({
  newFeedBack,
  handlerNewFeedBack,
}) => {
  const { push } = useHistory();

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm<SendFeedbackData>();

  const uploadedFiles = watch("files");

  const { mutate: createFeedback, isLoading } = useFeedbackQuery();
  const classes = useStyles();

  const onSubmit = handleSubmit((data) => {
    const formData = new FormData();

    formData.append("title", data.title);
    formData.append("description", data.description);

    for (const file of data.files ?? []) {
      formData.append("files", file, file.name);
    }

    createFeedback(formData, {
      onError: () => {
        toast.error("Произошла не известная ошибка");
      },
      onSuccess: (data) => {
        toast.success("Обращение отправлено");
        push(`feedback/${data.data._id}`);
      },
    });
  });

  const LoadedFiles = ({ files, setSelectedFiles }: any) => {
    const content: any = [];

    for (let key in files) {
      const currentFile = files[key];

      if (!currentFile || !+currentFile.size) {
        break;
      }
      content.push(
        <div key={key} className={clsx(styles.feedback_row)}>
          <div
            className={styles.feedback_row_file}
            onClick={() => {
              let newFileList: any = {};

              for (let key2 in files) {
                // IF CURRENT FILE IS NOT EQUAL TO CLICKED FILE, THEN DONT DELETE A FILE FROM FILES LIST
                if (
                  files[key2].lastModified !== currentFile.lastModified ||
                  files[key2].size !== currentFile.size
                ) {
                  newFileList[key2] = files[key2];
                }
              }
              setSelectedFiles({ ...newFileList });
            }}
          >
            <FileDocSVG />
            <div className={styles.fileName}>
              {currentFile.name} ({formatFileSize(currentFile.size)})
            </div>
            <X size={22} />
          </div>
        </div>
      );
    }
    return content;
  };

  return (
    <>
      {!isLoading && newFeedBack && (
        <div className={styles.formFeedback}>
          <div onClick={handlerNewFeedBack}>
            <BackButton off />
          </div>
          <form
            className={classes.root}
            noValidate
            autoComplete="off"
            onSubmit={onSubmit}
          >
            <TextField
              label="Тема обращения"
              variant="filled"
              error={!!errors.title}
              helperText={errors.title?.message}
              {...register("title", {
                required: "Обязательное поле",
                onChange: (e) => {
                  if (e.target.value.length > 125) {
                    e.target.value = e.target.value.slice(0, 125);
                  }
                },
              })}
            />
            <TextField
              label="Опишите проблему"
              multiline
              rows={4}
              variant="filled"
              error={!!errors.description}
              helperText={errors.description?.message}
              {...register("description", {
                required: "Обязательное поле",
              })}
            />
            <LoadedFiles
              files={uploadedFiles}
              setSelectedFiles={(files: any) => setValue("files", files)}
            />
            <div className={styles.btnAndClip}>
              <Button type="submit">Отправить</Button>
              <div className={clsx(styles.feedback_form_actions)}>
                <div className={styles.loadFile}>
                  <input
                    multiple
                    type="file"
                    id="send-feedback-files"
                    hidden
                    {...register("files")}
                  />
                  <label
                    htmlFor="send-feedback-files"
                    className={styles.labelFile}
                  >
                    <Paperclip size={22} color="#777" />
                  </label>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
      {isLoading && (
        <div className={styles.isLoader}>
          <Loader />
        </div>
      )}
    </>
  );
};
