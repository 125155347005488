import React, { useEffect, useState } from "react";
import { BodyNormal } from "@ui/fonts";
import { DropDownLabel } from "@ui/drop-down";
import styles from "./post-type-documents.module.scss";
import {
  SingleTrainingRequest,
  TrainingRequestDocumentShipping,
  trainingRequestDocumentShippings,
  UpdateTrainingRequestData,
} from "@interfaces/request-training";
import { useUpdateTrainingRequestMutation } from "@lib/request-training";
import { TextField } from "@mui/material";
import { handleApiError } from "@utils/handleApiError";
import { isTrainingRequestEditable } from "@utils/isTrainingRequestEditable";
import { useStore } from "effector-react";
import { $UserAddPermissions, ACTIONS } from "@store/user-store";

type FormErrors = {
  [key: string]: string | null;
};

export type PostTypeDocumentsProps = {
  training: SingleTrainingRequest | undefined;
};

export const PostTypeDocuments: React.FC<PostTypeDocumentsProps> = ({
  training,
}) => {
  const permissions = useStore($UserAddPermissions);

  const updateTrainingRequestMutation = useUpdateTrainingRequestMutation();

  const [errors, setErrors] = useState<FormErrors>({});
  const [deliveryAddress, setDeliveryAddress] = useState(
    training?.deliveryAddress ?? ""
  );
  const [contactPerson, setContactPerson] = useState(
    training?.contactPerson ?? ""
  );
  const [contactPhoneNumber, setContactPhoneNumber] = useState(
    training?.contactPhoneNumber ?? ""
  );

  const isEditable = isTrainingRequestEditable(training);
  const shouldShowDeliveryInfo =
    training?.typeOfWayToGetDocuments !== null &&
    training?.typeOfWayToGetDocuments !== "Самовывоз";

  useEffect(() => {
    if (!training) return;

    setDeliveryAddress(training.deliveryAddress ?? "");
    setContactPerson(training.contactPerson ?? "");
    setContactPhoneNumber(training.contactPhoneNumber ?? "");
  }, [training]);

  const handlerChangeFilter = (
    _: string,
    value: TrainingRequestDocumentShipping | ""
  ) => {
    if (!training || !isEditable) return;

    const item = trainingRequestDocumentShippings.find(
      (item) => item.id === value
    );
    if (!item) return;

    const wayToGetDocuments = item.title;

    updateTrainingRequestMutation.mutate({
      id: training.id,
      wayToGetDocuments,
      typeOfWayToGetDocuments: value || null,
    });
  };

  const handleFocus = (key: string) => {
    const newErrors = { ...errors };
    delete newErrors[key];
    setErrors(newErrors);
  };

  const handleUpdateDeliveryInfo = <
    TKey extends keyof UpdateTrainingRequestData,
  >(
    key: TKey,
    value: UpdateTrainingRequestData[TKey]
  ) => {
    if (!training) return;

    updateTrainingRequestMutation.mutate(
      { id: training.id, [key]: value },
      {
        onError: (error) => {
          handleApiError(error, setErrors);
        },
      }
    );
  };

  return (
    <div className={styles.content}>
      <div className={styles.content__head}>
        <BodyNormal weight={"bold"}>
          Доставка готовых документов об обучении
        </BodyNormal>
      </div>
      <div>
        <DropDownLabel
          styleLabel={styles.styleLabel}
          label={"Способ получения документов"}
          list={documentShippingTypes}
          type="type"
          handlerChangeFilter={(key, value) =>
            handlerChangeFilter(key, value as TrainingRequestDocumentShipping)
          }
          choice={false}
          defaultValue={training?.typeOfWayToGetDocuments ?? undefined}
          className={styles.select_post}
          disabled={
            !isEditable ||
            !permissions.hasPermission(
              ACTIONS.companyApplicationsForTraining_allowedToEdit
            )
          }
        />
      </div>
      {shouldShowDeliveryInfo && (
        <div className={styles.content__head}>
          <div>
            <BodyNormal weight={"bold"}>Данные для доставки:</BodyNormal>
          </div>
          <div className={styles.content__deliveryInfo}>
            <TextField
              disabled={
                !isEditable ||
                !permissions.hasPermission(
                  ACTIONS.companyApplicationsForTraining_allowedToEdit
                )
              }
              variant="filled"
              error={!!errors.contactPerson}
              helperText={errors.contactPerson}
              label="Контактное лицо"
              value={contactPerson}
              onChange={(e) => setContactPerson(e.target.value)}
              onFocus={() => handleFocus("contactPerson")}
              onBlur={() =>
                handleUpdateDeliveryInfo("contactPerson", contactPerson)
              }
            />
            <TextField
              disabled={
                !isEditable ||
                !permissions.hasPermission(
                  ACTIONS.companyApplicationsForTraining_allowedToEdit
                )
              }
              variant="filled"
              error={!!errors.contactPhoneNumber}
              helperText={errors.contactPhoneNumber}
              label="Номер телефона"
              value={contactPhoneNumber}
              onChange={(e) => setContactPhoneNumber(e.target.value)}
              onFocus={() => handleFocus("contactPhoneNumber")}
              onBlur={() =>
                handleUpdateDeliveryInfo(
                  "contactPhoneNumber",
                  contactPhoneNumber
                )
              }
            />
            <TextField
              disabled={
                !isEditable ||
                !permissions.hasPermission(
                  ACTIONS.companyApplicationsForTraining_allowedToEdit
                )
              }
              variant="filled"
              error={!!errors.deliveryAddress}
              helperText={errors.deliveryAddress}
              label="Адрес доставки"
              value={deliveryAddress}
              onChange={(e) => setDeliveryAddress(e.target.value)}
              onFocus={() => handleFocus("deliveryAddress")}
              onBlur={() =>
                handleUpdateDeliveryInfo("deliveryAddress", deliveryAddress)
              }
            />
            {/* <BodyNormal>{training?.deliveryAddress}</BodyNormal> */}
            {/* <BodyNormal>{training?.contactPerson}</BodyNormal> */}
            {/* <BodyNormal>{training?.contactPhoneNumber}</BodyNormal> */}
          </div>
        </div>
      )}
    </div>
  );
};

type Choice = {
  id: string;
  title: string;
};

const firstChoice: Choice = {
  id: "",
  title: "Выбрать...",
};

const documentShippingTypes = [
  firstChoice,
  ...trainingRequestDocumentShippings,
];
