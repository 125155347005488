import React, { useState } from "react";
import { Modal } from "react-responsive-modal";
import {
  SingleTrainingRequest,
  TrainingRequestStudent,
  UpdateTrainingRequestData,
} from "@interfaces/request-training";
import { Button } from "@ui/button";
import {
  useAvailableEmployeesQuery,
  useUpdateTrainingRequestMutation,
} from "@lib/request-training";
import { Program } from "./program";
import styles from "./add-employees-modal.module.scss";
import { Checkbox } from "@mui/material";
import { BodyNormal } from "@ui/fonts";
import { CheckboxCheckedSVG, CheckboxSVG } from "@components/svgs";

export type AddEmployeesModalProps = {
  open: boolean;
  training: SingleTrainingRequest;
  onClose: () => void;
};

export const AddEmployeesModal: React.FC<AddEmployeesModalProps> = ({
  open,
  training,
  onClose,
}) => {
  const { data } = useAvailableEmployeesQuery(training.id);

  const updateTrainingRequestMutation = useUpdateTrainingRequestMutation();

  const [includeOtherEmployees, setIncludeOtherEmployees] = useState(false);
  const [selectedStudents, setSelectedStudents] = useState<
    Record<number, TrainingRequestStudent[]>
  >({});

  const handleCloseModal = () => {
    setSelectedStudents([]);
    onClose();
  };

  const handleChange = (programId: number, value: TrainingRequestStudent[]) => {
    const newValues = { ...selectedStudents };
    newValues[programId] = value;

    setSelectedStudents(newValues);
  };

  const handleAddStudents = () => {
    if (!training) return;

    const addedStudents: UpdateTrainingRequestData["addedStudents"] = [];
    for (const [id, employees] of Object.entries(selectedStudents)) {
      employees.forEach((employee) => {
        addedStudents.push({ programId: +id, employeeId: employee.id });
      });
    }

    updateTrainingRequestMutation.mutate(
      {
        id: training.id,
        addedStudents: addedStudents,
      },
      {
        onSuccess: () => {
          handleCloseModal();
        },
      }
    );
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      center
      classNames={{ modal: styles.AddEmployeesModal }}
    >
      <div className={styles.AddEmployeesModal__content}>
        <div className={styles.AddEmployeesModal__programs}>
          {data?.map((item, i) => (
            <Program
              key={i}
              training={training}
              data={item}
              selectedStudents={selectedStudents}
              includeOtherEmployees={includeOtherEmployees}
              onChange={handleChange}
            />
          ))}
        </div>
        <div>
          <Checkbox
            checked={includeOtherEmployees}
            color="primary"
            icon={<CheckboxSVG />}
            checkedIcon={<CheckboxCheckedSVG />}
            onChange={(_, checked) => setIncludeOtherEmployees(checked)}
          />
          <BodyNormal>Показать весь список сотрудников</BodyNormal>
        </div>
        <Button
          className={styles.AddEmployeesModal__addButton}
          onClick={handleAddStudents}
        >
          Добавить
        </Button>
      </div>
    </Modal>
  );
};
