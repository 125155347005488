import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { TaskType, WorkplanTask } from "@interfaces/specialist-plan";

import { Button } from "@ui/button";
import moment from "moment";
import styles from "./employees-info.module.scss";
import { useHistory } from "react-router-dom";
import { useWorkplanTaskQuery } from "@lib/workplan";
import { useSelectedCompanyQuery } from "@lib/company";

export type WorkplanEmployeesInfoProps = {
  taskId: number;
  onClose: () => void;
};

export const WorkplanEmployeesInfo: React.FC<WorkplanEmployeesInfoProps> = ({
  taskId,
  onClose,
}) => {
  const history = useHistory();

  const { company } = useSelectedCompanyQuery();
  const { task } = useWorkplanTaskQuery(company?.id, taskId);

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen((prev) => !prev);

  const data = getEmployeeInfoDataFromTask(task);

  const handleOpenControlPage = () => {
    if (!task) {
      console.error("Ошибка: task is undefined");
      return;
    }

    onClose();
    if (
      task.type === TaskType.ConductBriefing ||
      task.type === TaskType.FillDataInBriefingControlTable ||
      task.type === TaskType.ConductPrimaryBriefing ||
      task.type === TaskType.ConductIntroductoryBriefing
    ) {
      history.push("/company/procedure-control/briefings");
    } else if (
      task.type === TaskType.ConductTrainingIn ||
      task.type === TaskType.ConductTrainingOut ||
      task.type === TaskType.FillDataInTrainingControlTable
    ) {
      history.push("/company/procedure-control/training");
    }
  };

  const handleOpenDepartmentPage = () => {
    if (!task) {
      console.error("Ошибка: task is undefined");
      return;
    }

    onClose();
    if (
      task.type === TaskType.ConductBriefing ||
      task.type === TaskType.FillDataInBriefingControlTable ||
      task.type === TaskType.ConductPrimaryBriefing ||
      task.type === TaskType.ConductIntroductoryBriefing
    ) {
      history.push("/company/procedure-control/briefings");
    } else if (
      task.type === TaskType.ConductTrainingIn ||
      task.type === TaskType.ConductTrainingOut ||
      task.type === TaskType.FillDataInTrainingControlTable
    ) {
      history.push("/company/procedure-control/training");
    }
  };

  const handleRequestTrainingPage = () => {
    history.push(`/company/request-training/${task?.applicationId}`);
    onClose();
  };

  return (
    <div className={styles.EmployeesInfo}>
      {isOpen && <EmployeesInfoTable data={data} />}
      <div className={styles.EmployeesInfoButton}>
        <Button type="button" onClick={toggle}>
          {isOpen ? "Свернуть" : "Подробнее"}
        </Button>

        {(task?.briefingControls || task?.trainingControls) && (
          <Button type="button" onClick={handleOpenControlPage}>
            Перейти в таблицу контроля
          </Button>
        )}

        {task?.applicationId && (
          <Button type="button" onClick={handleRequestTrainingPage}>
            Перейти в заявку
          </Button>
        )}
      </div>
      {task?.departmentId && (
        <Button
          type="button"
          className={styles.EmployeesInfo__departmentLink}
          onClick={handleOpenDepartmentPage}
        >
          Перейти в подразделение
        </Button>
      )}
    </div>
  );
};

const getEmployeeInfoDataFromTask = (
  task: WorkplanTask | null | undefined
): EmployeesInfo[] => {
  if (!task) return [];

  if (task.trainingControls) {
    return task.trainingControls.map((item) => ({
      department: item.departmentTitle,
      position: item.positionTitle,
      employee: item.employeeName,
      date: item.trainingDate,
    }));
  }

  if (task.briefingControls) {
    return task.briefingControls.map((item) => ({
      department: item.departmentTitle,
      position: item.positionTitle,
      employee: item.employeeName,
      date: item.briefingDate,
    }));
  }

  if (task.employees) {
    return task.employees.map((item) => ({
      department: item.position?.department?.title ?? "",
      position: item.position?.title ?? "",
      employee: item.name,
    }));
  }

  return [];
};

type EmployeesInfo = {
  department: string;
  position: string;
  employee: string;
  date?: Date | null;
};

type EmployeesInfoTableProps = {
  data: EmployeesInfo[];
};

const EmployeesInfoTable: React.FC<EmployeesInfoTableProps> = ({ data }) => {
  const withDateColumn = data.some((item) => !!item.date);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Подразделение</TableCell>
            <TableCell>Должность</TableCell>
            <TableCell>ФИО</TableCell>
            {withDateColumn && <TableCell>Дата</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, i) => (
            <TableRow key={i}>
              <TableCell>{item.department}</TableCell>
              <TableCell>{item.position}</TableCell>
              <TableCell>{item.employee}</TableCell>
              {withDateColumn && item.date && (
                <TableCell>
                  {moment(new Date(item.date)).format("DD.MM.YYYY")}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
