import { $UserAddPermissions, ACTIONS } from "@store/user-store";
import {
  useCreateQuestionAnswerMutation,
  useUpdateQuestionAnswerMutation,
  useDeleteQuestionAnswerMutation,
} from "@lib/question-answer";

import React from "react";
import { TextField } from "@mui/material";
import useModal from "@modals/modal-hook";
import { useStore } from "effector-react";
import useStyles from "@ui/material-ui-styles";

const CreateQuestionAnswerModal: React.FC = () => {
  const { modalComponent, modalData, close } = useModal();

  const classes = useStyles();
  const permissions = useStore($UserAddPermissions);

  const createQuestionAnswerMutation = useCreateQuestionAnswerMutation();
  const updateQuestionAnswerMutation = useUpdateQuestionAnswerMutation();
  const deleteQuestionAnswerMutation = useDeleteQuestionAnswerMutation();

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const modalBtn = document.querySelector(
      "button.modal_btn"
    ) as HTMLButtonElement;
    modalBtn.disabled = true;

    const title = document.querySelector(
      'input[name="titleQuestion"]'
    ) as HTMLInputElement;
    const answer = document.querySelector(
      'textarea[name="answerQuestion"]'
    ) as HTMLInputElement;

    // CREATING NEW GROUP
    if (title?.value && answer?.value && !modalData.editMode) {
      createQuestionAnswerMutation.mutate(
        {
          title: title.value,
          answer: answer.value,
        },
        {
          onSuccess: () => {
            close();

            if (modalData.onConfirm) {
              modalData.onConfirm();
            }
          },
        }
      );
    }

    if (title?.value && answer?.value && modalData.editMode) {
      updateQuestionAnswerMutation.mutate(
        {
          id: modalData.id,
          data: {
            title: title.value,
            answer: answer.value,
          }
        },
        {
          onSuccess: () => {
            close();
          },
        }
      );
    }
  };

  const handleDelete = () => {
    if (typeof modalData.id !== "number") return;

    deleteQuestionAnswerMutation.mutate(
      {
        id: modalData.id,
      },
      {
        onSuccess: () => {
          close();
        },
      }
    );
  };

  return (
    <div key={modalComponent.key}>
      <p className="modal_title">{modalData.modalTitle}</p>
      <div className="underline" />
      <form className={`modal_content ${classes.root}`} onSubmit={handleSubmit}>
        <TextField
          label={"Заголовок"}
          name={"titleQuestion"}
          variant="filled"
          required
          defaultValue={modalData.titleQuestion}
        />
        <TextField
          label={"Ответ"}
          name={"answerQuestion"}
          required
          multiline
          rows={5}
          variant="filled"
          defaultValue={modalData.answerQuestion}
        />
        {modalData.editMode ? (
          <div className="modal_buttons_group">
            <button type="submit" className="modal_btn">
              {modalComponent.btnText}
            </button>
            {permissions.hasPermission(
              ACTIONS.companyWorkspace_groups_allowedToDelete
            ) && (
              <button
                type="button"
                className="modal_btn"
                onClick={handleDelete}
              >
                Удалить
              </button>
            )}
          </div>
        ) : (
          <button type="submit" className="modal_btn">
            {modalComponent.btnText}
          </button>
        )}
      </form>
    </div>
  );
};

export default CreateQuestionAnswerModal;
