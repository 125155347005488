import { ServiceLoginPropsT } from "@interfaces/requests/auth";
import {
  FilledInput,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { login } from "@store/auth-store";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import styles from "./LoginForm.module.scss";

export const LoginForm: FC = () => {
  const history = useHistory();

  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm<ServiceLoginPropsT>();

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const onSubmit = handleSubmit((data) => {
    login({
      email: data.email,
      password: data.password,
      cb: (err) => {
        if (err && !err?.response) {
          toast.error("Техническое обслуживание", { id: "server-support" });
        }
        if (err?.response) {
          if (typeof err.response.data === "object") {
            if (
              "message" in err.response.data &&
              typeof err.response.data.message === "string"
            ) {
              setError("password", {
                type: "server",
                message: err.response.data.message,
              });
            }
          }
          return;
        }

        history.push("/");
      },
    });
  });

  return (
    <form onSubmit={onSubmit} className={styles.loginForm}>
      <TextField
        label="Логин"
        placeholder={"Логин"}
        variant="filled"
        error={!!errors.email}
        helperText={errors.email?.message}
        {...register("email", {
          required: "Обязательное поле",
        })}
      />
      <FormControl variant="filled">
        <InputLabel htmlFor="filled-adornment-password">Пароль</InputLabel>
        <FilledInput
          type={isPasswordVisible ? "text" : "password"}
          placeholder={"Пароль"}
          error={!!errors.password}
          {...register("password", {
            required: "Обязательное поле",
            validate: {
              minLength: (value) =>
                value.length < 6
                  ? "Пароль должен содержать не менее 6 символов"
                  : true,
              maxLength: (value) =>
                value.length > 40
                  ? "Пароль должен быть не меньше 40 символов"
                  : true,
            },
          })}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setIsPasswordVisible((prev) => !prev)}
                edge="end"
              >
                {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        <FormHelperText>
          <span className="error-text">{errors.password?.message}</span>
        </FormHelperText>
      </FormControl>
      <button className="modal_btn">Войти</button>
    </form>
  );
};
