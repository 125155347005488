import { Button, Group, Text, clsx } from "@mantine/core";
import { ButtonBase, TextField, Theme, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  createNewPosition,
  updatePosition,
} from "@store/company/departments-store";
import { createStyles, makeStyles } from "@mui/styles";

import EditInstructionProgramsModal from "./edit-instruction-modal";
import EditLearningProgramModal from "./edit-learning-program-modal";
import { NotePencil } from "@phosphor-icons/react";
import { WrapperSVG } from "@components/WrapperSVG/WrapperSVG";
import { getNoun } from "@utils/getNoun";
import useModal from "@modals/modal-hook";
import useStyles from "@ui/material-ui-styles";
import { MedicalExamsModal } from "./medical-exams-modal";
import { MedicalExaminationFactors } from "@interfaces/company/departments";

type Modal =
  | "learn"
  | "learn-edit"
  | "instruction"
  | "instruction-edit"
  | "medicalexams"
  | "medicalexams-edit";

export type ChildrenProps = {
  trainingProgramsValue: { id: number; location: string }[];
  setTraining: React.Dispatch<
    React.SetStateAction<{ id: number; location: string }[]>
  >;
  instructionValue: number[];
  setInstructions: React.Dispatch<React.SetStateAction<number[]>>;
  medicalExamsValue: number[];
  setMedicalExamsValue: React.Dispatch<React.SetStateAction<number[]>>;
  type: "view" | "edit";
};

const DATA: {
  [key in Modal]?: { title: string; Component: React.FC<ChildrenProps> };
} = {
  learn: {
    title: "Перечень выбранных программ обучения",
    Component: EditLearningProgramModal,
  },
  "learn-edit": {
    title: "Добавить перечень требуемых программ обучения",
    Component: EditLearningProgramModal,
  },
  instruction: {
    title: "Охрана труда",
    Component: EditInstructionProgramsModal,
  },
  "instruction-edit": {
    title: "Редактирование списка",
    Component: EditInstructionProgramsModal,
  },
  medicalexams: {
    title: "Медицинские осмотры",
    Component: MedicalExamsModal,
  },
  "medicalexams-edit": {
    title: "Редактирование медицинских осмотров",
    Component: MedicalExamsModal,
  },
};

const useFormStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#F5F6FA",
      marginTop: 24,
      padding: "10px 12px",
      borderRadius: 5,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      textAlign: "start",
    },
    label: {
      fontSize: "0.75rem",
      color: theme.palette.primary.main,
    },
    icon: {
      color: theme.palette.grey[500],
      fontSize: "1.50rem",
    },
    form: {
      backgroundColor: "#EFF1F8",
      padding: "0 0 24px",
    },
    button: {
      marginLeft: 70,
    },
    penIcon: {
      fontSize: 20,
    },
    buttonError: {
      border: `1px solid ${theme.palette.error.main}`,
    },
    labelError: {
      color: theme.palette.error.main,
    },
    inputError: {
      color: theme.palette.error.main,
      border: `1px solid ${theme.palette.error.main}`,
    },
    base: {
      width: "80vw",
      maxWidth: 700,
      minWidth: 280,
    },
  })
);

const CreatePositionModal = () => {
  const { modalComponent, modalData, close } = useModal();
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [title, setTitle] = useState<string>(modalData?.position?.title ?? "");
  const [trainingProgramsValue, setTrainingProgramsValue] = useState<
    { id: number; location: string }[]
  >(
    modalData?.trainingPrograms ??
      modalData?.position?.trainingPrograms?.map((item: any) =>
        "location" in item
          ? item
          : {
              id: item.Pos_TrProg.trainingProgramId,
              location: item.Pos_TrProg.location,
            }
      ) ??
      []
  );

  const [instructionValue, setInstructionValue] = useState<number[]>(
    modalData?.briefingPrograms ??
      modalData?.position?.briefingPrograms?.map((item: { id: number }) =>
        typeof item === "number" ? item : item.id
      ) ??
      []
  );
  const classes = useStyles();
  const styles = useFormStyles();

  const [medicalExamsValue, setMedicalExamsValue] = useState<number[]>(
    modalData?.medicalExams ??
      modalData?.position?.medicalExaminationFactors?.map(
        (item: MedicalExaminationFactors) => item.id
      ) ??
      []
  );

  const [modalType, setModalType] = useState<Modal | undefined>(
    modalData.defaultModalType
  );

  const isEdit = modalType?.split("-")[1] === "edit";

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = ({
    target: { value },
  }) => {
    setErrors({});
    setTitle(value);
  };

  const handleClickToAddLearnProgram = () => {
    setErrors({});
    if (trainingProgramsValue.length === 0) {
      setModalType("learn-edit");
      return;
    }
    setModalType("learn");
  };

  const Component = DATA[modalType as Modal]?.Component ?? React.Fragment;

  const handleClickToAddInstructions = () => {
    setErrors({});
    if (instructionValue.length === 0) {
      setModalType("instruction-edit");
      return;
    }
    setModalType("instruction");
  };

  const handleAddMedicalExams = () => {
    setErrors({});
    if (medicalExamsValue.length === 0) {
      setModalType("medicalexams-edit");
      return;
    }
    setModalType("medicalexams");
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    if (modalData.isUser) {
      modalData.setProgramsValue(
        trainingProgramsValue,
        instructionValue,
        medicalExamsValue
      );
      close();
      return;
    }
    if (modalType !== undefined) {
      setModalType(undefined);
      return;
    }
    if (title.trim() === "") {
      setErrors((prev) => ({ ...prev, title: "require" }));
      return;
    }

    if (modalData.editMode) {
      updatePosition({
        positionId: modalData.position.id,
        departmentId: modalData.departmentId,
        title,
        briefingPrograms: instructionValue,
        trainingPrograms: trainingProgramsValue,
        medicalExaminationFactors: medicalExamsValue,
        cb: (err, res) => {
          if (err) {
            return console.error("При изменении должности произошла ошибка");
          }
          close();
        },
      });
    } else {
      createNewPosition({
        departmentId: modalData.departmentId,
        title,
        briefingPrograms: instructionValue,
        trainingPrograms: trainingProgramsValue,
        medicalExaminationFactors: medicalExamsValue,
        cb: (err, res) => {
          if (err || !res) {
            return console.error("При добавлении должности произошла ошибка");
          }
          close();
        },
      });
    }
    return;
  };

  const handleEditButton = () => {
    setModalType((prev) => (prev + "-edit") as Modal);
  };

  return (
    <div key={modalComponent.key}>
      <p className="modal_title">{modalData.modalTitle}</p>
      <div className="underline" />
      <form
        className={`modal_content ${classes.root}  ${
          modalType !== undefined && styles.form
        } ${styles.base}`}
        onSubmit={handleSubmit}
      >
        {modalType ? (
          <Component
            trainingProgramsValue={trainingProgramsValue}
            instructionValue={instructionValue}
            medicalExamsValue={medicalExamsValue}
            setMedicalExamsValue={setMedicalExamsValue}
            setInstructions={setInstructionValue}
            setTraining={setTrainingProgramsValue}
            type={isEdit ? "edit" : "view"}
          />
        ) : (
          <>
            <TextField
              value={title}
              onChange={handleChange}
              label={modalData.fieldTitle}
              name={"title"}
              variant="filled"
              className={errors.title && styles.inputError}
            />
            <ButtonBase
              className={clsx(
                styles.root,
                errors.trainingPrograms && styles.buttonError
              )}
              onClick={handleClickToAddLearnProgram}
            >
              <Typography
                className={clsx(
                  styles.label,
                  errors.trainingPrograms && styles.labelError
                )}
              >
                Программы обучения
              </Typography>
              <Typography>
                {trainingProgramsValue.length === 0
                  ? "Добавить перечень требуемых программ обучения"
                  : `Добавлено ${trainingProgramsValue.length} ${getNoun(
                      trainingProgramsValue.length,
                      "программа",
                      "программы",
                      "программ"
                    )}`}
              </Typography>
            </ButtonBase>
            <ButtonBase
              className={clsx(
                styles.root,
                errors.training && styles.buttonError
              )}
              onClick={handleClickToAddInstructions}
            >
              <Typography
                className={clsx(
                  styles.label,
                  errors.trainingPrograms && styles.labelError
                )}
              >
                Инструктажи
              </Typography>
              <Typography>
                {instructionValue.length === 0
                  ? "Добавить перечень требуемых инструктажей"
                  : `Добавлено ${instructionValue.length} ${getNoun(
                      instructionValue.length,
                      "инструктаж",
                      "инструктажа",
                      "инструктажей"
                    )}`}
              </Typography>
            </ButtonBase>
            <ButtonBase
              className={clsx(
                styles.root,
                errors.training && styles.buttonError
              )}
              onClick={handleAddMedicalExams}
            >
              <Typography
                className={clsx(
                  styles.label,
                  errors.trainingPrograms && styles.labelError
                )}
              >
                Медицинские осмотры
              </Typography>
              <Typography>
                {medicalExamsValue.length === 0
                  ? "Добавить перечень требуемых осмотров"
                  : `Добавлено ${medicalExamsValue.length} ${getNoun(
                      medicalExamsValue.length,
                      "осмотр",
                      "осмотра",
                      "осмотров"
                    )}`}
              </Typography>
            </ButtonBase>
          </>
        )}
        {!!modalType && !isEdit && (
          <Group spacing={5} ml={70}>
            <Button
              onClick={handleEditButton}
              variant="subtle"
              disabled={modalData.disabledCustom}
              radius="xl"
              color="teal.6"
              sx={{ "&:hover": { backgroundColor: "transparent" } }}
            >
              Редактировать
              <WrapperSVG color={"orange"} margin>
                <NotePencil size={24} />
              </WrapperSVG>
            </Button>
            {!!modalData.disabledCustom && (
              <Text size="xs" color="gray">
                Вы не можете кастомизировать должность
              </Text>
            )}
          </Group>
        )}
        <button
          type="submit"
          className={`modal_btn ${modalType !== undefined && styles.button}`}
        >
          {modalComponent.btnText}
        </button>
      </form>
    </div>
  );
};

export default CreatePositionModal;
