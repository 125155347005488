import { $UserAddPermissions, ACTIONS } from "@store/user-store";
import { BodyLight, BodyNormal } from "@ui/fonts";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { memo, useState } from "react";

import { Button } from "@ui/button";
import DirectoryVersionService from "@services/version-service";
import { FetchDirectoryVersionInstructions } from "@store/directory-instructions-version";
import { FetchDirectoryVersionTraining } from "@store/directory-training-version";
import { Modal } from "@ui/modal";
import { RowVersion } from "../row-version";
import { VersionsAllT } from "@interfaces/directory/version";
import styles from "./table-version.module.scss";
import toast from "react-hot-toast";
import { useStore } from "effector-react";
import { FetchDirectoryVersionMedicalExamination } from "@store/directory-medical-examination-version";

type TableVersionProps = {
  type: "training" | "briefing" | "medical-examination";
  updateCategory: () => void;
  getVersion?: VersionsAllT[];
};

export const TableVersion: React.FC<TableVersionProps> = memo(
  ({ type, updateCategory, getVersion }) => {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [idRecovery, setIdRecovery] = useState<number | null>(null);
    const [disabled, setDisabled] = useState(false);
    const permissions = useStore($UserAddPermissions);
    const handleClickDisabled = () => {
      setDisabled(true);
      setTimeout(() => setDisabled(false), 5000);
    };

    const onCancelRecovery = () => {
      setIdRecovery(null);
      setOpenModal(false);
    };

    const recoveryChangeId = () => {
      if (!idRecovery) return;
      DirectoryVersionService.RecoveryChangeId(idRecovery, type, (err, res) => {
        if (err || !res) {
          toast.error("Что-то пошло не так");
          setIdRecovery(null);
          setOpenModal(false);
          return Error("При восстановлении версии произошла ошибка");
        }
        if (res.data) {
          if (type === "training") {
            FetchDirectoryVersionTraining(type);
          }
          if (type === "briefing") {
            FetchDirectoryVersionInstructions(type);
          }
          if (type === "medical-examination") {
            FetchDirectoryVersionMedicalExamination(type);
          }
        }
        setIdRecovery(null);
        setOpenModal(false);
        updateCategory();
        handleClickDisabled();
      });
    };
    const isRecoveryId = (id: number) => {
      setIdRecovery(id);
      setOpenModal(true);
    };
    return (
      <div className={styles.wrapper}>
        <div className={styles.table}>
          <Table className={styles.table_wrapper} aria-label="caption table">
            <TableHead>
              <TableRow>
                {headTable.map((item, i) => (
                  <TableCell
                    key={i}
                    align="right"
                    className={styles.table_head}
                  >
                    <div className={styles.table_head_wrapper}>
                      <BodyLight weight="bold">{item.title}</BodyLight>
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {getVersion &&
                getVersion.map((change: VersionsAllT, i: number) => {
                  return (
                    <RowVersion
                      key={change.id}
                      change={change}
                      disabled={disabled || i !== 0}
                      isRecoveryId={isRecoveryId}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </div>
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          className={styles.modal}
        >
          <div>
            <BodyNormal>
              После восстановления, данная версия будет не доступна
            </BodyNormal>
            <div className={styles.modal_buttons}>
              <Button
                onClick={onCancelRecovery}
                color={"white-green"}
                borderColor={"green"}
              >
                <BodyNormal>Отменить</BodyNormal>
              </Button>
              {permissions.hasPermission(
                ACTIONS.directories_allowedToReverseChanges
              ) && (
                <Button onClick={recoveryChangeId}>
                  <BodyNormal>Подтвердить</BodyNormal>
                </Button>
              )}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
);

const headTable = [
  {
    id: "1",
    title: "НАЗВАНИЕ ВЕРСИИ",
  },
  {
    id: "2",
    title: "ДАТА ИЗМЕНЕНИЯ",
  },
  {
    id: "3",
    title: "",
  },
];
