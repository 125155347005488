import {
  SingleTrainingRequest,
  SingleTrainingRequestStudent,
} from "@interfaces/request-training";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useMemo, useState } from "react";

import { AddEmployeesModal } from "../add-employees-modal";
import { BodyNormal } from "@ui/fonts";
import { Button } from "@ui/button";
import { UserTrainingBodyRow } from "../user-training-body-row";
import { isTrainingRequestEditable } from "@utils/isTrainingRequestEditable";
import moment from "moment";
import styles from "./table-for-user-training.module.scss";
import { useUpdateTrainingRequestMutation } from "@lib/request-training";
import { v4 as uuidv4 } from "uuid";
import { $UserAddPermissions, ACTIONS } from "@store/user-store";
import { useStore } from "effector-react";

export type TableForUserTrainingProps = {
  training: SingleTrainingRequest | undefined;
};

export const TableForUserTraining: React.FC<TableForUserTrainingProps> = ({
  training,
}) => {
  const permissions = useStore($UserAddPermissions);
  const updateTrainingRequestMutation = useUpdateTrainingRequestMutation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const isEditable = isTrainingRequestEditable(training);
  const formattedDate =
    training && moment(training.createdAt).format("DD.MM.YYYY");

  const students = useMemo(() => {
    if (!training) return [];

    const students: (SingleTrainingRequestStudent & {
      programTitle: string;
    })[] = [];
    for (const program of training.programsFromApplication) {
      for (const student of program.students) {
        const studentExists = students.find(
          (_student) => _student.id === student.id
        );
        if (!studentExists) {
          students.push({
            ...student,
            programTitle: program.programTitle,
            companyInn: training.customer.inn,
            companyName: training.customer.name,
          });
        }
      }
    }

    return students;
  }, [training]);

  const handleDeleteStudent = (id: number) => {
    if (!training || updateTrainingRequestMutation.isLoading) return;

    updateTrainingRequestMutation.mutate({
      id: training.id,
      deletedStudents: [id],
    });
  };

  return (
    <div className={styles.content}>
      {training && (
        <AddEmployeesModal
          open={isModalOpen}
          training={training}
          onClose={() => setIsModalOpen(false)}
        />
      )}

      <div className={styles.content__head}>
        <BodyNormal weight={"bold"}>
          Список обучающихся к заявке от {formattedDate}
        </BodyNormal>
      </div>
      <div className={styles.table}>
        <Table className={styles.table_wrapper} aria-label="caption table">
          <TableHead>
            <TableRow>
              {head.map((item) => (
                <TableCell
                  key={uuidv4()}
                  align="right"
                  className={styles.table_head}
                >
                  <div className={styles.table_head_wrapper}>
                    <div className={styles.table_head_name}>{item.title}</div>
                  </div>
                </TableCell>
              ))}
              {/* NOTE: because we add another cell if `isEditable === true` with delete button in `UserTrainingBodyRow` */}
              {isEditable && <TableCell />}
            </TableRow>
          </TableHead>
          <TableBody>
            {students?.map((student, index) => (
              <UserTrainingBodyRow
                key={uuidv4()}
                index={index}
                programTitle={student.programTitle}
                student={student}
                isEditable={isEditable}
                onDelete={() => handleDeleteStudent(student.id)}
              />
            ))}
          </TableBody>
        </Table>
      </div>
      <Button
        className={styles.add_employees_btn}
        disabled={
          !isEditable ||
          !permissions.hasPermission(
            ACTIONS.companyApplicationsForTraining_allowedToEdit
          )
        }
        onClick={() => setIsModalOpen(true)}
      >
        Добавить сотрудников
      </Button>
    </div>
  );
};

type HeadT = {
  id: number;
  title: string;
}[];

const head: HeadT = [
  {
    id: 1,
    title: "№",
  },
  {
    id: 2,
    title: "Место работы",
  },
  {
    id: 3,
    title: "ИНН работодателя",
  },
  {
    id: 4,
    title: "ФИО",
  },
  {
    id: 5,
    title: "СНИЛС",
  },
  {
    id: 6,
    title: "Должность",
  },
  {
    id: 7,
    title: "Адрес электронной почты",
  },
  {
    id: 8,
    title: "Причина обучения",
  },
  {
    id: 9,
    title: "Программа",
  },
];
