import { useEffect, useState } from "react";
import { BodyNormal } from "@ui/fonts";
import { FilledInput } from "@mui/material";
import { FileLoadSVG, PencilSVG, CheckboxCheckedSVG } from "@components/svgs";
import styles from "./query-info.module.scss";
import { SingleTrainingRequest } from "@interfaces/request-training";
import {
  useDownloadTrainingRequestMutation,
  useUpdateTrainingRequestMutation,
} from "@lib/request-training";
import { isTrainingRequestEditable } from "@utils/isTrainingRequestEditable";
import { downloadBlob } from "@utils/downloadBlob";
import { useStore } from "effector-react";
import { $UserAddPermissions, ACTIONS } from "@store/user-store";

export type QueryInfoProps = {
  training: SingleTrainingRequest | undefined;
};

export const QueryInfo: React.FC<QueryInfoProps> = ({ training }) => {
  const permissions = useStore($UserAddPermissions);

  const updateTrainingRequestMutation = useUpdateTrainingRequestMutation();
  const downloadTrainingRequestMutation = useDownloadTrainingRequestMutation();

  const [title, setTitle] = useState(() => training?.title ?? "");
  const [isEditingTitle, setIsEditingTitle] = useState(false);

  const isTrainingEditable = isTrainingRequestEditable(training);

  useEffect(() => {
    if (!training) return;

    setTitle(training.title);
  }, [training]);

  const handleDownloadExcelFile = () => {
    if (!training) return;

    downloadTrainingRequestMutation.mutate(training.id, {
      onSuccess: (blob) => {
        downloadBlob(blob, `${training.title}.xlsx`);
      },
    });
  };

  const handleUpdateTitle = () => {
    if (updateTrainingRequestMutation.isLoading || !training) return;

    updateTrainingRequestMutation.mutate(
      { id: training.id, title },
      {
        onSuccess: () => {
          setIsEditingTitle(false);
        },
      }
    );
  };

  return (
    <div className={styles.query_info}>
      <div className={styles.query_info__head}>
        {isEditingTitle &&
        permissions.hasPermission(
          ACTIONS.companyApplicationsForTraining_allowedToEdit
        ) ? (
          <FilledInput
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        ) : (
          <BodyNormal weight={"bold"}>{training?.title}</BodyNormal>
        )}
        {isEditingTitle ? (
          <div className={styles.edit} onClick={handleUpdateTitle}>
            <CheckboxCheckedSVG />
          </div>
        ) : isTrainingEditable ? (
          <div className={styles.edit} onClick={() => setIsEditingTitle(true)}>
            <PencilSVG width="18px" />
          </div>
        ) : null}
        <div
          className={styles.download}
          tabIndex={0}
          onClick={handleDownloadExcelFile}
        >
          <FileLoadSVG />
          &nbsp;&nbsp;
          <BodyNormal weight={"bold"} color={"green"}>
            xlsx
          </BodyNormal>
        </div>
      </div>
      <div className={styles.legal_entities}>
        <div className={styles.legal_entities__block}>
          <BodyNormal>Заказчик</BodyNormal>
          <BodyNormal>{training?.customer?.leadershipPosition}</BodyNormal>
          <BodyNormal>{training?.customer?.name}</BodyNormal>
          <BodyNormal>{training?.customer?.supervisor}</BodyNormal>
        </div>
        <div className={styles.line_vertical}></div>
        <div className={styles.legal_entities__block}>
          <BodyNormal>Исполнитель</BodyNormal>
          <BodyNormal>{training?.executor?.leadershipPosition}</BodyNormal>
          <BodyNormal>{training?.executor?.name}</BodyNormal>
          <BodyNormal>{training?.executor?.supervisor}</BodyNormal>
        </div>
      </div>
    </div>
  );
};
