import { BodyLight } from "@ui/fonts";
import { Link } from "react-router-dom";
import { PencilSVG } from "@components/svgs";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TrainingRequest } from "@interfaces/request-training";
import clsx from "clsx";
import moment from "moment";
import styles from "./table-request-body-row.module.scss";

type TableBodyRowProps = {
  index: number;
  request: TrainingRequest;
  ignoredFields?: (keyof TrainingRequest)[];
};

export const TableRequestBodyRow: React.FC<TableBodyRowProps> = ({
  index,
  request,
  ignoredFields,
}) => {
  return (
    <>
      {!!request && (
        <TableRow>
          <TableCell align="left">
            <div
              className={clsx(styles.table_row_center, styles.table_row_mini)}
            >
              <BodyLight>{++index}</BodyLight>
            </div>
          </TableCell>
          {!ignoredFields?.includes("customer") && (
            <TableCell align="left">
              <div className={styles.edit_and_save}>
                <div
                  className={clsx(
                    styles.table_row_center,
                    styles.table_row_mini
                  )}
                >
                  <BodyLight>{request.customer?.name}</BodyLight>
                </div>
              </div>
            </TableCell>
          )}
          {!ignoredFields?.includes("title") && (
            <TableCell align="left">
              <div
                className={clsx(
                  styles.table_row_center,
                  styles.table_row_title
                )}
              >
                <BodyLight>{request.title.slice(0, 150)}</BodyLight>
              </div>
            </TableCell>
          )}
          <TableCell align="left">
            <Link to={`/control-request-training/${request.id}`}>
              <div className={styles.table_row_editIcon} tabIndex={0}>
                <PencilSVG width="15" />
              </div>
            </Link>
          </TableCell>
          {!ignoredFields?.includes("createdAt") && (
            <TableCell align="left">
              <div
                className={clsx(styles.table_row_center, styles.table_row_mini)}
              >
                <BodyLight>
                  {moment(request.createdAt).format("D/M/YYYY")}
                </BodyLight>
              </div>
            </TableCell>
          )}
          {!ignoredFields?.includes("comment") && (
            <TableCell align="left">
              <div
                className={clsx(styles.table_row_center, styles.table_row_mini)}
              >
                <BodyLight>{request.comment}</BodyLight>
              </div>
            </TableCell>
          )}
          {!ignoredFields?.includes("status") && (
            <TableCell align="left">
              <div
                className={clsx(styles.table_row_center, styles.table_row_mini)}
              >
                <BodyLight>{request.status}</BodyLight>
              </div>
            </TableCell>
          )}
        </TableRow>
      )}
    </>
  );
};
