import {
  AvailabilityWorkspaceGroupData,
  DeleteWorkspaceGroupData,
  RequestAccessForGroupData,
  UpdateWorkspaceGroupData,
  WorkspaceAddFilesData,
  WorkspaceArchiveEmployeesParams,
  WorkspaceArchiveFileData,
  WorkspaceCommonParams,
  WorkspaceDeleteFileData,
  WorkspaceDirectoryData,
  WorkspaceDirectoryT,
  WorkspaceDownloadFileData,
  WorkspaceEmployeeParams,
  WorkspaceGetArchivedDirectoriesAndFilesResponse,
  WorkspaceGetDirectoriesAndFilesResponse,
  WorkspaceGetFilesParams,
  WorkspaceGroupData,
  WorkspaceGroupFile,
  WorkspaceGroupT,
  WorkspaceUpdateFileData,
  WorkspaceDocumentData,
  WorkspaceDocumentConvertData,
  WorkspaceFileT,
} from "@interfaces/company/workspace";

import Fetcher from "@http/fetcher";
import { GetArchiveEmployeesResT } from "@interfaces/requests/archive";

export const workspaceService = {
  // EMPLOYEE
  async unarchiveEmployee(data: WorkspaceEmployeeParams) {
    const resp = await Fetcher.put<any>(
      `companies/${data.companyId}/employees/${data.employeeId}/archive`,
      { isArchived: false }
    );

    return resp.data;
  },

  async unarchiveEmployees(data: WorkspaceArchiveEmployeesParams) {
    const resp = await Fetcher.post<any>(
      `companies/${data.companyId}/employees/archive`,
      {
        isArchived: false,
        employees: data.employees,
      }
    );

    return resp.data;
  },

  async getIsArchivedEmployee(companyId: number) {
    const resp = await Fetcher.get<GetArchiveEmployeesResT>(
      `/companies/${companyId}/employees`,
      {
        params: {
          isArchived: true,
        },
      }
    );

    return resp.data;
  },

  async deleteEmployeeId(data: WorkspaceEmployeeParams) {
    const resp = await Fetcher.delete<any>(
      `companies/${data.companyId}/employees/${data.employeeId}`
    );

    return resp.data;
  },

  // FILE
  async getGroups(companyId: number) {
    const resp = await Fetcher.get<WorkspaceGroupT[]>(
      `/companies/${companyId}/workspace/groups`
    );

    return resp.data;
  },

  async createGroup(data: WorkspaceGroupData) {
    const resp = await Fetcher.post<WorkspaceGroupT>(
      `/companies/${data.companyId}/workspace/groups`,
      {
        name: data.name,
      }
    );

    return resp.data;
  },

  async updateGroup(data: UpdateWorkspaceGroupData) {
    const resp = await Fetcher.patch<WorkspaceGroupT>(
      `/companies/${data.companyId}/workspace/groups/${data.id}`,
      {
        name: data.name,
      }
    );

    return resp.data;
  },

  async deleteGroup(data: DeleteWorkspaceGroupData) {
    const resp = await Fetcher.delete(
      `/companies/${data.companyId}/workspace/groups/${data.id}`
    );

    return resp.data;
  },

  async getDirectoriesAndFiles(
    companyId: number,
    groupId: number,
    directoryId: number,
    params?: WorkspaceGetFilesParams
  ) {
    const resp = await Fetcher.get<WorkspaceGetDirectoriesAndFilesResponse>(
      `/companies/${companyId}/workspace/groups/${groupId}/directories/${directoryId}`,
      { params }
    );

    return resp.data;
  },

  async getisArchivedDirectoriesAndFiles(
    companyId: number,
    groupId: number,
    params?: WorkspaceGetFilesParams
  ) {
    const resp =
      await Fetcher.get<WorkspaceGetArchivedDirectoriesAndFilesResponse>(
        `/companies/${companyId}/workspace/groups/${groupId}/archived`,
        {
          params,
        }
      );

    return resp.data;
  },

  async createDirectory(data: WorkspaceDirectoryData) {
    const resp = await Fetcher.post<WorkspaceDirectoryT>(
      `/companies/${data.companyId}/workspace/groups/${data.groupId}/directories/${data.directoryId}`,
      {
        name: data.name,
      }
    );

    return resp.data;
  },

  async updateDirectory(data: WorkspaceDirectoryData) {
    const resp = await Fetcher.patch<WorkspaceDirectoryT>(
      `/companies/${data.companyId}/workspace/groups/${data.groupId}/directories/${data.directoryId}`,
      {
        name: data.name,
      }
    );

    return resp.data;
  },

  async archiveDirectory(data: WorkspaceCommonParams) {
    const resp = await Fetcher.patch(
      `/companies/${data.companyId}/workspace/groups/${data.groupId}/directories/${data.directoryId}/archive`,
      { isArchived: true }
    );

    return resp.data;
  },

  async unarchiveDirectory(data: WorkspaceCommonParams) {
    const resp = await Fetcher.patch(
      `/companies/${data.companyId}/workspace/groups/${data.groupId}/directories/${data.directoryId}/archive`,
      { isArchived: false }
    );

    return resp.data;
  },

  async deleteDirectory(data: WorkspaceCommonParams) {
    const resp = await Fetcher.delete(
      `/companies/${data.companyId}/workspace/groups/${data.groupId}/directories/${data.directoryId}`
    );

    return resp.data;
  },

  async addFiles(data: WorkspaceAddFilesData) {
    const formData = new FormData();
    formData.set("signingFrequency", data.signingFrequency);
    data.files.forEach((file) => {
      formData.append("files", file, file.name);
    });

    const resp = await Fetcher.post(
      `/companies/${data.companyId}/workspace/groups/${data.groupId}/directories/${data.directoryId}/files`,
      formData
    );

    return resp.data;
  },

  async updateFile(data: WorkspaceUpdateFileData) {
    const resp = await Fetcher.put(
      `/companies/${data.companyId}/workspace/groups/${data.groupId}/directories/${data.directoryId}/files/${data.fileId}`,
      {
        name: data.name,
        signingFrequency: data.signingFrequency,
      }
    );

    return resp.data;
  },

  async archiveFile(data: WorkspaceArchiveFileData) {
    const resp = await Fetcher.patch(
      `/companies/${data.companyId}/workspace/groups/${data.groupId}/directories/${data.directoryId}/files/${data.fileId}`,
      { isArchived: true }
    );

    return resp.data;
  },

  async unarchiveFile(data: WorkspaceArchiveFileData) {
    const resp = await Fetcher.patch(
      `/companies/${data.companyId}/workspace/groups/${data.groupId}/directories/${data.directoryId}/files/${data.fileId}`,
      { isArchived: false }
    );

    return resp.data;
  },

  async deleteFile(data: WorkspaceDeleteFileData) {
    const resp = await Fetcher.delete(
      `/companies/${data.companyId}/workspace/groups/${data.groupId}/directories/${data.directoryId}/files/${data.fileId}`
    );

    return resp.data;
  },

  async downloadFile(data: WorkspaceDownloadFileData) {
    const resp = await Fetcher.get<Blob>(
      `/companies/${data.companyId}/workspace/groups/${data.groupId}/directories/${data.directoryId}/files/${data.fileId}/download`,
      {
        responseType: "blob",
      }
    );

    return resp.data;
  },

  async getGroupFiles(companyId: number, groups: number[]) {
    const resp = await Fetcher.get<WorkspaceGroupFile[]>(
      `/companies/${companyId}/workspace/groups/file-list`,
      {
        params: {
          groups,
        },
      }
    );

    return resp.data;
  },

  async requestAccessForGroup(data: RequestAccessForGroupData) {
    const resp = await Fetcher.post<boolean>(
      `/companies/${data.companyId}/workspace/groups/${data.groupId}/request-access`
    );

    return resp.data;
  },

  async changeGroupAvailability(data: AvailabilityWorkspaceGroupData) {
    const resp = await Fetcher.patch(
      `/companies/${data.companyId}/workspace/groups/${data.groupId}/access`,
      { isAvailable: data.isAvailable }
    );

    return resp.data;
  },

  async createDocument(data: WorkspaceDocumentData) {
    const resp = await Fetcher.post<WorkspaceFileT>(
      `/companies/${data.companyId}/workspace/groups/${data.groupId}/directories/${data.directoryId}/files/document`,
      {
        name: data.name,
        type: data.type,
        signingFrequency: 'Разово'
      }
    );

    return resp.data;
  },

  async convertDocument(data: WorkspaceDocumentConvertData) {
    const resp = await Fetcher.post<WorkspaceFileT>(
      `/companies/${data.companyId}/workspace/groups/${data.groupId}/directories/${data.directoryId}/files/${data.fileId}/convert`,
      {
        fileType: data.fileType,
        title: data.title,
        url: data.url
      }
    );

    return resp.data;
  },
};
