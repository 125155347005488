import { $UserAddPermissions, ACTIONS } from "@store/user-store";
import { Archive, X } from "@phosphor-icons/react";
import { ErrorIndicator, Loader } from "@ui/indicators";
import React, { useEffect, useState } from "react";
import {
  WorkspaceDirectoryShortDataT,
  WorkspaceTablePropsT,
} from "@interfaces/company/workspace";
import {
  useArchiveWorkspaceDirectoryMutation,
  useArchiveWorkspaceFileMutation,
  useDeleteWorkspaceDirectoryMutation,
  useDeleteWorkspaceFileMutation,
} from "@lib/workspace";
import { useHistory } from "react-router-dom";

import { BackButton } from "@components/back-button";
import { Tooltip } from "@mui/material";
import { WrapperSVG } from "@components/WrapperSVG/WrapperSVG";
import clsx from "clsx";
import qs from "qs";
import style from "@scss/pages/company/company-workspace.module.scss";
import tableStyle from "@scss/components/tables/base-table.module.scss";
import { useAsyncConfirm } from "@hooks/useAsyncConfirm";
import { useSelectedCompanyQuery } from "@lib/company";
import { useStore } from "effector-react";
import { useWorkspaceFilesQuery } from "@lib/workspace/useWorkspaceFilesQuery";
import { WorkspaceFile } from "./workspace-file";
import { WorkspaceDirectory } from "./workspace-directory";
import { useChoiceState } from "@hooks/useChoiceState";

const WorkspaceTable: React.FC<WorkspaceTablePropsT> = ({
  disabled = false,
}) => {
  const { company } = useSelectedCompanyQuery();

  const permissions = useStore($UserAddPermissions);

  const [filters, setFilters] = useState<{ groupId: number; folderId: number }>(
    { groupId: 0, folderId: 0 }
  );
  const [paths, setPaths] = useState<WorkspaceDirectoryShortDataT[]>([]);

  const { files, isLoading, error } = useWorkspaceFilesQuery(
    company?.id,
    filters.groupId,
    filters.folderId
  );

  const archiveWorkspaceDirectoryMutation =
    useArchiveWorkspaceDirectoryMutation();
  const archiveWorkspaceFileMutation = useArchiveWorkspaceFileMutation();

  const deleteWorkspaceDirectoryMutation =
    useDeleteWorkspaceDirectoryMutation();
  const deleteWorkspaceFileMutation = useDeleteWorkspaceFileMutation();

  const history = useHistory();
  const confirm = useAsyncConfirm();

  const dirChoice = useChoiceState(files?.directories ?? [], (dir) => dir.id);
  const filesChoice = useChoiceState(files?.files ?? [], (file) => file.id);

  const allItemsSelected =
    dirChoice.selectedItems.length + filesChoice.selectedItems.length ===
    (files?.directories.length ?? 0) + (files?.files.length ?? 0);

  useEffect(() => {
    if (!files?.directories) return;

    const Querystring = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });
    const folderId = Querystring?.folder_id;
    const groupId = Querystring?.group_id;

    if (folderId && +folderId) {
      if (paths.length > 1 && paths[paths.length - 2]?.id === +folderId) {
        paths.pop();
        setPaths(paths);
      } else {
        paths.push(
          files.directories.find(
            (dir: any) => dir?.id === +folderId
          ) as WorkspaceDirectoryShortDataT
        );
      }
    } else {
      setPaths([]);
    }

    setFilters({
      groupId: +(groupId as string) || 0,
      folderId: +(folderId as string) || 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.search]);

  const handleDeleteSelectedItems = async () => {
    if (!company) return;

    const ok = await confirm({
      modalData: {
        text: "Вы уверены, что хотите удалить выбранные файлы и папки?",
      },
    });
    if (!ok) return;

    const promises = [];

    for (const dir of dirChoice.selectedItems) {
      promises.push(
        deleteWorkspaceDirectoryMutation.mutateAsync({
          companyId: company.id,
          groupId: filters.groupId,
          directoryId: dir.id,
        })
      );
    }

    for (const file of filesChoice.selectedItems) {
      promises.push(
        deleteWorkspaceFileMutation.mutateAsync({
          companyId: company.id,
          groupId: filters.groupId,
          directoryId: filters.folderId,
          fileId: file.id,
        })
      );
    }

    await Promise.all(promises);

    dirChoice.clearSelectedItems();
    filesChoice.clearSelectedItems();
  };

  const handleArchiveSelectedItems = async () => {
    if (!company) return;

    const ok = await confirm({
      modalData: {
        text: "Вы уверены, что хотите отправить в архив выбранные файлы и папки?",
      },
    });
    if (!ok) return;

    const promises = [];

    for (const dir of dirChoice.selectedItems) {
      promises.push(
        archiveWorkspaceDirectoryMutation.mutateAsync({
          companyId: company.id,
          groupId: filters.groupId,
          directoryId: dir.id,
        })
      );
    }

    for (const file of filesChoice.selectedItems) {
      promises.push(
        archiveWorkspaceFileMutation.mutateAsync({
          companyId: company.id,
          groupId: filters.groupId,
          directoryId: filters.folderId,
          fileId: file.id,
        })
      );
    }

    await Promise.all(promises);

    dirChoice.clearSelectedItems();
    filesChoice.clearSelectedItems();
  };

  return (
    <div className={tableStyle.base_table_container}>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <ErrorIndicator />
      ) : (
        <>
          <p className={style.path_text}>
            Путь: /{" "}
            {paths.length > 0 ? paths.map((path) => path.name + " / ") : ""}
          </p>
          {!!paths.length && <BackButton className={style.path_text} />}
          <table className={clsx(tableStyle.base_table, style.workspace_table)}>
            <thead>
              <tr>
                <td>
                  <label>
                    <input
                      type="checkbox"
                      className={clsx(tableStyle.checkbox_item)}
                      name=""
                      hidden
                      checked={allItemsSelected}
                      onChange={() => undefined}
                    />
                    <label
                      onClick={() => {
                        if (!disabled) {
                          dirChoice.selectAll(!allItemsSelected);
                          filesChoice.selectAll(!allItemsSelected);
                        }
                      }}
                    >
                      <svg
                        className="w-5 h-5"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    </label>
                    <label className={clsx(tableStyle.checkbox_label)}>
                      Название
                    </label>
                  </label>
                </td>
                <td align="right">
                  {(dirChoice.selectedItems.length > 0 ||
                    filesChoice.selectedItems.length > 0) && (
                    <div className={style.selectedItemsActions}>
                      {permissions.hasPermission(
                        ACTIONS.companyWorkspace_directories_allowedToArchive
                      ) && (
                        <Tooltip title="В архив" placement="top">
                          <button
                            disabled={disabled}
                            onClick={() => handleArchiveSelectedItems()}
                          >
                            <WrapperSVG color="red">
                              <Archive size={24} />
                            </WrapperSVG>
                          </button>
                        </Tooltip>
                      )}
                      {permissions.hasPermission(
                        ACTIONS.companyWorkspace_files_allowedToDelete
                      ) && (
                        <Tooltip title="Удалить" placement="top">
                          <button
                            disabled={disabled}
                            onClick={() => handleDeleteSelectedItems()}
                          >
                            <X size={24} />
                          </button>
                        </Tooltip>
                      )}
                    </div>
                  )}
                </td>
              </tr>
            </thead>
            <tbody>
              {files?.directories.map((directory) => (
                <WorkspaceDirectory
                  key={directory.id}
                  directory={directory}
                  groupId={filters.groupId}
                  disabled={disabled}
                  isSelected={dirChoice.getIsItemSelected(directory)}
                  onSelect={(checked) =>
                    dirChoice.selectById(directory, checked)
                  }
                />
              ))}
              {files?.files.map((file) => (
                <WorkspaceFile
                  key={file.id}
                  file={file}
                  groupId={filters.groupId}
                  directoryId={filters.folderId}
                  disabled={disabled}
                  isSelected={filesChoice.getIsItemSelected(file)}
                  onSelect={(checked) => filesChoice.selectById(file, checked)}
                />
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default WorkspaceTable;
