import {
  GetUsefulMaterialsPropsT,
  GetUsefulMaterialsResT,
} from "@interfaces/requests/useful-materials";
import { UsefulMaterialT } from "@interfaces/useful-material";

import Fetcher from "@http/fetcher";

import { ResCallback } from "@interfaces/common";

class UsefulMaterialsService {
  static async getAll(
    params: GetUsefulMaterialsPropsT,
    cb: ResCallback<GetUsefulMaterialsResT>
  ) {
    try {
      const res = await Fetcher.get<GetUsefulMaterialsResT>(
        "/useful-materials",
        {
          params,
        }
      );

      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err: any) {
      return cb(err);
    }
  }

  static async getById(id: string, cb: ResCallback<UsefulMaterialT>) {
    try {
      const res = await Fetcher.get<UsefulMaterialT>(`/useful-materials/${id}`);

      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err: any) {
      return cb(err);
    }
  }

  static async create(data: FormData, cb: ResCallback<UsefulMaterialT>) {
    try {
      const res = await Fetcher.post<UsefulMaterialT>(
        `/useful-materials`,
        data
      );
      if (res.status === 201) {
        return cb(null, res);
      }
    } catch (err: any) {
      return cb(err);
    }
  }

  static async update(id: string, data: FormData, cb: ResCallback<string>) {
    const formData = {
      title: data.get("title"),
      tags: data.getAll("tags"),
      text: data.get("text"),
      createdAt: data.get("createdAt"),
    };

    try {
      const res = await Fetcher.patch<string>(
        `/useful-materials/${id}`,
        formData
      );
      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err: any) {
      return cb(err);
    }
  }

  static async delete(id: string, cb: ResCallback<string>) {
    try {
      const res = await Fetcher.delete<string>(`/useful-materials/${id}`);

      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err: any) {
      return cb(err);
    }
  }

  static async download(filename: string, cb: ResCallback<Blob>) {
    try {
      const res = await Fetcher.get<Blob>(
        `/useful-materials/download/${filename}`,
        {
          responseType: "blob",
        }
      );
      cb(null, res);
    } catch (err: any) {
      return cb(err);
    }
  }
}

export default UsefulMaterialsService;
