import fetcher from "@http/fetcher";
import { ResCallback } from "@interfaces/common";
import {
  GetAllProjectNewsParams,
  GetAllProjectNewsResult,
  ProjectNewsItem,
} from "@interfaces/project-news";

class ProjectNewsService {
  static async getAll(
    params: GetAllProjectNewsParams,
    cb: ResCallback<GetAllProjectNewsResult>
  ) {
    try {
      const resp = await fetcher.get<GetAllProjectNewsResult>("/project-news", {
        params,
      });

      cb(null, resp);
    } catch (err: any) {
      cb(err);
    }
  }

  static async getById(id: string, cb: ResCallback<ProjectNewsItem>) {
    try {
      const resp = await fetcher.get<ProjectNewsItem>(`/project-news/${id}`);
      cb(null, resp);
    } catch (err: any) {
      cb(err);
    }
  }

  static async create(data: FormData, cb: ResCallback<ProjectNewsItem>) {
    try {
      const res = await fetcher.post<ProjectNewsItem>(`/project-news`, data);
      cb(null, res);
    } catch (err: any) {
      cb(err);
    }
  }

  static async update(id: string, data: FormData, cb: ResCallback<string>) {
    try {
      const res = await fetcher.patch<string>(`/project-news/${id}`, data);
      cb(null, res);
    } catch (err: any) {
      cb(err);
    }
  }

  static async delete(id: string, cb: ResCallback<string>) {
    try {
      const res = await fetcher.delete<string>(`/project-news/${id}`);
      return cb(null, res);
    } catch (err: any) {
      return cb(err);
    }
  }

  static async download(filename: string, cb: ResCallback<Blob>) {
    try {
      const res = await fetcher.get<Blob>(
        `/project-news/download/${filename}`,
        {
          responseType: "blob",
        }
      );
      cb(null, res);
    } catch (err: any) {
      return cb(err);
    }
  }
}

export default ProjectNewsService;
