import "moment/locale/ru";

import { ChangeEvent, useEffect, useMemo, useState } from "react";
import {
  Checkbox,
  FormControl,
  Grid2 as Grid,
  InputLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { CheckboxCheckedSVG, CheckboxSVG } from "@components/svgs";
import { DatePicker } from '@mui/x-date-pickers';
import { TimePicker } from '@mui/x-date-pickers';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ruRU } from '@mui/x-date-pickers/locales';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { useCompaniesQuery, useSelectedCompanyQuery } from "@lib/company";

import DropDownList from "@ui/drop-down-list";
import EventService from "@services/event-service";
// import MomentUtils from "@date-io/moment";
import SpecialistPlanService from "@services/specialist-plan-service";
import clsx from "clsx";
import moment, { Moment } from "moment";
import style from "@scss/modals/company/calendar.module.scss";
import useModal from "@modals/modal-hook";
import useStyles from "@ui/material-ui-styles";
import { useWorkplanGroupsQuery } from "@lib/workplan";

moment.locale("ru");

const HOUR = 3600000;
const MINUTE = 60000;

const CreateEventModal = () => {
  const { modalComponent, modalData, close } = useModal();
  const { companies } = useCompaniesQuery();
  const { company } = useSelectedCompanyQuery();
  const [selectedCompany, setSelectedCompany] = useState<number | null>(
    modalData.companyId || modalData.event?.companyId || null
  );
  const [selectedGroupId, setSelectedGroupId] = useState(
    modalData?.event?.groupId
  );

  const { data: groups } = useWorkplanGroupsQuery({
    companies: company ? [company.id] : [],
  });

  const isCompanyCalendar = !!modalData.isCompanyCalendar;

  const companyOptions = useMemo(() => {
    if (!companies) return [];

    return companies.map((company) => ({
      label: company.name,
      value: company.id,
    }));
  }, [companies]);

  useEffect(() => {
    setSelectedGroupId(modalData?.event?.groupId);
  }, [modalData?.event?.groupId]);

  const getDateStart = () => {
    if (modalData.event?.dateStart) {
      return moment(modalData.event.dateStart);
    }
    return moment(Date.now() + MINUTE);
  };

  const getDateFinish = () => {
    if (modalData.event?.dateFinish) {
      return moment(modalData.event.dateFinish);
    }
    return moment(Date.now() + MINUTE);
  };

  const [selectedStartDate, setStartDate] = useState(getDateStart);
  const [selectedFinishDate, setFinishDate] = useState(getDateFinish);

  const [validation, setValidation] = useState<any>({
    titleError: "",
    descError: "",
    creationError: "",
    dateError: "",
    companyError: "",
  });
  const classes = useStyles();

  const handleSubmit = (e: any) => {
    e.preventDefault();

    let startDate = selectedStartDate || getDateStart();
    let finishDate = selectedFinishDate || getDateFinish();

    const isEditing = !!modalData?.event;

    const modalBtn = document.querySelector(
      "button.modal_btn"
    ) as HTMLButtonElement;
    modalBtn.disabled = true;

    const titleField = document.querySelector(
      'input[name="title"]'
    ) as HTMLInputElement;

    const descField = document.querySelector(
      'textarea[name="description"]'
    ) as HTMLInputElement;

    const financialCheckbox = document.querySelector(
      'input[name="isFinancial"]'
    ) as HTMLInputElement;

    if (!isEditing && !selectedCompany) {
      modalBtn.disabled = false;
      return setValidation({
        companyError: "Это поле обязательно",
      });
    }

    if (!titleField?.value || !descField?.value) {
      modalBtn.disabled = false;

      return setValidation({
        titleError: !titleField?.value ? "Это поле обязательно" : "",
        descError: !descField?.value ? "Это поле обязательно" : "",
      });
    }

    let groupId = selectedGroupId
      ? selectedGroupId
      : modalData?.event?.groupId ?? undefined;
    if (groupId === "0") {
      groupId = undefined;
    }

    const eventNewData = {
      companyId: selectedCompany ?? company?.id ?? -1,
      title: titleField.value,
      desc: descField.value,
      dateStart: startDate.valueOf(),
      dateFinish: finishDate.valueOf(),
      isFinancial: financialCheckbox.checked,
      status: 1,
      groupId,
      createdBy: modalData.event ? undefined : modalData.userId,
      eventId: undefined,
    };

    if (modalData.event && groupId) {
      eventNewData.eventId = modalData.event.id;
    }

    // UPDATING EVENT
    if (modalData.event) {
      // NOTE: закомментировал потому что в Ot-245 сказано что это баг
      // см. https://app.weeek.net/ws/361761/task/14438
      // if (selectedGroupId) {
      //   const planEventNewData = {
      //     title: titleField.value,
      //     desc: descField.value,
      //     periodicity: 0,
      //     groupId,
      //     savedToCalendar: 1,
      //     isFinancial: financialCheckbox.checked,
      //     status: 1,
      //     parentId: modalData.parentId || undefined,
      //     startDate: startDate.getTime(),
      //     deadline: finishDate.getTime(),
      //     withSigning: 0,
      //   };
      //
      //   SpecialistPlanService.createTask(
      //     modalData.companyId || selectedCompany,
      //     planEventNewData,
      //     (err, res) => {
      //       if (err || !res) {
      //         modalBtn.disabled = false;
      //         return console.error("При создании события произошла ошибка");
      //       }
      //       pushToTasks(res.data);
      //       close();
      //     }
      //   );
      // }
      return EventService.update(
        modalData.event.id,
        eventNewData,
        (err, res) => {
          if (err) {
            modalBtn.disabled = false;
            return setValidation({
              creationError: "При создании ивента произошла ошибка",
            });
          }
          if (modalComponent.onConfirm) {
            modalComponent.onConfirm({ ...modalData.event, ...eventNewData });
          }
          close();
        }
      );
    }

    EventService.create(eventNewData, (err, res) => {
      if (err || !res) {
        modalBtn.disabled = false;
        return setValidation({
          creationError: "При создании ивента произошла ошибка",
        });
      }
      if (modalComponent.onConfirm) {
        modalComponent.onConfirm(res.data);
      }

      // IF CH0SED CATEGORY SO CREATE A TASK IN SPECIALIST PLAN
      if (selectedGroupId) {
        const planEventNewData = {
          title: titleField.value,
          desc: descField.value,
          periodicity: 0,
          groupId,
          savedToCalendar: 1,
          isFinancial: financialCheckbox.checked,
          status: 1,
          parentId: modalData.parentId || undefined,
          startDate: startDate.valueOf(),
          deadline: finishDate.valueOf(),
          withSigning: 0,
          eventId: res.data.id,
        };

        SpecialistPlanService.createTask(
          modalData.companyId || selectedCompany,
          planEventNewData,
          (err, res) => {
            if (err || !res) {
              modalBtn.disabled = false;
              close();
              return console.error("При создании события произошла ошибка");
            }
            close();
          }
        );
      } else {
        close();
      }
    });
  };

  const onStartDateChange = (date: Moment | null) => {
    if (!date || !date.isValid()) return;

    if (date > selectedFinishDate) {
      const newDate = moment(date.toDate().getTime() + HOUR);
      setFinishDate(newDate);
    }

    setStartDate(date);
  };

  const onFinishDateChange = (date: Moment | null) => {
    if (!date || !date.isValid()) return;

    if (date < selectedStartDate) {
      const newDate = moment(date.toDate().getTime() - HOUR);
      setStartDate(newDate);
    }

    setFinishDate(date);
  };

  const handleCompanyChange = (event: SelectChangeEvent<number>) => 
    setSelectedCompany(event.target.value as number);

  const GROUP_OPTIONS = [
    { label: "Выбрать категорию из плана работ (необязательно)", value: 0 },
    ...(groups?.map((group) => ({
      label: group.title,
      value: group.id!,
    })) ?? []),
  ];

  return (
    <div key={modalComponent.key} className={clsx(style.create_event_modal)}>
      <p className="modal_title">{modalData.modalTitle}</p>
      <div className="underline" />
      <form className={`modal_content ${classes.root}`} onSubmit={handleSubmit}>
        {!isCompanyCalendar && (
          <FormControl variant="filled" className={clsx(style.period_select)}>
            <InputLabel id="ot-specialist">Компания</InputLabel>
            <Select
              required
              labelId="create-event-company-select"
              value={selectedCompany ?? ""}
              onChange={handleCompanyChange}
              disabled={modalData.event}
            >
              {companyOptions.map((item: { label: string; value: number }) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            <p className="error-text">{validation.companyError}</p>
          </FormControl>
        )}
        <TextField
          label="Название задачи"
          name={"title"}
          variant="filled"
          required
          defaultValue={modalData.event?.title}
        />
        <p className="error-text">{validation.titleError}</p>
        <TextField
          label="Описание"
          name={"description"}
          variant="filled"
          required
          multiline
          defaultValue={modalData.event?.desc}
        />
        <p className="error-text">{validation.descError}</p>
        <div className="create-event-modal-pickers">
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale={"ru"}
            localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
          >
            <Grid container className={clsx(style.main_pickers_container)}>
              <Grid size={{ xs: 12, md: 6 }}>
                <p className={style.headTime}>Начало задачи</p>
                <Grid container className={clsx(style.pickers_container)}>
                  <Grid size={{ xs: 12, sm: 5 }}>
                    <TimePicker
                      ampm={false}
                      value={selectedStartDate}
                      onChange={onStartDateChange}
                      format="HH:mm"
                      autoFocus={false}
                      className={clsx(style.pickers_input)}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      slotProps={{
                        actionBar: { actions: ['cancel', 'accept'] },
                        textField: {
                          id: 'time-picker',
                          variant: 'filled',
                          placeholder: 'Время'
                        },
                      }}
                      localeText={{
                        toolbarTitle: '',
                        cancelButtonLabel: 'Отменить',
                        okButtonLabel: 'ОК',
                      }}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6 }}>
                    <DatePicker
                      value={selectedStartDate}
                      onChange={onStartDateChange}
                      format="DD.MM.YYYY"
                      className={clsx(style.pickers_input)}
                      slotProps={{
                        actionBar: { actions: ['cancel', 'accept'] },
                        textField: {
                          variant: 'filled',
                          placeholder: 'Дата'
                        },
                      }}
                      localeText={{
                        toolbarTitle: '',
                        cancelButtonLabel: 'Отменить',
                        okButtonLabel: 'ОК',
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <p className={style.headTime}>Окончание задачи</p>
                <Grid container className={clsx(style.pickers_container)}>
                  <Grid size={{ xs: 12, sm: 6 }}>
                    <TimePicker
                      ampm={false}
                      value={selectedFinishDate}
                      onChange={onFinishDateChange}
                      format="HH:mm"
                      className={clsx(style.pickers_input)}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      slotProps={{
                        actionBar: { actions: ['cancel', 'accept'] },
                        textField: {
                          id: 'time-picker',
                          variant: 'filled',
                          placeholder: 'Время'
                        },
                      }}
                      localeText={{
                        toolbarTitle: '',
                        cancelButtonLabel: 'Отменить',
                        okButtonLabel: 'ОК',
                      }}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6 }}>
                    <DatePicker
                      value={selectedFinishDate}
                      onChange={onFinishDateChange}
                      format="DD.MM.YYYY"
                      className={clsx(style.pickers_input)}
                      slotProps={{
                        actionBar: { actions: ['cancel', 'accept'] },
                        textField: {
                          variant: 'filled',
                          placeholder: 'Дата'
                        },
                      }}
                      localeText={{
                        toolbarTitle: '',
                        cancelButtonLabel: 'Отменить',
                        okButtonLabel: 'ОК',
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <p className="error-text">{validation.dateError}</p>
          </LocalizationProvider>
          <div className={style.checkbox}>
            <Checkbox
              defaultChecked={modalData.event?.isFinancial ?? false}
              name="isFinancial"
              color="primary"
              icon={<CheckboxSVG />}
              checkedIcon={<CheckboxCheckedSVG />}
            />
            <span>Финансовая задача</span>
          </div>
          <DropDownList
            options={GROUP_OPTIONS}
            onChange={setSelectedGroupId}
            chosedValue={selectedGroupId}
          />
        </div>
        <button type="submit" className="modal_btn">
          {modalComponent.btnText}
        </button>
      </form>
    </div>
  );
};

export default CreateEventModal;
