import React, { memo, useEffect, useState } from "react";
import { useCompaniesQuery } from "@lib/company";

import { Search } from "@ui/search";
import TablePagination from "@mui/material/TablePagination";
import { WorkplanTable } from "./workplan-table/workplan-table";
import clsx from "clsx";
import { getTextExcerpt } from "@utils/common-utils";
import styles from "./specialist-plan.module.scss";
import { switchCompany } from "@store/company/company-store";
import { useSearch } from "@hooks/useSearch";
import { getCompanyLogo } from "@utils/getCompanyLogo";
import { useSorting } from "@hooks/useSorting";
import { useWorkplanGroupsQuery } from "@lib/workplan";
import { GetWorkplanTasksFilters } from "@interfaces/workplan";
import { Moment } from "moment";

const sortingKeys = ["startDateOrder", "deadlineOrder", "statusOrder"];
const allStatuses = [1, 2, 3, 4, 5, 6];

const defaultFilters: GetWorkplanTasksFilters = {
  companies: undefined,
  statuses: allStatuses,
  groups: [],
  startDate: null,
  deadline: null,
};

export enum TaskSortEnum {
  DEFAULT,
  BY_START_DATE,
  BY_FINISH_DATE,
  BY_STATUS,
}

type SelectListType = {
  id: number;
  title: string;
};

const SpecialistPlan = memo(() => {
  const [selectedCompanyId, setSelectedCompanyId] = useState<number>();

  const search = useSearch();

  const { companies } = useCompaniesQuery({
    searchString: search.debounced,
  });

  // filter
  const [resetCategory, setResetCategory] = useState<boolean>(false);
  const [resetCompany, setResetCompany] = useState<boolean>(false);
  const [resetStatus, setResetStatus] = useState<boolean>(false);

  const sorting = useSorting(sortingKeys, "ASC");
  const [filters, setFilters] =
    useState<GetWorkplanTasksFilters>(defaultFilters);

  const handleCompanyChanging = (companyId: number) => {
    if (companyId !== selectedCompanyId) {
      switchCompany({ id: companyId });
      setSelectedCompanyId(companyId);
    }
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filteredCompanies, setFilteredCompanies] = useState(companies ?? []);
  const paginatedCompanies = filteredCompanies.slice(
    rowsPerPage * page,
    rowsPerPage * (page + 1)
  );

  const { data } = useWorkplanGroupsQuery({
    companies: filters.companies ?? [],
  });

  const getDefaultFilters = () => {
    if (!companies) {
      return defaultFilters;
    }

    return {
      ...defaultFilters,
      companies: companies.map((company) => company.id),
    };
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeCompanies = (values: SelectListType[]) => {
    setFilters((prev) => ({
      ...prev,
      companies: values.map((value) => value.id),
    }));
  };

  const handleChangeCategories = (values: SelectListType[]) => {
    setFilters((prev) => ({
      ...prev,
      groups: values.map((value) => value.title),
    }));
  };

  const handleChangeStatus = (values: SelectListType[]) => {
    setFilters((prev) => ({
      ...prev,
      statuses: values.map((value) => value.id),
    }));
  };

  const clearAllFilters = () => {
    setResetCategory(true);
    setResetCompany(true);
    setResetStatus(true);
    setFilters(getDefaultFilters());
  };

  useEffect(() => {
    if (!companies) return;

    setFilteredCompanies(companies);
    setFilters((prev) => ({
      ...prev,
      companies: companies.map((company) => company.id),
    }));
  }, [companies]);

  const handlerReset = () => {
    setResetCategory(false);
    setResetCompany(false);
    setResetStatus(false);
    setFilters(getDefaultFilters());
  };

  const onStartDateChange = (date: Moment | null) => {
    if (!date || !date.isValid()) {
      return setFilters((prev) => ({
        ...prev,
        startDate: null,
      }));
    }

    setFilters((prev) => ({
      ...prev,
      startDate: date,
    }));
  };

  const onEndDateChange = (date: Moment | null) => {
    if (!date || !date.isValid()) {
      return setFilters((prev) => ({
        ...prev,
        deadline: null,
      }));
    }

    setFilters((prev) => ({
      ...prev,
      deadline: date,
    }));
  };

  const handleIsArchivedChange = (checked: boolean) => {
    if (checked) {
      setFilters((prev) => ({
        ...prev,
        statuses: [...(prev.statuses ?? []), 7],
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        statuses: (prev.statuses ?? []).filter((status) => status !== 7),
      }));
    }
  };

  return (
    <>
      <Search
        options={companies ?? []}
        getOptionLabel={(company) => company.name}
        placeholder="Поиск по ИНН и названию компании"
        color="white"
        showDropDown={false}
        value={search.value}
        onChange={search.onChange}
      />
      <div className={clsx(styles.companies_list)}>
        {paginatedCompanies.length > 0 ? (
          paginatedCompanies.map((company) => (
            <div
              key={company.id}
              className={clsx(styles.company_item, "hover-animation-card", {
                [styles.active]: selectedCompanyId === company.id,
              })}
              onClick={() => handleCompanyChanging(company.id)}
            >
              <img
                src={getCompanyLogo(company.image)}
                alt=""
                className={clsx(styles.company_img)}
              />
              <p className={clsx(styles.company_info_text)}>
                {getTextExcerpt(
                  `${company.name}`,
                  document.documentElement.clientWidth > 1124
                    ? 25
                    : document.documentElement.clientWidth < 480
                      ? 13
                      : 20
                )}
              </p>
            </div>
          ))
        ) : (
          <div style={{ marginBottom: "10px" }}>
            По вашему запросу компаний не нашлось...
          </div>
        )}
      </div>
      <TablePagination
        component="div"
        count={filteredCompanies.length}
        page={page}
        rowsPerPageOptions={[
          { label: "5", value: 5 },
          { label: "10", value: 10 },
          { label: "25", value: 25 },
          { label: "50", value: 50 },
          { label: "100", value: 100 },
        ]}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={"Кол-во компаний на странице"}
      />
      <div>
        <WorkplanTable
          groups={data ?? []}
          filters={filters}
          sorting={sorting}
          handlerArchive={handleIsArchivedChange}
          selectedCompanyId={selectedCompanyId}
          resetCategory={resetCategory}
          resetStatus={resetStatus}
          resetCompany={resetCompany}
          handlerChoiceCompany={handleChangeCompanies}
          handlerChoiceCategory={handleChangeCategories}
          handlerChoiceStatus={handleChangeStatus}
          handlerReset={handlerReset}
          onStartDateChange={onStartDateChange}
          onEndDateChange={onEndDateChange}
          clearAllFilters={clearAllFilters}
          withDeleteChoice={false}
        />
      </div>
    </>
  );
});

export default SpecialistPlan;
