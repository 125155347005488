import {
  $UserAddPermissions,
  ACTIONS,
  UserPriorityEnum,
} from "@store/user-store";
import {
  ArrowDropDownSVG,
  CheckboxCheckedSVG,
  CheckboxSVG,
} from "@components/svgs";
import { Checkbox, TableCell, TableRow, Tooltip } from "@mui/material";
import { FC, memo } from "react";

import { ColorfulButton } from "@components/common/common";
import SpecialistPlanService from "@services/specialist-plan-service";
import { Trash } from "@phosphor-icons/react";
import { WorkplanGroupItem } from "../workplan-group-item/workplan-group-item";
import { WrapperSVG } from "@components/WrapperSVG/WrapperSVG";
import clsx from "clsx";
import styles from "./workplan-group.module.scss";
import useModal from "@modals/modal-hook";
import { useSelectedCompanyQuery } from "@lib/company";
import { useStore } from "effector-react";
import { GetWorkplanTasksFilters, WorkplanGroupT } from "@interfaces/workplan";
import { useWorkplanTasksQuery } from "@lib/workplan";
import { Button } from "@ui/button";
import { UseSortingResult } from "@hooks/useSorting";
import { useQueryClient } from "react-query";
import { workplanQueryKeys } from "@lib/workplan/queryKeys";
import { Loader } from "@mantine/core";

type TWorkplanGroup = {
  isOpen: boolean;
  isSelected: boolean;
  group: WorkplanGroupT;
  filters: GetWorkplanTasksFilters;
  sorting: UseSortingResult;
  isCompanyDefined: boolean;
  categoryId?: number | null;
  hideEmptyOptions?: boolean;
  listCompanies?: { title: string; id: number }[];
  withCheckbox?: boolean;
  onToggleOpen: () => void;
  onSelectChange: (groupId: number, checked: boolean) => void;
};
export const WorkplanGroup: FC<TWorkplanGroup> = memo(
  ({
    isOpen,
    isSelected,
    group,
    filters,
    sorting,
    isCompanyDefined,
    categoryId = null,
    hideEmptyOptions = false,
    withCheckbox = true,
    onToggleOpen,
    onSelectChange,
  }) => {
    const queryClient = useQueryClient();

    const { company } = useSelectedCompanyQuery();

    const { open } = useModal();
    const permissions = useStore($UserAddPermissions);

    const { data, hasNextPage, isFetchingNextPage, isLoading, fetchNextPage } =
      useWorkplanTasksQuery(
        {
          groupTitle: group.title,
          limit: 50,
          companies: filters.companies,
          statuses: filters.statuses,
          startDate: filters.startDate
            ? filters.startDate.toISOString()
            : undefined,
          endDate: filters.deadline
            ? filters.deadline.toISOString()
            : undefined,
          ...sorting.getActiveSorting(),
        },
        {
          enabled: isOpen,
        }
      );

    const handleGroupDeleting = (e: any) => {
      e.stopPropagation();
      if (!company) return;

      if (permissions.hasRequiredLevel(UserPriorityEnum.Admin)) {
        open("ConfirmActionModal", {
          btnText: "Удалить",
          modalData: {
            text: `Вы уверены что хотите удалить категорию "${group.title}" и все её задачи?`,
          },
          onConfirm: () =>
            SpecialistPlanService.deleteGroup(
              company.id,
              group.id ?? -1,
              (err, res) => {
                if (err || !res) {
                  if (err?.message) {
                    open("NotificationModal", {
                      btnText: "Хорошо",
                      modalData: {
                        modalTitle: "Ошибка при удалении",
                        titleStyle: {
                          color: "#b00420",
                        },
                        text: err?.message,
                        btnText: "Хорошо",
                      },
                    });
                  }
                  return console.error(
                    "При удалении категории произошла ошибка"
                  );
                }
                if (!!res.data) {
                  queryClient.invalidateQueries(workplanQueryKeys.all);
                }
              }
            ),
        });
      } else {
        open("NotificationModal", {
          modalData: {
            modalTitle: "",
            text: "Вы не можете удалить категорию, в которой находятся задачи",
          },
        });
      }
    };

    if (hideEmptyOptions && group.title !== "Прочие задачи") return <></>;

    return (
      <>
        {!categoryId ||
        categoryId === 0 ||
        (categoryId && categoryId === group.id) ? (
          <>
            <TableRow
              className={clsx(styles.row, styles.cell_section)}
              onClick={onToggleOpen}
            >
              <TableCell align="left" component="th" scope="row">
                <div className={styles.section_row}>
                  {withCheckbox && (
                    <Checkbox
                      onClick={(e) => e.stopPropagation()}
                      color="primary"
                      icon={<CheckboxSVG />}
                      checkedIcon={<CheckboxCheckedSVG />}
                      checked={isSelected}
                      onChange={(e) =>
                        onSelectChange(group.id ?? -1, e.target.checked)
                      }
                    />
                  )}
                  <p
                    className={clsx(styles.arrow_drop, {
                      [styles.arrow_drop__opened]: isOpen,
                    })}
                  >
                    <ArrowDropDownSVG />
                  </p>
                  <span className={styles.section_row_title}>
                    {group.title}
                  </span>
                </div>
              </TableCell>
              {!isCompanyDefined && <TableCell align="left"></TableCell>}
              <TableCell align="left"></TableCell>
              <TableCell align="left"></TableCell>
              <TableCell align="left"></TableCell>
              <TableCell align="left"></TableCell>
              <TableCell align="left">
                <div className={styles.cell}>
                  {permissions.hasPermission(
                    ACTIONS.companySpecialistWorkPlan_tasks_allowedToCreate
                  ) && (
                    <ColorfulButton
                      text={""}
                      onClick={(e) => {
                        e.stopPropagation();
                        open("CreatePlanEventModal", {
                          btnText: "Создать",
                          modalData: {
                            modalTitle: "Создать задачу",
                            groupId: group.id,
                            companyId: company?.id,
                            groupTitle: group.title,
                            isCompanyDefined: !isCompanyDefined,
                          },
                        });
                      }}
                    />
                  )}
                  {isCompanyDefined && (
                    <div className={styles.createTaskBtn}>
                      {permissions.hasPermission(
                        ACTIONS.companySpecialistWorkPlan_groups_allowedToDelete
                      ) && (
                        <WrapperSVG color="">
                          <Tooltip title="Удалить категорию" placement="top">
                            <Trash size={24} onClick={handleGroupDeleting} />
                          </Tooltip>
                        </WrapperSVG>
                      )}
                    </div>
                  )}
                </div>
              </TableCell>
            </TableRow>
            {isLoading && (
              <div style={{ margin: "12px" }}>
                <Loader size="lg" color="green" />
              </div>
            )}
            {isOpen && data && (
              <>
                {data.pages.map((page) =>
                  page.tasks.map((task) => (
                    <WorkplanGroupItem
                      key={task.id}
                      task={task}
                      group={group}
                      isCompanyDefined={isCompanyDefined}
                    />
                  ))
                )}
                {hasNextPage && (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Button
                        size="small"
                        onClick={() => {
                          fetchNextPage();
                        }}
                      >
                        {isFetchingNextPage ? (
                          <Loader size="sm" color="white" />
                        ) : (
                          "Загрузить еще"
                        )}
                      </Button>
                    </TableCell>
                  </TableRow>
                )}
              </>
            )}
          </>
        ) : null}
      </>
    );
  }
);
