import {
  $CommonResponsibilityDocuments,
  $ResponsibilityDocuments,
  $ResponsibilityStates,
  setResponsibilityCommonDocs,
  setResponsibilityDocuments,
  setResponsibilityError,
  setResponsibilityLoading,
  setResponsibilityStates,
} from "@store/responsibility-store";
import { $UserAddPermissions, ACTIONS } from "@store/user-store";
import { Trash } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import {
  ResponsibilityDirectoryT,
  ResponsibilityFileT,
  ResponsibilityTablePropsT,
} from "@interfaces/responsibility";
import { useHistory } from "react-router-dom";

import { ErrorIndicator } from "@ui/indicators";
import Loader from "@ui/indicators/loader";
import ResponsibilityService from "@services/responsibility-service";
import { Tooltip } from "@mui/material";
import clsx from "clsx";
import qs from "qs";
import style from "@scss/pages/responsibility.module.scss";
import tableStyle from "@scss/components/tables/base-table.module.scss";
import useModal from "@modals/modal-hook";
import { useStore } from "effector-react";
import { useChoiceState } from "@hooks/useChoiceState";
import { useAsyncConfirm } from "@hooks/useAsyncConfirm";
import {
  useDeleteResponsibilityDirectoryMutation,
  useDeleteResponsibilityFileMutation,
} from "@lib/responsibility";
import { ResponsibilityDirectory } from "./responsibility-directory";
import { ResponsibilityFile } from "./responsibility-file";

const ResponsibilityTable: React.FC<ResponsibilityTablePropsT> = ({
  setWithHistory,
  sort,
}) => {
  const commonDocuments = useStore($CommonResponsibilityDocuments);
  const currentDocuments = useStore($ResponsibilityDocuments);
  const { isFetched, isLoading, error } = useStore($ResponsibilityStates);
  const permissions = useStore($UserAddPermissions);
  const [directoryId, setDirectoryId] = useState<number>(0);

  const history = useHistory();
  const { close } = useModal();

  const deleteDirectoryMutation = useDeleteResponsibilityDirectoryMutation();
  const deleteFileMutation = useDeleteResponsibilityFileMutation();

  const confirm = useAsyncConfirm();

  const dirChoice = useChoiceState(currentDocuments[1], (dir) => dir.id);
  const filesChoice = useChoiceState(currentDocuments[0], (file) => file.id);

  useEffect(() => {
    const folderId = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    })?.folder_id;
    if (+(folderId as string) > 0) {
      setWithHistory(true);
    } else {
      setWithHistory(false);
    }

    setDirectoryId(+(folderId as string) || 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.search]);

  useEffect(() => {
    setResponsibilityLoading(true);
    if (directoryId !== 0) {
      ResponsibilityService.GetResponsibilityDocuments(
        directoryId,
        (err, res) => {
          if (err || !res) {
            return setResponsibilityError(true);
          }
          setResponsibilityDocuments(
            sortDocuments([res.data.files, res.data.directories])
          );
          setResponsibilityLoading(false);
        }
      );
      return;
    }
    if (directoryId === 0 && !isFetched) {
      ResponsibilityService.GetResponsibilityDocuments(null, (err, res) => {
        if (err || !res) {
          return setResponsibilityError(true);
        }
        setResponsibilityCommonDocs([res.data.files, res.data.directories]);
        setResponsibilityStates({ error, isLoading: false, isFetched: true });
      });
      return;
    }
    setResponsibilityDocuments(sortDocuments(commonDocuments));
    setResponsibilityLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directoryId]);

  useEffect(() => {
    if (directoryId === 0) {
      setResponsibilityDocuments(sortDocuments(commonDocuments));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonDocuments]);

  useEffect(() => {
    setResponsibilityDocuments(sortDocuments(currentDocuments));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort]);

  const sortDocuments = (
    documents: [ResponsibilityFileT[], ResponsibilityDirectoryT[]]
  ) => {
    if (sort === 10) {
      documents.forEach((docs) => {
        docs.sort((doc1, doc2) => {
          return (
            new Date(doc2.updatedAt).getTime() -
            new Date(doc1.updatedAt).getTime()
          );
        });
      });
    } else if (sort === 20) {
      documents.forEach((docs) => {
        docs.sort((doc1, doc2) => {
          return (
            new Date(doc1.updatedAt).getTime() -
            new Date(doc2.updatedAt).getTime()
          );
        });
      });
    }
    return Array.from(documents) as [
      ResponsibilityFileT[],
      ResponsibilityDirectoryT[],
    ];
  };

  const handleDirectoryDelete = (folderId: number) => {
    deleteDirectoryMutation.mutate(folderId, {
      onSuccess: () => {
        setResponsibilityCommonDocs([
          commonDocuments[0],
          commonDocuments[1].filter((dir) => dir.id !== folderId),
        ]);
        setResponsibilityDocuments(
          sortDocuments([
            currentDocuments[0],
            currentDocuments[1].filter((dir) => dir.id !== folderId),
          ])
        );
        close();
      },
    });
  };

  const handleFileDelete = (id: number) => {
    deleteFileMutation.mutate(id, {
      onSuccess: () => {
        setResponsibilityCommonDocs([
          commonDocuments[0].filter((file) => file.id !== id),
          commonDocuments[1],
        ]);
        setResponsibilityDocuments(
          sortDocuments([
            currentDocuments[0].filter((file) => file.id !== id),
            currentDocuments[1],
          ])
        );
        close();
      },
    });
  };

  const handleDeleteSelectedItems = async () => {
    const ok = await confirm({
      btnText: "Удалить",
      modalData: {
        text: `Вы уверены, что хотите выбранные файлы и папки?`,
      },
    });
    if (!ok) return;

    const deletedDirectories = dirChoice.selectedItems.map((dir) => dir.id);
    const deletedFiles = filesChoice.selectedItems.map((file) => file.id);

    const promises = [];

    for (const dir of deletedDirectories) {
      promises.push(deleteDirectoryMutation.mutateAsync(dir));
    }

    for (const file of deletedFiles) {
      promises.push(deleteFileMutation.mutateAsync(file));
    }

    await Promise.all(promises);

    setResponsibilityCommonDocs([
      commonDocuments[0].filter((file) => !deletedFiles.includes(file.id)),
      commonDocuments[1].filter((dir) => !deletedDirectories.includes(dir.id)),
    ]);
    setResponsibilityDocuments(
      sortDocuments([
        currentDocuments[0].filter((file) => !deletedFiles.includes(file.id)),
        currentDocuments[1].filter(
          (dir) => !deletedDirectories.includes(dir.id)
        ),
      ])
    );
  };

  return (
    <div className={clsx(tableStyle.base_table_container)}>
      {error ? (
        <ErrorIndicator />
      ) : isLoading ? (
        <Loader />
      ) : (
        <table
          className={clsx(tableStyle.base_table, style.responsibility_table)}
        >
          <thead>
            <tr>
              <td>
                <label>
                  <input
                    type="checkbox"
                    className={clsx(tableStyle.checkbox_item)}
                    hidden
                    id="all-items-checkbox"
                    checked={
                      dirChoice.areAllItemsSelected &&
                      filesChoice.areAllItemsSelected
                    }
                    onChange={(e) => {
                      dirChoice.selectAll(e.target.checked);
                      filesChoice.selectAll(e.target.checked);
                    }}
                  />
                  <label htmlFor="all-items-checkbox">
                    <svg
                      className="w-5 h-5"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </label>
                  <label className={clsx(tableStyle.checkbox_label)}>
                    Название документа
                  </label>
                </label>
              </td>
              <td align="left">Дата обновления</td>
              <td align="right">
                {(dirChoice.selectedItems.length > 0 ||
                  filesChoice.selectedItems.length > 0) &&
                  permissions.hasPermission([
                    ACTIONS.responsibility_files_allowedToDelete,
                    ACTIONS.responsibility_directories_allowedToDelete,
                  ]) && (
                    <Tooltip title={"Удалить"} placement="top">
                      <Trash size={24} onClick={handleDeleteSelectedItems} />
                    </Tooltip>
                  )}
              </td>
            </tr>
          </thead>
          <tbody>
            {currentDocuments[1].map((directory) => (
              <ResponsibilityDirectory
                key={directory.id}
                directory={directory}
                isSelected={dirChoice.getIsItemSelected(directory)}
                onSelect={(checked) => dirChoice.selectById(directory, checked)}
                onDelete={() => handleDirectoryDelete(directory.id)}
              />
            ))}
            {currentDocuments[0].map((file) => (
              <ResponsibilityFile
                key={file.id}
                file={file}
                isSelected={filesChoice.getIsItemSelected(file)}
                onSelect={(checked) => filesChoice.selectById(file, checked)}
                onDelete={() => handleFileDelete(file.id)}
              />
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ResponsibilityTable;
