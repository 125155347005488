import {
  Checkbox,
  FormControl,
  Grid2 as Grid,
  InputLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { CheckboxCheckedSVG, CheckboxSVG } from "@components/svgs";
import { DatePicker } from '@mui/x-date-pickers';
import { TimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ruRU } from '@mui/x-date-pickers/locales';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from "moment";

import React, { useEffect, useState } from "react";

import { $User } from "@store/user-store";
import { BodyBold } from "@ui/fonts";
import SpecialistPlanService from "@services/specialist-plan-service";
import { UserDataT } from "@interfaces/user";
import { WorkplanEmployeesInfo } from "./employees-info";
import clsx from "clsx";
import modalStyles from "@scss/modals/company/calendar.module.scss";
import style from "@scss/pages/specialist-plan.module.scss";
import { useModal } from "@modals/index";
import { useStore } from "effector-react";
import useStyles from "@ui/material-ui-styles";
import { useCompaniesQuery } from "@lib/company";
import { useQueryClient } from "react-query";
import { workplanQueryKeys } from "@lib/workplan/queryKeys";

const PeriodicityItems = [
  { value: 1, label: "Разово" },
  { value: 2, label: "Ежедневно" },
  { value: 3, label: "1 раз в неделю" },
  { value: 4, label: "1 раз в месяц" },
  { value: 5, label: "1 раз в квартал" },
  { value: 6, label: "1 раз в полгода" },
  { value: 7, label: "1 раз в год" },
  { value: 8, label: "1 раз в 3 года" },
  { value: 9, label: "1 раз в 5 лет" },
  { value: 10, label: "Задачи без срока (заметки, доп.информация и т.д.)" },
];

type FormFields = {
  title: string;
  description: string;
  isFinancial: boolean;
};

const CreatePlanEventModal = () => {
  const queryClient = useQueryClient();

  const { companies } = useCompaniesQuery();

  const { modalComponent, modalData, close } = useModal();
  // search id for http query
  const [queryIdGroup, setQueryIdGroup] = useState<{
    groupId: number;
    companyId: number;
  } | null>(null);

  const [errorMessage, setErrorMessage] = useState<string>("");

  const getDateStart = () => {
    if (modalData.event?.startDate) {
      return moment(modalData.task.startDate);
    }
    return moment();
  };
  const getDateFinish = () => {
    if (modalData.event?.deadline) {
      return moment(modalData.task.deadline);
    }
    const now = moment().toDate();
    return moment(now.setHours(now.getHours()));
  };
  // // START DATE
  const [selectedStartDate, setStartDateChange] = useState<Moment>(
    modalData.task?.startDate
      ? moment(modalData.task.startDate)
      : getDateStart
  );
  // // FINISH DATE
  const [selectedFinishDate, setFinishDateChange] = useState(
    modalData.task?.deadline ? moment(modalData.task.deadline) : getDateFinish
  );
  // // OTHER STATES
  const [periodicity, setPeriodicity] = useState<number>(
    modalData.task?.periodicity ?? PeriodicityItems[0].value
  );
  const [company, setCompany] = useState<number>();
  const user = useStore($User) as UserDataT;

  const [listCompanyes, setListCompanyes] = useState<
    {
      label: string;
      value: number;
    }[]
  >([]);

  useEffect(() => {
    if (!companies) return;

    if (modalData?.isCompanyDefined) {
      const listCompanyesState = companies.map((company) => ({
        label: company.name,
        value: company.id,
      }));
      if (listCompanyesState[0]?.value) {
        setCompany(listCompanyesState[0]?.value);
      }

      setListCompanyes(listCompanyesState);
      return;
    } else {
      setCompany(modalData.companyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, modalData, companies]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form, setForm] = useState<FormFields>({
    title: modalData?.task?.title ?? "",
    description: modalData?.task?.desc ?? "",
    isFinancial: modalData?.task?.isFinancial ?? false,
  });
  const [validation, setValidation] = useState<any>({
    titleError: "",
    descError: "",
    periodicityError: "",
    creationError: "",
  });

  const classes = useStyles();

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prev) => ({
      ...prev,
      title: e.target.value,
    }));
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prev) => ({
      ...prev,
      description: e.target.value,
    }));
  };

  const handleIsFinancialChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prev) => ({
      ...prev,
      isFinancial: e.target.checked,
    }));
  };

  useEffect(() => {
    if (modalData.editMode && modalData.event?.periodicity) {
      setPeriodicity(modalData.event.periodicity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setForm({
      title: modalData?.task?.title,
      description: modalData?.task?.desc,
      isFinancial: modalData?.task?.isFinancial,
    });
  }, [modalData?.task]);

  useEffect(() => {
    if (!company) return;
    SpecialistPlanService.getAllGroupId(company, (err, res) => {
      if (err || !res) {
        return console.error("При создании события произошла ошибка");
      }
      if (res.data && modalData?.groupTitle) {
        let group = res.data.find(
          (groupId) => groupId.title === modalData?.groupTitle
        );
        if (group) {
          setQueryIdGroup({
            groupId: group.id,
            companyId: group.companyId,
          });
          setErrorMessage("");
          return;
        }
        if (group === undefined) {
          setErrorMessage(
            "У выбранной компании нет данной категории в плане работ"
          );
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (modalData?.disabled) {
      return close();
    }

    let startDate: Moment | null = null;
    let finishDate: Moment | null = null;

    if (moment().valueOf() > selectedStartDate.valueOf()) {
      onStartDateChange(moment(moment().valueOf() + 3600000));
      startDate = moment();
      if (startDate > selectedFinishDate) {
        onFinishDateChange(moment(moment().valueOf() + 3600000));
        finishDate = moment(moment().valueOf() + 3600000);
      }
    }

    const modalBtn = document.querySelector(
      "button.modal_btn"
    ) as HTMLButtonElement;

    const invalidPeriodicityValue = !PeriodicityItems.find(
      (item) => item.value === periodicity
    );
    if (!form.title || !form.description || invalidPeriodicityValue) {
      modalBtn.disabled = false;
      return setValidation({
        titleError: !form.title ? "Это поле обязательно" : "",
        descError: !form.description ? "Это поле обязательно" : "",
        periodicityError: invalidPeriodicityValue
          ? "Некорректное значение"
          : "",
      });
    }
    !startDate && (startDate = moment(selectedStartDate));
    !finishDate && (finishDate = moment(selectedFinishDate));

    if (form.title.length > 255) {
      return setValidation({
        titleError: "Длина названия задачи не должна превышать 255 символов",
      });
    }

    const eventNewData = {
      title: form.title,
      desc: form.description,
      periodicity,
      savedToCalendar: modalData.task?.savedToCalendar ?? false,
      status: modalData.task ? modalData.task.status : 5,
      groupId: queryIdGroup?.groupId,
      parentId: modalData.parentId || undefined,
      startDate: moment(selectedStartDate).valueOf(),
      deadline: moment(selectedFinishDate).valueOf(),
      withSigning: modalData.task?.withSigning ?? 0,
      isFinancial: form.isFinancial,
    };
    if (modalData.editMode) {
      eventNewData.parentId = undefined;
      if (!modalData?.companyId) {
        setIsLoading(false);
        return console.error("Не выбрана компания");
      }
      SpecialistPlanService.updateTask(
        modalData?.companyId,
        modalData.task.id,
        eventNewData,
        (err, res) => {
          if (err || !res) {
            modalBtn.disabled = false;
            setIsLoading(false);
            return console.error("При изменении задачи произошла ошибка");
          }
          queryClient.invalidateQueries(workplanQueryKeys.all);
        }
      );
      setIsLoading(false);
      close();
      return;
    }

    // CREATING NEW EVENT
    setIsLoading(true);

    // setQueryIdGroup

    if (!queryIdGroup) return setIsLoading(false);
    return SpecialistPlanService.createTask(
      queryIdGroup.companyId,
      { ...eventNewData, groupId: queryIdGroup.groupId },
      (err, res) => {
        if (err || !res) {
          modalBtn.disabled = false;
          setIsLoading(false);
          return console.error("При создании события произошла ошибка");
        }
        queryClient.invalidateQueries(workplanQueryKeys.all);

        setIsLoading(false);
        close();
      }
    );
  };

  const onStartDateChange = (date: Moment | null) => {
    if (date?.isValid() && date > selectedFinishDate) {
      setValidation({
        dateError: "Дата окончания не может быть раньше даты начала",
      });
      setFinishDateChange(moment(date.valueOf() + 3600000));
    }
    setValidation({ dateError: "" });
    date?.isValid() && setStartDateChange(date);
  };

  const onFinishDateChange = (date: Moment | null) => {
    if (date?.isValid() && date < selectedStartDate) {
      setValidation({
        dateError: "Дата окончания не может быть раньше даты начала",
      });
      setStartDateChange(moment(date.valueOf() - 3600000));
    }
    date?.isValid() && setFinishDateChange(date);
  };

  const handlePeriodicityChange = (event: SelectChangeEvent<any>) => {
    setPeriodicity(+event.target.value);
  };

  const handleCompanyChange = (event: SelectChangeEvent<any>) => {
    setCompany(+event.target.value);
  };

  return (
    <div
      key={modalComponent.key}
      className={clsx(modalStyles.create_event_modal)}
    >
      <p className="modal_title">
        {modalData.modalTitle}{" "}
        {modalData?.groupTitle && `- ${modalData?.groupTitle}`}
      </p>
      <div className="underline" />
      <form className={`modal_content ${classes.root}`} onSubmit={handleSubmit}>
        {!!company && modalData?.isCompanyDefined && (
          <FormControl variant="filled" className={clsx(style.period_select)}>
            <InputLabel id="ot-specialist">Компания</InputLabel>
            <Select
              labelId="specialist-plan-periodicity"
              value={company}
              onChange={handleCompanyChange}
              disabled={modalData?.disabled}
            >
              {listCompanyes?.map((item: { label: string; value: number }) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {errorMessage && (
          <BodyBold color="red">
            {errorMessage} - {modalData?.groupTitle}
          </BodyBold>
        )}
        <TextField
          label="Название задачи"
          name={"title"}
          variant="filled"
          required
          disabled={modalData?.disabled}
          value={form.title}
          onChange={handleTitleChange}
        />
        <p className="error-text">{validation.titleError}</p>
        <TextField
          label="Примечание"
          name={"description"}
          variant="filled"
          required
          multiline
          disabled={modalData?.disabled}
          value={form.description}
          onChange={handleDescriptionChange}
        />
        <p className="error-text">{validation.descError}</p>
        <FormControl variant="filled" className={clsx(style.period_select)}>
          <InputLabel id="ot-specialist">Указать периодичность</InputLabel>
          <Select
            labelId="specialist-plan-periodicity"
            value={periodicity}
            onChange={handlePeriodicityChange}
            disabled={modalData?.disabled}
          >
            {PeriodicityItems.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <p className="error-text">{validation.periodicityError}</p>
        {modalData.task && (
          <WorkplanEmployeesInfo taskId={modalData.task.id} onClose={close} />
        )}
        <div className="create-event-modal-pickers">
          <p className={clsx(style.datepickers_title)}>Начало и крайний срок</p>
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale={"ru"}
            localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
          >
            <Grid
              container
              className={clsx(modalStyles.main_pickers_container)}
            >
              <Grid size={{ xs: 12, md: 6 }}>
                <Grid container className={clsx(modalStyles.pickers_container)}>
                  <Grid size={{ xs: 12, sm: 5 }}>
                    <TimePicker
                      ampm={false}
                      value={modalData.task?.startDate ? selectedStartDate ?? moment(modalData.task.startDate) : selectedStartDate}
                      onChange={onStartDateChange}
                      format="HH:mm"
                      autoFocus={false}
                      className={clsx(modalStyles.pickers_input)}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      slotProps={{
                        actionBar: { actions: ['cancel', 'accept'] },
                        textField: {
                          variant: 'filled',
                          placeholder: 'Время',
                          disabled: modalData?.disabled ?? modalData.task?.isCreatedAutomatically
                        },
                      }}
                      localeText={{
                        toolbarTitle: '',
                        cancelButtonLabel: 'Отменить',
                        okButtonLabel: 'ОК',
                      }}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6 }}>
                    <DatePicker
                      value={modalData.task?.startDate ? selectedStartDate ?? moment(modalData.task.startDate) : selectedStartDate}
                      onChange={onStartDateChange}
                      format="DD.MM.YYYY"
                      autoFocus={false}
                      className={clsx(modalStyles.pickers_input)}
                      slotProps={{
                        actionBar: { actions: ['cancel', 'accept'] },
                        textField: {
                          variant: 'filled',
                          placeholder: 'Дата',
                          disabled: modalData?.disabled ?? modalData.task?.isCreatedAutomatically,
                        },
                      }}
                      localeText={{
                        toolbarTitle: '',
                        cancelButtonLabel: 'Отменить',
                        okButtonLabel: 'ОК',
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Grid container className={clsx(modalStyles.pickers_container)}>
                  <Grid size={{ xs: 12, sm: 5 }}>
                    <TimePicker
                      ampm={false}
                      value={modalData.task?.deadline ? selectedFinishDate ?? moment(modalData.task.deadline) : selectedFinishDate}
                      onChange={onFinishDateChange}
                      format="HH:mm"
                      autoFocus={false}
                      className={clsx(modalStyles.pickers_input)}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      slotProps={{
                        actionBar: { actions: ['cancel', 'accept'] },
                        textField: {
                          id: 'time-picker',
                          variant: 'filled',
                          placeholder: 'Время',
                          disabled: modalData?.disabled ?? modalData.task?.isCreatedAutomatically
                        },
                      }}
                      localeText={{
                        toolbarTitle: '',
                        cancelButtonLabel: 'Отменить',
                        okButtonLabel: 'ОК',
                      }}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6 }}>
                    <DatePicker
                      value={modalData.task?.deadline ? selectedFinishDate ?? moment(modalData.task.deadline) : selectedFinishDate}
                      onChange={onFinishDateChange}
                      format="DD.MM.YYYY"
                      autoFocus={false}
                      className={clsx(modalStyles.pickers_input)}
                      slotProps={{
                        actionBar: {actions: ['cancel', 'accept']},
                        textField: {
                          variant: 'filled',
                          placeholder: 'Дата',
                          disabled: modalData?.disabled ?? modalData.task?.isCreatedAutomatically,
                        },
                      }}
                      localeText={{
                        toolbarTitle: '',
                        cancelButtonLabel: 'Отменить',
                        okButtonLabel: 'ОК',
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </LocalizationProvider>
          <p className="error-text">{validation.dateError}</p>

          <div className={style.checkbox}>
            <Checkbox
              checked={form.isFinancial}
              onChange={handleIsFinancialChange}
              name="isFinancial"
              color="primary"
              icon={<CheckboxSVG />}
              checkedIcon={<CheckboxCheckedSVG />}
              disabled={modalData?.disabled}
            />
            <span>Финансовая задача</span>
          </div>
        </div>
        <button
          type="submit"
          className="modal_btn"
          disabled={isLoading || !!errorMessage}
        >
          {modalComponent.btnText}
        </button>
      </form>
    </div>
  );
};

export default CreatePlanEventModal;
