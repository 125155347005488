import Fetcher from "@http/fetcher";

import { ResCallback } from "@interfaces/common";
import { WorkerShortDataT } from "@interfaces/user";
import { AddNewUserPropsT } from "@interfaces/requests/workers";

class WorkerService {
  static async GetWorkers(cb: ResCallback<WorkerShortDataT[]>) {
    try {
      const res = await Fetcher.get<WorkerShortDataT[]>("/users");

      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err: any) {
      return cb(err);
    }
  }

  static async AddNewWorker(
    data: AddNewUserPropsT,
    cb: ResCallback<WorkerShortDataT>
  ) {
    try {
      const res = await Fetcher.post<WorkerShortDataT>("/users", data);

      if (res.status === 201) {
        return cb(null, res);
      }
    } catch (err: any) {
      return cb(err);
    }
  }

  static async UpdateWorker(
    workerId: number,
    data: Partial<AddNewUserPropsT>,
    cb: ResCallback<WorkerShortDataT>
  ) {
    try {
      const res = await Fetcher.patch<WorkerShortDataT>(
        `/users/${workerId}`,
        data
      );

      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err: any) {
      return cb(err);
    }
  }
}

export default WorkerService;
