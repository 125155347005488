import "@scss/components/menu.scss";

import {
  $User,
  $UserAddPermissions,
  ACTIONS,
  UserPriorityEnum,
} from "@store/user-store";
import { TariffEnum } from "@interfaces/company/company";
import { MenuItemT, MenuPanelPropsT } from "@interfaces/common";

import { AttentionSVG } from "@components/svgs";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { UserDataT } from "@interfaces/user";
import clsx from "clsx";
import { useStore } from "effector-react";
import { useSelectedCompanyQuery } from "@lib/company";

const MenuItemsComponent: React.FC<{
  items: MenuItemT[];
  onItemClick: () => void;
}> = ({ items }) => {
  const handlerLink = (link: string) => {
    return window.location.pathname.split("/")[1] === link.split("/")[1];
  };

  return (
    <>
      {items.map((item, idx) => (
        <Link key={idx} to={item.link}>
          <div
            className={clsx(
              "menu-item",
              item.link === "/goskey" && "menu-goskey",
              {
                active: handlerLink(item.link),
                notification: item.notification,
              }
            )}
          >
            <img
              src={item.imageSrc}
              alt=""
              className={clsx(
                "menu-item-icon",
                item?.link === "/goskey" && "menu-goskey-icon"
              )}
            />
            {item?.text}
            {item?.attention && (
              <Tooltip title={item?.attentionText || ""} placement="top">
                <div className="attention">
                  <AttentionSVG />
                </div>
              </Tooltip>
            )}
          </div>
        </Link>
      ))}
    </>
  );
};

export const MenuPanel: React.FC<MenuPanelPropsT> = ({ onItemClick }) => {
  const { company } = useSelectedCompanyQuery();
  const permissions = useStore($UserAddPermissions);
  const user = useStore($User) as UserDataT;

  let menuItems: any = [
    {
      imageSrc: "/img/static/sidebar/company.svg",
      text: "Компания",
      link: "/company/companies",
    },
  ];
  if (
    permissions.hasPermission(ACTIONS.companySpecialistWorkPlan_allowedToShow)
  ) {
    menuItems.push({
      imageSrc: "/img/static/sidebar/specialist-plan.svg",
      text: "План специалиста ",
      link: "/specialist-plan",
    });
  }
  if (permissions.hasPermission(ACTIONS.educationalCenter_allowedToShow)) {
    menuItems.push({
      imageSrc: "/img/static/sidebar/study-center.svg",
      text: "Учебный центр",
      link: "/study-center",
    });
  }
  if (
    permissions.hasPermission(ACTIONS.companyProcedureControls_allowedToShow)
  ) {
    menuItems.push({
      imageSrc: "/img/static/sidebar/procedure-control.svg",
      text: "Контроль выполнения процедур",
      link: "/procedure-control-company",
    });
  }
  if (
    permissions.hasPermission(
      ACTIONS.companyApplicationsForTraining_allowedToShow
    )
  ) {
    menuItems.push({
      imageSrc: "/img/static/sidebar/users.svg",
      text: "Контроль заявок на обучение",
      link: "/control-request-training",
    });
  }

  if (
    permissions.hasPermission(ACTIONS.usefulMaterials_allowedToShow) &&
    company &&
    ([2, 3, 4, 5].includes(company.tariff) || [777, 1, 2].includes(user.roleId))
  ) {
    menuItems.push({
      imageSrc: "/img/static/sidebar/useful-materials.svg",
      text: "Полезные материалы",
      link: "/help",
    });
  }

  if (permissions.hasPermission(ACTIONS.developmentNews_allowedToShow)) {
    menuItems.push({
      imageSrc: "/img/static/sidebar/notifications.svg",
      text: "Скоро в Личном кабинете",
      link: "/development-news",
    });
  }

  if (permissions.hasPermission(ACTIONS.legislationNews_allowedToShow)) {
    menuItems.push({
      imageSrc: "/img/static/sidebar/news.svg",
      text: "Новости законодательства",
      link: "/legislation-news",
    });
  }

  if (permissions.hasPermission(ACTIONS.projectNews_allowedToShow)) {
    menuItems.push({
      imageSrc: "/img/static/sidebar/document-templates.svg", // TODO: change icon to something else
      text: "Новости проекта",
      link: "/project-news",
    });
  }

  menuItems.push(
    {
      imageSrc: "/img/static/sidebar/responsibility.svg",
      text: "Ответственность",
      link: "/responsibility",
    },
    {
      imageSrc: "/img/static/sidebar/legal-information.svg",
      text: "Юридическая информация",
      link: "/legal-information",
    }
  );

  if (permissions.hasPermission(ACTIONS.judicialPractice_allowedToShow)) {
    menuItems.push({
      imageSrc: "/img/static/sidebar/practices.svg",
      text: "Судебная практика",
      link: "/practices",
    });
  }
  if (
    permissions.hasPermission(ACTIONS.companyDocumentTemplates_allowedToShow)
  ) {
    menuItems.push({
      imageSrc: "/img/static/sidebar/document-templates.svg",
      text: "Шаблоны документов",
      link: "/document-templates",
    });
  }
  if (permissions.hasPermission(ACTIONS.directories_allowedToShow)) {
    menuItems.push({
      imageSrc: "/img/static/sidebar/program-training.svg",
      text: "Справочники",
      link: "/directories/training",
    });
  }
  if (permissions.hasPermission(ACTIONS.payment_tariffs_allowedToChoose)) {
    menuItems.push({
      imageSrc: "/img/static/sidebar/payment.svg",
      text: "Оплата",
      link: "/payment/tariffs",
      attention: user.doesTariffExpire,
      attentionText: "Срок действия тарифов истекает",
    });
  }

  menuItems.push({
    imageSrc: "/img/static/mini-goskey.png",
    text: "Госключ",
    link: "/goskey",
  });
  if (permissions.hasPermission(ACTIONS.specialistHelp_allowedToShow)) {
    menuItems.push({
      imageSrc: "/img/static/sidebar/question-answer.svg",
      text: "Вопрос специалисту",
      link: "/question-answer",
    });
  }
  if (permissions.hasPermission(ACTIONS.users_allowedToShow)) {
    menuItems.unshift({
      imageSrc: "/img/static/sidebar/settings.svg",
      text: "Настройки ЛК",
      link: "/settings/users",
    });
  }

  return (
    <nav className="menu">
      <div className="menu-cont">
        <div className="menu-section">
          <MenuItemsComponent onItemClick={onItemClick} items={menuItems} />
        </div>
        <div className="menu-section">
          <MenuItemsComponent
            onItemClick={onItemClick}
            items={[
              {
                imageSrc: "/img/static/sidebar/feedback.svg",
                text: "Техподдержка",
                link: "/feedback",
              },
            ]}
          />
        </div>
      </div>
    </nav>
  );
};
