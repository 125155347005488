import { FC } from "react";
import { CompanyT, TariffEnum } from "@interfaces/company/company";
import styles from "./CompanyCard.module.scss";
import { WrapperSVG } from "@components/WrapperSVG/WrapperSVG";
import { BodyLight, BodyNormal, Subtitle } from "@ui/fonts";
import { Tooltip } from "@mui/material";
import { Buildings, NotePencil, Trash } from "@phosphor-icons/react";
import moment from "moment";
import { renderDayWithEnding } from "@utils/renderDayEndingDependsOnCount";
import { $UserAddPermissions, ACTIONS } from "@store/user-store";
import { useStore } from "effector-react";
import { useModal } from "@modals/index";
import { $AllTariffs } from "@store/payment-store";
import { setCompany } from "@store/company/company-store";
import { useAsyncConfirm } from "@hooks/useAsyncConfirm";
import {
  useCompaniesQuery,
  useDeleteCompanyMutation,
  useSelectedCompanyQuery,
} from "@lib/company";
import { CompanyDiskUsage } from "@components/CompanyDiskUsage";
import { getCompanyLogo } from "@utils/getCompanyLogo";

export type CompanyCardPropsT = {
  company: CompanyT;
};

export const CompanyCard: FC<CompanyCardPropsT> = ({ company }) => {
  const permissions = useStore($UserAddPermissions);
  const allTariffs = useStore($AllTariffs);

  const { companies } = useCompaniesQuery();
  const { company: selectedCompany } = useSelectedCompanyQuery();

  const deleteCompanyMutation = useDeleteCompanyMutation();

  const modal = useModal();
  const confirm = useAsyncConfirm();

  const handleEditCompany = () => {
    modal.open("CreateCompanyModal", {
      modalData: company,
      btnText: "Изменить",
    });
  };

  const handlerTitleOptions = (str?: string) => {
    if (str?.includes("[")) {
      // eslint-disable-next-line no-useless-escape
      return str.replace(/[\[\(][^\]\)]*[\]\)]/g, "");
    }
    return str;
  };

  const handleDeleteCompany = async () => {
    if (!selectedCompany || !companies) return;

    const ok = await confirm({
      btnText: "Удалить",
      modalData: {
        text: `Вы уверены, что хотите удалить компанию "${company.name}"?`,
      },
    });
    if (!ok) return;

    deleteCompanyMutation.mutate(company.id, {
      onSuccess: () => {
        if (company.id === selectedCompany.id) {
          setCompany(companies[0].id);
        }
      },
    });
  };

  return (
    <div className={styles.companyCard}>
      <div className={styles.companyCard__image}>
        {company.image &&
        !company.image.includes("/img/static/dummy-logo.png") ? (
          <img src={getCompanyLogo(company.image)} alt="" />
        ) : (
          <WrapperSVG cursor={false} color={"gray"}>
            <Buildings size={32} />
          </WrapperSVG>
        )}
      </div>
      <div className={styles.companyCard__info}>
        <div className={styles.companyCard__detail}>
          <p>
            <Subtitle weight="bold">{company?.name && company.name}</Subtitle>
          </p>
          <div>
            <BodyLight>Организационно-правовая форма:</BodyLight>
            <p>
              {company?.organizationalLegalForm &&
                company.organizationalLegalForm}
            </p>
          </div>
          <div>
            <p>
              <BodyLight>ИНН:</BodyLight>
              <BodyNormal weight="bold">
                {" "}
                {company?.inn && company.inn}
              </BodyNormal>
            </p>
          </div>
        </div>

        <div>
          {/* {!permissions.hasRequiredLevel(UserPriorityEnum.Admin) && */}
          {[1, 2, 3, 4, 5].includes(company.tariff) && (
            <>
              <div>
                {[TariffEnum.Temp, TariffEnum.Demo].includes(company.tariff) ? (
                  <p>{company.tariff === 4 ? "Временный" : "Демо дни"}</p>
                ) : (
                  <>
                    <p>
                      Тариф:{" "}
                      <span>
                        {allTariffs &&
                          handlerTitleOptions(
                            allTariffs[company.tariff - 1].title
                          )}
                      </span>
                    </p>
                    <p>
                      Истекает:{" "}
                      <span>
                        {moment(company.tariffExpires).format("DD.MM.YYYY")}
                      </span>
                    </p>
                  </>
                )}
              </div>
              {[TariffEnum.Temp, TariffEnum.Demo].includes(company.tariff) ? (
                <div className="remains-free-space">
                  <progress
                    value={company.tariffExpires - Date.now()}
                    max={company.tariffExpires - company.tariffStarts}
                  ></progress>
                  <p>
                    {Math.ceil(
                      (company.tariffExpires - Date.now()) / 86400000
                    ) === 1
                      ? "Остался"
                      : "Осталось"}{" "}
                    {Math.ceil((company.tariffExpires - Date.now()) / 86400000)}{" "}
                    {renderDayWithEnding(
                      Math.ceil((company.tariffExpires - Date.now()) / 86400000)
                    )}
                  </p>
                </div>
              ) : (
                <CompanyDiskUsage company={company} />
              )}
            </>
          )}
        </div>
      </div>

      <div className={styles.companyCard__actions}>
        {permissions.hasPermission(ACTIONS.companies_allowedToEdit) && (
          <WrapperSVG color={"orange"} margin>
            <Tooltip title="Изменить" placement={"top"}>
              <NotePencil size={24} onClick={handleEditCompany} />
            </Tooltip>
          </WrapperSVG>
        )}
        {permissions.hasPermission(ACTIONS.companies_allowedToDelete) && (
          <WrapperSVG color={""}>
            <Tooltip title="Удалить" placement={"top"}>
              <Trash size={24} onClick={handleDeleteCompany} />
            </Tooltip>
          </WrapperSVG>
        )}
      </div>
    </div>
  );
};
