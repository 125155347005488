import { ServiceSignupPropsT } from "@interfaces/requests/auth";
import {
  FilledInput,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { signup } from "@store/auth-store";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import styles from "./RegisterForm.module.scss";

export const RegisterForm: FC = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ServiceSignupPropsT>();

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const onSubmit = handleSubmit((data) => {
    signup({
      email: data.email,
      name: data.name,
      password: data.password,
      passwordConfirm: data.passwordConfirm,
      cb: (err) => {
        if (err && !err?.response) {
          toast.error("Техническое обслуживание", { id: "server-support" });
        }
        if (err?.response) {
          if (typeof err.response.data === "object") {
            // TODO: handle errors from api here using `setError`
          }
          return;
        }
      },
    });
  });

  return (
    <form onSubmit={onSubmit} className={styles.registerForm}>
      <TextField
        label="ФИО"
        placeholder={"ФИО"}
        variant="filled"
        error={!!errors.name}
        helperText={errors.name?.message}
        {...register("name", {
          required: "Обязательное поле",
          validate: {
            length: (value) =>
              value.length < 3 || value.length > 40
                ? "Поле должно содержать не менее 3 и не более 40 символов"
                : true,
          },
        })}
      />
      <TextField
        label="Почта"
        placeholder={"Почта"}
        variant="filled"
        error={!!errors.email}
        helperText={errors.email?.message}
        {...register("email", {
          required: "Обязательное поле",
        })}
      />
      <FormControl key={3} variant="filled">
        <InputLabel htmlFor="filled-adornment-password">Пароль</InputLabel>
        <FilledInput
          type={isPasswordVisible ? "text" : "password"}
          placeholder={"Пароль"}
          error={!!errors.password}
          {...register("password", {
            required: "Обязательное поле",
            validate: {
              minLength: (value) =>
                value.length < 6
                  ? "Пароль должен содержать не менее 6 символов"
                  : true,
              maxLength: (value) =>
                value.length > 40
                  ? "Пароль должен быть не меньше 40 символов"
                  : true,
            },
          })}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setIsPasswordVisible((prev) => !prev)}
                edge="end"
              >
                {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        <FormHelperText>{errors.password?.message}</FormHelperText>
      </FormControl>
      <FormControl key={4} variant="filled">
        <InputLabel htmlFor="filled-password-conf">
          Подтвердите пароль
        </InputLabel>
        <FilledInput
          type={isPasswordVisible ? "text" : "password"}
          placeholder={"Подтвердите пароль"}
          error={!!errors.passwordConfirm}
          {...register("passwordConfirm", {
            required: "Обязательное поле",
            validate: {
              equalToPassword: (value, values) =>
                value === values.password ? true : "Должно совпадать с паролем",
              minLength: (value) =>
                value.length < 6
                  ? "Пароль должен содержать не менее 6 символов"
                  : true,
              maxLength: (value) =>
                value.length > 40
                  ? "Пароль должен быть не меньше 40 символов"
                  : true,
            },
          })}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setIsPasswordVisible((prev) => !prev)}
                edge="end"
              >
                {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        <FormHelperText>{errors.passwordConfirm?.message}</FormHelperText>
      </FormControl>
      <button className="modal_btn">Зарегистрироваться</button>
    </form>
  );
};
