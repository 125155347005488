import { DateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ruRU } from '@mui/x-date-pickers/locales';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

import React, { useState } from "react";
import {
  useCreateEducationalEventMutation,
  useStudyCenterEducationalEventQuery,
  useUpdateEducationalEventMutation,
} from "@lib/study-center";
import { useHistory, useParams } from "react-router-dom";

import { BodyNormal } from "@ui/fonts";
import { Button } from "@ui/button";
import Dropzone from "@ui/dropzone";
import { RichEditor } from "@ui/rich-editor";
import { handleApiError } from "@utils/handleApiError";
import moment, { Moment } from "moment";
import styles from "./create-educational-event.module.scss";

type CreateEventParams = {
  id?: string;
};

export const CreateEducationalEvent: React.FC = () => {
  const history = useHistory();
  const eventId = useParams<CreateEventParams>().id;

  const id = typeof eventId === "undefined" ? undefined : +eventId;
  const { event } = useStudyCenterEducationalEventQuery(id);

  const createEducationalEventMutation = useCreateEducationalEventMutation();
  const updateEducationalEventMutation = useUpdateEducationalEventMutation();

  const [errors, setErrors] = useState<Record<string, string>>({});
  const [title, setTitle] = useState(event?.title ?? "");
  const [date, setDate] = useState<Moment | null>(
    event?.date ? moment(event?.date) : moment()
  );
  const [description, setDescription] = useState(event?.description ?? "");
  const [shortDescription, setShortDescription] = useState(
    event?.shortDescription ?? ""
  );
  const [files, setFiles] = useState<File[]>([]);

  const isEdit = !history.location.pathname.endsWith("/create");

  const handleUploadFiles = (files: File[]) => {
    setFiles((prev) => [...prev, ...files]);
  };

  const handleDeleteFile = (file: File) => {
    setFiles((files) => files.filter((_file) => _file.name !== file.name));
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const onSuccess = () => {
      history.push("/study-center/materials/educational-events");
    };

    const onError = (error: unknown) => {
      handleApiError(error, setErrors);
    };

    if (isEdit) {
      updateEducationalEventMutation.mutate(
        {
          id,
          title,
          shortDescription,
          description,
          file: files[0],
          date: date?.toISOString(),
        },
        {
          onSuccess,
          onError,
        }
      );
    } else {
      createEducationalEventMutation.mutate(
        {
          title,
          shortDescription,
          description,
          file: files[0],
          date: date ? date.toISOString() : moment().toISOString(),
        },
        {
          onSuccess,
          onError,
        }
      );
    }
  };

  const handleContent = (content: string) => {
    setDescription(content);
  };

  return (
    <div className={styles.Event}>
      <form className={styles.Event__form} onSubmit={onSubmit}>
        <input
          placeholder="Название события или вебинара*"
          required
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className={styles.Event__input}
        />
        <input
          placeholder="Краткое описание события или вебинара*"
          required
          maxLength={255}
          value={shortDescription}
          onChange={(e) => setShortDescription(e.target.value)}
          className={styles.Event__input}
        />
        <LocalizationProvider
          dateAdapter={AdapterMoment}
          adapterLocale={"ru"}
          localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
        >
          <DateTimePicker
            value={date}
            disablePast
            onChange={setDate}
            label="Начало"
            format="DD.MM.YYYY HH:mm"
            autoFocus={false}
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
              seconds: renderTimeViewClock,
            }}
            slotProps={{
              actionBar: { actions: ['cancel', 'accept'] },
              textField: { variant: 'filled' }
            }}
            localeText={{
              toolbarTitle: '',
              cancelButtonLabel: 'Отменить',
              okButtonLabel: 'ОК'
            }}
          />
        </LocalizationProvider>
        <RichEditor
          label="Описание*"
          initialContent={description || ""}
          handleContent={handleContent}
        />
        <Dropzone
          files={files}
          maxFiles={1}
          withPreviews
          accept="image/*"
          onUpload={handleUploadFiles}
          onDeleteFile={handleDeleteFile}
        />
        {errors.upload && <BodyNormal color="red">{errors.upload}</BodyNormal>}
        <Button
          type="submit"
          className={styles.Event__submitBtn}
          disabled={
            updateEducationalEventMutation.isLoading ||
            createEducationalEventMutation.isLoading
          }
        >
          Сохранить
        </Button>
      </form>
    </div>
  );
};
