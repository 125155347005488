import {
  $RolesPermissions,
  $RolesPermissionsStates,
  $User,
  $UserAddPermissions,
  ACTIONS,
  UserPriorityEnum,
  changeRolePermission,
  fetchRolesPermissions,
  getPermissionName,
} from "@store/user-store";
import { Checkbox, FormControlLabel } from "@mui/material";
import { CheckboxCheckedSVG, CheckboxSVG } from "@components/svgs";
import { ErrorIndicator, Loader } from "@ui/indicators";
import { Fragment, memo, useEffect, useMemo, useState } from "react";

import { ColorfulButton } from "@components/common/common";
import RolesPermissionsService from "@services/roles-permissions-service";
import { UserDataT } from "@interfaces/user";
import clsx from "clsx";
import s from "@scss/pages/permissions.module.scss";
import { useLocation } from "react-router-dom";
import { useModal } from "@modals/index";
import { useStore } from "effector-react";
import { useSelectedCompanyQuery } from "@lib/company";

export const PermissionsRoles = memo(() => {
  const rolesNPermissions = useStore($RolesPermissions);
  const { isFetched, isLoading, error, isFetchedCompany } = useStore(
    $RolesPermissionsStates
  );
  const [selectedRole, setSelectedRole] = useState<any>(null);
  const permissions = useStore($UserAddPermissions);
  const user = useStore($User) as UserDataT;
  const location = useLocation();
  const IS_COMPANY_MODULE = location.pathname.includes("company");

  const { company } = useSelectedCompanyQuery();

  useEffect(() => {
    if (!selectedRole && rolesNPermissions?.roles[0]) {
      setSelectedRole({
        ...rolesNPermissions.roles[0],
        permissions: rolesNPermissions.roles[0].permissions.map(
          (perm: any) => perm.action_name
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolesNPermissions]);

  useEffect(() => {
    if (!company) return;

    const doesNeedToFetch =
      (!isFetchedCompany && !isLoading && IS_COMPANY_MODULE) ||
      (!isFetched && !isLoading && !IS_COMPANY_MODULE);

    if (doesNeedToFetch) {
      fetchRolesPermissions(
        IS_COMPANY_MODULE
          ? {
              exactCompany: true,
              companyId: company.id,
              priority: user.role.priority,
            }
          : { priority: user.role.priority }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  const rolesToRender = useMemo(() => {
    if (!rolesNPermissions) return null;
    const rolesByPriority: any = [];
    rolesNPermissions.roles.forEach((role) => {
      if (!rolesByPriority[role.priority]) rolesByPriority[role.priority] = [];
      rolesByPriority[role.priority].push(role);
    });
    return rolesByPriority.map((rolesGrop: any, index: number) => {
      return (
        <Fragment key={index}>
          <p className={s.level}>{index} уровень:</p>
          {rolesGrop.map((role: any) => {
            return (
              <button
                key={role.id}
                className={clsx(s.role, {
                  [s.role_selected]: role?.name === selectedRole?.name,
                })}
                onClick={() => changeRole(role)}
              >
                {role.name}
              </button>
            );
          })}
        </Fragment>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolesNPermissions, rolesNPermissions?.roles.length, selectedRole]);

  const { open } = useModal();

  if (error) return <ErrorIndicator />;
  if (isLoading) return <Loader />;
  if (
    (!isFetched && !IS_COMPANY_MODULE) ||
    (!isFetchedCompany && IS_COMPANY_MODULE)
  )
    return <Loader />;

  const changeRole = (role: any) => {
    setSelectedRole({
      ...role,
      permissions: role.permissions.map((perm: any) => perm.action_name),
    });
  };

  const changePermission = (permission: any) => {
    changeRolePermission({
      roleId: selectedRole.id,
      permission: permission,
    });
    RolesPermissionsService.ChangeRolePermissions(
      {
        roleId: selectedRole.id,
        permissionId: permission.id,
      },
      (err, res) => {
        if (res) {
          const existingPermission = selectedRole.permissions.find(
            (perm: any) => perm === permission.action_name
          );

          if (existingPermission) {
            const filteredPermissions = selectedRole.permissions.filter(
              (perm: any) => perm !== existingPermission
            );
            return setSelectedRole((prev: any) => ({
              ...prev,
              permissions: filteredPermissions,
            }));
          } else {
            return setSelectedRole((prev: any) => ({
              ...prev,
              permissions: [...prev.permissions, permission.action_name],
            }));
          }
        }
      }
    );
  };

  const filterPermissions = (permission: any) => {
    if (
      !permissions.hasRequiredLevel(
        UserPriorityEnum.SuperAdmin
      ) &&
      [
        ACTIONS.roles_levelFirstRoles_allowedToCreate,
        ACTIONS.roles_levelFirstRoles_allowedToEdit,
        ACTIONS.roles_levelFirstRoles_allowedToAssign,
      ].includes(permission.action_name)
    ) return false;
    if (
      !permissions.hasRequiredLevel(UserPriorityEnum.Admin) &&
      [
        ACTIONS.roles_levelFirstRoles_allowedToCreate,
        ACTIONS.roles_levelFirstRoles_allowedToEdit,
        ACTIONS.roles_levelFirstRoles_allowedToAssign,
        ACTIONS.roles_levelSecondRoles_allowedToCreate,
        ACTIONS.roles_levelSecondRoles_allowedToEdit,
        ACTIONS.roles_levelSecondRoles_allowedToAssign,
      ].includes(permission.action_name)
    ) return false;

    return true;
  }

  const groupsPermissions = (groups: any, permission: any) => {
    let title: string | null = null;
    let slug: string = permission.action_name.split('_')[0];
    switch(slug){
      case 'personalAccount':
        title = "Личный кабинет";
        break;
      case 'users':
        title = "Пользователи";
        break;
      case 'roles':
        title = "Роли";
        break;
      case 'companies':
        title = "Компании";
        break;
      case 'companyWorkspace':
        title = "Компания - рабочее пространство";
        break;
      case 'companyDocumentTemplates':
        title = "Компания - шаблоны документов";
        break;
      case 'companyStaffing':
        title = "Компания - штатное расписание";
        break;
      case 'companyEventsCalendar':
        title = "Компания - календарь событий";
        break;
      case 'companySpecialistWorkPlan':
        title = "Компания - план работы специалиста";
        break;
      case 'companyDocumentSigningControl':
        title = "Компания - контроль подписания документов";
        break;
      case 'companyPrescriptions':
        title = "Компания - предписания";
        break;
      case 'companyProcedureControls':
        title = "Компания - контроль выполнения процедур";
        break;
      case 'companyApplicationsForTraining':
        title = "Компания - заявки на обучение";
        break;
      case 'projectNews':
        title = "Новости проекта";
        break;
      case 'educationalCenter':
        title = "Учебный центр";
        break;
      case 'developmentNews':
        title = "Скоро в Личном кабинете";
        break;
      case 'legislationNews':
        title = "Новости законодательства";
        break;
      case 'usefulMaterials':
        title = "Полезные материалы";
        break;
      case 'responsibility':
        title = "Ответственность";
        break;
      case 'legalInformation':
        title = "Юридическая информация";
        break;
      case 'judicialPractice':
        title = "Судебная практика";
        break;
      case 'payment':
        title = "Оплата";
        break;
      case 'specialistHelp':
        title = "Помощь специалиста";
        break;
      case 'directories':
        title = "Справочники";
        break;
      case 'support':
        title = "Техподдержка";
        break;
      case 'questionAnswer':
        title = "Вопрос ответ";
        break;
      case 'documentTemplates':
        title = "Шаблоны документов";
        break;
      default:
        title = "Прочее (не распределено)";
    }
    if(!groups[slug]){
      groups[slug] = {
        title: title,
        slug: slug,
        permissions: [{...permission}]
      }
    }else{
      groups[slug].permissions.push(permission)
    }
    return groups;
  }

  return (
    <>
      {(permissions.hasRequiredLevel(UserPriorityEnum.Admin) ||
        permissions.hasPermission(ACTIONS.roles_allowedToShow)) && (
        <div className={s.content}>
          <div className={s.roles}>
            <ColorfulButton
              text={"Создать роль"}
              onClick={() =>
                open("CreateRoleModal", {
                  modalData: {
                    text: ``,
                    modalTitle: "Создание роли",
                    companyId: company?.id,
                  },
                  btnText: "Создать",
                })
              }
            />
            {rolesToRender}
          </div>
          <div className={s.devider}></div>
          <div className={s.permissions}>
            <p className="font-xl">
              {selectedRole?.name ? selectedRole.name : ""}
            </p>
            {selectedRole &&
              Object.values(rolesNPermissions?.permissions
                .filter((permission: any) => filterPermissions(permission))
                .reduce(groupsPermissions, []))
                .map((group: any, index: number) => {
                  return (
                    <div key={index}>
                      <div className="mt-30 mb-5 font-lg">{group.title}:</div>
                      {group.permissions && group.permissions.map((permission: any, index: number) => {
                          const included = selectedRole?.permissions[0]
                          ? selectedRole?.permissions.includes(permission.action_name)
                          : false;

                          return (
                            <div>
                              <FormControlLabel
                                key={index * Math.random()}
                                control={
                                  <Checkbox
                                    checked={included}
                                    name="isFinancial"
                                    color="primary"
                                    icon={<CheckboxSVG />}
                                    checkedIcon={<CheckboxCheckedSVG />}
                                    onChange={() => changePermission(permission)}
                                  />
                                }
                                label={getPermissionName(permission.action_name) || permission.action_name}
                              />
                            </div>
                          )
                        }
                      )}
                    </div>
                  )
                  // const included = selectedRole?.permissions[0]
                  //   ? selectedRole?.permissions.includes(permission.action_name)
                  //   : false;

                  // return (
                  //   <div key={permission.id}>
                  //     {title && (
                  //       <div className="mt-30 mb-5 font-lg">{title}:</div>
                  //     )}
                  //     <FormControlLabel
                  //       key={index * Math.random()}
                  //       control={
                  //         <Checkbox
                  //           checked={included}
                  //           name="isFinancial"
                  //           color="primary"
                  //           icon={<CheckboxSVG />}
                  //           checkedIcon={<CheckboxCheckedSVG />}
                  //           onChange={() => changePermission(permission)}
                  //         />
                  //       }
                  //       label={getPermissionName(permission.action_name) || permission.action_name}
                  //     />
                  //   </div>
                  // );
                }
              )}
          </div>
        </div>
      )}
    </>
  );
});
