import { FormControl, InputLabel, MenuItem } from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React, { useEffect, useState } from "react";

import clsx from "clsx";
import styles from "./drop-down-label.module.scss";
import { v4 as uuidv4 } from "uuid";

type List = {
  id: number | string;
  title: string;
};

type Props = {
  label?: string;
  styleLabel?: string;
  className?: string;
  list: List[];
  choice?: boolean;
  type: string;
  handlerChangeFilter: (key: string, value: string) => void;
  defaultValue?: string;
  disabled?: boolean;
  isGeneral?: boolean;
};

export const DropDownLabel = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      label,
      list,
      className,
      type,
      handlerChangeFilter,
      styleLabel,
      defaultValue,
      disabled,
      isGeneral,
    },
    ref
  ) => {
    const [itemType, setItemType] = useState<string>(
      defaultValue ?? list[0].id.toString()
    );
    const handleChangeSelect = (event: SelectChangeEvent<string>) => {
      setItemType(event.target.value as string);
    };

    useEffect(() => {
      if (itemType !== undefined) {
        if (
          isGeneral &&
          ["positions", "departments", "employees"].includes(type)
        ) {
          const findTitle = list.find((item) => item.id === itemType);
          handlerChangeFilter(
            type,
            findTitle?.title && findTitle.id !== 0 ? findTitle?.title : ""
          );
          return;
        }

        handlerChangeFilter(
          type,
          itemType.toString() === "0" ? "" : itemType.toString()
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemType]);

    return (
      <FormControl variant="filled">
        {!!label && (
          <InputLabel className={clsx(styles.label, styleLabel)}>
            {label}
          </InputLabel>
        )}
        <Select
          ref={ref}
          value={itemType}
          onChange={handleChangeSelect}
          className={clsx(!!className ? className : styles.select)}
          disabled={disabled}
        >
          {!!list.length &&
            list.map((item: List) => (
              <MenuItem key={uuidv4()} value={item.id}>
                <span className={styles.item}>{item.title}</span>
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
  }
);
