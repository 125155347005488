import { DateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ruRU } from '@mui/x-date-pickers/locales';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

import Select from "react-select";
import { File, Image } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

import { BreadCrumb } from "@components/common";
import { Button } from "@ui/button";
import Dropzone from "@ui/dropzone";
import { RichEditor } from "@ui/rich-editor";
import { TextField } from "@mui/material";
import {
  selectColourStyles,
  selectTheme,
  Title,
} from "@components/common/common";
import { WrapperSVG } from "@components/WrapperSVG/WrapperSVG";
import clsx from "clsx";
import style from "@scss/pages/news-and-practice/news-and-practice.module.scss";
import useStyles from "@ui/material-ui-styles";
import moment from "moment";
import { useForm, Controller } from "react-hook-form";
import { getNewsArticleBreadcrumbs } from "@utils/news";
import {
  useCreateProjectNewsMutation,
  useProjectNewsItemQuery,
  useUpdateProjectNewsMutation,
} from "@lib/project-news";

const COLOR_OPTIONS = [
  { value: "", label: "Без цвета" },
  { value: "#0f9d58", label: "Зелёный" },
  { value: "#F4B400", label: "Жёлтый" },
  { value: "#FFA500", label: "Оранжевый" },
  { value: "#DB4437", label: "Красный" },
  { value: "#4285F4", label: "Синий" },
];

type FormFields = {
  title: string;
  text: string;
  tags: string;
  color: string;
  createdAt: string;
};

type CreateProjectNewsPropsT = {
  mode: "create" | "edit";
};

const CreateProjectNews: React.FC<CreateProjectNewsPropsT> = ({ mode }) => {
  const { pathname } = useLocation();
  const query = useRouteMatch<{ id: string }>();
  const history = useHistory();
  const classes = useStyles();

  const createNewsMutation = useCreateProjectNewsMutation();
  const updateNewsMutation = useUpdateProjectNewsMutation();

  const { data: currentItem, isLoading } = useProjectNewsItemQuery(
    query.params.id
  );

  const [uploadedImages, setUploadedImages] = useState<File[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [uploadError, setUploadError] = useState<string>();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormFields>({
    values: {
      title: currentItem?.title ?? "",
      text: currentItem?.text ?? "",
      tags: currentItem?.tags.join(",") ?? "",
      color: currentItem?.color ?? "",
      createdAt: currentItem?.createdAt ?? "",
    },
    defaultValues: {
      createdAt: currentItem?.createdAt ?? moment().toISOString(),
    },
  });

  const breadcrumbs = getNewsArticleBreadcrumbs("PROJECT-NEWS");

  useEffect(() => {
    if (isLoading) return;

    if (mode === "edit" && !currentItem) {
      return history.push("/project-news/" + query.params.id);
    }
  }, [currentItem, history, isLoading, mode, query.params.id]);

  const onSubmit = handleSubmit((data) => {
    const formData = new FormData();
    formData.append("title", data.title);
    if (mode === "create" || data.text.length !== 0) {
      formData.append("text", data.text);
    }
    data.tags.split(",").forEach((tag) => {
      formData.append("tags", tag.trim());
    });

    formData.append("color", data.color);

    if (data.createdAt) {
      formData.append("createdAt", data.createdAt);
    }

    if (mode === "create") {
      if (uploadedImages.length === 0) {
        return setUploadError("Обязательное поле");
      }

      uploadedImages.forEach((file) => formData.append("images", file));
      uploadedFiles.forEach((file) => formData.append("files", file));
    }

    if (mode === "edit") {
      if (!currentItem) return;

      updateNewsMutation.mutate(
        {
          id: currentItem._id,
          data: formData,
        },
        {
          onSuccess: () => {
            history.goBack();
          },
        }
      );
    } else {
      createNewsMutation.mutate(formData, {
        onSuccess: () => {
          history.goBack();
        },
      });
    }
  });

  const canRenderEditor = () => {
    if (mode === "edit") {
      return !!currentItem;
    }

    return mode === "create";
  };

  return (
    <main className="content-container">
      <div className="content-section">
        <div className="top-content">
          <BreadCrumb items={breadcrumbs} />
          <div className="content-title-section-with-btn">
            <Title
              text={
                pathname.includes("create")
                  ? "Создание новости"
                  : "Изменение новости"
              }
              withHistory
            />
          </div>
        </div>
        <div className={clsx(style.create_news_container)}>
          <form
            key={isLoading + ""}
            className={classes.root}
            onSubmit={onSubmit}
          >
            <Controller
              control={control}
              name="title"
              rules={{
                required: "Обязательное поле",
              }}
              render={({ field }) => (
                <TextField
                  label="Название новости"
                  variant="filled"
                  placeholder="Название новости"
                  error={!!errors.title}
                  helperText={errors.title?.message}
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="tags"
              render={({ field }) => (
                <TextField
                  label="Теги (через запятую)"
                  variant="filled"
                  placeholder="Через запятую добавьте теги"
                  error={!!errors.tags}
                  helperText={errors.tags?.message}
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="color"
              render={({ field }) => (
                <Select
                  noOptionsMessage={() => "Ничего не найдено"}
                  options={COLOR_OPTIONS}
                  placeholder="Цвет новости"
                  styles={selectColourStyles()}
                  theme={selectTheme}
                  menuPlacement="auto"
                  menuPortalTarget={document.body}
                  getOptionValue={(item: { value: any; }) => item.value}
                  getOptionLabel={(item: { label: any; }) => item.label}
                  isOptionSelected={(item: { value: string; }) => item.value === field.value}
                  {...field}
                  value={COLOR_OPTIONS.find(
                    (item) => item.value === field.value
                  )}
                  onChange={(item: { value: any; }) => field.onChange(item.value)}
                />
              )}
            />
            <Controller
              control={control}
              name="createdAt"
              render={({ field, formState }) => (
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  adapterLocale={"ru"}
                  localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
                >
                  <DateTimePicker
                    value={moment(field.value) ?? moment(formState.defaultValues?.createdAt)}
                    disablePast
                    onChange={(date) => field.onChange(date)}
                    label="Дата"
                    format="DD.MM.YYYY HH:mm"
                    autoFocus={false}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    slotProps={{
                      actionBar: { actions: ['cancel', 'accept'] },
                      textField: {
                        variant: 'filled',
                        helperText: errors.createdAt?.message,
                        error: !!errors.createdAt
                      },
                    }}
                    localeText={{
                      toolbarTitle: '',
                      cancelButtonLabel: 'Отменить',
                      okButtonLabel: 'ОК',
                    }}
                  />
                </LocalizationProvider>
              )}
            />
            <>
              {mode === "create" && (
                <>
                  <p className={clsx(style.create_news_field_title)}>
                    <WrapperSVG color="green" cursor={false}>
                      <Image size={24} />
                    </WrapperSVG>
                    Загрузить фото *
                  </p>

                  <p className={clsx(style.create_news_field_desc)}>
                    Допускается только формат изображения!
                  </p>
                  <Dropzone
                    widthFull={true}
                    accept="image/*"
                    maxFiles={64}
                    typeFile={"image"}
                    onUpload={(files) => {
                      setUploadError(undefined);
                      setUploadedImages(files);
                    }}
                  />
                  {uploadError && <p className="error-text">{uploadError}</p>}
                </>
              )}
              {canRenderEditor() && (
                <Controller
                  control={control}
                  name="text"
                  rules={{
                    required: "Обязательное поле",
                  }}
                  render={({ field }) => (
                    <RichEditor
                      initialContent={currentItem?.text ?? ""}
                      handleContent={field.onChange}
                      label="Текст новости *"
                      error={errors.text?.message}
                    />
                  )}
                />
              )}
              {mode === "create" && (
                <>
                  <p className={clsx(style.create_news_field_title)}>
                    <WrapperSVG color="green" cursor={false}>
                      <File size={24} />
                    </WrapperSVG>
                    Загрузить файлы для скачивания
                  </p>
                  <Dropzone
                    widthFull={true}
                    maxFiles={64}
                    onUpload={(files) => setUploadedFiles(files)}
                  />
                </>
              )}
            </>
            {/* TODO: отключилючено до востребования */}
            {/* <p className="error-text">{validation.textError}</p> */}
            {/* <p className="error-text">{validation.processError}</p> */}
            <Button type="submit" className={clsx(style.create_news_btn)}>
              {mode === "edit" ? "Сохранить" : "Создать"}
            </Button>
          </form>
        </div>
      </div>
    </main>
  );
};

export default CreateProjectNews;
