import MenuItem from '@mui/material/MenuItem';
import { useCreateWorkspaceDocumentMutation } from "@lib/workspace";

import React from "react";
import { TextField } from "@mui/material";
import qs from "qs";
import { useHistory } from "react-router-dom";
import useModal from "@modals/modal-hook";
import { useStore } from "effector-react";
import useStyles from "@ui/material-ui-styles";
import { useSelectedCompanyQuery } from "@lib/company";

const CreateWorkspaceDocumentModal = () => {
  const { company } = useSelectedCompanyQuery();

  const createWorkspaceDocumentMutation = useCreateWorkspaceDocumentMutation();

  const { modalComponent, modalData, close, open } = useModal();
  const classes = useStyles();
  const history = useHistory();

  const onLoadComponentError = () => {

  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!company) return;

    const documentTitle = document.querySelector('input[name="documentTitle"]') as HTMLInputElement;
    const documentType = document.querySelector('input[name="documentType"]') as HTMLInputElement;
    const modalBtn = document.querySelector("button.modal_btn") as HTMLButtonElement;
    modalBtn.disabled = true;

    // CREATING FOLDER
    if (documentTitle?.value && documentType?.value && !modalData?.item?.id) {
      const queryString = qs.parse(history.location.search, {
        ignoreQueryPrefix: true,
      });

      return createWorkspaceDocumentMutation.mutate(
        {
          companyId: company.id,
          groupId: modalData.groupId,
          directoryId: queryString.folder_id ? +queryString.folder_id : 0,
          name: documentTitle.value,
          type: documentType.value,
        },
        {
          onSuccess: (file) => {
            close();
            setTimeout(() => {
              open("DocumentEditorModal", {
                btnText: "Сохранить",
                modalData: {
                  file: {
                    type: file.path.split('.')[1].toLocaleLowerCase(),
                    name: file.name,
                    path: `uploads/companies/${company.id}/workspace/${file.path}`,
                    key: `${file.path}_${company.id}`,
                  },
                  onLoadComponentError: onLoadComponentError
                },
              });
            }, 500);
          },
        }
      );
    }
  };

  return (
    <div key={modalComponent.key}>
      <p className="modal_title">{modalData.modalTitle}</p>
      <div className="underline" />
      <form className={`modal_content ${classes.root}`} onSubmit={handleSubmit}>
        <TextField
          label={"Название документа"}
          name={"documentTitle"}
          variant="filled"
          required
          defaultValue={modalData.itemTitle}
        />
        <TextField
          label={"Тип документа"}
          name={"documentType"}
          variant="filled"
          required
          select
          defaultValue="docx"
        >
          <MenuItem value="docx">Word</MenuItem>
          <MenuItem value="xlsx">Excel</MenuItem>
        </TextField>
        <button type="submit" className="modal_btn">
          {modalComponent.btnText}
        </button>
      </form>
    </div>
  );
};

export default CreateWorkspaceDocumentModal;
