import { FC } from "react";
import clsx from "clsx";
import { Center, Loader, Text } from "@mantine/core";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  MedicalExamControlsBody,
  MedicalExamControlsT,
} from "@interfaces/medical-examination";
import { UseSortingResult } from "@hooks/useSorting";
import styles from "./medical-exam-controls-table.module.scss";
import { ArrowDropDownGraySVG } from "@components/svgs";
import { MedicalExamControlsTableRow } from "./medical-exam-controls-table-row";

export type MedicalExamControlsTablePropsT = {
  data: MedicalExamControlsT["body"];
  isLoading: boolean;
  sorting: UseSortingResult;
  offset: number;
  onUpdateLastDate: (
    row: MedicalExamControlsBody,
    lastDate: string | null
  ) => void;
  withCompany?: boolean;
  className?: string;
};

export const MedicalExamControlsTable: FC<MedicalExamControlsTablePropsT> = ({
  data,
  isLoading,
  sorting,
  offset,
  onUpdateLastDate,
  withCompany = false,
  className,
}) => {
  if (isLoading) {
    return (
      <Center h={500}>
        <Loader />
      </Center>
    );
  }

  if (data.length === 0) {
    return (
      <Text mt={40} size="xl" align="center" color="gray">
        Нет данных
      </Text>
    );
  }

  return (
    <div className={clsx(styles.table, className)}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={25} align="center">
              №
            </TableCell>
            <HeadCell
              label="Дата прохождения МО"
              sortingLabel="lastDateOrder"
              sorting={sorting}
              className={styles.table__lastDate}
            />
            {withCompany && (
              <HeadCell
                label="Организация"
                sortingLabel="companyNameOrder"
                sorting={sorting}
                className={styles.table__companyName}
              />
            )}
            <HeadCell
              label="ФИО"
              sortingLabel="employeeNameOrder"
              sorting={sorting}
              className={styles.table__employeeName}
            />
            <HeadCell
              label="Должность"
              sortingLabel="positionTitleOrder"
              sorting={sorting}
              className={styles.table__position}
            />
            <HeadCell
              label="Подразделение"
              sortingLabel="departmentTitleOrder"
              sorting={sorting}
              className={styles.table__department}
            />
            <HeadCell
              label="Вредный фактор"
              sortingLabel="factorTitleOrder"
              sorting={sorting}
              className={styles.table__factor}
            />
            <HeadCell
              label="Периодичность прохождения"
              sortingLabel="daysBeforeNextDateOrder"
              sorting={sorting}
            />
            <HeadCell
              label="Дата следущего МО"
              sortingLabel="nextDateOrder"
              sorting={sorting}
              className={styles.table__nextDate}
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, i) => (
            <MedicalExamControlsTableRow
              key={row.id}
              row={row}
              number={i + offset + 1}
              withCompany={withCompany}
              onUpdateLastDate={onUpdateLastDate}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

const HeadCell: FC<{
  label: string;
  sortingLabel: string;
  sorting: UseSortingResult;
  className?: string;
}> = ({ label, sortingLabel, sorting, className }) => {
  return (
    <TableCell className={className}>
      <div
        className={styles.table__headCell}
        onClick={() => sorting.onItemSortingChange(sortingLabel)}
      >
        <div>{label}</div>
        <SortingIcon rotate={sorting.shouldIconRotate(sortingLabel)} />
      </div>
    </TableCell>
  );
};

const SortingIcon: FC<{ rotate: boolean }> = ({ rotate }) => {
  return (
    <div
      className={clsx(styles.table__sortIcon, {
        [styles.table__sortIcon_rotated]: rotate,
      })}
    >
      <ArrowDropDownGraySVG />
    </div>
  );
};
