import { FC } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Modal } from "@ui/modal";
import { useEmployeeMedicalExamReferralQuery } from "@lib/medical-examination";
import moment from "moment";
import { Center, Loader } from "@mantine/core";
import styles from "./medical-exam-referral-modal.module.scss";
import { BodyBold } from "@ui/fonts";

export type MedicalExamReferralModalPropsT = {
  isOpen: boolean;
  companyId: number;
  employeeId: number;
  isNewReferral: boolean;
  onClose: () => void;
};

export const MedicalExamReferralModal: FC<MedicalExamReferralModalPropsT> = ({
  isOpen,
  companyId,
  employeeId,
  isNewReferral,
  onClose,
}) => {
  const { data, isLoading } = useEmployeeMedicalExamReferralQuery({
    companyId,
    employeeId,
    isNew: isNewReferral,
  });

  const examType = data?.referral.isPreliminary
    ? "предварительный"
    : "периодический";

  const factors = data?.factors
    ? Object.keys(data.factors).map((factor) => ({
        title: factor,
        value: data.factors[factor],
      }))
    : [];

  return (
    <Modal open={isOpen} onClose={onClose} className={styles.modal}>
      {isLoading || !data ? (
        <Center h={200}>
          <Loader />
        </Center>
      ) : (
        <div>
          <BodyBold weight="bold" className={styles.modal__title}>
            Направление на {examType} медицинский осмотр (обследование) №
            {data.referral.number} от "{data.referral.day}"{" "}
            {data.referral.monthName} {data.referral.year} г.
          </BodyBold>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>ФИО</TableCell>
                <TableCell>{data?.employee.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Дата рождения</TableCell>
                <TableCell>
                  {data.employee.birthdate
                    ? moment(data.employee.birthdate).format("DD.MM.YYYY")
                    : "Не указано"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Пол</TableCell>
                <TableCell>{data.employee.sex ?? "Не указано"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Структурное подразделение</TableCell>
                <TableCell>{data.employee.departmentName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Должность (профессия) или вид работы</TableCell>
                <TableCell>{data.employee.positionName}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          {factors.map((factor, i) => (
            <Table key={i} className={styles.modal__factorsTable}>
              <TableHead>
                <TableCell colSpan={2} width={280}>
                  {factor.title}
                </TableCell>
              </TableHead>
              <TableBody>
                {factor.value.map((v, i) => (
                  <TableRow key={i}>
                    <TableCell colSpan={2}>{v}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ))}
        </div>
      )}
    </Modal>
  );
};
