import { $UserAddPermissions, ACTIONS } from "@store/user-store";
import { TableCell, TableRow } from "@mui/material";

import { BodyBold } from "@ui/fonts";
import { Button } from "@ui/button";
import { VersionsAllT } from "@interfaces/directory/version";
import { memo } from "react";
import moment from "moment";
import styles from "./row-version.module.scss";
import { useStore } from "effector-react";

type RowVersionProps = {
  change: VersionsAllT;
  disabled: boolean;
  isRecoveryId: (id: number) => void;
};

export const RowVersion: React.FC<RowVersionProps> = memo(
  ({ change, disabled, isRecoveryId }) => {
    const permissions = useStore($UserAddPermissions);
    return (
      <TableRow>
        <TableCell align="left">
          <div className={styles.table_row_center}>{change.name}</div>
        </TableCell>
        <TableCell align="left">
          <div className={styles.table_row_center}>
            {moment(change.createdAt).format("D MMM YYYYг., HH:mm")}
          </div>
        </TableCell>
        <TableCell align="center">
          <>
            {permissions.hasPermission(
              ACTIONS.directories_allowedToReverseChanges
            ) && (
              <div className={styles.table_row_center}>
                <Button
                  color={"white-green"}
                  onClick={() => isRecoveryId(change.id)}
                  borderColor={"green"}
                  disabled={disabled}
                >
                  <BodyBold>Отменить изменения</BodyBold>
                </Button>
              </div>
            )}
          </>
        </TableCell>
      </TableRow>
    );
  }
);
