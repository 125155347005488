import { BodyLight } from "@ui/fonts";
import { SingleTrainingRequestStudent } from "@interfaces/request-training";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Trash } from "@phosphor-icons/react";
import clsx from "clsx";
import styles from "./user-training-body-row.module.scss";

type UserTrainingBodyRowPropsT = {
  index: number;
  programTitle: string;
  student: SingleTrainingRequestStudent;
  isEditable: boolean;
  onDelete: () => void;
};

export const UserTrainingBodyRow: React.FC<UserTrainingBodyRowPropsT> = ({
  index,
  programTitle,
  student,
  isEditable,
  onDelete,
}) => {
  return (
    <TableRow>
      <TableCell align="left">
        <div className={clsx(styles.table_row_center)}>
          <BodyLight>{++index}</BodyLight>
        </div>
      </TableCell>
      <TableCell align="left">
        <div className={styles.table_row_center}>
          <BodyLight className={styles.table_row_cellText}>
            {student.companyName}
          </BodyLight>
        </div>
      </TableCell>
      <TableCell align="left">
        <div className={styles.table_row_center}>
          <BodyLight>{student.companyInn}</BodyLight>
        </div>
      </TableCell>
      <TableCell align="left">
        <div className={styles.edit_and_save}>
          <div
            className={clsx(styles.table_row_center, styles.table_row_title)}
          >
            <BodyLight>{student.employeeName}</BodyLight>
          </div>
        </div>
      </TableCell>
      <TableCell align="left">
        <div className={styles.table_row_center}>
          <BodyLight>{student.employeeSnils}</BodyLight>
        </div>
      </TableCell>
      <TableCell align="left">
        <div className={styles.table_row_center}>
          <BodyLight>{student.positionTitle}</BodyLight>
        </div>
      </TableCell>
      <TableCell align="left">
        <div className={styles.table_row_center}>
          <BodyLight>{student.employeeEmail}</BodyLight>
        </div>
      </TableCell>
      <TableCell align="left">
        <div className={styles.table_row_center}>{student.cause}</div>
      </TableCell>
      <TableCell align="left">
        <div className={styles.table_row_center}>{programTitle}</div>
      </TableCell>
      {isEditable && (
        <TableCell>
          <div className={styles.table_row_center}>
            <div tabIndex={0} onClick={onDelete} className={styles.delete_btn}>
              <Trash size={24} />
            </div>
          </div>
        </TableCell>
      )}
    </TableRow>
  );
};
