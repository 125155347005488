import {
  $Employees,
  $EmployeesStates,
  setEmployees,
  setEmployeesLoading,
} from "@store/company/employees-store";
import {
  $ImportEmployees,
  removeMissingEmployee,
} from "@store/company/import-employees-store";
import { $Departments, setDepartments } from "@store/company/departments-store";
import {
  $UserAddPermissions,
  ACTIONS,
  UserPriorityEnum,
} from "@store/user-store";
import { Archive, NotePencil } from "@phosphor-icons/react";
import {
  EmployeeListDataT,
  EmployeeTablePropsT,
} from "@interfaces/company/employees";
import { ErrorIndicator, Loader } from "@ui/indicators";
import React, { useEffect, useMemo, useState } from "react";

import { BodyNormal } from "@ui/fonts";
import { Button } from "@ui/button";
import EmployeeService from "@services/employee-service";
import { Tooltip } from "@mui/material";
import { WarnSVG } from "@components/svgs";
import { WrapperSVG } from "@components/WrapperSVG/WrapperSVG";
import clsx from "clsx";
import { getTextExcerpt } from "@utils/common-utils";
import { pushToArchiveEmployees } from "@store/company/archive-store";
import style from "@scss/pages/company/employees.module.scss";
import tableStyle from "@scss/components/tables/base-table.module.scss";
import { useAsyncConfirm } from "@hooks/useAsyncConfirm";
import { useChoiceState } from "@hooks/useChoiceState";
import { useHistory } from "react-router-dom";
import { usePostDocumentsQuery } from "@lib/request-training";
import { useStore } from "effector-react";

const EmployeesTable: React.FC<EmployeeTablePropsT> = ({
  departmentId,
  idsPostDocuments,
  items,
  setItems,
  isStatusPostDocumets,
}) => {
  const departments = useStore($Departments);
  const permissions = useStore($UserAddPermissions);
  const employees = useStore($Employees);
  const { isLoading, error } = useStore($EmployeesStates);
  const { missingEmployees } = useStore($ImportEmployees);

  const [renderingEmployees, setRenderingEmployees] = useState(
    typeof items !== "undefined" ? items : employees
  );

  const history = useHistory();
  const confirm = useAsyncConfirm();

  const {
    selectedItems,
    areAllItemsSelected,
    selectById,
    selectAll,
    clearSelectedItems,
    getIsItemSelected,
  } = useChoiceState(renderingEmployees ?? [], (item) => item.id);

  const usePostDocuments = usePostDocumentsQuery();
  const handleEmployeeId = (id: number) => {
    usePostDocuments.mutate(id, {
      onSuccess: () => {
        setEmployees(
          renderingEmployees?.filter((employee) => {
            if (id === employee.id) {
              employee.status = "Документы отправлены";
              return employee;
            }
            return employee;
          }) ?? []
        );
      },
    });
  };

  const idsPostList = useMemo(() => {
    return (
      selectedItems
        .filter((employee) => employee.status !== "Документы отправлены")
        .map((employee) => employee.id) ?? []
    );
  }, [selectedItems]);

  useEffect(() => {
    idsPostDocuments?.(idsPostList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idsPostList]);

  const handleLeaveEmployee = (id: number) => removeMissingEmployee(id);

  useEffect(() => {
    if (isStatusPostDocumets) {
      selectAll(false);
      Object.entries(isStatusPostDocumets).map(([responseId, status]) => {
        setEmployees(
          renderingEmployees?.filter((employee) => {
            if (+responseId === employee.id) {
              employee.status = "Документы отправлены";
              return employee;
            }
            return employee;
          }) ?? []
        );

        return null;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStatusPostDocumets]);

  useEffect(() => {
    if (typeof items !== "undefined") {
      if (items !== null) {
        setRenderingEmployees(items);
        setEmployeesLoading(false);
      }
      return;
    }
    setRenderingEmployees(employees);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employees]);

  useEffect(() => {
    if (items) {
      setRenderingEmployees(items);
    }
  }, [items]);

  const handleAddToArchive = (
    employee: EmployeeListDataT,
    removeEmployee: boolean
  ) => {
    return new Promise((resolve, reject) => {
      EmployeeService.AddToArchive(employee.id, (err, res) => {
        if (err) {
          reject(err);
          return console.error(
            "При добавлении пользователя в архив произошла ошибка"
          );
        }

        if (items && setItems) {
          setItems(
            items.filter(
              (departmentEmployee) => departmentEmployee.id !== employee.id
            )
          );
        }

        if (removeEmployee) {
          removeMissingEmployee(employee.id);
        }

        setDepartments(
          departments.map((department) => {
            if (department.id === employee?.position?.department?.id) {
              department.employeesCount--;
            }
            return department;
          })
        );
        pushToArchiveEmployees(employee);
        setEmployees(employees.filter((empl) => empl.id !== employee.id));

        resolve(res?.data);
      });
    });
  };

  const handleArchiveEmployees = (employeesToArchive: EmployeeListDataT[]) => {
    return new Promise((resolve, reject) => {
      EmployeeService.ArchiveEmployees(
        employeesToArchive.map((employee) => employee.id),
        true,
        (err, res) => {
          if (err) {
            reject(err);
            return console.error(
              "При добавлении пользователей в архив произошла ошибка"
            );
          }

          if (items && setItems) {
            setItems(
              items.filter(
                (departmentEmployee) =>
                  !employeesToArchive.find(
                    (employee) => departmentEmployee.id === employee.id
                  )
              )
            );
          }

          setDepartments(
            departments.map((department) => {
              const isEmployeeDepartment = !!employeesToArchive.find(
                (employee) => employee.position.department.id === department.id
              );
              if (isEmployeeDepartment) {
                department.employeesCount--;
              }
              return department;
            })
          );

          setEmployees(
            employees.filter(
              (empl) =>
                !employeesToArchive.find((employee) => empl.id === employee.id)
            )
          );

          for (const archivedEmployee of employeesToArchive) {
            pushToArchiveEmployees(archivedEmployee);
          }

          resolve(res?.data);
        }
      );
    });
  };

  const handleArchiveSelectedEmployees = async () => {
    const ok = await confirm({
      modalData: {
        text: "Вы уверены, что хотите отправить в архив выбранных сотрудников?",
      },
    });
    if (!ok) return;

    handleArchiveEmployees(selectedItems);

    clearSelectedItems();
  };

  // TODO: move this to a separate component
  const tableBodyContent = renderingEmployees?.map((employee) => {
    const isSelected = getIsItemSelected(employee);
    const firstRow = (
      <label htmlFor={`key`} className={clsx(tableStyle.column_fixed_height)}>
        <input
          type="checkbox"
          className={clsx(tableStyle.checkbox_item)}
          hidden
          checked={isSelected}
          name=""
          onChange={() => undefined}
          id={`key`}
        />
        <label
          htmlFor={`key`}
          onClick={() => selectById(employee, !isSelected)}
        >
          <svg
            className="w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 13l4 4L19 7"
            />
          </svg>
        </label>
        <label
          htmlFor={`key`}
          className={clsx(tableStyle.checkbox_label)}
          onClick={() => history.push(`/company/employees/${employee.id}`)}
        >
          {employee.name}
        </label>
      </label>
    );

    return (
      <tr key={employee.id} className={style.row}>
        <td>{firstRow}</td>
        <td>
          {getTextExcerpt(employee.position?.department?.title ?? "", 35)}
        </td>
        <td>{getTextExcerpt(employee.position?.title ?? "", 35)}</td>
        <td className={style.warning}>
          {!employee.isDataFilled && (
            <div>
              <WarnSVG />
              <span>
                Пожалуйста, заполните персональные данные по сотруднику
              </span>
            </div>
          )}
        </td>
        <td className={style.options}>
          <Button
            className={style.options_statusAction}
            onClick={() => handleEmployeeId(employee.id)}
            disabled={employee.status !== "Отправить документы"}
          >
            {employee.status}
          </Button>
          <div className={style.options_buttons}>
            {!permissions.hasRequiredLevel(UserPriorityEnum.Client) ? null : (
              <div className={clsx(style.employee_control_col)}>
                {missingEmployees.includes(employee.id) && (
                  <div className={style.importActions}>
                    <BodyNormal
                      color="red"
                      className={style.importActions__text}
                    >
                      Сотрудника нет в списке
                    </BodyNormal>
                    <Button onClick={() => handleLeaveEmployee(employee.id)}>
                      Оставить
                    </Button>
                    <Button
                      color="white"
                      border
                      borderColor="green"
                      onClick={() => handleAddToArchive(employee, true)}
                    >
                      В архив
                    </Button>
                  </div>
                )}
                {permissions.hasPermission(
                  ACTIONS.companyStaffing_employees_allowedToEdit
                ) && (
                  <WrapperSVG color={"orange"} margin>
                    <Tooltip title="Изменить" placement="top">
                      <NotePencil
                        size={24}
                        onClick={() =>
                          history.push(`/company/employees/${employee.id}`)
                        }
                      />
                    </Tooltip>
                  </WrapperSVG>
                )}

                {permissions.hasPermission(
                  ACTIONS.companyStaffing_employees_allowedToArchive
                ) && (
                  <WrapperSVG color="red">
                    <Tooltip title="В архив" placement="top">
                      <Archive
                        size={24}
                        onClick={() => handleAddToArchive(employee, false)}
                      />
                    </Tooltip>
                  </WrapperSVG>
                )}
              </div>
            )}
          </div>
        </td>
      </tr>
    );
  });

  return (
    <div
      className={clsx(tableStyle.base_table_container, style.employee_table)}
    >
      {isLoading ? (
        <Loader />
      ) : error ? (
        <ErrorIndicator />
      ) : (
        <table className={clsx(tableStyle.base_table)}>
          <thead>
            <tr>
              <td>
                <label>
                  <input
                    type="checkbox"
                    className={clsx(tableStyle.checkbox_item)}
                    hidden
                    name=""
                    checked={areAllItemsSelected}
                    onChange={() => undefined}
                  />
                  <label onClick={() => selectAll(!areAllItemsSelected)}>
                    <svg
                      className="w-5 h-5"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </label>
                  <label className={clsx(tableStyle.checkbox_label)}>
                    Сотрудник
                  </label>
                </label>
              </td>
              <td>Подразделение</td>
              <td>Должность</td>
              <td />
              <td className={style.selectedItemsActions}>
                {selectedItems.length > 0 &&
                  permissions.hasPermission(
                    ACTIONS.companyStaffing_employees_allowedToArchive
                  ) && (
                    <span className={style.selectedItemsActions__inner}>
                      <Tooltip title="В архив" placement="top">
                        <WrapperSVG color="red">
                          <Archive
                            size={24}
                            onClick={handleArchiveSelectedEmployees}
                          />
                        </WrapperSVG>
                      </Tooltip>
                    </span>
                  )}
              </td>
            </tr>
          </thead>
          <tbody>{tableBodyContent}</tbody>
        </table>
      )}
    </div>
  );
};

export default EmployeesTable;
