import { FC, HTMLAttributes, memo } from "react";
import { ThemeProvider, createTheme } from '@mui/material/styles';

import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

const theme = createTheme({
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 32,
        },
        flexContainer: {
          border: "2px solid #CED1D6",
          borderRadius: "50px",
          width: "calc(100% - 6px)",
          marginRight: "2px",
        },
        indicator: {
          minHeight: 36,
          marginLeft: "-2px",
          backgroundColor: "#00B856",
          borderRadius: "35px",
          paddingLeft: 100,
        },
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: 32,
          textTransform: "unset",
          fontFamily: "Source Sans Pro",
          fontSize: 18,
          lineHeight: 24,
          '&.Mui-selected': {
            backgroundColor: "#00000000",
            zIndex: 1000,
            color: "#ffffff",
            maxHeight: 32,
            minHeight: 32,
          }
        },
        textColorInherit: {
          color: "#000000",
        },
        wrapped: {
          minHeight: 32
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          maxHeight: 32,
          minHeight: 32,
        },
      }
    },
  }
});

type Props = Omit<HTMLAttributes<HTMLInputElement>, "onChange"> & {
  onChange: (value: number) => void;
  value?: number;
  labels: string[];
};

export const TabSwicher: FC<Props> = memo(({ onChange, value = 0, labels }) => {
  return (
    <ThemeProvider theme={theme}>
      <div>
        <Tabs value={value} onChange={(e, index) => onChange(index)}>
          {labels.map((label) => (
            <Tab key={label} label={label} />
          ))}
        </Tabs>
      </div>
    </ThemeProvider>
  );
});
