import {
  ArrowDropDownGraySVG,
  CheckboxCheckedSVG,
  CheckboxSVG,
} from "@components/svgs";
import { useEffect, useState } from "react";

import { Checkbox } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { SELECTS_ID } from "@store/company/procedure-instrunctions-store";
import Select from "@mui/material/Select";
import clsx from "clsx";
import style from "./drop-down-multi-select.module.scss";

type SelectListType = {
  id: number;
  title: string;
};

type PropsDropDownMultiSelect = {
  title?: string;
  typeStyle?: "input";
  defaultTitle?: string;
  direction: { id: number; title: string }[];
  handlerChange?: (value: string) => void;
  handlerList?: (values: SelectListType[]) => void;
  reset?: boolean;
  handlerReset?: () => void;
  selectOnly?: number;
  typeSelect?: string;
  // TODO: убрать этот проп и поменять SELECTS_ID везде с 99999 на -1
  allId?: number;
};

export const DropDownMultiSelect: React.FC<PropsDropDownMultiSelect> = ({
  title,
  typeSelect,
  reset = false,
  handlerReset,
  typeStyle,
  defaultTitle,
  direction,
  handlerChange,
  handlerList,
  selectOnly,
  allId = SELECTS_ID,
}) => {
  const [ids, setIds] = useState<number[]>([allId]);
  const [fullListID, setFullListID] = useState<number[]>([]);
  const [valueInput, setValueInput] = useState<string>(title ?? "");
  const [upadteProgram, setUpadteProgram] = useState<SelectListType[]>([]);
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    if (selectOnly) {
      setIds([selectOnly]);

      const updatedList = direction.filter(
        (item) => item.id !== allId && selectOnly === item.id
      );
      handlerList?.(updatedList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOnly]);
  useEffect(() => {
    if (reset) {
      handlerList?.(direction);
      setIds(fullListID);
      handlerReset && handlerReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  useEffect(() => {
    const updatedList = direction.filter(
      (item) => item.id !== allId && ids.includes(item.id)
    );
    setUpadteProgram(updatedList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ids]);

  useEffect(() => {
    if (title) {
      setValueInput(title);
    }
  }, [title]);

  useEffect(() => {
    const arr: number[] = [];
    direction.map((item, _) => arr.push(item.id));
    setFullListID(arr);
    const updatedList = direction.filter(
      (item) => item.id !== allId && arr.includes(item.id)
    );
    setUpadteProgram(updatedList);
    // handlerList(updatedList); // commented out because it was causing infinite loop in `WorkplanGroup` but left it here if in future issues will appear
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [direction]);

  const changeIncludingOfDirection = (id: number) => {
    if (id === allId) {
      let clearAllSelects = !!ids.find((item, _) => item === allId);
      if (clearAllSelects) {
        return setIds([]);
      }
      let addAllSelects = !!ids.find((item, _) => item !== allId);
      if (addAllSelects) {
        return setIds(fullListID);
      }
    }
    let removeDirection = !!ids.find((item, _) => item === id);
    if (id !== allId && removeDirection) {
      return setIds(ids.filter((item, i) => item !== id && item !== allId));
    }
    let idsNull = ids.length === 0;
    if (idsNull) {
      if (id === allId) {
        return setIds(fullListID);
      }
      if (id !== allId) {
        return setIds([id]);
      }
    }
    let addDirection = !!ids.find((item, _) => item !== id);
    if (id !== allId && addDirection) {
      if (ids.length === fullListID.length - 2) {
        return setIds([allId, id, ...ids]);
      } else {
        return setIds([id, ...ids]);
      }
    }
  };

  useEffect(() => {
    if (!!fullListID.length) {
      let inputAllSelects = !!ids.find((item, _) => item === allId);
      if (inputAllSelects) {
        setIds(fullListID);
        if (!title) {
          return setValueInput(defaultTitle ?? "Выбрать все");
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullListID]);

  useEffect(() => {
    if (ids.length === 0) {
      return setValueInput(defaultTitle ?? "Выбрать все");
    }

    if (!!ids.length) {
      let firstId = ids.sort((a, b) => (a > b ? 1 : a < b ? -1 : 0));
      let inputAllSelects = !!ids.find((item, _) => item === allId);
      if (inputAllSelects && !title) {
        return setValueInput(defaultTitle ?? "Выбрать все");
      }

      if (!inputAllSelects) {
        if (typeStyle === "input") {
          let updatedList = direction.filter(
            (item) => item.id !== allId && ids.includes(item.id)
          );
          !title &&
            setValueInput(
              updatedList.map((direction) => direction.title).join(", ")
            );
          return;
        }

        direction.find(
          (item, _) =>
            item.id === firstId[0] && !title && setValueInput(item.title)
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ids]);

  const handleSelectClose = () => {
    handlerChange && handlerChange(ids.join(","));
    handlerList?.(upadteProgram);
    setIsOpened(false);
  };

  return (
    <div
      className={clsx(style.container, {
        [style.container_big]: typeStyle !== "input",
      })}
    >
      {typeStyle === "input" && (
        <div className={style.selectedValue}>
          <span className={style.selectedValue__text}>{valueInput}</span>
          <ArrowDropDownGraySVG
            className={clsx(style.selectedValue__icon, {
              [style.selectedValue__icon_rotated]: isOpened,
            })}
          />
        </div>
      )}
      <FormControl variant="filled">
        <Select
          open={isOpened}
          onOpen={() => setIsOpened(true)}
          onClose={handleSelectClose}
          multiple
          value={[valueInput]}
          renderValue={(selected) => selected}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            PaperProps: {
              style: {
                maxHeight: 48 * 4.5 + 8,
                maxWidth: 600,
                minWidth: 300,
                left: "50%",
              },
            },
          }}
          className={clsx(
            style.box_input,
            { [style.box_input_big]: typeStyle !== "input" },
            {
              [style.box_input_smile]: typeStyle === "input",
            }
          )}
        >
          {direction.map((item: { id: number; title: string }) => (
            <div
              key={item.id}
              className={style.checked_list}
              onClick={(e) => changeIncludingOfDirection(item.id)}
            >
              <Checkbox
                checked={!!ids.find((id, _) => item.id === id)}
                name="select_all"
                color="primary"
                icon={<CheckboxSVG />}
                checkedIcon={<CheckboxCheckedSVG />}
              />
              {item.title}
            </div>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
