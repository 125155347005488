import { FC, useState } from "react";
import clsx from "clsx";
import Select from "react-select";
import moment, { Moment } from "moment";
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ruRU } from '@mui/x-date-pickers/locales';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { CompanyDiskUsage } from "@components/CompanyDiskUsage";
import { CompanyT, TariffEnum } from "@interfaces/company/company";
import { SimpleOptionT } from "@interfaces/company/employees";
import CompanyService from "@services/company-service";
import {
  ColorfulButton,
  selectColourStylesTarrifChangeButton,
} from "@components/common";
import { useModal } from "@modals/index";
import styles from "@scss/components/tables/tariff-change-table.module.scss";
import tableStyle from "@scss/components/tables/base-table.module.scss";

function isTariffExpired(
  tariff: TariffEnum,
  tariffExpires: number | string
): boolean {
  tariffExpires = new Date(tariffExpires).getTime();

  const THREE_DAYS = 259200000;
  const SEVEN_DAYS = 604800000;

  switch (tariff) {
    case TariffEnum.Demo:
      return tariffExpires - Date.now() < THREE_DAYS;
    case TariffEnum.Basic:
      return tariffExpires - Date.now() < SEVEN_DAYS;
    case TariffEnum.Extended:
      return tariffExpires - Date.now() < SEVEN_DAYS;
    case TariffEnum.Maximum:
      return tariffExpires - Date.now() < SEVEN_DAYS;
    case TariffEnum.Temp:
      return true;
    case TariffEnum.None:
      return false;
    default:
      return false;
  }
}

export type TariffCompanyPropsT = {
  company: CompanyT;
  updateCompanyLocal: (
    companyId: number,
    tariff: number,
    tariffStarts: number,
    tariffExpires: number
  ) => void;
  onUpdateDeadline: (companyId: number, date: Moment) => void;
  onAddAdditionalSpace: (companyId: number, size: number) => void;
};

export const TariffCompany: FC<TariffCompanyPropsT> = ({
  company,
  updateCompanyLocal,
  onUpdateDeadline,
  onAddAdditionalSpace,
}) => {
  const modal = useModal();

  const [deadline, setDeadline] = useState<Moment | null>(
    moment(company.tariffExpires)
  );
  const [isDeadlineError, setIsDeadlineError] = useState(false);

  const doesTariffExpire = isTariffExpired(
    company.tariff,
    company.tariffExpires
  );

  const handleChangeDeadline = (date: any) => {
    setDeadline(date ?? null);
  };

  const handleUpdateDeadline = () => {
    if (!deadline?.isValid() || !deadline) {
      return setIsDeadlineError(true);
    }

    onUpdateDeadline(company.id, deadline);
  };

  const handleAddAdditionalSpace = () => {
    modal.open("AddAdditionalSpace", {
      modalData: {
        text: ``,
        companyId: company.id,
      },
      btnText: "Добавить",
      onConfirm: (size: number) => {
        onAddAdditionalSpace(company.id, size);
      },
    });
  };

  return (
    <tr className={clsx({ [tableStyle.expires]: doesTariffExpire })}>
      <td>
        <span>{company.name}</span>
      </td>
      <td>
        <span>{company.inn}</span>
      </td>
      <td>
        <TariffChangeButton
          companyId={company.id}
          selected={company.tariff}
          options={[
            { label: "Демо дни", value: 5 },
            { label: "Базовый", value: 1 },
            { label: "Расширенный", value: 2 },
            { label: "Максимальный", value: 3 },
            { label: "Временный", value: 4 },
            { label: "Без тарифа", value: 0 },
          ]}
          updateCompanyLocal={updateCompanyLocal}
        />
      </td>
      <td>
        {company.tariff ? (
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale={"ru"}
            localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
          >
            <DatePicker
              value={deadline}
              onChange={handleChangeDeadline}
              onClose={handleUpdateDeadline}
              className={clsx(styles.pickers_input, {
                [styles.dateError]: isDeadlineError,
              })}
              format="DD.MM.YYYY"
              autoFocus={false}
              slotProps={{
                actionBar: {actions: ['cancel', 'accept']},
                textField: {
                  variant: 'filled',
                  error: isDeadlineError
                },
              }}
              localeText={{
                toolbarTitle: '',
                cancelButtonLabel: 'Отменить',
                okButtonLabel: 'ОК',
              }}
            />
          </LocalizationProvider>
        ) : null}
      </td>
      <td>
        {[1, 2, 3, 4, 5].includes(company.tariff) ? (
          <ColorfulButton
            text="Добавить объем"
            onClick={handleAddAdditionalSpace}
          />
        ) : null}
      </td>
      <td>
        <CompanyDiskUsage company={company} />
      </td>
    </tr>
  );
};

const TariffChangeButton: React.FC<{
  options: SimpleOptionT[];
  selected: number;
  companyId: number;
  updateCompanyLocal: Function;
}> = ({ options, selected, companyId, updateCompanyLocal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    options.find((option) => option.value === selected)
  );

  const onOptionChange = (optionV: any) => {
    setIsLoading(true);
    const chosedTariff = optionV.value;
    CompanyService.changeTariff(
      companyId,
      { tariffId: chosedTariff },
      async (err, res) => {
        if (err || !res) {
          setIsLoading(false);
        } else if (res) {
          const NOW = Date.now();
          const tariffExpires =
            chosedTariff === 4
              ? NOW + 172800000
              : chosedTariff === 0
                ? 0
                : chosedTariff === 5
                  ? NOW + 1296000000
                  : NOW + 2592000000;
          updateCompanyLocal(companyId, chosedTariff, NOW, tariffExpires);
          setSelectedOption(
            options.find((option) => option.value === chosedTariff)
          );
          setIsLoading(false);
        }
      }
    );
  };

  return (
    <>
      <Select
        className={styles.select}
        isLoading={isLoading}
        defaultValue={selectedOption}
        options={options}
        placeholder="Выберите тариф"
        onChange={(value: any) => onOptionChange(value)}
        menuPortalTarget={document.body}
        menuPlacement="auto"
        closeMenuOnScroll={(e: { target: HTMLDivElement; }) => {
          const target = e.target as HTMLDivElement;
          return target.classList.contains("content-section");
        }}
        theme={(theme: any) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: "#eaeaea",
            primary: "#eaeaea",
          },
        })}
        styles={selectColourStylesTarrifChangeButton({
          backgroundColor:
            selectedOption?.value === 1
              ? "#59D1F8"
              : selectedOption?.value === 2
                ? "#00B856"
                : selectedOption?.value === 3
                  ? "#8250BE"
                  : selectedOption?.value === 4
                    ? "#E57373"
                    : selectedOption?.value === 5
                      ? "#ffaa2b"
                      : "#8D96A5",
        })}
      />
    </>
  );
};
